import React from 'react'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'
import Toggle from 'components/Toggle'

type Props = {
  checked?: boolean,
  labelText: string,
  updateCheckedValue: (value: any) => void,
  callUpdateNotification: () => void,
}
const NotificationToggle: React.FunctionComponent<Props> = ({ ...Props }) => {
  const { checked, labelText, updateCheckedValue, callUpdateNotification } = Props

  const onToggle = (val: boolean) => {
    updateCheckedValue(val)
    callUpdateNotification()
  }
  return (
    <FlexContainer alignItem="center" justifyContent="space-between">
      <ToggleLabel>{labelText}</ToggleLabel>
      <Toggle value={checked} onToggle={onToggle} />
    </FlexContainer>
  )
}

const ToggleLabel = styled.div`
  margin: 6px 0px;
  color: #001246;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
`

export default NotificationToggle
