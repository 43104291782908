import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { FlexContainer } from 'components/flex'
import { ManageButton } from 'components/manage'
import { MyProfileButton, UserHaloIcon } from 'components/myProfile'
import { ConnectedPanel } from 'components/ConnectedPanel'
import { BannerButton } from 'components/bannerButton'
import AutoRenewWidget from 'components/SubscriptionBox/AutoRenewWidget'
import GracePeriodWidget from 'components/SubscriptionBox/GracePeriodWidget'
import { P2PPanel } from 'components/P2PPanel'
import SubscriptionBox from 'components/SubscriptionBox'
import { ReactComponent as IconComment } from 'assets/svg/icon_comment.svg'
import { ReactComponent as IconNewsletters } from 'assets/svg/icon_newsletters.svg'
import { ReactComponent as IconBookmark } from 'assets/svg/icon_bookmark.svg'
import { isMobile } from 'react-device-detect'
import { ReactComponent as Edit } from 'assets/svg/icon_edit.svg'
import { hideOnMedia, media, showOnMedia } from 'utils/style'
import {
  HeaderLabelMap,
  DataLayerEventCategory,
  DataLayerEventAction,
  DataLayerGtmCustomEventName,
} from 'config/constants'
import { AppState } from 'states/reducers'
import { onSignOutClick } from 'utils/common'
import { CustomModal } from 'components/modal'
import { triggerGA4GtmEvent, triggerGtmEvent } from 'utils/tracking'
import { useRosetta } from 'components/Piano/hooks'

const UserProfileContainer: React.FunctionComponent = () => {
  const isPianoSupported = useSelector((state: AppState) => state.application.isPianoSupported)
  const destination = useSelector((state: AppState) => state.application.redirectDestinationUrl)
  const subscriptionPackageName = useSelector((state: AppState) => state.userInfo.subscriptionPackageName)
  const isScmpUser = useSelector((state: AppState) => state.userInfo.isScmpUser)
  const isWinback = useSelector((state: AppState) => state.subscriptionStatus.scmpWinback.isEligible)
  const isSubscriber = useSelector((state: AppState) => state.subscriptionStatus.isSubscriber)
  const isShowRosetta = useSelector((state: AppState) => state.userInfo.isShowRosetta)
  const [showModal, isShowModal] = useState<boolean>(false)
  const modalTitle = 'Are you sure you want to <strong>log out</strong>?'

  const { hasActiveEpaperConversions } = useRosetta()

  const {
    username,
    firstname,
    lastname,
    email,
  } = useSelector((state: AppState) => state.userInfo)
  const {
    isAutoRenew,
    isAutoRenewable,
    gracePeriod,
  } = useSelector((state: AppState) => state.subscriptionStatus)
  const displaySubscription = () => {
    return (isSubscriber && isShowRosetta) || (isPianoSupported && isShowRosetta)
  }

  const ShowHideHandler = () => {
    isShowModal(!showModal)
    triggerGtmEvent(
      DataLayerEventAction.ACCOUNT_LOGOUT_CANCEL,
      DataLayerEventCategory.MY_ACCOUNT,
      DataLayerGtmCustomEventName.TRACKING,
      '',
    )
  }

  const logOutButtonClicked = () => {
    isShowModal(true)
    triggerGtmEvent(
      DataLayerEventAction.ACCOUNT_LOGOUT_CLICK,
      DataLayerEventCategory.MY_ACCOUNT,
      DataLayerGtmCustomEventName.TRACKING,
      '',
    )
  }

  const showP2PPanel = false // isSubscripted

  const clickBannerHandler = () => {
    if (isMobile) {
      triggerGtmEvent(
        DataLayerEventAction.APP_DOWNLOAD_CLICK,
        DataLayerEventCategory.MY_ACCOUNT,
        DataLayerGtmCustomEventName.TRACKING,
        '',
      )
      window.location.href = 'https://scmp.onelink.me/3586748601/b6e54b1'
      return
    }
    triggerGtmEvent(
      DataLayerEventAction.EPAPER,
      DataLayerEventCategory.MY_ACCOUNT,
      DataLayerGtmCustomEventName.TRACKING,
      '',
    )
    triggerGA4GtmEvent({
      category: 'myaccount',
      action: 'click',
      customized_parameters: {
        category: 'epaper',
      },
    })
    window.location.href = 'http://scmp.com/epaper'
  }

  return (
    <PageContainer id='user-profile-container' flexDirection='column' alignItem='stretch' justifyContent='flex-start' wrap='no-wrap'>
      <UserProfileTop isPianoNotSupported={!isPianoSupported} flexDirection='row' alignItem='center'>
        <UserDetails alignItem='flex-start'>
          <UserIconContainer hideOn='mobile'>
            <UserHaloIcon size='large' />
          </UserIconContainer>
          <UserInfo flexDirection='column' alignItem='stretch' justifyContent='flex-start' wrap='no-wrap'>
            <Greeting>{isWinback ? 'Welcome back,' : 'Good day,'}</Greeting>
            <UserTitle>{firstname && lastname ? `${firstname} ${lastname}` : username}</UserTitle>
            <UserIconContainer showOn='mobile'>
              <UserHaloIcon size='large'/>
            </UserIconContainer>
            <EmailContainer>
              <UserEmail>{email}</UserEmail>
              { !isScmpUser && <Link to='/settings/change-email-address'><IconEdit></IconEdit></Link> }
            </EmailContainer>
          </UserInfo>
        </UserDetails>
      </UserProfileTop>
      { displaySubscription() && <SubscriptionBoxContainer />}
      {(!isAutoRenew && isAutoRenewable && subscriptionPackageName !== 'Access Granted' && subscriptionPackageName !== '' && isPianoSupported) || gracePeriod.isActive
        ? gracePeriod.isActive ? <GracePeriodWidget /> : <AutoRenewWidget />
        : <>
          <BannerButtonContainer hasActiveEpaperConversions={hasActiveEpaperConversions} onClick={() => clickBannerHandler()}>
            <BannerButton hasActiveEpaperConversions={hasActiveEpaperConversions}></BannerButton>
          </BannerButtonContainer>
        </>
      }
      <ManageWidgetsRowContainer>
        <ManageWidgetsRow flexDirection='row' justifyContent='space-between' alignItem='space-between' wrap='wrap'>
          <ManageButtonGroup >
            <StyledLink to='/activities/comments'>
              <ManageBannerButtonContainer flexDirection='column' justifyContent='center'>
                <IconComment />
                <StyledMyProfileButton buttonTitle={HeaderLabelMap.COMMENT} />
              </ManageBannerButtonContainer>
            </StyledLink>
          </ManageButtonGroup>
          <ManageButtonGroup >
            <StyledLink to='/manage/bookmark'>
              <ManageBannerButtonContainer flexDirection='column' justifyContent='center'>
                <StyledIconBookmark />
                <StyledMyProfileButton buttonTitle={HeaderLabelMap.BOOKMARKS} />
              </ManageBannerButtonContainer>
            </StyledLink>
          </ManageButtonGroup>
        </ManageWidgetsRow>
        <ManageWidgetsRow flexDirection='row' justifyContent='space-between' alignItem='space-between' wrap='wrap'>
          <ManageButtonGroup>
            <StyledLink to='/manage/newsletter'>
              <ManageBannerButtonContainer flexDirection='column' justifyContent='center'>
                <IconNewsletters />
                <StyledMyProfileButton buttonTitle='Newsletters' />
              </ManageBannerButtonContainer>
            </StyledLink>
          </ManageButtonGroup>
          <ManageButtonGroup alignItem='center'>
            <ManageBannerButtonContainer flexDirection='column' justifyContent='center'>
              <ConnectedPanel className='my-profile'/>
            </ManageBannerButtonContainer>
          </ManageButtonGroup>
        </ManageWidgetsRow>
      </ManageWidgetsRowContainer>
      { showP2PPanel &&
        <P2PPanel />
      }
      <div id="banner-container"></div>
      <ManagePanel>
        <StyledLink to='/manage/following'>
          <ManagePanelContainer background='#ffffff' showOn='mobile'>
            <ManageButton noBorder={true} buttonTitle='Following' fontColor={'#000'}></ManageButton>
          </ManagePanelContainer>
        </StyledLink>
        <StyledLink to='/manage/notifications'>
          <ManagePanelContainer background='#ffffff' showOn='mobile'>
            <ManageButton buttonTitle='Notifications' fontColor={'#000'}></ManageButton>
          </ManagePanelContainer>
        </StyledLink>
        <StyledLink to='/settings/profile'>
          <ManagePanelContainer background='#ffffff' showOn='mobile'>
            <ManageButton buttonTitle='Edit Profile' fontColor={'#000'} ></ManageButton>
          </ManagePanelContainer>
        </StyledLink>
        <ManagePanelContainer onClick={() => logOutButtonClicked()} background='#ffffff' showOn='mobile'>
          <ManageButton buttonTitle='Log Out' fontColor={'#000'}>
          </ManageButton>
        </ManagePanelContainer>
      </ManagePanel>
      { showModal
        ? <CustomModal title={modalTitle} customButton={true} onShow={ShowHideHandler}>
            <ModalTopButton onClick={ShowHideHandler}>Cancel</ModalTopButton>
            <ModalBottomButton onClick={() => onSignOutClick(destination)}>Log Out</ModalBottomButton>
          </CustomModal>
        : null}
    </PageContainer>
  )
}

interface CommonStyle {
  background?: string
  showOn?: string
  hideOn?: string
  noMargin?: boolean
  isPianoNotSupported?: boolean
  hasActiveEpaperConversions?: boolean
}

const PageContainer = styled(FlexContainer)`
  margin: 0 15px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  max-width: 416px;
  ${media.tabletUp`
    max-width: 100%;
    margin: 0;
  `}
`

const SubscriptionBoxContainer = styled(SubscriptionBox)`
`

const BannerButtonContainer = styled(FlexContainer)<CommonStyle>`
  cursor: pointer;
  width: 100%;
  ${media.tabletUp`
    display: none;
  `}
  ${({ hasActiveEpaperConversions }) => hasActiveEpaperConversions && `
    ${media.tabletUp`
      display: flex;
    `}
  `}
`

const UserProfileTop = styled(FlexContainer)<CommonStyle>`
  margin: 24px 0 0;
  padding-bottom: 24px;
  ${media.tabletUp`
    margin: 0;
    padding: 0;
  `}
`

const UserIconContainer = styled.div<CommonStyle>`
  margin-top: 12px;
  ${media.tabletUp`
    margin: 0;
  `}
  ${({ hideOn }) => hideOn ? hideOnMedia([hideOn]) : ''}
  ${({ showOn }) => showOn ? showOnMedia([showOn]) : ''}
`

const UserDetails = styled(FlexContainer)`
  width: 100%;
`
const UserInfo = styled(FlexContainer)`
  margin-left: 16px;
  align-items: center;
  width: 100%;
  ${media.tabletUp`
    align-items: flex-start;
    width: auto;
  `}
`

const Greeting = styled.div`
  color: #000000;
  font-family: "Roboto Condensed";
  letter-spacing: 0;
  font-size: 16px;
  line-height: 29px;
  ${media.tabletUp`
    font-size: 21px;
    line-height: 24px;
  `}
`

const UserTitle = styled(FlexContainer)`
  color: #000000;
  font-family: "Roboto Condensed";
  font-weight: bold;
  letter-spacing: 0;
  height: 24px;
  font-size: 21px;
  line-height: 24px;
  margin-top: 4px;
  ${media.tabletUp`
    height: 38px;
    font-size: 32px;
    line-height: 38px;
    margin-top: 8px;
  `}
`

const EmailContainer = styled(FlexContainer)`
  margin-top: 16px;
`

const UserEmail = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
`

const StyledLink = styled(Link)`
  width: 100%;
`

const StyledIconBookmark = styled(IconBookmark)`
  width: 22px;
  height: 24px;
  fill: #4585FF;
`

const ManagePanel = styled.div`
  margin-top: 12px;
  ${media.tabletUp`
    margin-top: 30px;
  `}
`

const ManagePanelContainer = styled.div<CommonStyle>`
  background-color: ${({ background }) => background || '#fff'};
  margin: 0 -20px;
  padding-bottom: 18px;
  ${({ showOn }) => showOn ? showOnMedia([showOn]) : ''}
`

const StyledMyProfileButton = styled(MyProfileButton)`
  margin-top: 13px;
`

const ManageButtonGroup = styled(FlexContainer)`
  border: 1px solid #D7D7D7;
  border-radius: 2px;
  width: calc(50% - 8px);
  height: 89px;
  &:last-child{
    margin-left: 8px;
  }
  ${media.tabletUp`
      max-width: 328px;
      width: 100%;
      flex: 1 1 0;
      margin-left: 0;
      &:not(:first-child){
        margin-top: 0;
        flex: 1 1 0;
      }
      &:last-child{
        margin-left: 0;
      }
  `}
`

const ManageBannerButtonContainer = styled(FlexContainer)`
  padding: 16px;
  width: auto;
`

const ManageWidgetsRowContainer = styled(FlexContainer)`
`

const ManageWidgetsRow = styled(FlexContainer)`
  margin-top: 12px;
  width: 100%;

  &:first-of-type {
    margin-top: 0;

    ${media.tabletUp`
      margin-top: 24px;
    `}
  }

  ${media.tabletUp`
    margin-top: 24px;
  `}
`

const IconEdit = styled(Edit)`
  height: 19px;
  width: 19px;
  margin-left: 4px;
`

const ModalTopButton = styled.div`
  padding: 12px 0;
  width: 100%;
  border-radius: 2px;
  background-color: #FFCA05;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
`
const ModalBottomButton = styled.div`
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  color: #4585FF;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
`

export default UserProfileContainer
