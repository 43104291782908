import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import IconBackArrow from 'assets/svg/back_arrow.svg'

type Props = {
    labelText: string,
    goBackLink: string,
  }

const GoBackButton: React.FunctionComponent<Props> = ({ labelText, goBackLink }) => {
  return (
    <GoBackButtonContainer to={goBackLink}>
      <BackArrow src={IconBackArrow}/>
      <LabelText>{labelText}</LabelText>
    </GoBackButtonContainer>
  )
}

const GoBackButtonContainer = styled(({ ...rest }) => <Link {...rest} />)`
  width: 100px;
`

const BackArrow = styled.img`
  display:inline-block;
  margin-right: 11px;
`

const LabelText = styled.div`
  display:inline-block;
  vertical-align: top;
  color: #4585FF;
  font-family: "Roboto Condensed";
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
`

export default GoBackButton
