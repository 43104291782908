import { ZENDESK_CHECK_INTERVAL, ZENDESK_CHECK_TIMEOUT } from 'config/app.config'
import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'
import * as appActions from 'states/actions/application'
import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

const Zendesk = () => {
  const dispatch = useDispatch()
  const {
    isZendeskReady,
    isZendeskVisible,
    firstname,
    lastname,
    username,
    email,
  } = useSelector(({
    application: {
      isZendeskReady,
      isZendeskVisible,
    },
    userInfo: {
      firstname,
      lastname,
      username,
      email,
    },
  }) => ({
    isZendeskReady,
    isZendeskVisible,
    firstname,
    lastname,
    username,
    email,
  }), shallowEqual)

  const addZendesk = () => {
    if (!document.getElementById('ze-snippet')) {
      const src = 'https://static.zdassets.com/ekr/snippet.js?key=db2bcb47-c329-4a6d-b088-123b4ab6884b'
      const a = document.createElement('script')
      a.setAttribute('id', 'ze-snippet')
      a.type = 'text/javascript'
      a.async = true
      a.src = src
      if (document && document.head) {
        zendeskSettings()
        document.head.appendChild(a)
      }
    }
  }

  const zendeskSettings = () => {
    const a = document.createElement('script')
    a.text = `
      window.zESettings = {
        analytics: false
      }
    `
    if (document && document.body) {
      document.body.appendChild(a)
    }
  }

  // update
  const zendeskReady = () => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (_isFunction(window.zE) && _isFunction(window.zE.show) && _isFunction(window.zE.hide)) {
          // eslint-disable-next-line no-use-before-define
          clearTimeout(timeout)
          clearInterval(interval)
          return resolve()
        }
      }, ZENDESK_CHECK_INTERVAL)
      const timeout = setTimeout(() => {
        // eslint-disable-next-line no-use-before-define
        clearTimeout(timeout)
        clearInterval(interval)
        return reject(new Error('Load Zendesk timeout.'))
      }, ZENDESK_CHECK_TIMEOUT)
    })
  }

  useEffect(() => {
    addZendesk()
    zendeskReady().then(() => {
      dispatch(appActions.updateZendeskStatus(true))
    }).catch(error => {
      console.error(error.message)
    })
    return () => {}
  }, [])

  useEffect(() => {
    if (isZendeskReady) {
      if (isZendeskVisible) {
        window.zE.show()
      } else if (!isZendeskVisible) {
        window.zE.hide()
      }
      if (window.zE && document.getElementById('launcher') && !document.getElementsByClassName('scmp-ze').length) {
        document.getElementById('launcher').classList.add('scmp-ze')
        document.getElementById('launcher').contentDocument.head.insertAdjacentHTML('beforeend', '<style id="ze-style">.u-userLauncherColor:not([disabled]){box-shadow: inset 0 0 0 2px #fff4cd !important;}</style>')
        window.zE('webWidget:on', 'userEvent', function (_ref) {
          const category = _ref.category
          const action = _ref.action
          const label = _ref.properties

          window.dataLayer.push({
            event: 'tracking',
            tracking: {
              category: category,
              action: action,
              label: label,
            },
          })
        })
      }
    }
    return () => {}
  }, [isZendeskReady, isZendeskVisible])

  useEffect(() => {
    if (email && isZendeskReady && isZendeskVisible) {
      const firstlastname = `${firstname} ${lastname}`.trim()
      window.zE.identify({
        name: _isEmpty(firstlastname) ? username : firstlastname,
        email,
      })
    }
    return () => {}
  }, [email, firstname, lastname, username, isZendeskReady, isZendeskVisible])
  return null
}

export default Zendesk
