import axios from 'axios'
import moment from 'moment'
import { Cookies } from 'react-cookie'
// import { USER_COOKIE_DOMAIN } from 'config/app.config'

import { SCMP_COM_HOST, SCMP_COM_PROTOCOL } from 'config/app.config'
import {
  DataLayerEventAction,
  DataLayerEventCategory,
  DataLayerGtmCustomEventName,
} from 'config/constants'
import { isScmpDomains } from 'utils/common/domain'
import {
  hasMixed,
  hasNumber,
  hasSpecial,
} from 'utils/formValidation'
import { triggerGtmEvent } from 'utils/tracking'

/**
 * Check the password's strength and return a number as the score
 * @param {string} password the password to be tested
 * @returns {number} return the score of the password strength
 */
export function getPasswordStrength (password: string): number {
  let passwordStrength: number = 0

  if (hasNumber(password)) {
    passwordStrength++
  }
  if (hasMixed(password)) {
    passwordStrength++
  }
  if (hasSpecial(password)) {
    passwordStrength++
  }
  if (password.length >= 8) {
    passwordStrength++
  }
  return passwordStrength
}

/**
 * Output the pretty datetime for a timestamp
 * @param {Number} timestamp Unix epoch timestamp in millisecond
 * @return {String} the formatted datetime output
 */
export function getPrettyDatetime (timestamp: number | undefined, type: string = 'long') {
  if (!timestamp) return ''

  const current = Math.floor(Date.now() / 1000)
  const diffInSecond = Math.abs(current - (timestamp / 1000))
  const diffInMinute = Math.round(diffInSecond / 60)
  const diffInHour = Math.round(diffInSecond / 3600)
  // const diffInDay = Math.round(diffInSecond / 3600 / 24)
  if (diffInMinute < 60) {
    // Within 1 hour
    // Format: x minutes ago
    if (diffInMinute > 1) {
      return `${diffInMinute} minutes ago`
    }
    return `${diffInMinute} minute ago`
  } else if (diffInHour <= 6) {
    // Within 6 hours
    // Format: x hours ago
    if (diffInHour > 1) {
      return `${diffInHour} hours ago`
    }
    return `${diffInHour} hour ago`
  } else {
    // Other case:
    // Format: 14 May 2018 - 11:07am
    switch (type) {
      case 'short':
        return moment(timestamp, 'x').utcOffset(8).format('D MMM YYYY')
      default:
        return moment(timestamp, 'x').utcOffset(8).format('D MMM YYYY [-] h:mmA')
    }
  }
}

export function getPrettyDatetimeC3PO (timestamp: number | undefined, type: string = 'long') {
  if (!timestamp) return ''

  const current = Date.now()
  const diffInSecond = Math.abs(current - timestamp)
  const diffInMinute = Math.round(diffInSecond / 60)
  const diffInHour = Math.round(diffInSecond / 3600)
  // const diffInDay = Math.round(diffInSecond / 3600 / 24)
  if (diffInMinute < 60) {
    // Within 1 hour
    // Format: x minutes ago
    if (diffInMinute > 1) {
      return `${diffInMinute} minutes ago`
    }
    return `${diffInMinute} minute ago`
  } else if (diffInHour <= 6) {
    // Within 6 hours
    // Format: x hours ago
    if (diffInHour > 1) {
      return `${diffInHour} hours ago`
    }
    return `${diffInHour} hour ago`
  } else {
    // Other case:
    // Format: 14 May 2018 - 11:07am
    switch (type) {
      case 'short':
        return moment(timestamp, 'x').utcOffset(8).format('D MMM YYYY')
      default:
        return moment(timestamp, 'x').utcOffset(8).format('D MMM YYYY [-] h:mmA')
    }
  }
}

/**
 * Process a breadcrumb label using the app routes
 * @param {String} appUrl the path that used to transform into breadcrumb label
 * @return {String} the breadcrumb label
 */
export function getBreadcrumbLabel (appUrl: string): string {
  switch (appUrl) {
    case '/settings':
      return 'My Account'
    case '/settings/profile':
      return 'Edit Profile'
    case '/settings/change-password':
      return 'Change Password'
    case '/settings/change-email-address':
      return 'Change Email Address'
    case '/reset-password/success':
      return 'Reset Password'
    case '/activities':
      return 'My Account'
    case '/activities/comments':
      return 'My Comments'
    case '/manage':
      return 'My Account'
    case '/manage/newsletter':
      return 'Newsletters'
    case '/manage/get-connected':
      return 'Get Connected'
    case '/manage/subscription':
      return 'Subscription'
    case '/social/connect':
      return 'Connect your profile with a Facebook, Google or Apple account'
    case '/manage/devices':
      return 'Manage Devices'
    case '/manage/group-subscriber-benefits':
      return 'Group Subscriber Benefits'
    default:
      return appUrl
  }
}

/**
 * After an AJAX call with API server, check if scmp_user cookie is still here because backend would have deleted it due to auth tokens invalidated
 */
export function forceLogOutChecking (): void {
  const cookies = new Cookies()
  if (cookies.get('scmp_user') === 0) {
    window.location.replace('/')
  }
}

export async function onSignOutClick (destination: string) {
  triggerGtmEvent(
    DataLayerEventAction.ACCOUNT_LOGOUT,
    DataLayerEventCategory.MY_ACCOUNT,
    DataLayerGtmCustomEventName.TRACKING,
    '',
  )

  const redirectedURL = isScmpDomains(destination) ? destination : `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}`

  try {
    const response = await axios.post('/logout')
    // handle logout success case
    if (response.status === 200) {
      // const cookies = new Cookies()
      // set the timestamp is seconds
      // cookies.set('last_logout_timestamp', Math.round(Date.now() / 1000), { httpOnly: false, secure: false, domain: USER_COOKIE_DOMAIN })
      // logout piano instance
      const tp = (window as any).tp || []
      tp.push(['setExternalJWT', null])

      window.location.href = redirectedURL
    }
  } catch (e) {
    console.log('an error has been thrown', e)
    window.location.href = redirectedURL
  }
}

export const isValidUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url)
    return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:'
  } catch (_) {
    return false
  }
}
