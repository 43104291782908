import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Cookies } from 'react-cookie'
import { FlexContainer } from 'components/flex'
import { AppState } from 'states/reducers'
import { media } from 'utils/style'
import PageContainer from 'components/PageContainer'

import * as appAction from 'states/actions/application'
import * as scmpAccount from '@product/scmp-account-sdk'
import {
  DEFAULT_AUTH_CALLBACK_URL,
  DEFAULT_REGISTER_CALLBACK_URL,
  DEFAULT_DESTINATION_URL,
  GTM_CONTAINER_ID,
  RECAPTCHA_SITE_KEY,
  SITE_HOST,
  SITE_PROTOCOL,
  SETTING_ENV,
} from 'config/app.config'

type Props = {
  match: any,
  history: any,
  isIpAccess: any,
}

const DeviceCap: React.FunctionComponent<Props> = ({ ...Props }: Props) => {
  const callbackUrl = useSelector((state: AppState) => state.signin.callbackUrl)
  const destination = useSelector((state: AppState) => state.application.redirectDestinationUrl)
  const callbackRegisterUrl = useSelector((state: AppState) => state.registration.callbackUrl)
  const dispatch = useDispatch()
  const cookies = new Cookies()

  const [isDisplayFooter] = useState<boolean>(true)

  useEffect(() => {
    dispatch(appAction.updateFlowType('Login'))
  }, [dispatch])

  useEffect(() => {
    // init Scmp Account SDK
    // callback function, please define before event happen
    if (callbackUrl !== '' && callbackRegisterUrl !== '' && destination !== '') {
      scmpAccount.init({
        SCMP_ACCOUNT_HOST: `${SITE_HOST}`,
        SCMP_ACCOUNT_PROTOCOL: `${SITE_PROTOCOL}`,
        AUTH_CALLBACK_URL: callbackUrl || `${DEFAULT_AUTH_CALLBACK_URL}`,
        REGISTER_CALLBACK_URL: callbackRegisterUrl || `${DEFAULT_REGISTER_CALLBACK_URL}`,
        DESTINATION: destination || `${DEFAULT_DESTINATION_URL}`,
        GTM_CONTAINER_ID: `${GTM_CONTAINER_ID}`,
        RECAPTCHA_SITE_KEY: `${RECAPTCHA_SITE_KEY}`,
        RECAPTCHA_CONTAINER_ID: '',
        DEBUG_LOG: SETTING_ENV === 'staging',
      })

      const accountAppContext = (window as any).accountAppContext
      const retryToken = cookies.get('deviceCapRetryToken')
      cookies.remove('deviceCapRetryToken')

      const callbackState = accountAppContext.deviceCap.callbackState || {}
      console.log('\'login-container\', accountAppContext.deviceList, retryToken, accountAppContext.email, accountAppContext.loginMethod', 'login-container', accountAppContext.deviceList, retryToken, accountAppContext.email, accountAppContext.loginMethod)
      scmpAccount.initDeviceCapContainer(
        'login-container',
        accountAppContext.deviceCap.deviceList || [],
        retryToken, accountAppContext.deviceCap.email,
        accountAppContext.deviceCap.loginMethod,
        callbackState,
      )
    }
  }, [callbackUrl, callbackRegisterUrl, destination])

  return (
    <PageContainer id='login-page' isLogoLink footerText={true} isDisplayFooter={isDisplayFooter}>
      <SigninMethods className='login-section' flexDirection='column' justifyContent='flex-start' ipAccess={Props.isIpAccess}>
        <SigninForm id='login-container' className='login-section__signin-form signin-form' flexDirection='column'></SigninForm>
      </SigninMethods>
    </PageContainer>
  )
}

interface SigninMethodsProps {
  ipAccess?: boolean
}

const SigninMethods = styled(FlexContainer)<SigninMethodsProps>`
  max-width: 270px;
  min-width: 270px;
  flex: 1;
  .sa-login-widget-title {
    font-size: ${({ ipAccess }) => ipAccess ? '22px' : '24px'};
  }
`
const SigninForm = styled(FlexContainer)`
  margin-bottom: 0px;
  ${media.tabletUp`
    margin-bottom: 20px;
  `}
`

export default DeviceCap
