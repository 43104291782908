import { useCallback, useEffect, useRef, useState } from 'react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'
import { triggerExternalGtmEvent, triggerGA4GtmEvent, triggerUserPropertiesGtmEvent } from 'utils/tracking'
import * as appActions from 'states/actions/application'
import * as userInfoActions from 'states/actions/user-info'
import * as subscriptionStatusActions from 'states/actions/subscription-status'
import RosettaSDK, { ChannelCallbackParams, PublisherConversion } from '@product/rosetta-sdk'
import { getRosettaSdkConfig } from 'utils/piano'
import { AppState } from 'states/reducers'
import {
  ROSETTA_PROTOCOL,
  ROSETTA_TEMPLATE_BUILDER_HOST,
  ROSETTA_HOST,
  PIANO_AID,
  USER_COOKIE_DOMAIN,
  GEO_LOCATION_URL,
  GRAPHQL_CONTENTSERVICE_APIKEY,
  CORP_SUB_TIER_API_URI,
} from 'config/app.config'
import axios from 'axios'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'

const getConversionsData = async () => {
  const uid = Cookies.get('Drupal.visitor.cwp.uid')
  try {
    const response = await axios.post<{ conversions: PublisherConversion[] }>(
      `${ROSETTA_PROTOCOL}://${ROSETTA_HOST}/api/user/conversions`,
      { aid: PIANO_AID, uid },
    )
    if (response.data) {
      return response.data
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error response:', error.response)
    } else {
      console.error('error:', error)
    }
  }
}
const useCancelFlow = () => {
  const tagId = 'cancelflow-script'
  const getTemplateBuilderLink = () => {
    const urlPrefix = `${ROSETTA_PROTOCOL}://${ROSETTA_TEMPLATE_BUILDER_HOST}`
    const isProduction = urlPrefix.includes('assets.i-scmp.com')
    const timestamp = Math.round(Date.now().valueOf() / 1000 / 60 / 60)
    const jsFile = `cancelflow.js?ts=${timestamp}`
    if (isProduction) {
      return `${urlPrefix}/${jsFile}`
    }
    const searchParams = new URLSearchParams(window.location.search)
    const branchName = searchParams.get('branch')

    if (_isEmpty(branchName)) {
      return `${urlPrefix}/staging/${jsFile}`
    }

    if (branchName === 'localhost') {
      return `http://0.0.0.0:6016/dev/${jsFile}`
    }
    return `${urlPrefix}/${branchName}/${jsFile}`
  }
  const addCancelFlowScript = () => {
    const jsLink = getTemplateBuilderLink()
    if (!jsLink) {
      return
    }
    if (document.getElementById(tagId)) {
      return
    }

    const script = document.createElement('script')
    script.setAttribute('id', tagId)
    script.src = jsLink
    script.defer = true
    document.head.appendChild(script)
  }
  const removeCancelFlowScript = () => {
    const element = document.getElementById(tagId)
    if (element && element.remove) {
      element.remove()
    }
  }
  return {
    addCancelFlowScript,
    removeCancelFlowScript,
  }
}

const PianoScript = ({ updateUserRoleState, updateUserRoleStateOfGA4 }) => {
  const [isRosettaSDKInit, setIsRosettaSDKInit] = useState(false)
  const isPianoReady = useSelector((state: AppState) => state.application.isPianoReady)
  const dispatch = useDispatch()
  const isSignedIn = useSelector((state: AppState) => state.userInfo.isSignedIn)
  const subscriptionStatus = useSelector((state: AppState) => state.subscriptionStatus)
  const pendingTemplateRequestsRef = useRef<{
    pendingTemplateRequests: any[]
    isRosettaSDKReady: boolean
  }>({
    pendingTemplateRequests: [],
    isRosettaSDKReady: false,
  })
  const currentPendingTemplateRequestsRef = pendingTemplateRequestsRef.current
  // handle the pending requests when sdk is ready
  useEffect(() => {
    if (!isRosettaSDKInit) return
    currentPendingTemplateRequestsRef.isRosettaSDKReady = true
    // listener's callback fn ,handleTemplateRequest() , capture the old value of isRosettaSDKInit when init.
    // using currentPendingTemplateRequestsRef store updated value as handleTemplateRequest() can retrieve.
    currentPendingTemplateRequestsRef.pendingTemplateRequests.forEach(executeTemplateRequest)
    currentPendingTemplateRequestsRef.pendingTemplateRequests = []
  }, [isRosettaSDKInit])

  const handleTemplateRequest = (request: ChannelCallbackParams) => {
    if (!currentPendingTemplateRequestsRef.isRosettaSDKReady) {
      currentPendingTemplateRequestsRef.pendingTemplateRequests.push(request)
    } else {
      executeTemplateRequest(request)
    }
  }

  const executeTemplateRequest = (request: ChannelCallbackParams) => {
    const requestType = _get(request, 'message.requestType')
    const requestData = _get(request, 'message.requestData')
    let responseMessage: any
    if (!_isEmpty(requestType)) {
      // include requestType in responseMessage
      responseMessage = { requestType }
      switch (requestType) {
        case 'context/subscriberPhase':
          responseMessage.responseData = RosettaSDK.rulesEngine.getOutcome('tracking.ga4.subscriberPhase')
          break
        case 'appConfig/rosetta':
          responseMessage.responseData = RosettaSDK.getRosettaAppConfig()
          break
        case 'state/subscriptionStatus':
          responseMessage.responseData = subscriptionStatus
          break
        case 'rule/facts': {
          const factName = _get(requestData, 'name', '')
          if (factName === '*') {
            responseMessage.responseData = RosettaSDK.rulesEngine.getAllFacts()
          } else {
            responseMessage.responseData = RosettaSDK.rulesEngine.getFact(factName)
          }
          break
        }
        case 'rule/outcome': {
          const outcomeName = _get(requestData, 'name', '')
          if (outcomeName === '*') {
            responseMessage.responseData = RosettaSDK.rulesEngine.getAllOutcome()
          } else {
            responseMessage.responseData = RosettaSDK.rulesEngine.getOutcome(outcomeName)
          }
          break
        }
        case 'action/trackGA4': {
          (async () => {
            try {
              const baseParameters = RosettaSDK.rulesEngine.getOutcome('tracking.ga4.baseParameters')
              if (requestData?.customized_parameters?.transaction_id === '') {
                const conversionsData = await getConversionsData()
                requestData.customized_parameters.transaction_id =
                  conversionsData?.conversions?.[0]?.user_payment?.external_transaction_id
              }
              triggerGA4GtmEvent({
                ...requestData,
                customized_parameters: {
                  ...baseParameters,
                  ...requestData?.customized_parameters,
                },
              })
            } catch (error) {
              console.error('Error in action/trackGA4:', error)
            }
          })()
          break
        }
        default:
          responseMessage.responseData = null
          responseMessage.error = true
          responseMessage.errorMessage = 'unknown requestType'
          break
      }
      // re-use the `e` object, it has properties required by sendMessage() (type, source etc.)
      request.message = responseMessage
      RosettaSDK.sendMessage('pwa/response-from-app', request)
    }
  }

  const getTpCustomVariables = () => {
    if (typeof window !== 'undefined') {
      return _get(window, 'tp.customVariables', {})
    } else {
      return {}
    }
  }
  const onExperienceExecute = useCallback(async () => {
    const tp = window.tp || []
    const hasIpAccess = _get(tp, 'customVariables.ip_access_user')
    if (hasIpAccess) {
      const clientName = _get(tp, 'customVariables.ip_access_client')
      if (clientName) {
        /**
         * note, since we don't have YP IP whitelisting users yet
         * and it requires piano setup for YP IP whitelisting
         * we can decide how to separate SCMP and YP IP whitelisting users until then
         * for now I'm using hard-coded product value for IP whitelisting
         */
        const clientIsEZproxy = _get(tp, 'customVariables.ip_ezproxy_user')

        triggerExternalGtmEvent({
          subscribe: {
            ipClientName: clientName,
          },
        })

        triggerUserPropertiesGtmEvent({
          product: 'SCMP - IP whitelisting',
          ipClientIsEZproxy: clientIsEZproxy ? 'EZproxy' : 'IP whitelisting',
        })
      }
    }

    // execute rulesEngine
    try {
      const engine = RosettaSDK.rulesEngine
      // note, isIPAccessUser fact has changed to hasIpAccess
      engine.addFact('hasIpAccess', hasIpAccess)
      engine.setReady('pianoExperience')
      await engine.run()

      // ga4 user properties
      const { ipAccess, siteLicenseContractId } = engine.getOutcome('tracking.ga4')
      if (siteLicenseContractId) {
        triggerUserPropertiesGtmEvent({ ga4SiteLicensing: siteLicenseContractId })
      }
      const ipAccessClientId = _get(ipAccess, 'id')
      const ipAccessClientType = _get(ipAccess, 'type')
      if (ipAccessClientId && ipAccessClientType) {
        if (ipAccessClientType === 'ezproxy') {
          triggerUserPropertiesGtmEvent({ ga4Ezproxy: ipAccessClientId })
        } else {
          triggerUserPropertiesGtmEvent({ ga4IpWhitelisting: ipAccessClientId })
        }
      }
    } catch (e) {
      console.error('sdk error', e)
    }
  }, [])

  const commChannelCallback = (e: any) => {
    if (_isEmpty(_get(e, 'message'))) {
      return
    }

    const requestType = _get(e, 'message.requestType')
    let responseMessage: any
    if (!_isEmpty(requestType)) {
      // include requestType in responseMessage
      responseMessage = { requestType }

      // SEE https://project.scmp.tech/product/web/npm/rosetta-sdk/-/wikis/Event-Channels
      switch (requestType) {
        case 'tp/customVariables':
          responseMessage.responseData = getTpCustomVariables()
          break
        case 'context/subscriberPhase':
        case 'appConfig/rosetta':
        case 'state/subscriptionStatus':
        case 'action/trackGA4':
        case 'rule/facts':
        case 'rule/outcome': {
          // since the rosetta sdk won't be ready when this callback formed. We need to trigger the useEffect to pass the right data back
          return handleTemplateRequest(e)
        }
        default:
          responseMessage.responseData = null
          responseMessage.error = true
          responseMessage.errorMessage = 'unknown requestType'
          break
      }

      // re-use the `e` object, it has properties required by sendMessage() (type, source etc.)
      e.message = responseMessage
      RosettaSDK.sendMessage('pwa/response-from-app', e)
    }
  }

  useEffect(() => {
    const setupRosetta = async () => {
      try {
        // set up rosetta sdk config
        const pageUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
        RosettaSDK.setConfig(getRosettaSdkConfig()).setPageUrl(pageUrl).setToken()

        // register rosetta sdk service
        RosettaSDK.registerService('geo', {
          apiUri: GEO_LOCATION_URL,
          apiKey: GRAPHQL_CONTENTSERVICE_APIKEY,
        })
        RosettaSDK.registerService('tierInfo', {
          apiUri: `${CORP_SUB_TIER_API_URI}/tier`,
          apiKey: GRAPHQL_CONTENTSERVICE_APIKEY,
        })
        RosettaSDK.registerService('pianoUserInfo', {
          apiUri: `https://${ROSETTA_HOST}/api/user`,
          aid: PIANO_AID,
        })
        RosettaSDK.registerService('pianoPublisherConversions', {
          apiUri: `https://${ROSETTA_HOST}/api/user/conversions`,
          aid: PIANO_AID,
        })

        // register rosetta event handlers
        RosettaSDK.on('experienceExecute', onExperienceExecute)
        RosettaSDK.registerListener('pwa/request-from-template')
        RosettaSDK.on('COMM_CHANNEL', commChannelCallback)

        // update application state or global state
        const isPianoSupport = !_get(window, 'tp.customVariables.paywall_not_supported', false)
        dispatch(appActions.updatePianoSupportedStatus(isPianoSupport))
        dispatch(appActions.updatePianoStatus(true))

        // call rosetta sdk init and initRules
        if (!isPianoReady) { return }
        await RosettaSDK.init()
        await RosettaSDK.initRules()

        // call rosetta sdk other service/modules
        RosettaSDK.execute()
        setIsRosettaSDKInit(true)

        const engine = RosettaSDK.rulesEngine

        const { userRole, subscriberStatus, ga4 } = engine.getOutcome('tracking')

        if (subscriberStatus) {
          Cookies.set('scmp_subscriber', subscriberStatus, {
            expires: dayjs().add(1, 'day').toDate(),
            domain: USER_COOKIE_DOMAIN,
          })
        }

        if (!_isEmpty(userRole)) {
          updateUserRoleState(userRole)
        }

        if (!_isEmpty(ga4) && !_isEmpty(ga4.userRole)) {
          updateUserRoleStateOfGA4(ga4.userRole)
        }

        const isShowRosetta = engine.getOutcome('guest.supportPiano')
        if (isShowRosetta === false) {
          dispatch(userInfoActions.updateUserIsShowRosetta(false))
        }

        if (isSignedIn) {
          const subscriptionStatusData = engine.getOutcome('user')
          if (!_isEmpty(subscriptionStatusData)) {
            subscriptionStatusData.isAutoRenew = subscriptionStatusData.autoRenewEnabled
            subscriptionStatusData.hasActiveConversions = engine.getFact('hasActiveConversions')
            subscriptionStatusData.isSubscriber = subscriberStatus === 'active'
            subscriptionStatusData.userRole = userRole

            dispatch(subscriptionStatusActions.updateSubscriptionStatus(subscriptionStatusData))

            const {
              clientName,
              isYPSubscriber,
              isCorpSubscriber,
              isSiteLicenseSubscriber,
              isOverallChurned,
              subscriptionProducts,
              packages,
              tier,
            } = subscriptionStatusData

            if (isOverallChurned === true) {
              dispatch(userInfoActions.updateIsChurnSubscriber())
            }

            const isGroupSubscriber = isCorpSubscriber === true || isSiteLicenseSubscriber === true
            dispatch(appActions.updatePianoIsCorpSubscriber(!!isGroupSubscriber))

            dispatch(appActions.updatePianoIsYPubscriber(!!isYPSubscriber))

            if (!_isEmpty(clientName)) {
              triggerExternalGtmEvent({ subscribe: { ipClientName: clientName } })
            }

            if (!_isEmpty(tier)) {
              triggerUserPropertiesGtmEvent({ tier })
              dispatch(appActions.updateCorpSubscriberTier(tier))
            } else {
              dispatch(appActions.updateCorpSubscriberTier(''))
            }

            if (!_isEmpty(subscriptionProducts) && subscriptionProducts.length > 0) {
              triggerUserPropertiesGtmEvent({ product: subscriptionProducts[0] })
            }

            if (!_isEmpty(packages) && packages.length > 0) {
              const packageName = _get(packages, '0.name', '')
              const expDate = _get(packages, '0.expireDate', '')
              dispatch(userInfoActions.updateUserSubscriptionData(packageName, expDate))
            }
          }
          dispatch(userInfoActions.updateIsPianoSubscriberDataReady())
        } else {
          dispatch(userInfoActions.updateIsPianoSubscriberDataReady())
        }
      } catch (error) {
        console.error('initRosettaSDK', error)
      }
    }

    setupRosetta()

    return () => {
      RosettaSDK.off('experienceExecute', onExperienceExecute)
      RosettaSDK.off('COMM_CHANNEL', commChannelCallback)
    }
  }, [isSignedIn, isPianoReady])

  const { addCancelFlowScript, removeCancelFlowScript } = useCancelFlow()
  useEffect(() => {
    addCancelFlowScript()
    return removeCancelFlowScript()
  }, [])

  return null
}

export default PianoScript
