import axios from 'axios'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'
import PageContainer from 'components/PageContainer'
import { StyledButton } from 'components/styled'

import { AppState } from 'states/reducers'

import { triggerGA4GtmEvent } from 'utils/tracking'

type Props = {
  history: any
  match: any
}

const AccountActivationFailContainer: React.FunctionComponent<Props> = ({ history, match }) => {
  const { callbackUrl } = useSelector((state: AppState) => state.registration)

  useEffect(() => {
    triggerGA4GtmEvent({
      category: 'login',
      subcategory: 'your_link_is_expired',
      action: 'imp',
      customized_parameters: {
        method: 'email',
      },
    })
  }, [])

  const uid: string = match.params.id
  if (!uid) {
    window.location.href = '/'
  }

  const resendActivationHandler = async () => {
    try {
      triggerGA4GtmEvent({
        category: 'login',
        subcategory: 'your_link_is_expired',
        action: 'click',
        customized_parameters: {
          method: 'email',
        },
      })

      const response: any = await axios.post('/activation/resend', {
        uid,
      })

      if (response.status === 200) {
        window.location.href = `${callbackUrl}`
      }
    } catch (error) {
      history.push('/login')
    }
  }

  return (
    <>
      <PageContainer id='resetpassword-page' title={'Your link is expired'} isLogoLink footerText={false} isDisplayFooter={false}>
        <AccountActivationFailSection flexDirection='column' alignItem='center'>
          <Message>Click resend button for a new activation email and click the link to complete your account set-up.</Message>
          <StyledButton
            color='#000'
            fontSize='16px'
            lineHeight='19px'
            padding='12px'
            marginBottom='16px'
            onClick={resendActivationHandler}>RESEND</StyledButton>
        </AccountActivationFailSection>
      </PageContainer>
    </>
  )
}

const AccountActivationFailSection = styled(FlexContainer)`
  max-width: 272px;
`

const Message = styled.div`
  color: #333333;
  font-family: Roboto;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 1px;
`

export default AccountActivationFailContainer
