import { DropDownMenuItem } from 'components/menu/DropDownMenu'

export const SCMP_COM_PROTOCOL = process.env.SCMP_COM_PROTOCOL || 'https'
export const SCMP_COM_HOST = process.env.SCMP_COM_HOST || 'www.scmp.com'
export const YP_PROTOCOL = process.env.YP_PROTOCOL || 'https'
export const YP_HOST = process.env.YP_HOST || 'www.scmp.com/yp'
export const DEFAULT_AUTH_CALLBACK_URL = process.env.DEFAULT_AUTH_CALLBACK_URL || 'https://www.scmp.com/centralize/signin?nonce='

export const DEFAULT_REGISTER_CALLBACK_URL = process.env.DEFAULT_REGISTER_CALLBACK_URL || 'https://www.scmp.com/centralize/register?email='
export const DEFAULT_DESTINATION_URL = '/'
export const DRUPAL_API_GRAPHQL_PROTOCOL = process.env.DRUPAL_API_GRAPHQL_PROTOCOL || 'https'
export const DRUPAL_API_GRAPHQL_HOST = process.env.DRUPAL_API_GRAPHQL_HOST || ''
export const DRUPAL_API_PROTOCOL = process.env.DRUPAL_API_PROTOCOL || 'https'
export const DRUPAL_API_HOST = process.env.DRUPAL_API_HOST || ''
export const GRAPHQL_CONTENTSERVICE_API_URI = process.env.GRAPHQL_CONTENTSERVICE_API_URI || ''
export const GRAPHQL_CONTENTSERVICE_API_URI_ROSETTA_SDK = process.env.GRAPHQL_CONTENTSERVICE_API_URI_ROSETTA_SDK || ''
export const GRAPHQL_CONTENTSERVICE_APIKEY = process.env.GRAPHQL_CONTENTSERVICE_APIKEY || ''
export const NODE_ENV = process.env.NODE_ENV || 'development'
export const SETTING_ENV = process.env.SETTING_ENV || 'staging'
export const USER_COOKIE_DOMAIN = process.env.USER_COOKIE_DOMAIN || '.product-web.dev-2.scmp.tech'
export const GTM_CONTAINER_ID = process.env.GTM_CONTAINER_ID || ''
export const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY || ''
export const RECAPTCHA_SECRET = process.env.RECAPTCHA_SECRET || ''
export const TWITTER_NAME = 'scmpnews'

export const SITE_HOST = process.env.SITE_HOST || 'account.scmp.com'
export const SITE_PROTOCOL = process.env.SITE_PROTOCOL || 'https'

export const GEO_LOCATION_URL = process.env.GEO_LOCATION_URL || 'https://staging-apigw.product-web.dev-2.scmp.tech/geo-location/v1'

export const PIANO_USER_PROVIDER = process.env.PIANO_USER_PROVIDER || ''
export const PIANO_AID = process.env.PIANO_AID || ''
export const PIANO_ENV = process.env.PIANO_ENV || ''
export const PIANO_API_HOST = process.env.PIANO_API_HOST || ''
export const PIANO_API_PROTOCOL = process.env.PIANO_API_PROTOCOL || ''
export const PIANO_ORIGIN = process.env.PIANO_ORIGIN || ''

export const ZENDESK_CHECK_INTERVAL = 100
export const ZENDESK_CHECK_TIMEOUT = 10 * 1000

export const C3PO_MIDDLEWARE_API_URI_LOCAL = process.env.C3PO_MIDDLEWARE_API_URI_LOCAL || ''
export const C3PO_MIDDLEWARE_API_URI = process.env.C3PO_MIDDLEWARE_API_URI || ''
export const C3PO_MIDDLEWARE_API_KEY = process.env.C3PO_MIDDLEWARE_API_KEY || ''
export const PWA_GA_PROFILE_ID = process.env.PWA_GA_PROFILE_ID || ''

export const USER_PROFILE_PIC_PREFIX = process.env.USER_PROFILE_PIC_PREFIX || 'https://cdn.i-scmp.com/user/pic/'

export const ROSETTA_PROTOCOL = process.env.ROSETTA_PROTOCOL || 'https'
export const ROSETTA_HOST = process.env.ROSETTA_HOST || 'staging-rosetta.product-web.dev-2.scmp.tech'
export const ROSETTA_TEMPLATE_BUILDER_HOST = process.env.ROSETTA_TEMPLATE_BUILDER_HOST || 'assets.i-scmp.com/subscription-template'

export const FOLLOW_SERVICE_ENDPOINT = process.env.FOLLOW_SERVICE_ENDPOINT || ''

export const GRAPHQL_D8_API_URI = process.env.GRAPHQL_D8_API_URI || ''

export const SENTRY_DSN = process.env.SENTRY_DSN || ''

export const DROP_DOWN_MENU_ITEMS: DropDownMenuItem[] = [
  { key: 'settings', value: 'My Account', url: '/settings', activeUrls: ['/settings'], ga4Category: 'my account' },
  { key: 'subscription', value: 'Subscription', url: '/manage/subscription', activeUrls: ['/manage/subscription'], ga4Category: 'subscription' },
  { key: 'groupSubscriberBenefits', value: 'Group Subscriber Benefits', url: '/manage/group-subscriber-benefits', activeUrls: ['/manage/group-subscriber-benefits'], ga4Category: 'group subscriber benefits' },
  { key: 'conversations', value: 'My Conversations', url: '/activities/comments', activeUrls: ['/activities/comments'], ga4Category: 'my conversations' },
  { key: 'newsletters', value: 'Newsletters', url: '/manage/newsletter', activeUrls: ['/manage/newsletter'], ga4Category: 'newsletters' },
  { key: 'bookmarks', value: 'Bookmarks', url: '/manage/bookmark', activeUrls: ['/manage/bookmark'], ga4Category: 'bookmarks' },
  { key: 'following', value: 'Following', url: '/manage/following', activeUrls: ['/manage/following'], ga4Category: 'following' },
  { key: 'notifications', value: 'Notifications', url: '/manage/notifications', activeUrls: ['/manage/notifications'], ga4Category: 'notifications' },
  { key: 'devices', value: 'Manage Devices', url: '/manage/devices', activeUrls: ['/manage/devices'], ga4Category: 'manage devices' },
  { key: 'editProfile', value: 'Edit Profile', url: '/settings/profile', activeUrls: ['/settings/profile', '/manage/get-connected', '/settings/change-password', '/settings/change-email-address'], ga4Category: 'edit profile' },
  { key: 'contactUs', value: 'Contact Us', url: `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}/contact-us`, target: '_blank', activeUrls: [''], ga4Category: 'contact us' },
  { key: 'separateLine1', value: '_separateLine_', activeUrls: [''] },
  { key: 'logout', value: 'Log Out', url: '/logout', activeUrls: [''], ga4Category: 'log out' },
]

export const OAUTH_LINKING_ROUTE: string = '/oauth/linking'
export const OAUTH_LINKING_CALLBACK_URL: string = `${process.env.SITE_PROTOCOL}://${process.env.SITE_HOST}/oauth/linking/return`

export const CORP_SUB_TIER_API_URI: string = process.env.CORP_SUB_TIER_API_URI || ''
