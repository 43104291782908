import React, { useState } from 'react'
import styled from 'styled-components'
import map from 'lodash/map'

import { FlexContainer } from 'components/flex'
import FieldTitle from './FieldTitle'
import Select from './Select'

type Props = {
  title: string,
  value?: string,
  onChange: (value: string) => void
}

const monthLabels = ['-', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const years = new Date().getFullYear()

const DateSelectField: React.FunctionComponent<Props> = ({ title, value, onChange }) => {
  const values = value ? value.split('-') : []
  const monthVal = values[1] || 0
  const yearVal = values[0] || 0
  const [month, setMonth] = useState(monthVal)
  const [year, setYear] = useState(yearVal)

  const monthOptions: React.ReactElement[] = map(monthLabels, (monthLabel, index) => {
    if (index !== 0) {
      const value = index < 10 ? '0' + index : index
      return (
        <option key={value} value={value}>{monthLabel}</option>
      )
    } else {
      return (
        <option key='0' value='0'>{monthLabel}</option>
      )
    }
  })

  const yearOptions: React.ReactElement[] = [<option key='-'>-</option>]
  for (let i = years; i >= 1930; i--) {
    const value = i
    yearOptions.push(<option key={value}>{value}</option>)
  }

  const updateMonth = (val: string) => {
    setMonth(val)
    if (val !== '0') {
      onChange && onChange(`${year}-${val}-01`)
    } else {
      onChange && onChange('')
    }
  }

  const updateYear = (val: string) => {
    setYear(val)
    if (val !== '0' && val !== '-') {
      onChange && onChange(`${val}-${month}-01`)
    } else {
      onChange && onChange('')
    }
  }

  return (
    <InputContainer>
      <FieldTitle>{ title }</FieldTitle>
      <DateContainer justifyContent='space-between'>
        <Select value={monthVal} onChange={e => updateMonth(e.target.value)}>{ monthOptions }</Select>
        <Select value={year} onChange={e => updateYear(e.target.value)}>{ yearOptions }</Select>
      </DateContainer>
    </InputContainer>
  )
}

const InputContainer = styled.div`
  text-align: left;
`

const DateContainer = styled(FlexContainer)`
  max-width: 221px;

  & > * {
    width: 101px;
  }
`

export default DateSelectField
