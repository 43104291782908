import React, { useRef } from 'react'
import styled from 'styled-components'
import 'swiper/swiper.min.css'
import _get from 'lodash/get'
import dayjs from 'dayjs'

import NewLabelComponent from 'components/CorporateSubscription/newLabel'

import { media } from 'utils/style'
import { triggerGtmEvent, triggerGA4GtmEvent } from 'utils/tracking'
import { DataLayerEventAction, DataLayerEventCategory, DataLayerGtmCustomEventName } from 'config/constants'

import ReportDownloadButton from 'assets/svg/icon_report_download_button.svg'
import DefaultReportCoverPage from 'assets/svg/generic_report_coverpage.png'
// import { ReactComponent as ShowLessArrowUp } from 'assets/svg/show-less-arrow-up.svg'
// import { ReactComponent as ShowMoreArrowDown } from 'assets/svg/show-more-arrow-down.svg'

import './styles.css'

type ReportImage = {
  url: string
}

type ReportFile = {
  url: string
}

export type Report = {
  entityUuid: string
  entityId: string
  title: string
  createdDate: number
  updatedDate: number
  publishedDate: number
  images: ReportImage
  privateFiles: ReportFile
  body: string
  summary: string
}

type Props = {
  report: Report
}

const getReportCoverPage = (report: any) => {
  return _get(report, 'images[0].url', DefaultReportCoverPage)
}

const sendGaEvent = (reportTitle: string) => {
  triggerGtmEvent(DataLayerEventAction.GROUP_SUBSCRIPTION_BENEFIT_DOWNLOAD_PDF, DataLayerEventCategory.MY_ACCOUNT, DataLayerGtmCustomEventName.TRACKING, reportTitle)
  triggerGA4GtmEvent({
    category: 'myaccount',
    subcategory: 'group_benefits',
    action: 'click',
    customized_parameters: {
      action_type: 'scmp research download',
      report_name: reportTitle,
    },
  })
}

const downloadPdf = (url: string, title: string) => {
  if (!url) {
    return false
  }

  sendGaEvent(title)
  window.open(url)
}

const parsePdfUrl = (url: string) => {
  return url.replace(/production-app-scmp-d8/g, 'cms.scmp.com')
}

const ReportItem: React.FunctionComponent<Props> = ({ report }) => {
  const descriptionRef = useRef<HTMLDivElement>(null)
  // const [showMore, updateShowMore] = useState(false)
  // const [showToggle, updateShowToggle] = useState(false)
  const url: string = parsePdfUrl(_get(report, 'privateFiles[0].url', ''))

  const observer = React.useRef(
    new ResizeObserver(entries => {
      // Only care about the first element, we expect one element ot be watched
      const firstElement: any = entries[0]
      const contentRect = firstElement.contentRect
      const target = firstElement.target

      if (target.scrollHeight > contentRect.height) {
        // updateShowToggle(true)
      }
    }),
  )

  React.useEffect(() => {
    if (descriptionRef.current) {
      observer.current.observe(descriptionRef.current)
    }

    return () => {
      if (descriptionRef.current) {
        (observer.current as any).unobserve(descriptionRef.current)
      }
    }
  }, [descriptionRef, observer])

  const getIsShowNewLabel = (report: Report): boolean => {
    return dayjs(report.publishedDate).add(30, 'day').valueOf() >= dayjs().valueOf()
  }

  // const toggleHandler = () => {
  //   updateShowMore(!showMore)
  // }

  return (<Item key={report.entityId}>
      <ReportCoverPage src={getReportCoverPage(report)} onClick={() => downloadPdf(url, report.title)} url={url} />
      <ReportTitle onClick={() => downloadPdf(url, report.title)} url={url}><ItemTitle>{report.title}</ItemTitle> { getIsShowNewLabel(report) && <NewLabelContainer><NewLabel /></NewLabelContainer> }</ReportTitle>
      { report.body &&
        <>
          <ReportDescription dangerouslySetInnerHTML={{ __html: report.body }} showMore={false} ref={descriptionRef} />
          {/* <ShowMoreButton onClick={() => toggleHandler()}>
            { showToggle && !showMore && <><ShowMoreText>show more</ShowMoreText><ShowMoreArrowDown /></> }
            { showToggle && showMore && <><ShowMoreText>show less</ShowMoreText><ShowLessArrowUp /></> }
          </ShowMoreButton> */}
        </> }
      { <DownloadReportButton onClick={() => downloadPdf(url, report.title)} url={url}>
        <DownloadReportButtonImg src={ReportDownloadButton}/>
      </DownloadReportButton>}
    </Item>)
}

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 196px;
  align-items: space-between;
  &:nth-child(3) {
    margin-right: 0px;
  }
  ${media.tabletUp`
    margin-right: 28px;
  `}
`

type ReportCoverPageProps = {
  url: string
}

const ReportCoverPage = styled.img<ReportCoverPageProps>`
  border: 1px solid #BBBBBB;
  box-sizing: border-box;
  width: 195.5px;
  height: 279.5px;
  cursor: ${(props: any) => (props.url) ? 'pointer' : 'auto'};
`

// const ShowMoreButton = styled.label`
//   font-family: Roboto;
//   font-style: normal;
//   font-weight: normal;
//   font-size: 14px;
//   line-height: 20px;
//   cursor: pointer;
//   color: #4585FF;
//   margin-top: 8px;

//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
// `

// const ShowMoreText = styled.div`
//   margin-right: 6px;
// `

type ReportTitleProps = {
  url: string
}

const ReportTitle = styled.div<ReportTitleProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 140%;
  text-align: left;
  color: #000000;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: ${(props: any) => (props.url) ? 'pointer' : 'auto'};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

type ReportDescriptionProps = {
  showMore: any
}

const ReportDescription = styled.div<ReportDescriptionProps>`
  width: 100%;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 8px;

  /* display: -webkit-box;
  -webkit-line-clamp: ${(props: any) => props.showMore ? 'unset' : '4'};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; */
`

type DownloadReportButtonProps = {
  url: string
}

const DownloadReportButton = styled.div<DownloadReportButtonProps>`
  margin-top: 11.5px;
  align-self: flex-start;
  cursor: ${(props: any) => (props.url) ? 'pointer' : 'auto'};
  visibility: ${(props: any) => (props.url) ? 'auto' : 'hidden'};
`

const DownloadReportButtonImg = styled.img`
  margin-top: 7.5px;
`

const NewLabelContainer = styled.div`
  display: flex;
  margin-left: 12px;
`

const NewLabel = styled(NewLabelComponent)`
  display: flex;
`

const ItemTitle = styled.span`
  display: flex;
`

export default ReportItem
