import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { FlexContainer } from 'components/flex'
import FacebookLogo from 'assets/svg/icon_fb_login.svg'
import GoogleLogo from 'assets/svg/icon_google_login.svg'
import AppleLogo from 'assets/svg/icon_apple_login.svg'
import EmailLogo from 'assets/svg/icon_email.svg'

import { ButtonWithLogo } from 'components/buttonWithLogo'

import { AppState } from 'states/reducers'
import { DataLayerEventCategory, DataLayerEventAction, DataLayerGtmCustomEventName } from 'config/constants'
import { triggerGtmCustomEvent } from 'utils/tracking'

type Props = {
  withEmail?: boolean,
  withFacebook?: boolean,
  withGoogle?: boolean,
  withApple?: boolean,
  headerText?: string,
  emailSubmitted?: boolean,
  emailHandler?: () => void,
}

const SignInWithOption :React.FunctionComponent<Props> = ({ ...Props }) => {
  const { emailHandler, withEmail, withFacebook, withGoogle, withApple, headerText } = Props
  const callbackUrl = useSelector((state: AppState) => state.signin.callbackUrl)
  const destination = useSelector((state: AppState) => state.application.redirectDestinationUrl)
  const facebookLoginHandler = () => {
    const path = `/login/facebook/?callback=${encodeURIComponent(callbackUrl)}${destination ? '&destination=' + encodeURIComponent(destination) : ''}`
    triggerGtmCustomEvent(DataLayerEventAction.FACEBOOK_LOGIN, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN, path)
    window.location.href = path
  }
  const googleLoginHandler = () => {
    const path = `/login/google/?callback=${encodeURIComponent(callbackUrl)}${destination ? '&destination=' + encodeURIComponent(destination) : ''}`
    triggerGtmCustomEvent(DataLayerEventAction.GOOGLE_LOGIN, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN, path)
    window.location.href = path
  }
  const appleLoginHandler = () => {
    const path = `/login/apple/?callback=${encodeURIComponent(callbackUrl)}${destination ? '&destination=' + encodeURIComponent(destination) : ''}`
    triggerGtmCustomEvent(DataLayerEventAction.APPLE_LOGIN, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN, path)
    window.location.href = path
  }

  return (
    <>
      {
        headerText && (
          <>
            <Headertext>{headerText}</Headertext>
          </>
        )
      }
      <ThirdPartySignInContainer className='signin-section__third-party third-party' flexDirection='column' justifyContent='space-between' wrap='nowrap' alignItem='center'>
        { withFacebook && <LoginButton image={FacebookLogo} backgroundColor='#FFF' onClick={facebookLoginHandler} nonBoldtext="WITH" boldText="FACEBOOK" textColor="#666666"></LoginButton> }
        { withGoogle && <LoginButton image={GoogleLogo} backgroundColor='#FFF' onClick={googleLoginHandler} nonBoldtext="WITH" boldText="GOOGLE" textColor="#666666"/>}
        { withApple && <LoginButton image={AppleLogo} backgroundColor='#FFF' onClick={appleLoginHandler} nonBoldtext="WITH" boldText="APPLE" textColor="#666666"/>}
      </ThirdPartySignInContainer>

     {
       withEmail && (
        <>
          <ContentSeparator className='signin-section__separator separator'>
            <SeparatorWrapper>
              <SeparatorLabel>OR</SeparatorLabel>
            </SeparatorWrapper>
          </ContentSeparator>
          <SignInWithEmailContainer>
            <LoginButton image={EmailLogo} backgroundColor='#FFF' onClick={emailHandler} nonBoldtext="WITH" boldText="EMAIL" textColor="#666666"/>
          </SignInWithEmailContainer>
        </>
       )
     }
    </>
  )
}

const Headertext = styled.div`
  text-align: center;
  color: #444444;
  font-size: 14px;
  line-height: 18px;
  font-family: 'Roboto';
  padding-bottom: 24px;
`

const ThirdPartySignInContainer = styled(FlexContainer)`
  width: 100%;
  div {
    margin-bottom: 16px
  }
`

const SignInWithEmailContainer = styled(FlexContainer)`
  width: 100%;
  div {
    margin-left: 7px;
    margin-right: 7px;
  }
  div:first-child {
    margin-left: 0;
  }
  div:last-child {
    margin-right: 0;
  }
`

const LoginButton = styled(ButtonWithLogo)`
  width: 100%;
  border: 1px solid #999999
`

const ContentSeparator = styled.div`
  width: 100%;
  border-bottom: 1px solid #DDDDDD;
  position: relative;
  text-align: center;
  height: 0;
  z-index: 1;
  margin: 17px 0 33px;
`

const SeparatorWrapper = styled.div`
  background: white;
  position: relative;
  display: inline-block;
  top: -10px;
`

const SeparatorLabel = styled.span`
  color: #001246;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 10px;
  text-transform: uppercase;
`

export default SignInWithOption
