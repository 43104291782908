import styled from 'styled-components'
import React from 'react'
import { media } from 'utils/style'
import { NODE_ENV } from 'config/app.config'
import { FlexContainer } from 'components/flex'
import { ReactComponent as TickSvg } from '../../assets/svg/icon_signedup_tick.svg'
import newsletterImg from 'assets/svg/subscribe_visual@2x.png'

type Props = {
  trackingGTM: () => void,
}

const DefaultSubscriptionPageContent: React.FunctionComponent<Props> = ({ ...Props }) => {
  const { trackingGTM } = Props
  const letterLink = 'https://www.scmp.com/announcements/article/3094621/introducing-south-china-morning-posts-new-digital-subscription-plans'
  return (
    <>
      <SubscriptionToTitle>
        Start your subscription today
      </SubscriptionToTitle>
      <SubscriptionToBoxButton onClick={trackingGTM} href={NODE_ENV === 'production' ? 'https://subscribe.scmp.com/' : 'https://staging-rosetta.product-web.dev-2.scmp.tech/'}>SUBSCRIBE</SubscriptionToBoxButton>
      <SubscriptionToBox flexDirection='column' alignItem='center'>
        <SubscriptionToContent>
          <SubscriptionToBoxTitle>Unique insights and analysis on the stories that matter to you</SubscriptionToBoxTitle>
          <SubscriptionToBoxPointList flexDirection='column' alignItem='flex-start'>
            <SubscriptionToBoxPoint wrap='nowrap'>
              <StyledTick />
              <SubscriptionToBoxPointContent>
                Unlimited access to scmp.com and mobile apps
              </SubscriptionToBoxPointContent>
            </SubscriptionToBoxPoint>
            <SubscriptionToBoxPoint wrap='nowrap'>
              <StyledTick />
              <SubscriptionToBoxPointContent>
                Unlock award-winning coverage and authoritative China insights
              </SubscriptionToBoxPointContent>
            </SubscriptionToBoxPoint>
            <SubscriptionToBoxPoint wrap='nowrap'>
              <StyledTick />
              <SubscriptionToBoxPointContent>
                Join the community and receive our subscriber-only newsletter
              </SubscriptionToBoxPointContent>
            </SubscriptionToBoxPoint>
          </SubscriptionToBoxPointList>
        </SubscriptionToContent>
        <LetterLink href={letterLink} target="_blank">A Letter from the Editor-in-Chief</LetterLink>
        <SubscribeImg src={newsletterImg}></SubscribeImg>
      </SubscriptionToBox>
      <TipsContent flexDirection='column' alignItem='flex-start'>
        <TipsHeader>Print-only subscribers</TipsHeader>
        <TipsText>Please contact Customer Support at 2565 9388 or at <a className="tipsATag" href="mailto:support@scmp.com">support@scmp.com</a> to manage your subscription.</TipsText>
        <TipsText>Or try our&nbsp;
          <a className="tipsATag"
            onClick={trackingGTM}
            href={NODE_ENV === 'production' ? 'https://subscribe.scmp.com/' : 'https://staging-rosetta.product-web.dev-2.scmp.tech/'}
          >digital plan.</a></TipsText>
      </TipsContent>
    </>
  )
}

const SubscriptionToContent = styled.div`
  margin: 16px 16px 0px 16px;
`

const SubscriptionToBoxPointList = styled(FlexContainer)`
`

const TipsContent = styled(FlexContainer)`
  padding: 0;
  max-width: 473px;
  ${media.tabletUp`
    padding: 0 88px;
    max-width: 330px;
  `}
`

const SubscriptionToTitle = styled.div`
  color: #000000;
  font-family: Merriweather;
  font-size: 21px;
  line-height: 27px;
  padding: 25px 55px 0px 55px;
  text-align: center;
  ${media.tabletUp`
    width: 100%;
    font-size: 36px;
    line-height: 45px;
    padding: 0 40px;
  `}
`
const SubscriptionToBox = styled(FlexContainer)`
  margin-top: 24px;
  margin-bottom: 24px;
  border: 2px solid #FFCA05;
  border-radius: 4px;
  ${media.tabletUp`
    padding: 25px 0 0 0;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: inherit;
  `}
`

const SubscriptionToBoxTitle = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  padding-bottom: 20px;
`

const StyledTick = styled(TickSvg)`
  margin-right: 8px;
  height: 15px;
`

const SubscriptionToBoxPoint = styled(FlexContainer)`
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: left;
  margin-bottom: 12px;
  ${media.tabletUp`
    padding: 0 20px;
  `}
`

const SubscriptionToBoxPointContent = styled.div`
  max-width: 265px;
  ${media.tabletUp`
    max-width: 100%;
  `}
`

const SubscriptionToBoxButton = styled.a`
  color: #001246;
  font-family: Roboto;
  font-size: 16px;
  text-align: center;
  padding: 10px 15px;
  line-height: 19px;
  border-radius: 2px;
  background-color: #FFCA05;
  width: 113px;
  cursor: pointer;
  margin-top: 20px;
  box-sizing: border-box;
`

const TipsHeader = styled.div`
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 8px;
  ${media.tabletUp`
    margin-bottom: 4px;
  `}
`

const TipsText = styled.div`
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  .tipsATag {
    color: #222222;
    text-decoration: underline;
  }
`

const SubscribeImg = styled.img`
  width: 332px;
  ${media.tabletUp`
    width: 441px;
  `}
`

const LetterLink = styled.a`
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 16px;
  text-decoration: underline;
`

export default DefaultSubscriptionPageContent
