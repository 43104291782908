import React from 'react'
import styled from 'styled-components'
import { AppState } from 'states/reducers'
import { OutSideStyledButton } from 'components/styled'
import { useSelector } from 'react-redux'
import { FlexContainer } from 'components/flex'

const UnScbscibeSuccess: React.FunctionComponent = () => {
  const destination = useSelector((state: AppState) => state.application.redirectDestinationUrl)

  const onClickGoToButton = () => {
    window.location.href = destination
  }

  return (
    <>
      <ContentContainer alignItem='baseline' justifyContent="center">
        <Message>You have successfully unsubscribed from <BoldText>Recommended by SCMP</BoldText> email alerts.</Message>
        <OutSideStyledButton
          backgroundColour="#fff"
          border="1px solid"
          borderColor="#4585FF"
          color="#4585FF"
          fontSize="16px"
          lineHeight="19px"
          marginBottom="40px"
          onClick={onClickGoToButton}
        >GO TO SCMP.COM</OutSideStyledButton>
      </ContentContainer>
    </>
  )
}

const BoldText = styled.span`
  font-weight:bold;
`

const ContentContainer = styled(FlexContainer)`
  width: 100%;
  border-bottom: 1px solid #D8D8D8;
`

const Message = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-family: Roboto;
  width: 100%;
  margin-bottom: 40px;
`

export default UnScbscibeSuccess
