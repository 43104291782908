/* eslint-disable camelcase */
import TagManager from 'react-gtm-module'

interface TrackingData {
  action: string,
  category: string,
  label: string,
}

interface DataLayer {
  tracking: TrackingData,
  event: string,
  eventCallback?: () => void,
  eventTimeout?: number,
}

export function triggerGtmEvent (action: string, category: string, eventName: string, label: string = '', redirectUrl: string = '') {
  // const action = (DataLayerEventAction as any)[`${eventType}`] // tag action
  const dataLayer: DataLayer = {
    tracking: {
      action,
      category, // tag category
      label, // tag label
    },
    event: eventName, // custom event name
  }
  if (redirectUrl) {
    TagManager.dataLayer({
      dataLayer: Object.assign(dataLayer, {
        eventCallback: () => {
          window.location.href = redirectUrl
        },
        eventTimeout: 200,
      }),
    })
  } else {
    TagManager.dataLayer({ dataLayer })
  }
}

// To trigger custom event and fire gtag
export function triggerGtmCustomEvent (eventType: string, category: string, eventName: string, label: string = '') {
  const url = `${window.location.host}${label}`
  // const action = (DataLayerEventAction as any)[`${eventType}`] // tag action
  triggerGtmEvent(eventType, category, eventName, url)
}

export function triggerExternalGtmEvent (dataLayer: object) {
  TagManager.dataLayer({ dataLayer })
}

export function triggerUserPropertiesGtmEvent (properties: object) {
  const dataLayer = {
    _clear: false,
    userProperties: properties,
  }

  TagManager.dataLayer({ dataLayer })
}

export const eventNameOfGA4 = 'GA4_tracking'
export const keyOfCustomizedParameters = 'customized_parameters'

export type SocialLoginMethod = 'apple' | 'facebook' | 'google' | 'google-one-tap'
export type LoginMethod = 'apple' | 'email' | 'facebook' | 'google' | 'google-one-tap' | ''
export type ShareWidgetMethod = 'copy_link' | 'twitter' | 'facebook' | 'email'

interface CustomizedParametersOfEmailLoginFlow {
  method: 'email'
}

type PageName = 'my_account'
type TriggerPoint = 'my_account' | 'my_account_banner'

interface ShareWidgetButtonClickEventParameters {
  category: 'share'
  subcategory: ''
  action: 'click'
  [keyOfCustomizedParameters]: {
    method: ShareWidgetMethod,
    position: 'bookmark',
    share_url: string
  }
}

interface BookmarkEventParameters {
  category: 'bookmark'
  subcategory: ''
  action: 'click' | 'sys'
  [keyOfCustomizedParameters]: {
    // eslint-disable-next-line camelcase
    action_type: 'remove' | 'read'
    // eslint-disable-next-line camelcase
    article_id: string
    // eslint-disable-next-line camelcase
    page_name?: PageName
    // eslint-disable-next-line camelcase
    trigger_point: TriggerPoint
  }
}

interface CommentPopupImpressionEventParameters {
  category: 'comment'
  subcategory: 'popup'
  action: 'imp'
  [keyOfCustomizedParameters]: {
    // eslint-disable-next-line camelcase
    article_id: string
    // eslint-disable-next-line camelcase
    trigger_point: TriggerPoint
  }
}

interface EmailLoginFlowEventParameters {
  [keyOfCustomizedParameters]: CustomizedParametersOfEmailLoginFlow
}

interface ForgotPasswordResetLinkEventParameters extends EmailLoginFlowEventParameters {
  category: 'login'
  subcategory: 'forgot_password_reset_link'
  action: 'click' | 'imp'
}

export type MyAccountClickCategory = 'my account' | 'subscription' | 'group subscriber benefits' | 'my conversations' | 'newsletters' | 'bookmarks' | 'following' | 'notifications' | 'manage devices' | 'edit profile' | 'contact us' | 'log out'
interface MyAccountClickEventParameters {
  category: 'myaccount'
  subcategory: ''
  action: 'click'
  [keyOfCustomizedParameters]: {
    category: MyAccountClickCategory
  }
}

interface MyAccountForgotPasswordClickEventParameters {
  category: 'myaccount'
  subcategory: 'forgot_password'
  action: 'click'
}

interface MyAccountGroupBenefitsDownloadClickEventParameters {
  category: 'myaccount'
  subcategory: 'group_benefits'
  action: 'click'
  [keyOfCustomizedParameters]: {
    // eslint-disable-next-line camelcase
    action_type: 'scmp research download'
    // eslint-disable-next-line camelcase
    report_name?: string
  }
}

interface MyAccountGroupBenefitsEventClickEventParameters {
  category: 'myaccount'
  subcategory: 'group_benefits'
  action: 'click'
  [keyOfCustomizedParameters]: {
    // eslint-disable-next-line camelcase
    action_type: 'event'
    // eslint-disable-next-line camelcase
    event_name?: string
  }
}

type MyAccountGroupBenefitsClickEventParameters = MyAccountGroupBenefitsDownloadClickEventParameters | MyAccountGroupBenefitsEventClickEventParameters

interface MyAccountManageDevicesRemoveClickEventParameters {
  category: 'myaccount'
  subcategory: 'manage_devices_remove'
  action: 'click'
  [keyOfCustomizedParameters]: {
    device: string
  }
}

interface MyAccountNotificationSystemEventParameters {
  category: 'myaccount'
  subcategory: 'notification'
  action: 'sys'
  [keyOfCustomizedParameters]: {
    // eslint-disable-next-line camelcase
    notification_type: 'comment moderation' | 'replied comment' | 'follow or reminders' | 'marketing message from scmp' | 'marketing message from scmp business partners'
    status: 'on' | 'off'
  }
}

interface MyAccountProfileUpdateSystemEventParameters {
  category: 'myaccount'
  subcategory: 'profile_update'
  action: 'sys'
  [keyOfCustomizedParameters]: {
    // eslint-disable-next-line camelcase
    update_type: 'change_email' | 'change_password'
  }
}

// 3.4.1
interface NewsletterUnsubscribeSystemEventParameters {
  category: 'nl'
  subcategory: 'unsub'
  action: 'sys'
  [keyOfCustomizedParameters]: {
    // eslint-disable-next-line camelcase
    newsletter_name: string
    // eslint-disable-next-line camelcase
    page_name: PageName
  }
}

// 3.2.1
interface NewsletterSignUpEntryPointClickEventParameters {
  category: 'nl'
  subcategory: 'entry'
  action: 'click'
  [keyOfCustomizedParameters]: {
    internal_campaign: string
    trigger_point: TriggerPoint
  }
}

// 3.1.3
interface NewsletterSignUpImpressionEventParameters {
  category: 'nl'
  subcategory: 'sign_up'
  action: 'imp'
  [keyOfCustomizedParameters]: {
    internal_campaign: string
    trigger_point: TriggerPoint
  }
}

interface PopupImpressionEventParameters {
  category: 'login'
  subcategory: 'popup'
  action: 'imp'
}

interface ResetPasswordEventParameters extends EmailLoginFlowEventParameters {
  category: 'login'
  subcategory: 'reset_password'
  action: 'click' | 'imp'
}

export interface ResultSystemEventParameters {
  category: 'login'
  subcategory: 'result'
  action: 'sys'
  [keyOfCustomizedParameters]: {
    method: LoginMethod
    result: 'login_success' | 'register_success'
    // eslint-disable-next-line camelcase
    trigger_point?: string
  }
}

interface SetPasswordEventParameters extends EmailLoginFlowEventParameters {
  category: 'login'
  subcategory: 'set_password'
  action: 'click' | 'imp'
}

interface SocialConnectClickEventParameters {
  category: 'login'
  subcategory: 'social_connect'
  action: 'click'
  [keyOfCustomizedParameters]: {
    method: SocialLoginMethod
  }
}

interface SocialDisconnectClickEventParameters {
  category: 'login'
  subcategory: 'social_disconnect'
  action: 'click'
  [keyOfCustomizedParameters]: {
    method: SocialLoginMethod
  }
}

export type FollowType = 'author' | 'knowledge' | 'section' | 'topic' | 'eventName'

interface UnFollowClickEventParameters {
  category: 'follow'
  subcategory: ''
  action: 'click'
  [keyOfCustomizedParameters]: {
    // eslint-disable-next-line camelcase
    action_type: 'unfollow'
    // eslint-disable-next-line camelcase
    author_id?: string
    // eslint-disable-next-line camelcase
    follow_type: FollowType
    // eslint-disable-next-line camelcase
    section_id?: string
    // eslint-disable-next-line camelcase
    topic_id?: string
    // eslint-disable-next-line camelcase
    trigger_point: TriggerPoint
  }
}
interface UnFollowSystemEventParameters {
  category: 'follow'
  subcategory: 'result'
  action: 'sys'
  [keyOfCustomizedParameters]: {
    // eslint-disable-next-line camelcase
    action_type: 'unfollow'
    // eslint-disable-next-line camelcase
    author_id?: string
    // eslint-disable-next-line camelcase
    follow_type: FollowType
    // eslint-disable-next-line camelcase
    section_id?: string
    // eslint-disable-next-line camelcase
    topic_id?: string
    // eslint-disable-next-line camelcase
    trigger_point: TriggerPoint
  }
}
type UnFollowEventParameters = UnFollowClickEventParameters | UnFollowSystemEventParameters

interface YourLinkIsExpiredEventParameters extends EmailLoginFlowEventParameters {
  category: 'login'
  subcategory: 'your_link_is_expired'
  action: 'click' | 'imp'
}

interface EpaperInMyAccountEventParameters {
  category: 'myaccount'
  action: 'click'
  [keyOfCustomizedParameters]: {
    category: 'epaper'
  }
}

export interface MyAccountSubscriptionEventParameters {
  category: 'myaccount'
  subcategory: 'subscription'
  action: 'click' | 'imp',
  [keyOfCustomizedParameters]: {
    trigger_point?: string,
    subscriber_phase?: string,
    call_to_action: string,
    internal_campaign?: string,
    promo_code?: string,
    auto_renew?: 'auto renew on' | 'auto renew off',
    no_of_days_left_expiry?: number,
    plan_type?: '1-month' | '1-year' | '2-years'
  }
}

interface MyAccountDailyEmailAlertEventParameters{
  category: 'follow'
  subcategory: 'email_alert'
  action: 'sys',
  [keyOfCustomizedParameters]: {
    alert: 'on' | 'off'
  }
}

type TriggerGA4GtmEventArgs =
  | BookmarkEventParameters
  | CommentPopupImpressionEventParameters
  | ForgotPasswordResetLinkEventParameters
  | MyAccountClickEventParameters
  | MyAccountForgotPasswordClickEventParameters
  | MyAccountGroupBenefitsClickEventParameters
  | MyAccountManageDevicesRemoveClickEventParameters
  | MyAccountNotificationSystemEventParameters
  | MyAccountProfileUpdateSystemEventParameters
  | NewsletterUnsubscribeSystemEventParameters
  | NewsletterSignUpEntryPointClickEventParameters
  | NewsletterSignUpImpressionEventParameters
  | PopupImpressionEventParameters
  | ResetPasswordEventParameters
  | ResultSystemEventParameters
  | SetPasswordEventParameters
  | SocialConnectClickEventParameters
  | SocialDisconnectClickEventParameters
  | UnFollowEventParameters
  | YourLinkIsExpiredEventParameters
  | EpaperInMyAccountEventParameters
  | MyAccountSubscriptionEventParameters
  | ShareWidgetButtonClickEventParameters
  | MyAccountDailyEmailAlertEventParameters

type DataLayerParameters = TriggerGA4GtmEventArgs & {
  event: typeof eventNameOfGA4,
}

export function triggerGA4GtmEvent (args: TriggerGA4GtmEventArgs) {
  const dataLayer: DataLayerParameters = {
    event: eventNameOfGA4,
    ...args,
  }

  TagManager.dataLayer({ dataLayer })

  /** clear the optional parameters of `customized_parameters` to avoid sending unnecessary event parameters in the following GA4 events */
  if (keyOfCustomizedParameters in args) {
    const customizedParametersCleared = {
      [keyOfCustomizedParameters]: undefined,
    }
    TagManager.dataLayer({ dataLayer: customizedParametersCleared })
  }
}

/**
 * Trigger virtual pageview
 */
export function pushVirtualPageview (): void {
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push({ event: 'VirtualPageview' })
}

interface GetFollowOptionalCustomizedParametersArgs {
  entityId: string
  followType: FollowType
}
export function getFollowOptionalCustomizedParameters (args: GetFollowOptionalCustomizedParametersArgs) {
  const { entityId, followType } = args

  switch (followType) {
    case 'author': {
      return {
        author_id: entityId,
        follow_type: followType,
      }
    }
    case 'knowledge': {
      return {
        follow_type: followType,
      }
    }
    case 'section': {
      return {
        section_id: entityId,
        follow_type: followType,
      }
    }
    case 'topic': {
      return {
        topic_id: entityId,
        follow_type: followType,
      }
    }
    default: {
      return {
        follow_type: followType,
      }
    }
  }
}
