export const UPDATE_PAGE_URL = 'UPDATE_PAGE_URL'

export const UPDATE_SIGNIN_CALLBACK_URL = 'UPDATE_SIGNIN_CALLBACK_URL'

export const UPDATE_REGISTRATION_STEP = 'UPDATE_REGISTRATION_STEP'
export const UPDATE_REGISTRATION_OPT_IN_AGREEMENT = 'UPDATE_REGISTRATION_OPT_IN_AGREEMENT'
export const UPDATE_REGISTRATION_EMAIL = 'UPDATE_REGISTRATION_EMAIL'
export const UPDATE_REGISTRATION_PASSWORD = 'UPDATE_REGISTRATION_PASSWORD'
export const UPDATE_REGISTER_CALLBACK_URL = 'UPDATE_REGISTER_CALLBACK_URL'

export const UPDATE_USER_UID = 'UPDATE_USER_UID'
export const UPDATE_USER_UUID = 'UPDATE_USER_UUID'
export const UPDATE_USER_USERNAME = 'UPDATE_USER_USERNAME'
export const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL'
export const UPDATE_USER_FRSTNAME = 'UPDATE_USER_FRSTNAME'
export const UPDATE_USER_LASTNAME = 'UPDATE_USER_LASTNAME'
export const UPDATE_USER_SIGNIN_STATUS = 'UPDATE_USER_SIGNIN_STATUS'
export const UPDATE_USER_USERPIC = 'UPDATE_USER_USERPIC'
export const UPDATE_USER_SUBSCRIPTION_DATA = 'UPDATE_USER_SUBSCRIPTION_DATA'
export const UPDATE_USER_IS_CHURN_SUBSCRIBER = 'UPDATE_USER_IS_CHURN_SUBSCRIBER'
export const UPDATE_USER_OPTIN = 'UPDATE_USER_OPTIN'
export const UPDATE_USER_OPTINID = 'UPDATE_USER_OPTINID'
export const UPDATE_USER_3RD_OPTIN = 'UPDATE_USER_3RD_OPTIN'
export const UPDATE_USER_USERLVL = 'UPDATE_USER_USERLVL'
export const UPDATE_USER_USERSCS = 'UPDATE_USER_USERSCS'
export const UPDATE_USER_USERSOURCE = 'UPDATE_USER_USERSOURCE'
export const UPDATE_USER_USERCREATED = 'UPDATE_USER_USERCREATED'
export const UPDATE_USER_ISSCMPUSER = 'UPDATE_USER_ISSCMPUSER'
export const UPDATE_ISPIANOSUBSCRIBER_DATA_READY = 'UPDATE_ISPIANOSUBSCRIBER_DATA_READY'
export const UPDATE_USER_ISSHOWROSETTA = 'UPDATE_USER_ISSHOWROSETTA'
export const UPDATE_USER_KEYWORD_ALERTS = 'UPDATE_USER_KEYWORD_ALERTS'

export const UPDATE_MANAGE_NEWSLETTER_IDS = 'UPDATE_MANAGE_NEWSLETTER_IDS'
export const REMOVE_MANAGE_NEWSLETTER_IDS = 'REMOVE_MANAGE_NEWSLETTER_IDS'
export const UPDATE_MANAGE_AUTH_CONTENT = 'UPDATE_MANAGE_AUTH_CONTENT'
export const UPDATE_MANAGE_UNLINK_MEDIA = 'UPDATE_MANAGE_UNLINK_MEDIA'
export const REMOVE_MANAGE_UNLINK_MEDIA = 'REMOVE_MANAGE_UNLINK_MEDIA'

export const UPDATE_MODAL_MESSAGE = 'UPDATE_MODAL_MESSAGE'
export const UPDATE_UNFOLLOW_MODAL_MESSAGE = 'UPDATE_UNFOLLOW_MODAL_MESSAGE'
export const UPDATE_REDIRECT_DESTINATION_URL = 'UPDATE_REDIRECT_DESTINATION_URL'
export const UPDATE_REDIRECT_SOURCE = 'UPDATE_REDIRECT_SOURCE'
export const UPDATE_SOICAL_MEDIA_CONNECTION_ERROR = 'UPDATE_SOICAL_MEDIA_CONNECTION_ERROR'
export const UPDATE_REDIRECT_BEHAVIOUR = 'UPDATE_REDIRECT_BEHAVIOUR'
export const UPDATE_FLOW_TYPE = 'UPDATE_FLOW_TYPE'

export const UPDATE_PIANO_STATUS = 'UPDATE_PIANO_STATUS'
export const UPDATE_PIANO_IS_SUPPORTED = 'UPDATE_PIANO_IS_SUPPORTED'
export const UPDATE_PIANO_CONVERSIONS = 'UPDATE_PIANO_CONVERSIONS'
export const UPDATE_PIANO_IS_CORP_SUBSCRIBER = 'UPDATE_PIANO_IS_CORP_SUBSCRIBER'
export const UPDATE_PIANO_IS_YP_SUBSCRIBER = 'UPDATE_PIANO_IS_YP_SUBSCRIBER'
export const UPDATE_PIANO_CORP_SUBSCRIBER_TIER = 'UPDATE_PIANO_CORP_SUBSCRIBER_TIER'

export const UPDATE_ZENDESK_STATUS = 'UPDATE_ZENDESK_STATUS'
export const UPDATE_ZENDESK_VISIBILITY = 'UPDATE_ZENDESK_VISIBILITY'

export const UPDATE_FOLLOW_LIST = 'UPDATE_FOLLOW_LIST'

export const UPDATE_IS_GTM_READY = 'UPDATE_IS_GTM_READY'

export const UPDATE_SUBSCRIPTION_STATUS = 'UPDATE_SUBSCRIPTION_STATUS'
export const UPDATE_SUBSCRIPTION_ISAUTORENEW = 'UPDATE_SUBSCRIPTION_ISAUTORENEW'
