import { useQuery } from '@apollo/react-hooks'
import axios from 'axios'
import moment from 'moment'
import _concat from 'lodash/concat'
import _get from 'lodash/get'
import _map from 'lodash/map'
import _remove from 'lodash/remove'
import _slice from 'lodash/slice'
import _union from 'lodash/union'
import React, { useState } from 'react'
import styled from 'styled-components'

import { BookmarkList } from 'components/bookmark'
import { FlexContainer } from 'components/flex'
import { ManageMenu } from 'components/manage'
import { Loading } from 'components/Loading'

import { FOLLOW_SERVICE_ENDPOINT } from 'config/app.config'
import { DataLayerEventCategory, DataLayerEventAction, DataLayerGtmCustomEventName } from 'config/constants'

import GET_BOOKMARK_ARTICLES from 'universal/apollo/d8/GET_BOOKMARK_ARTICLES.gql'

import { media, showOnMedia } from 'utils/style'
import { triggerGtmEvent, triggerGA4GtmEvent } from 'utils/tracking'

interface OnClickRemoveButtonArgs {
  entityId: string,
  urlAlias: string,
}
export type OnClickRemoveButton = (args: OnClickRemoveButtonArgs) => Promise<void>

type Props = {
  match: any,
  history: any,
}

const BookmarkPageContainer: React.FunctionComponent<Props> = ({ ...Props }) => {
  const activeItem = Props.match.params.id
  const [bookmarkedList, updateBookmarkedList] = useState<any>([])
  const [moreBookmarkList, updateMoreBookmarkList] = useState<any>([])

  const { loading, data } = useQuery(GET_BOOKMARK_ARTICLES, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      getBookmarkedList(0, 7)
    },
  })

  const processedData = _get(data, 'currentUserContext.preferencesProfiles.entity.fieldRelatedContent', [])

  const processedBookmarkList = _map(processedData, (item: any) => {
    return {
      authors: _get(item, 'entity.authors', ''),
      entityId: _get(item, 'entity.entityId', ''),
      entityUuid: _get(item, 'entity.entityUuid', ''),
      headline: _get(item, 'entity.headline', ''),
      images: _get(item, 'entity.images', ''),
      publishedDate: _get(item, 'entity.publishedDate', ''),
      socialHeadline: _get(item, 'entity.socialHeadline', ''),
      urlAlias: _get(item, 'entity.entityUrl.path', ''),
    }
  })

  const setBookmarkLocalStorage = async (data: any) => {
    try {
      if (window.localStorage) {
        localStorage.setItem('bookmarkedList', JSON.stringify(data))
        localStorage.setItem('bookmarkedListTimeStamp', moment().format('x'))
      }
      return
    } catch (e) {
      console.info('setBookmarkLocalStorage error', e)
    }
  }

  const getBookmarkedList = async (offset = 0, limit = 7) => {
    const reverseList = processedBookmarkList.reverse()

    const sliceData = _slice(reverseList, offset, offset + limit)
    updateBookmarkedList(_union(_concat(bookmarkedList, sliceData)))
    await setBookmarkLocalStorage(_union(_concat(bookmarkedList, sliceData)))

    const updatedMoreBookmarkList = _slice(reverseList, offset + limit, offset + limit * 2)
    updateMoreBookmarkList(updatedMoreBookmarkList)
  }

  const updateLocalStorageItem = async (entityId: string) => {
    try {
      if (typeof window !== 'undefined' && window.localStorage) {
        const list = localStorage.getItem('bookmarkedList') || '[]'
        const bookmarkedList = JSON.parse(list)
        _remove(bookmarkedList, (item: any) => {
          return item.entityId === entityId
        })
        updateBookmarkedList(bookmarkedList)
        await setBookmarkLocalStorage(bookmarkedList)
      }
    } catch (e) {
      console.info('parse error', e)
    }
  }

  const onClickRemoveButton: OnClickRemoveButton = async (args) => {
    const { entityId, urlAlias } = args

    triggerGA4GtmEvent({
      category: 'bookmark',
      subcategory: '',
      action: 'click',
      customized_parameters: {
        action_type: 'remove',
        article_id: entityId,
        trigger_point: 'my_account',
      },
    })

    const data = {
      messageItem: {
        action: 'unbookmark',
        id: entityId,
        type: 'node',
      },
    }
    await axios.post(`${FOLLOW_SERVICE_ENDPOINT}/bookmark`, data,
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })

    triggerGtmEvent(
      DataLayerEventAction.BOOKMARK_REMOVE,
      DataLayerEventCategory.BOOKMARK,
      DataLayerGtmCustomEventName.COMMENT,
      urlAlias,
      '',
    )
    triggerGA4GtmEvent({
      category: 'bookmark',
      subcategory: '',
      action: 'sys',
      customized_parameters: {
        action_type: 'remove',
        article_id: entityId,
        trigger_point: 'my_account',
      },
    })
    await updateLocalStorageItem(entityId)
  }

  return (
    <PageContainer id='manage-container' flexDirection='row' alignItem='stretch' justifyContent='flex-start' wrap='no-wrap'>
      <ManageMenuContainer showOn='desktop'>
        <ManageMenu activeItem={activeItem}></ManageMenu>
      </ManageMenuContainer>
      <ManageContentContainer justifyContent='center'>
        {loading
          ? <LoadingContainer />
          : <BookmarkList
              bookmarkedList={bookmarkedList}
              getBookmarkedList={getBookmarkedList}
              moreBookmarkList={moreBookmarkList}
              onClickRemoveButton={onClickRemoveButton}
            />
        }
      </ManageContentContainer>
    </PageContainer>
  )
}

interface CommonStyle {
  showOn?: string
}

const PageContainer = styled(FlexContainer)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin: auto;
  ${media.mobileUp`
    background-color: #F2F2F2;
    height: calc(100vh - 50px);
  `}
  ${media.tabletUp`
    margin-top: 0;
    max-width: 680px;
    background-color: #fff;
    height: auto;
  `}
  ${media.smallDesktopUp`
    margin-top: 0;
    max-width: 944px;
  `}
`

const ManageMenuContainer = styled(FlexContainer) <CommonStyle>`
  margin-right: 56px;
  width: 147px;
  position: sticky;
  top: 103px;
  height: 100%;
  ${({ showOn }) => showOn ? showOnMedia([showOn]) : ''}
`

const ManageContentContainer = styled(FlexContainer)`
  flex: 1 1 0;
  margin: 0;
`

const LoadingContainer = styled(Loading)`
  margin-top: 20px;
`

export default BookmarkPageContainer
