import {
  UPDATE_SUBSCRIPTION_STATUS,
  UPDATE_SUBSCRIPTION_ISAUTORENEW,
} from '../constants/action-types'
import _get from 'lodash/get'

const initialState = {
  clientName: null,
  userRole: null,
  hasActiveConversions: null,
  isCorpSubscriber: null,
  isFreeSubscriber: null,
  isInternalStaff: null,
  isSCMPChurned: null,
  isSubscriber: null,
  isSCMPSubscriber: null,
  isSiteLicenseSubscriber: null,
  isVipExclusive: null,
  isYPChurned: null,
  isYPSubscriber: null,
  isOverallChurned: null,
  isActiveExternalSubscriber: null,
  scmpWinback: {
    isEligible: false,
  },
  subscriptionProducts: [],
  packages: [],
  isAutoRenew: false,
  isAutoRenewable: false,
  expiryReminder: [],
  subscriptions: [],
  tier: null,
}

export default function subscriptionStatus (state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_SUBSCRIPTION_STATUS:
      return Object.assign(state, _get(action, 'data', {}))
    case UPDATE_SUBSCRIPTION_ISAUTORENEW:
      return {
        ...state,
        isAutoRenew: _get(action, 'isAutoRenew', null),
      }
    default:
      return state
  }
}
