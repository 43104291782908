import * as Sentry from '@sentry/browser'
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  Offline as OfflineIntegration,
} from '@sentry/integrations'

import { supportedBrowsers } from './supported-browsers'
import { config as commonSentryConfig } from './config'
import { Cookies } from 'react-cookie'

export const useSentrySetup = () => {
  const cookie = new Cookies()
  const user = cookie.get('scmp_user') || {}

  Sentry.init({
    ...commonSentryConfig,
    beforeSend: event => {
      // Filter out those browser is not in our supported list
      if (!supportedBrowsers.test(navigator.userAgent)) return null
      return event
    },
    integrations: [
      new ExtraErrorDataIntegration({ depth: 6 }),
      new OfflineIntegration({ maxStoredEvents: 100 }),
    ],
  })

  if (!user) return
  Sentry.configureScope(scope => {
    scope.setUser({
      ...user,
    })
  })
}
