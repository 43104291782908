import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FlexContainer } from 'components/flex'

import { ReactComponent as FacebookLogo } from 'assets/svg/icon_fb.svg'
import { ReactComponent as GoogleLogo } from 'assets/svg/icon_google.svg'
import { ReactComponent as AppleLogo } from 'assets/svg/icon_apple.svg'
import { ReactComponent as FacebookLogoDeactivate } from 'assets/svg/icon_fb_deactivate.svg'
import { ReactComponent as GoogleLogoDeactivate } from 'assets/svg/icon_google_deactivate.svg'
import { ReactComponent as AppleLogoDeactivate } from 'assets/svg/icon_apple_deactivate.svg'
import { MyProfileButton } from '../myProfile/'
import useSocialData from 'universal/data/useSocialData'

type Props = {
  className?: string,
}

const ConnectedPanel: React.FunctionComponent<Props> = ({ className }) => {
  const { appleData, facebookData, googleData } = useSocialData()

  return (
    <ConnectedPanelContainer className={className}>
      <StyledLink to='/manage/get-connected'>
        <ManageButtonContainer className={className}>
          <StyledMyProfileButton className={className} buttonTitle='Get Connected' />
          <SoicalMediaGroup className={className}>
            { googleData.name ? <IconGoogle /> : <IconGoogleDeactivate /> }
            { facebookData.name ? <IconFacebook /> : <IconFacebookDeactivate /> }
            { appleData.name ? <IconApple /> : <IconAppleDeactivate /> }
          </SoicalMediaGroup>
        </ManageButtonContainer>
      </StyledLink>
    </ConnectedPanelContainer>
  )
}

const ConnectedPanelContainer = styled.div`
  background-color: #ffffff;
  &.my-profile{
    width: 100%;
  }
  &.edit-profile{
    width: 100%;
    max-width: 344px;
    box-sizing: border-box;
    height: 53px;
    border: 1px solid #D7D7D7;
    border-radius: 2px;
    margin-top: 28px;
  }
`

const ManageButtonContainer = styled(FlexContainer)`
  &.my-profile{
    flex-direction: column-reverse;
    justify-content: center;
    height: 100%;
  }
  &.edit-profile{
    max-width: 344px;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`

const StyledMyProfileButton = styled(MyProfileButton)`
  &.my-profile{
    margin-top: 13px;
  }
`

const SoicalMediaGroup = styled.div`
  &.edit-profile{
    margin-left: 16px;
  }
`

const IconGoogle = styled(GoogleLogo)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const IconFacebook = styled(FacebookLogo)`
  margin-left: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const IconApple = styled(AppleLogo)`
  margin-left: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const IconGoogleDeactivate = styled(GoogleLogoDeactivate)`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const IconFacebookDeactivate = styled(FacebookLogoDeactivate)`
  margin-left: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`
const IconAppleDeactivate = styled(AppleLogoDeactivate)`
  margin-left: 12px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const StyledLink = styled(Link)`
  width: 100%;
`
export default ConnectedPanel
