import styled from 'styled-components'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'states/reducers'
import { hideOnMedia, media, showOnMedia } from 'utils/style'
import { ReactComponent as TickSvg } from '../../assets/svg/icon_signedup_tick.svg'
import { NODE_ENV } from 'config/app.config'
import { FlexContainer } from 'components/flex'
import { triggerGtmEvent } from 'utils/tracking'
import {
  DataLayerEventCategory,
  DataLayerGtmCustomEventName,
  DataLayerEventAction,
} from 'config/constants'

type Props = {
  trackingGTM: () => void,
  impressionTracking: () => void,
}

const DefaultSubscriptionContent: React.FunctionComponent<Props> = ({ ...Props }) => {
  const isWinback = useSelector((state: AppState) => state.subscriptionStatus.scmpWinback.isEligible)
  const { trackingGTM, impressionTracking } = Props

  useEffect(() => {
    impressionTracking()
  }, [])

  const trackBenefit = (campaign: any) => {
    triggerGtmEvent(
      DataLayerEventAction.SUBSCRIBE_TO_SCMP_CLICK_URL,
      DataLayerEventCategory.SUBSCRIPTION,
      DataLayerGtmCustomEventName.TRACKING,
      campaign,
    )
  }

  return (
    <SubscriptionContent flexDirection='row' justifyContent='space-between'>
      <SubscriptionInfo flexDirection='column'>
        <SubscriptionStatus>Are you ready to start a digital subscription plan?</SubscriptionStatus>
        <SubscriptionButtonContainer showOn='mobile'>
          <SubscriptionButton href={NODE_ENV === 'production' ? 'https://subscribe.scmp.com/' : 'https://staging-rosetta.product-web.dev-2.scmp.tech/'} onClick={trackingGTM}>
            {isWinback ? 'REACTIVATE' : 'SUBSCRIBE'}
          </SubscriptionButton>
        </SubscriptionButtonContainer>
        <SubscriptionInfoList>
          <SubscriptionInfoItem wrap='no-wrap'>
            <StyledTick />
            <SubscriptionInfoWording>
              Get unlimited access to our award-winning coverage across the website and app
            </SubscriptionInfoWording>
          </SubscriptionInfoItem>
          <SubscriptionInfoItem wrap='no-wrap'>
            <StyledTick />
            <SubscriptionInfoWording>
              Unlock <Underline href="https://multimedia.scmp.com/infographics/news/china/article/3185672/ccp-politburo/index.html" target="_blank" onClick={() => trackBenefit('databank')}>exclusive analysis</Underline> for annual subscribers<GrayText> (1-year and 2-year plans)</GrayText>
            </SubscriptionInfoWording>
          </SubscriptionInfoItem>
          <SubscriptionInfoItem wrap='no-wrap'>
            <StyledTick />
            <SubscriptionInfoWording>
              Explore past stories from our online archive
            </SubscriptionInfoWording>
          </SubscriptionInfoItem>
          <SubscriptionInfoItem wrap='no-wrap'>
            <StyledTick />
            <SubscriptionInfoWording>
              Discover subscriber-only newsletters written and curated by our senior editors
            </SubscriptionInfoWording>
          </SubscriptionInfoItem>
        </SubscriptionInfoList>
      </SubscriptionInfo>
      <SubscriptionButtonContainer hideOn='mobile'>
        <SubscriptionButton href={NODE_ENV === 'production' ? 'https://subscribe.scmp.com/' : 'https://staging-rosetta.product-web.dev-2.scmp.tech/'} onClick={trackingGTM}>
          {isWinback ? 'REACTIVATE' : 'SUBSCRIBE'}
        </SubscriptionButton>
      </SubscriptionButtonContainer>
    </SubscriptionContent>
  )
}

interface CommonStyle {
  background?: string
  showOn?: string
  hideOn?: string
}

const StyledTick = styled(TickSvg)`
  margin-right: 8px;
  height: 16px;
`

const SubscriptionInfo = styled(FlexContainer)`
  padding-top: 24px;
  padding-bottom: 25px;
  width: 100%;
  ${media.tabletUp`
    width: auto;
    padding: 0;
  `}
`

const SubscriptionInfoList = styled.div`
  ${media.tabletUp`
    margin-top: 20px;
    div:first-child {
      margin-top: 0;
    }
  `}
`

const SubscriptionInfoItem = styled(FlexContainer)`
  padding-left: 27px;
  padding-right: 27px;
  margin-bottom: 12px;
  ${media.tabletUp`
    padding: 0;
    margin-top: 12px;
    margin-bottom: 0;
  `}
`

const SubscriptionInfoWording = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  width: 100%;
  white-space: none;
  ${media.tabletUp`
    max-width: 100%;
    white-space: nowrap;
  `}
`

const SubscriptionContent = styled(FlexContainer)`
  flex-flow: initial;
  border: 2px solid #FFCA05;
  border-radius: 4px;
  width: 100%;
  ${media.tabletUp`
    padding: 25px 29px 28px 29px;
  `}
`

const SubscriptionStatus = styled.div`
  color: #000000;
  font-family: Merriweather;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  margin: 0 20px;
  ${media.tabletUp`
    margin: 0;
    text-align: left;
    width: 483px;
    line-height: 26px;
    font-size: 18px;
  `}
`

const SubscriptionButtonContainer = styled.div<CommonStyle>`
  margin-top: 26px;
  text-align: center;
  margin-bottom: 26px;
  ${media.tabletUp`
    text-align: left;
    margin-top: 10px;
    margin-bottom: 0;
  `}
  ${({ showOn }) => showOn ? showOnMedia([showOn]) : ''}
  ${({ hideOn }) => hideOn ? hideOnMedia([hideOn]) : ''}
`

const SubscriptionButton = styled.a`
  border-radius: 2px;
  background-color: #FFCA05;
  color: #001246;
  font-family: Roboto;
  font-size: 16px;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  padding: 10px 15px;
`
const GrayText = styled.span`
  color: #666;
`
const Underline = styled.a`
  text-decoration: underline;
  color: black;
`
export default DefaultSubscriptionContent
