import _merge from 'lodash/merge'

import { MyAccountSubscriptionEventParameters, triggerGA4GtmEvent } from '../../utils/tracking'
import { useCallback } from 'react'
import RosettaSDK from '@product/rosetta-sdk'

export const useTrackRosettaGA4Event = () => {
  const trackRosettaGA4Event = useCallback(
    (params) => {
      const baseParams: MyAccountSubscriptionEventParameters = RosettaSDK.rulesEngine.getOutcome('tracking.ga4.baseParameters')
      triggerGA4GtmEvent(_merge({}, baseParams, params))
    },
    [triggerGA4GtmEvent],
  )

  return {
    trackRosettaGA4Event,
  }
}

export const useRosetta = () => {
  const engine = RosettaSDK.rulesEngine

  return {
    hasActiveEpaperConversions: engine.getFact('hasActiveEpaperConversions') as boolean,
  }
}
