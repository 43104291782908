import { useQuery, useMutation } from '@apollo/react-hooks'
import _get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import DropDown, { LabelArrowStyle, LabelTheme } from 'components/menu/DropDown'
import { DropDownMenuItem } from 'components/menu/DropDownMenu'
import { FlexContainer } from 'components/flex'
import { Loading } from 'components/Loading'

import { SCMP_COM_HOST, SCMP_COM_PROTOCOL } from 'config/app.config'
import { ErrorMessage } from 'config/constants'

import * as modalAction from 'states/actions/application'

import { GetCurrentUserQuery } from 'typings/c3po/graphql'

import GET_CURRENT_USER from 'universal/apollo/c3po/GET_CURRENT_USER.gql'
import GET_USER_CONTEXT from 'universal/apollo/d8/GET_USER_CONTEXT.gql'
import UPDATE_USER_CONTEXT from 'universal/apollo/d8/UPDATE_USER_CONTEXT.gql'

import { media } from 'utils/style'

import CommentList from './List'

const years: DropDownMenuItem[] = []
for (let i = 2020; i >= 1980; i--) {
  years.push({ key: i.toString(), value: i.toString() })
}

const months: DropDownMenuItem[] = [
  { key: 'Jan', value: 'Jan' },
  { key: 'Feb', value: 'Feb' },
  { key: 'Mar', value: 'Mar' },
  { key: 'Apr', value: 'Apr' },
  { key: 'May', value: 'May' },
  { key: 'Jun', value: 'Jun' },
  { key: 'Jul', value: 'Jul' },
  { key: 'Aug', value: 'Aug' },
  { key: 'Sep', value: 'Sep' },
  { key: 'Oct', value: 'Oct' },
  { key: 'Nov', value: 'Nov' },
  { key: 'Dec', value: 'Dec' },
]

const Comments: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const [pageCursor, updatePageCursor] = useState<any>(null)
  const [comments, setComments] = useState<any>([])
  const [entityId, updateEntityId] = useState<string>('')
  const [notificationChecked] = useState<boolean>(false)
  const [activeYear, updateActiveYear] = useState<DropDownMenuItem>(years[0])
  const [activeMonth, updateActiveMonth] = useState<DropDownMenuItem>(months[0])
  const [isYearOpen, updateIsYearOpen] = useState<boolean>(false)
  const [isMonthOpen, updateIsMonthOpen] = useState<boolean>(false)

  useEffect(() => {
    dispatch(modalAction.updateZendeskVisibility(false))
    return () => {
      dispatch(modalAction.updateZendeskVisibility(true))
    }
  }, [dispatch])

  useEffect(() => {
    if (isYearOpen) {
      updateIsMonthOpen(false)
    }
  }, [isYearOpen])

  useEffect(() => {
    if (isMonthOpen) {
      updateIsYearOpen(false)
    }
  }, [isMonthOpen])

  const container: any = React.createRef()

  // let noData = false
  const limitAmount = 5

  const { loading, data } = useQuery<GetCurrentUserQuery>(GET_CURRENT_USER, {
    variables: {
      limit: limitAmount,
      filter: {
        commentAfter: pageCursor,
        replyAfter: pageCursor,
      },
    },
    context: {
      clientName: 'c3po',
      headers: {
        'x-coral-auth': true,
      },
    },
  })

  const profile = useQuery(GET_USER_CONTEXT, { variables: { typeValue: 'terms_and_preferences' } })
  let commentsPageInfoHasNextPage = _get(data, 'currentUser.comments.pageInfo.hasNextPage')

  useEffect(() => {
    setComments([...comments, ..._get(data, 'currentUser.comments.nodes', [])])
  }, [data])

  useEffect(() => {
    const profileEntityId: string = _get(profile, 'data.currentUserContext.reverseUidProfile.entities[0].entityId')
    updateEntityId(profileEntityId ? profileEntityId.toString() : '')
  }, [profile])

  const onLoadMore = () => {
    if (loading) {
      return false
    }

    updatePageCursor(_get(data, 'currentUser.comments.pageInfo.endCursor', null))
    commentsPageInfoHasNextPage = _get(data, 'currentUser.comments.pageInfo.hasNextPage', null)
  }

  useMutation(UPDATE_USER_CONTEXT, {
    onCompleted: (response) => {
    },
    onError: () => {
      dispatch(modalAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
    },
    variables: {
      uid: entityId,
      input: { fieldCommentEmailNotification: notificationChecked ? '0' : '1' },
    },
  })

  const onClickYearHandler = (item: any) => {
    updateActiveYear(item)
    updateIsYearOpen(false)
  }

  const onClickMonthHandler = (item: any) => {
    updateActiveMonth(item)
    updateIsMonthOpen(false)
  }

  return (
    <Comment ref={container}>
      <FilterMenuContainer alignItem='center'>
        <FilterMenu>
          { '' }
          { false && <DropDown activeItem={activeYear}
            items={years}
            labelArrowStyle={LabelArrowStyle.DOUBLE_ARROW}
            labelTheme={LabelTheme.BLACK}
            labelCustomStyle={{ marginRight: '16px' }}
            menuCustomStyle={{ minWidth: '50px' }}
            onClickHandler={onClickYearHandler}
            isOpen={isYearOpen}
            updateIsOpen={updateIsYearOpen}
          /> }
          { false && <DropDown activeItem={activeMonth}
            items={months}
            labelArrowStyle={LabelArrowStyle.DOUBLE_ARROW}
            labelTheme={LabelTheme.BLACK}
            menuCustomStyle={{ minWidth: '50px' }}
            onClickHandler={onClickMonthHandler}
            isOpen={isMonthOpen}
            updateIsOpen={updateIsMonthOpen}
          /> }
        </FilterMenu>
        <NotificationLink to={'/manage/notifications'}>Notifications Setting</NotificationLink>
      </FilterMenuContainer>
      { comments.length > 0 && <CommentList comments={comments} loading={loading} loadMoreHandler={onLoadMore} hasMoreConversations={commentsPageInfoHasNextPage}></CommentList> }
      { !loading && comments.length <= 0
        ? (<EmptyMessageContainer>
          <EmptyMessage>Your conversations will show here.</EmptyMessage>
          <a href={`${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}`} > <StartExploring>START EXPLORING</StartExploring></a>
        </EmptyMessageContainer>)
        : null
      }
      { loading ? <LoadingWrapper><LoadingContainer></LoadingContainer></LoadingWrapper> : null}
    </Comment>
  )
}

interface CommonStyle {
  hideOn?: string
}

const Comment = styled.div`
  width: 100%;
  ${media.tabletUp`
    max-width: 680px;
  `}
`

const FilterMenuContainer = styled(FlexContainer)`
  padding: 0 16px;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  ${media.tabletUp`
    height: 43px;
    align-items: flex-start;
  `}
`

const FilterMenu = styled(FlexContainer)<CommonStyle>`
  font-family: 'Roboto Condensed';
  color: #001246;
  height: 31px;
`

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
`

const LoadingContainer = styled(Loading)`
  margin-top: 20px;
`

const NotificationLink = styled(Link)`
  font-family: 'Roboto';
  height: 16px;
  color: #4585FF;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  ${media.tabletUp`
    margin-top: 8px;
  `}
`

const EmptyMessageContainer = styled.div`
  background: #F2F2F2;
  padding: 24px 0;
  height: 100vh;
  ${media.tabletUp`
    height: auto;
  `}
`

const EmptyMessage = styled.div`
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
`

const StartExploring = styled.div`
  color: #4585FF;
  font-family: Roboto;
  text-align: center;
  padding: 8.5px 0;
  width: 150px;
  margin: auto;
  border: 1px solid #4585FF;
  border-radius: 2px;
  cursor: pointer;
  margin-top: 20px;
  color: #4585FF;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
`

export default Comments
