import _isEmpty from 'lodash/isEmpty'
import React, { useEffect, useRef } from 'react'
import { Cookies } from 'react-cookie'
import TagManager from 'react-gtm-module'
import { useDispatch, useSelector } from 'react-redux'

import { GTM_CONTAINER_ID } from 'config/app.config'
import { TRACKING_USER_ROLE } from 'config/constants'

import { AppState } from 'states/reducers'
import * as applicationAction from 'states/actions/application'

import { triggerExternalGtmEvent, triggerUserPropertiesGtmEvent } from 'utils/tracking'

interface GetGTMUserRoleArgs {
  isLoggedIn: boolean
  userRoleFromRosettaSDK: string
}

const getGTMUserRole = (args: GetGTMUserRoleArgs) => {
  const { isLoggedIn, userRoleFromRosettaSDK } = args

  if (_isEmpty(userRoleFromRosettaSDK)) {
    const fallbackUserRole = isLoggedIn ? TRACKING_USER_ROLE.AUTHENTICATED_USER : TRACKING_USER_ROLE.ANONYMOUS_USER
    return fallbackUserRole
  }

  return userRoleFromRosettaSDK
}

type Props = {
  userRole: string
  ga4UserRole: string
}

const Tracking: React.FunctionComponent<Props> = (props) => {
  const { userRole, ga4UserRole } = props

  const cookie = new Cookies()
  const isPianoSubscriberDataReady = useSelector((state: AppState) => state.userInfo.isPianoSubscriberDataReady)
  const isPianoSubscriberDataReadyRef = useRef(false)
  isPianoSubscriberDataReadyRef.current = false
  const dispatch = useDispatch()

  const tagManagerArgs = {
    gtmId: GTM_CONTAINER_ID,
  }

  let tagManagerInitTimestamp = 0
  const checkPianoReadyBeforeTagManagerInit = () => {
    if (isPianoSubscriberDataReadyRef.current === true || tagManagerInitTimestamp > 3000) {
      dispatch(applicationAction.updateIsGtmReady(true))
      TagManager.initialize(tagManagerArgs)
    } else {
      tagManagerInitTimestamp += 200
      setTimeout(function () {
        checkPianoReadyBeforeTagManagerInit()
      }, 200)
    }
  }

  useEffect(() => {
    const loginType = cookie.get('scmp_user_login_type')
    if (loginType) {
      triggerUserPropertiesGtmEvent({ loginType })
    }
  }, [triggerUserPropertiesGtmEvent])

  useEffect(() => {
    if (isPianoSubscriberDataReady === true) {
      isPianoSubscriberDataReadyRef.current = true
    }
  }, [isPianoSubscriberDataReady])

  useEffect(() => {
    checkPianoReadyBeforeTagManagerInit()
  }, [])

  useEffect(() => {
    const isLoggedIn = !_isEmpty(cookie.get('scmp_user'))
    /** UA event */
    triggerExternalGtmEvent({ page: { userRole: getGTMUserRole({ isLoggedIn, userRoleFromRosettaSDK: userRole }) } })
  }, [userRole])

  useEffect(() => {
    const isLoggedIn = !_isEmpty(cookie.get('scmp_user'))
    /** GA4 event */
    triggerUserPropertiesGtmEvent({ ga4UserRole: getGTMUserRole({ isLoggedIn, userRoleFromRosettaSDK: ga4UserRole }) })
  }, [ga4UserRole])

  return null
}

export default Tracking
