// for route setting
export const AUTH_ONLY = 'AUTH_ONLY'
export const NO_CONTAINER_AUTH_ONLY = 'NO_CONTAINER_AUTH_ONLY'

// header labels
export enum HeaderLabelMap {
  COMMENT = 'My Conversations',
  FOLLOWING = 'Your Followings',
  BOOKMARK = 'Your Bookmarks',
  HISTORY = 'Reading History',
  NEWSLETTER = 'Newsletter',
  EDIT_PROFILE = 'Edit Profile',
  CHANGE_PASSWORD = 'Change Password',
  BOOKMARKS = 'Bookmarks'
}

export enum SCMP_DOMAINS {
  SCMP = 'scmp.com',
  SCMP_STAGING = 'scmp.tech',
  ABACUS = 'abacusnews.com',
  INKSTONE = 'inkstonenews.com',
  GOLDTHREAD = 'goldthread2.com',
}

export enum VALID_AMP_DOMAINS_REGEX {
  AMP = 'http[s]?:\\/\\/(.*?\\.[google|ampproject]+)\\.(.*?)\\/(.*?)\\/amp\\.scmp\\.com\\/(.*)?',
  BING_AMP = 'http[s]?:\\/\\/(.*?\\.[bing\\-amp]+)\\.(.*?)\\/(.*?)',
}

export enum WHITELIST_IFRAME_DOMAINS {
  SCMP = 'scmp.com',
  SCMP_STAGING = 'scmp.tech',
  ABACUS = 'abacusnews.com',
  INKSTONE = 'inkstonenews.com',
  GOLDTHREAD = 'goldthread2.com',
  GOOGLE = 'google.com',
}

export enum SCMP_USER_LEVEL_ID {
  SCMP_EDITORIAL = 506103,
  SCMP_EXPERT = 506105,
  SCMP_STAFF = 506104,
}

export enum USER_PLUGIN_ID {
  SOCIAL_AUTH_FACEBOOK = 'social_auth_facebook',
  SOCIAL_AUTH_GOOGLE = 'social_auth_google',
  SOCIAL_AUTH_APPLE = 'social_auth_apple',
}

export enum REGISTER_TYPE {
  EMAIL = 'email',
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  GOOGLE_GSUITE = 'google:gsuite',
  GOOGLE_YOLO = 'google:yolo',
  APPLE = 'apple',
  PIANO_SWG = 'piano:swg',
  PIANO_EMAIL = 'piano:email',
}

export enum TRACKING_USER_ROLE {
  ANONYMOUS_USER = 'anonymous user',
  AUTHENTICATED_USER = 'authenticated user',
  PASSWORDLESS_USER = 'authenticated user - Register (passwordless)',
  EMAIL = 'authenticated user - Register (Email)',
  FACEBOOK = 'authenticated user - Register (Facebook)',
  GOOGLE = 'authenticated user - Register (Google)',
  GOOGLE_YOLO = 'authenticated user - Register (Google one-tap)',
  APPLE = 'authenticated user - Register (Apple)',
  SUBSCRIBER_FACEBOOK = 'Subscriber (Facebook)',
  SUBSCRIBER_GOOGLE = 'Subscriber (Google)',
  SUBSCRIBER_EMAIL = 'Subscriber (Email)',
  SUBSCRIBER_GOOGLE_YOLO = 'Subscriber (Google one-tap)',
  SUBSCRIBER_APPLE = 'Subscriber (Apple)',
  NL_FACEBOOK = 'authenticated user - Register (NL-Facebook)',
  NL_GOOGLE = 'authenticated user - Register (NL-Google)',
  NL_EMAIL = 'authenticated user - Register (NL-Email)',
  CHURN_NL = 'Churn (No login)',
  CHURN_FACEBOOK = 'Churn (Facebook)',
  CHURN_GOOGLE = 'Churn (Google)',
  CHURN_GOOGLE_YOLO = 'Churn (Google one-tap)',
  CHURN_EMAIL = 'Churn (Email)',
  CHURN_APPLE = 'Churn (Apple)',
}

export enum CORP_SUBCRIBER_TIER {
  T0 = 'T0',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  C_SUITE = 'C-suite',
  EXPERT_NETWORK = 'Expert Network'
}

export enum ErrorMessage {
  ACTIVATION_MISSING_FIRST_AND_LAST_NAME = 'Please enter your first name and last name',
  CHNAGE_PASSWORD_DOES_NOT_MATCH = 'Your passwords do not match',
  CHANGE_PASSWORD_MISSING_CONFIRM_PASSWORD = 'Please enter your password again',
  CHANGE_PASSWORD_MISSING_NEW_PASSWORD = 'Please enter your password',
  CHANGE_PASSWORD_MISSING_OLD_PASSWORD = 'Please enter your old password',
  CHANGE_PASSWORD_NOT_STRONG_ENOUGH = 'The new password you have entered is not strong enough. Please ensure your password includes uppercase and lowercase, at least one special character (e.g. @#) and uses a combination of alpha and numeric characters',
  CHANGE_PASSWORD_UPDATE_FAILED = 'Sorry, cannot update your password',
  CHANGE_PASSWORD_UPDATE_SUCCESS = 'Your password has been updated',
  EDIT_PROFILE_MANDATORY_FIELD = 'Please enter all required fields',
  FAIL_TO_VERIFY_RECAPTCHA = 'Failed to verify ReCaptcha. Please try again',
  FAIL_TO_GENERATE_RECAPTCHA = 'There is an error occurred, please try again later',
  FORGOT_PASSWORD_MISSING_EMAIL = 'Please enter your email address',
  FORGOT_PASSWORD_NO_EXISTING_EMAIL = 'The email address entered isn\'t in our system. Please check and try again',
  FORGOT_PASSWORD_WRONG_EMAIL = 'Please enter a valid email address',
  LOGIN_EMAIL_DOMAIN_BLOCKED = 'There is an error occurred, please try with another email address.',
  LOGIN_INCORRECT_CONFIRM_EMAIL = 'Email address is incorrect',
  LOGIN_MISSING_EMAIL = 'Please enter your email address',
  LOGIN_MISSING_EMAIL_AND_PASSWORD = 'Please enter your email address and password',
  LOGIN_MISSING_PASSWORD = 'Please enter your password',
  LOGIN_WRONG_EMAIL_AND_PASSWORD = 'Your email address or password is incorrect. Please check and try again',
  REGISTER_MISSING_EMAIL_OR_PASSWORD = 'Please enter your email address and password',
  REGISTER_PASSWORD_DOES_NOT_MATCH = 'Your passwords do not match',
  REGISTER_MISSING_FIRST_AND_LAST_NAME = 'Please enter your first name and last name',
  REGISTER_EMAIL_ALREADY_EXIST = 'Your email address is already registered. Please login or register using an alternate email address',
  PROFILE_UPDATE_SUCCESS = 'Your profile has been updated',
  PROFILE_UPDATE_FAILED = 'Sorry, cannot update your profile',
  REGISTER_PASSWORD_NOT_STRONG_ENOUGH = 'The new password you have entered is not strong enough. Please ensure your password includes uppercase and lowercase, at least one special character (e.g. @#) and uses a combination of alpha and numeric characters',
  REGISTER_VALID_FIRST_AND_LAST_NAME = 'Please enter a valid first name and last name',
  REGISTER_WRONG_EMAIL = 'Please enter a valid email address',
  CHANGE_PASSWORD_DOES_NOT_MATCH = 'Your passwords do not match',
  SOMETHING_GOES_WRONG = 'Sorry, something went wrong.',
  CHANGE_EMAIL_ADDRESS_WRONG_EMAIL = 'Please enter a valid email address',
  CHANGE_EMAIL_ADDRESS_MISSING_EMAIL = 'Please enter your email address',
  CHANGE_EMAIL_ADDRESS_DOES_NOT_MATCH = 'Your email address does not match',
  CHANGE_EMAIL_ADDRESS_MISSING_CONFIRM_EMAIL = 'Please enter your email address again',
  BLOCKED_USER = 'Your account is blocked, please contact our support at digitalsupport@scmp.com for further assistance.'
}

// The name is used to match the custom event name in gtm
export enum DataLayerGtmCustomEventName {
  LOGIN = 'login',
  COMMENT = 'comment',
  TRACKING = 'tracking',
  MY_ACCOUNT = 'my-account',
}

export enum DataLayerEventCategory {
  LOGIN = 'Login',
  COMMENT = 'Comment',
  SUBSCRIPTION = 'Subscription Entry Point',
  SUBSCRIPTION_MANAGEMENT = 'Subscription Management',
  NOTIFICATION_CENTER = 'Notification Center',
  BOOKMARK = 'Bookmark',
  MY_ACCOUNT = 'My Account',
}

export enum DataLayerEventAction {
  // facebook
  FACEBOOK_LOGIN = 'Login/Facebook/Click',
  FACEBOOK_REGISTER = 'Login/Facebook/Register',
  FACEBOOK_AUTH_SUCCESS = 'Login/Facebook/Success',
  FACEBOOK_AUTH_FAIL = 'Login/Facebook/Fail',
  FACEBOOK_CONNECT = 'Connect/Facebook',
  FACEBOOK_DISCONNECT_SUCCESS = 'Disconnect/Facebook/Success',
  FACEBOOK_DISCONNECT_FAIL = 'Disconnect/Facebook/Fail',
  FACEBOOK_LOGIN_AFTER_EMAIL = 'Login/Email/Social login/Facebook',
  // google
  GOOGLE_LOGIN = 'Login/Google/Click',
  GOOGLE_REGISTER = 'Login/Google/Register',
  GOOGLE_REGISTER_SUCCESS = 'Login/Google/Register/Success',
  GOOGLE_AUTH_SUCCESS = 'Login/Google/Success',
  GOOGLE_LOGIN_SUCCESS = 'Login/Google/Login/Success',
  GOOGLE_AUTH_FAIL = 'Login/Google/Fail',
  GOOGLE_CONNECT = 'Connect/Google',
  GOOGLE_DISCONNECT_SUCCESS = 'Disconnect/Google',
  GOOGLE_DISCONNECT_FAIL = 'Disconnect/Google/Fail',
  GOOGLE_LOGIN_AFTER_EMAIL = 'Login/Email/Social login/Google',
  // apple
  APPLE_LOGIN = 'Login/Apple/Click',
  APPLE_REGISTER = 'Login/Apple/Register',
  APPLE_AUTH_SUCCESS = 'Login/Apple/Success',
  APPLE_AUTH_FAIL = 'Login/Apple/Fail',
  APPLE_CONNECT = 'Connect/Apple',
  APPLE_DISCONNECT_SUCCESS = 'Disconnect/Apple',
  APPLE_DISCONNECT_FAIL = 'Disconnect/Apple/Fail',
  APPLE_LOGIN_AFTER_EMAIL = 'Login/Email/Social login/Apple',
  // custom email
  EMAIL_LOGIN = 'Login/Email/Click',
  EMAIL_REGISTER = 'Login/Email/Register/Success',
  EMAIL_REGISTER_FAIL = 'Login/Email/Register/Fail',
  EMAIL_AUTH_SUCCESS = 'Login/Email/Login/Success',
  EMAIL_AUTH_FAIL = 'Login/Email/Login/Fail',
  EMAIL_INPUT = 'Login/Email/Input email/Click',
  EMAIL_CONFIRM_PAGE = 'Login/Email/Confirm email/Impression',
  EMAIL_CONFIRM_INPUT = 'Login/Email/Confirm email/Click',
  EMAIL_CHECK_INBOX = 'Login/Email/Check you inbox/Click',
  EMAIL_CHECK_INBOX_FIRST_TIME = 'Login/Email/Check you inbox (first time)/Click',
  EMAIL_ENTER_PASSWORD = 'Login/Email/Enter your password/Click',
  EMAIL_ENTER_PASSWORD_SUCCESS = 'Login/Email/Enter your password/Success',
  EMAIL_ENTER_PASSWORD_FAIL = 'Login/Email/Enter your password/Failed',
  // Reset password
  RESET_PASSWORD_CANCEL_CLICK = 'Login/Reset password/Cancel',
  RESET_PASSWORD_SUCCESS = 'Login/Reset password/Success',
  RESET_PASSWORD_FAIL = 'Login/Reset password/Fail',
  // password update
  PASSWORD_UPDATED_LOGIN_CLICK = 'Login/Password updated/Login/Click',
  // forget pwd
  FORGET_PASSWORD = 'Login/ForgetPassword/Click',
  SUMBIT_COMMENT = 'Comment Button/Submit Comment',
  // resend activation email
  RESEND_ACTIVATION_EMAIL = 'Login/Email/Re-send activation email/Click',
  // activation
  ACTIVATION_CLICK = 'Login/Email/Activation/Click',
  ACTIVATION_NEWSLETTER_CLICK = 'Login/Newsletter/Email/Activation/Click',
  // Subscription
  SUBSCRIPTION_MY_ACCOUNT_INDEX = 'Subscription Entry Point/My Account/My Account Index',
  SUBSCRIPTION_PAGE = 'Subscription Entry Point/My Account/Subscription Page',
  SUBSCRIPTION_EXPIRY_REMINDERS_IMPRESSION = 'Subscription Management - Account App/Expiry Reminders/Impression',
  // Bookmark
  BOOKMARK_REMOVE = 'Bookmark/Article/Remove',
  // Notification center
  NOTIFICATION_CENTER_DAILY_EMAIL_ON = 'My Account/Daily email news alert/On',
  NOTIFICATION_CENTER_DAILY_EMAIL_OFF = 'My Account/Daily email news alert/Off',
  // Unfollow
  UNFOLLOW_TOPIC = 'My Account/Unfollow/Topic',
  UNFOLLOW_AUTHOR = 'My Account/Unfollow/Author',
  UNFOLLOW_KNOWLEDGE = 'My Account/Unfollow/Knowledge',
  // Newsletter
  NEWSLETTER_UNSUBSCRIBE = 'My Account/Newsletters/Unsubscribe',
  // Manage Device
  REMOVE_DEVICE = 'My Account/Manage Devices/Remove',
  // Group Subscriber Benefits
  GROUP_SUBSCRIPTION_IMPRESSION = 'My Account/Group Subscribption Benefit/Impression',
  GROUP_SUBSCRIPTION_BENEFIT_DOWNLOAD_PDF = 'My Account/Group Subscribption Benefit/SCMP Research/Download',
  GROUP_SUBSCRIPTION_EVENT_CLICK = 'My Account/Group Subscribption Benefit/Event/Click',
  // App
  APP_DOWNLOAD_CLICK = 'My Account/Settings/App Download/Click',
  // Epaper
  EPAPER = 'My Account/Epaper',
  // Logout
  ACCOUNT_LOGOUT_CLICK = 'My Account/Log out/Click',
  ACCOUNT_LOGOUT = 'My Account/Log out',
  ACCOUNT_LOGOUT_CANCEL = 'My Account/Log out/Cancel',

  // SUBSCRIBE TO SCMP
  SUBSCRIBE_TO_SCMP_CLICK_URL = 'Subscribe to SCMP/Click URL',
}

export enum DataLayerEventLabel {
  CLICK_CONNECT_FACEBOOK = 'Click/Connect Facebook',
  CLICK_CONNECT_GOOGLE = 'Click/Connect Google',
  CLICK_CONNECT_APPLE = 'Click/Connect Apple',
  FACEBOOK_AUTH_SUCCESS = 'Comment Button/Social Connect/Facebook/Success',
  GOOGLE_AUTH_SUCCESS = 'Comment Button/Social Connect/Google/Success',
  APPLE_AUTH_SUCCESS = 'Comment Button/Social Connect/Apple/Success',
}

export const SocialMediaError = {
  google: {
    429: 'This feature is temporarily unavailable. Please try again later.',
    701: 'This Google account is already connected with a SCMP profile. Please try again using an alternate social account.',
    702: 'We’ve been unable to connect this account to your SCMP profile. Please try again. If the problem persists please try again using an alternate social account.',
    703: 'We’ve been unable to connect this account to your SCMP profile. Please try again. If the problem persists please try again using an alternate social account.',
    704: 'We’ve been unable to connect this account to your SCMP profile. Please try again. If the problem persists please try again using an alternate social account.',
    705: 'We’ve been unable to connect this account to your SCMP profile. Please try again using an alternate social account.',
    706: 'We’ve been unable to connect this account to your SCMP profile. Please try again. If the problem persists please try again using an alternate social account.',
    707: 'Your account is not yet verified with Google. Please verify your account and try again.',
  },
  facebook: {
    429: 'This feature is temporarily unavailable. Please try again later.',
    701: 'This Facebook account is already connected with a SCMP profile. Please try again using an alternate social account.',
    702: 'We’ve been unable to connect this account to your SCMP profile. Please try again. If the problem persists please try again using an alternate social account.',
    703: 'We’ve been unable to connect this account to your SCMP profile. Please try again. If the problem persists please try again using an alternate social account.',
    704: 'We’ve been unable to connect this account to your SCMP profile. Please try again. If the problem persists please try again using an alternate social account.',
    705: 'We’ve been unable to connect this account to your SCMP profile. Please try again using an alternate social account.',
    706: 'We’ve been unable to connect this account to your SCMP profile. Please try again. If the problem persists please try again using an alternate social account.',
  },
  apple: {
    429: 'This feature is temporarily unavailable. Please try again later.',
    701: 'This Apple account is already connected with a SCMP profile. Please try again using an alternate social account.',
    702: 'We’ve been unable to connect this account to your SCMP profile. Please try again. If the problem persists please try again using an alternate social account.',
    703: 'We’ve been unable to connect this account to your SCMP profile. Please try again. If the problem persists please try again using an alternate social account.',
    704: 'We’ve been unable to connect this account to your SCMP profile. Please try again. If the problem persists please try again using an alternate social account.',
    705: 'We’ve been unable to connect this account to your SCMP profile. Please try again using an alternate social account.',
    706: 'We’ve been unable to connect this account to your SCMP profile. Please try again. If the problem persists please try again using an alternate social account.',
    707: 'Please check Apple ID setting and try again',
  },
}

export enum HttpStatusCodes {
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_ACCEPTABLE = 406,
  BAD_GATEWAY = 400,
}

// Hong Kong / Singapore / Malaysia / Indonesia / India / Brunei Darussalam / Burma (Myanmar) / Cambodia / Timor-Leste
// Laos / Thailand / Vietnam / Philippines
export const PIANO_SUPPORTED_LOCATION = 'HK SG MY ID IN BN MM KH TL LA TH VN PH'

/**
 * JWT remaining time (in seconds) to trigger refresh on client-entry
 */
// export const JWT_EXPIRY_THRESHOLD = 180
// TEMP: For test - trigger the refresh more frequently
export const JWT_EXPIRY_THRESHOLD = 1800

type ChangeEmailAddressMessageInterface = {
  [key: string]: string
}

export const ChangeEmailAddressMessage: ChangeEmailAddressMessageInterface = {
  200: 'Your account email address is updated, please login with your new email address.',
  730: 'Invalid email address, please try again or contact our support at',
  737: 'Invalid email change, please try again or contact our support at ',
  731: 'User is invalid or blocked, please try again or contact our support at ',
  732: 'Updating the email address is not available, please try again later or contact our support at ',
  733: 'You have tried to use an email change approval link that has expired. Please login and submit the email change request again.',
  734: 'The email address has changed since this approval link was sent. Please login and submit the email change request again, if required.',
  735: 'You have tried to use an email change approval link that is no longer valid. Please login and submit the email change request again if necessary.',
  736: 'An account already exists for new email. Unable to change email.',
  740: 'An error has occurred, please login and try again.',
  741: 'An error has occurred, please try again later.',
  743: 'User account has been blocked. Please contact our support at digitalsupport@scmp.com',
  744: 'Missing or invalid email address.',
  745: 'A user account with the same email address already exists.',
  746: 'Updating the email address is not available at this moment. Please contact our support at digitalsupport@scmp.com',
  747: 'Missing password.',
  748: 'Your password does not match.',
  749: 'The new email address is not allowed. Please change to another email.',
  SomethingWentWrong: 'Sorry, something went wrong. Please try again.',
}

export enum CodeMapping {
  CHANGE_EMAIL_ADDRESS_SUCCESS = '1001',
  CHANGE_EMAIL_ADDRESS_FAIL_INVALID_EMAIL_ADDRESS = '1002',
  CHANGE_EMAIL_ADDRESS_FAIL_INVALID_EMAIL_CHANGE = '1003',
  CHANGE_EMAIL_ADDRESS_FAIL_USER_IS_INVALID = '1004',
  CHANGE_EMAIL_ADDRESS_FAIL_UPDATE_NOT_AVALIABLE = '1005',
  CHANGE_EMAIL_ADDRESS_FAIL_APPROVAL_LINK_EXPIRED = '1006',
  CHANGE_EMAIL_ADDRESS_FAIL_EMAIL_CHANGED_ALREADY = '1007',
  CHANGE_EMAIL_ADDRESS_FAIL_APPROVAL_LINK_INVALID = '1008',
  CHANGE_EMAIL_ADDRESS_FAIL_NEW_EMAIL_ALREADY_EXISTS = '1009',
  CHANGE_EMAIL_ADDRESS_FAIL_UNKNOWN_ERROR = '1010',
}

type ResultPageDetail = {
  title: string,
  buttonText: string,
  redirectUrl: string,
  message: string,
  mailTo: string,
  logout: boolean,
}

type ResultPageDetailsInterface = {
  [key: string]: ResultPageDetail
}

export const ResultPageDetails: ResultPageDetailsInterface = {
  1001: {
    title: 'Email Updated',
    buttonText: 'OK',
    redirectUrl: '/login',
    message: 'Your account email address is updated, please login with your new email address.',
    mailTo: '',
    logout: true,
  },
  1002: {
    title: 'Email Update Failed',
    buttonText: 'Go Back',
    redirectUrl: '/login',
    message: 'Invalid email address, please try again or contact our support at [[email]]',
    mailTo: 'digitalsupport@scmp.com',
    logout: true,
  },
  1003: {
    title: 'Email Update Failed',
    buttonText: 'Go Back',
    redirectUrl: '/login',
    message: 'Invalid email change, please try again or contact our support at [[email]]',
    mailTo: 'digitalsupport@scmp.com',
    logout: true,
  },
  1004: {
    title: 'Email Update Failed',
    buttonText: 'Go Back',
    redirectUrl: '/login',
    message: 'User is invalid or blocked, please try again or contact our support at [[email]]',
    mailTo: 'digitalsupport@scmp.com',
    logout: true,
  },
  1005: {
    title: 'Email Update Failed',
    buttonText: 'Go Back',
    redirectUrl: '/login',
    message: 'Updating the email address is not available, please try again later or contact our support at [[email]]',
    mailTo: 'digitalsupport@scmp.com',
    logout: true,
  },
  1006: {
    title: 'Link expired',
    buttonText: 'Go Back',
    redirectUrl: '/login',
    message: 'Your email change approve link has expired. Please contact our support at [[email]] for any assistance, or you can login your account and submit the email change request again if necessary.',
    mailTo: 'digitalsupport@scmp.com',
    logout: true,
  },
  1007: {
    title: 'Email Update Failed',
    buttonText: 'Go Back',
    redirectUrl: '/login',
    message: 'The email address has changed since this approval link was sent. Please login and submit the email change request again, if required.',
    mailTo: '',
    logout: true,
  },
  1008: {
    title: 'Email Update Failed',
    buttonText: 'Go Back',
    redirectUrl: '/login',
    message: 'You have tried to use an email change approval link that is no longer valid. Please login and submit the email change request again if necessary.',
    mailTo: '',
    logout: true,
  },
  1009: {
    title: 'Email Update Failed',
    buttonText: 'Go Back',
    redirectUrl: '/login',
    message: 'An account already exists for new email. Unable to change email.',
    mailTo: '',
    logout: true,
  },
  1010: {
    title: 'Email Update Failed',
    buttonText: 'Go Back',
    redirectUrl: '/login',
    message: 'An error has occurred, please try again later.',
    mailTo: '',
    logout: true,
  },
  1011: {
    title: 'Link expired',
    buttonText: 'Go Back',
    redirectUrl: '/login',
    message: 'Your email change deny link has expired. Please contact our support at [[email]] for any assistance, or you can login your account and submit the email change request again if necessary.',
    mailTo: 'digitalsupport@scmp.com',
    logout: true,
  },
  1012: {
    title: 'Email change denied',
    buttonText: 'Go Back',
    redirectUrl: '/login',
    message: 'Your account email address update has been cancelled and your email address is restored to the previous one. Please try to login with the email address that you clicked the deny link from.',
    mailTo: '',
    logout: true,
  },
  1013: {
    title: 'Login Failed',
    buttonText: 'Go Back',
    redirectUrl: '/login',
    message: 'Your account is blocked, please contact our support at [[email]] for assistance.',
    mailTo: 'digitalsupport@scmp.com',
    logout: false,
  },
}

export const GA_CUSTOM_DIMENSION = {
  USER_ROLE: 'cd1',
  LOGIN_TYPE: 'cd42',
}

export const LOGIN_TYPE = {
  EMAIL: 'Email',
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google',
  APPLE: 'Apple',
  GOOGLE_YOLO: 'Google one-tap',
}

export const EVENT_ACTION = {
  LOGIN: 'Login',
  EMAIL_LOGIN_SUCCESS: 'Login/Email/Success',
  EMAIL_REGISTER_NEWSLETTER_CLICK: 'Login/Newsletter/Email/Input email/Click',
  EMAIL_LOGIN_FAIL: 'Login/Email/Fail',
  FACEBOOK_LOGIN_SUCCESS: 'Login/Facebook/Login/Success',
  FACEBOOK_LOGIN_FAIL: 'Login/Facebook/Login/Fail',
  FACEBOOK_REGISTER_SUCCESS: 'Login/Facebook/Register/Success',
  FACEBOOK_NEWSLETTER_LOGIN_SUCCESS: 'Login/Newsletter/Facebook/Login/Success',
  FACEBOOK_NEWSLETTER_LOGIN_FAIL: 'Login/Newsletter/Facebook/Login/Fail',
  FACEBOOK_NEWSLETTER_REGISTER_SUCCESS: 'Login/Newsletter/Facebook/Register/Success',
  FACEBOOK_CONNECT: 'Connect/Facebook',
  GOOGLE_LOGIN_SUCCESS: 'Login/Google/Login/Success',
  GOOGLE_LOGIN_FAIL: 'Login/Google/Login/Fail',
  GOOGLE_REGISTER_SUCCESS: 'Login/Google/Register/Success',
  GOOGLE_NEWSLETTER_LOGIN_SUCCESS: 'Login/Newsletter/Google/Login/Success',
  GOOGLE_NEWSLETTER_LOGIN_FAIL: 'Login/Newsletter/Google/Login/Fail',
  GOOGLE_NEWSLETTER_REGISTER_SUCCESS: 'Login/Newsletter/Google/Register/Success',
  GOOGLE_CONNECT: 'Connect/Google',
  APPLE_LOGIN_SUCCESS: 'Login/Apple/Login/Success',
  APPLE_LOGIN_FAIL: 'Login/Apple/Login/Fail',
  APPLE_REGISTER_SUCCESS: 'Login/Apple/Register/Success',
  APPLE_CONNECT: 'Connect/Apple',
  ONE_TAP_LOGIN_SUCCESS: 'Login/Google one-tap/Login/success',
  ONE_TAP_LOGIN_FAIL: 'Login/Google one-tap/Login/failed',
  ONE_TAP_REGISTER_SUCCESS: 'Login/Google one-tap/Register/success',
}
