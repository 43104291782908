import { Config } from '@product/rosetta-sdk'
import {
  PIANO_AID,
  PIANO_ENV,
  PIANO_API_HOST,
  PIANO_API_PROTOCOL,
  SETTING_ENV,
  GRAPHQL_D8_API_URI,
  GRAPHQL_CONTENTSERVICE_API_URI,
  GRAPHQL_CONTENTSERVICE_APIKEY,
  ROSETTA_PROTOCOL,
  ROSETTA_HOST,
} from 'config/app.config'

export function getRosettaSdkConfig (): Config {
  return {
    piano: {
      debug: false,
      aid: PIANO_AID,
      env: PIANO_ENV === 'production' ? 'production' : 'sandbox',
      apiUri: `${PIANO_API_PROTOCOL}://${PIANO_API_HOST}`,
    },
    drupal: {
      env: SETTING_ENV === 'production' ? 'production' : 'development',
      apiUri: GRAPHQL_D8_API_URI,
      apiKey: GRAPHQL_CONTENTSERVICE_APIKEY,
    },
    contentService: {
      env: SETTING_ENV === 'production' ? 'production' : 'development',
      apiUri: `${GRAPHQL_CONTENTSERVICE_API_URI}/graphql`,
      apiKey: GRAPHQL_CONTENTSERVICE_APIKEY,
    },
    rosetta: {
      env: SETTING_ENV === 'production' ? 'production' : 'development',
      uri: `${ROSETTA_PROTOCOL}://${ROSETTA_HOST}`,
    },
  }
}
