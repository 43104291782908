import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import _get from 'lodash/get'
import _sizes from 'lodash/size'
import _transform from 'lodash/transform'

import IconFacebook from 'assets/svg/icon_fb.svg'
import IconGoogle from 'assets/svg/icon_google.svg'
import IconApple from 'assets/svg/icon_apple.svg'

import { getBreadcrumbLabel } from 'utils/common'
import { AnimatedFlexContainer } from 'components/animation'
import { FlexContainer } from 'components/flex'
import { MediaButton } from 'components/manage/getConnected'

import GET_MANAGE from 'universal/apollo/d8/GET_MANAGE.gql'
import * as appAction from 'states/actions/application'
import { AppState } from 'states/reducers'

import { ErrorMessage, SocialMediaError, DataLayerEventAction, DataLayerEventCategory, DataLayerGtmCustomEventName, DataLayerEventLabel } from 'config/constants'
import { media, hideOnMedia } from 'utils/style'
import { triggerGtmEvent } from 'utils/tracking'
import { Loading } from 'components/Loading'

type Props = {
  match: any,
  history: any,
}

const SocialConnectContainer: React.FunctionComponent<Props> = ({ ...Props }) => {
  const dispatch = useDispatch()
  const { redirectBehaviour, socialMediaError } = useSelector((state: AppState) => state.application)
  const [fbData, setFbData] = useState<any>()
  const [appleData, setAppleData] = useState<any>({})
  const [googleData, setGoogleData] = useState<any>()
  const [redirectURL, updateRedirectURL] = useState<string>('')
  const [isLoading, setLoading] = useState<boolean>(false)

  const { data } = useQuery(GET_MANAGE)
  const currentPath = window.location.pathname

  useEffect(() => {
    const media = ['social_auth_google', 'social_auth_facebook', 'social_auth_apple']
    const mediaConnection: { [key: string]: any } = _transform(media,
      (result: { [key: string]: any }, value: string, key) => {
        result[value] = {}
      }, {})

    const authContent = data && data.user && data.user.auth ? data.user.auth : []
    if (_sizes(authContent.entities) > 0) {
      authContent.entities.forEach((plugin: any) => {
        const userData = JSON.parse(_get(plugin, 'additionalData', '{}'))
        const userName = `${userData.first_name} ${userData.last_name}`
        const mediaData = {
          mediaId: plugin.id,
          name: userName,
        }
        mediaConnection[plugin.pluginId] = mediaData
      })

      // Redirect with condition when connected social media
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.get('redirectFrom') === 'comment') {
        if (Object.keys(mediaConnection.social_auth_facebook).length) {
          triggerGtmEvent(
            DataLayerEventAction.SUMBIT_COMMENT,
            DataLayerEventCategory.COMMENT,
            DataLayerGtmCustomEventName.COMMENT,
            DataLayerEventLabel.FACEBOOK_AUTH_SUCCESS,
            decodeURIComponent(urlParams.get('destination') || ''),
          )
        }
        if (Object.keys(mediaConnection.social_auth_google).length) {
          triggerGtmEvent(DataLayerEventAction.SUMBIT_COMMENT, DataLayerEventCategory.COMMENT, DataLayerGtmCustomEventName.COMMENT,
            DataLayerEventLabel.GOOGLE_AUTH_SUCCESS, decodeURIComponent(urlParams.get('destination') || ''))
          // triggerGtmEvent(
          //   DataLayerEventAction.SUMBIT_COMMENT,
          //   DataLayerEventCategory.COMMENT,
          //   DataLayerGtmCustomEventName.COMMENT,
          //   DataLayerEventLabel.GOOGLE_AUTH_SUCCESS,
          //   decodeURIComponent(urlParams.get('destination') || ''))
        }
        if (Object.keys(mediaConnection.social_auth_apple).length) {
          triggerGtmEvent(DataLayerEventAction.SUMBIT_COMMENT, DataLayerEventCategory.COMMENT, DataLayerGtmCustomEventName.COMMENT,
            DataLayerEventLabel.APPLE_AUTH_SUCCESS, decodeURIComponent(urlParams.get('destination') || ''))
          // triggerGtmEvent(
          //   DataLayerEventAction.SUMBIT_COMMENT,
          //   DataLayerEventCategory.COMMENT,
          //   DataLayerGtmCustomEventName.COMMENT,
          //   DataLayerEventLabel.APPLE_AUTH_SUCCESS,
          //   decodeURIComponent(urlParams.get('destination') || ''))
        }
      }
    }

    if (data && data.user) {
      setGoogleData(Object.keys(mediaConnection.social_auth_google).length ? mediaConnection.social_auth_google : {})
      setFbData(Object.keys(mediaConnection.social_auth_facebook).length ? mediaConnection.social_auth_facebook : {})
      setAppleData(Object.keys(mediaConnection.social_auth_apple).length ? mediaConnection.social_auth_apple : {})

      // Redirect with condition when connected social media
      if (Object.keys(mediaConnection.social_auth_google).length || Object.keys(mediaConnection.social_auth_facebook).length || Object.keys(mediaConnection.social_auth_apple).length) {
        const urlParams = new URLSearchParams(window.location.search)
        if (urlParams.get('redirectFrom') === 'comment') {
          setLoading(true)
          window.location.href = decodeURIComponent(urlParams.get('destination') || '')
        }
      }
    }
  }, [data])

  useEffect(() => {
    const { from, destination } = redirectBehaviour
    if (from === 'comment') {
      updateRedirectURL(destination)
    }
  }, [redirectBehaviour])

  // Handle Social media connection error
  useEffect(() => {
    if (socialMediaError.media) {
      if (socialMediaError.code) {
        const message = _get(SocialMediaError, `${socialMediaError.media}.${socialMediaError.code}`, null)
        if (message) {
          dispatch(appAction.updateModalMessage(message))
        }
      } else {
        dispatch(appAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
      }
    }
    // remove the error state on the store
    dispatch(appAction.removeSoicalMediaError())
  }, [dispatch, socialMediaError.code, socialMediaError.media])

  if (!redirectURL) {
    return null
  }

  return (
    // <PageContainer animationType='fadeIn' flexDirection='column' alignItem='center' justifyContent='center'>
    <PageContainer animationType='fadeIn' flexDirection='column'>
      { isLoading && <LoadingWrapper><LoadingContainer></LoadingContainer></LoadingWrapper> }
      <SocialConnectSection flexDirection='column' alignItem='flex-start'>
        <FunctioningHeader>
          <HeaderLabel>{getBreadcrumbLabel(currentPath)}</HeaderLabel>
        </FunctioningHeader>
        <ManageContentContainer justifyContent='center'>
          <Content flexDirection='column' alignItem='center'>
            <MediaContainer>
              <MediaButton authContent={fbData} connectedName='facebook' mediaName={'Facebook'} mediaIcon={IconFacebook} />
            </MediaContainer>
            <Separator />
            <MediaContainer>
              <MediaButton authContent={googleData} connectedName='google' mediaName={'Google'} mediaIcon={IconGoogle} />
            </MediaContainer>
            <Separator />
            <MediaContainer>
              <MediaButton authContent={appleData} connectedName='apple' mediaName={'Apple'} mediaIcon={IconApple} />
            </MediaContainer>
            <Separator />
            <ContentContainer alignItem='center' justifyContent='center'>
              <ContentLabel>For priority moderation, connect your profile to a Facebook, Google or Apple account.</ContentLabel>
              <ExternalBackButton href={redirectURL}>Maybe later</ExternalBackButton>
            </ContentContainer>
          </Content>
        </ManageContentContainer>
      </SocialConnectSection>
    </PageContainer>
  )
}

const PageContainer = styled(AnimatedFlexContainer)`
  min-width: 375px;
  padding: 0px;
  height: auto;
  ${media.tabletUp`
    min-height: 650px;
    height: 100%;
  `}
`

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(255,255,255,0.8);
`

const LoadingContainer = styled(Loading)`
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
`

const SocialConnectSection = styled(FlexContainer)`
  min-height: 616px;
  padding: 0px;
  margin: 0px;
  max-width: none;
  width: 100%;
  border: none;
  ${media.tabletUp`
    padding-bottom: 20px;
    max-width: 400px;
  `}
`

const FunctioningHeader = styled(FlexContainer)`
  ${hideOnMedia(['mobile'])}
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
`

const HeaderLabel = styled.span`
  color: #001236;
  font-family:'Roboto Condensed';
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
`
const ContentLabel = styled.span`
  color: #000;
  font-family:'Roboto';
  font-size: 14px;
  line-height: 18px;
  padding: 20px;
  text-align: center;
`

const ExternalBackButton = styled.a`
  font-size: 14px;
  line-height: 18px;
  color: #4585FF;
`

const ManageContentContainer = styled(FlexContainer)`
  flex: 1 1 0;
  margin: 0 auto;
  width: 100%;
`
const Content = styled(FlexContainer)`
  width: 100%;
  margin: 0px;
`
const MediaContainer = styled(FlexContainer)`
  width: 100%;
`
const ContentContainer = styled(FlexContainer)`
  width: 100%;
`

const Separator = styled.div`
  border-bottom: 1px solid #CCCCCC;
  width: 100%;
`

export default SocialConnectContainer
