import * as scmpAccount from '@product/scmp-account-sdk'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'
import PageContainer from 'components/PageContainer'
import { isValidUrl } from 'utils/common'

import * as appAction from 'states/actions/application'
import { AppState } from 'states/reducers'

import {
  DEFAULT_AUTH_CALLBACK_URL,
  DEFAULT_REGISTER_CALLBACK_URL,
  GTM_CONTAINER_ID,
  RECAPTCHA_SITE_KEY,
  SITE_HOST,
  SITE_PROTOCOL,
  SETTING_ENV,
  SCMP_COM_PROTOCOL,
  SCMP_COM_HOST,
} from 'config/app.config'

import { media } from 'utils/style'
import { triggerGA4GtmEvent } from 'utils/tracking'

type Props = {
  match: any,
  history: any,
  isIpAccess: any,
  location: any,
}

const Login: React.FunctionComponent<Props> = ({ ...Props }: Props) => {
  const callbackUrl = useSelector((state: AppState) => state.signin.callbackUrl)
  const destination = useSelector((state: AppState) => state.application.redirectDestinationUrl)
  const callbackRegisterUrl = useSelector((state: AppState) => state.registration.callbackUrl)
  const isGtmReady = useSelector((state: AppState) => state.application.isGtmReady)

  const dispatch = useDispatch()

  const defaultTitleText = 'Sign In / Register'
  const titleText = Props.isIpAccess ? 'Log in / Sign up to access \nyour organisation’s account' : defaultTitleText
  const titleDesc = Props.isIpAccess ? 'Your organisation has subscribed to SCMP. You can access SCMP content by logging in/signing up using your credentials.' : ''

  const [isDisplayFooter, setIsDisplayFooter] = useState(true)
  const [isLoginWidgetInitialized, setIsLoginWidgetInitialized] = useState(true)
  const readerId = new URLSearchParams(Props.location.search).get('reader_id') || new URLSearchParams(Props.location.search).get('ampReaderId')

  useEffect(() => {
    dispatch(appAction.updateFlowType('Login'))
  }, [dispatch])

  useEffect(() => {
    // init Scmp Account SDK
    // callback function, please define before event happen
    if (callbackUrl !== '' && callbackRegisterUrl !== '' && destination !== '') {
      scmpAccount.init({
        SCMP_ACCOUNT_HOST: `${SITE_HOST}`,
        SCMP_ACCOUNT_PROTOCOL: `${SITE_PROTOCOL}`,
        AUTH_CALLBACK_URL: callbackUrl || `${DEFAULT_AUTH_CALLBACK_URL}`,
        REGISTER_CALLBACK_URL: callbackRegisterUrl || `${DEFAULT_REGISTER_CALLBACK_URL}`,
        DESTINATION: isValidUrl(destination) ? destination : `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}`,
        GTM_CONTAINER_ID: `${GTM_CONTAINER_ID}`,
        RECAPTCHA_SITE_KEY: `${RECAPTCHA_SITE_KEY}`,
        RECAPTCHA_CONTAINER_ID: '',
        DEBUG_LOG: SETTING_ENV === 'staging',
        AMP_READER_ID: readerId || '',
      })

      scmpAccount.on(scmpAccount.EVENT.LOGIN_WIDGET_INIT_SUCCESS, () => {
        setIsLoginWidgetInitialized(true)
      })

      scmpAccount.on(scmpAccount.EVENT.REGISTER_SUCCESS_PAGE_INIT, () => {
        setIsDisplayFooter(false)
      })

      scmpAccount.initLoginWidget('login-container', {
        title: titleText,
        emailLoginTitle: defaultTitleText,
        loginDescription: titleDesc,
      })
    }
  }, [callbackUrl, callbackRegisterUrl, destination])

  useEffect(() => {
    if (isGtmReady && isLoginWidgetInitialized) {
      triggerGA4GtmEvent({
        category: 'login',
        subcategory: 'popup',
        action: 'imp',
      })
    }
  }, [isGtmReady, isLoginWidgetInitialized])

  return (
    <PageContainer id='login-page' isLogoLink footerText={true} isDisplayFooter={isDisplayFooter}>
      <SigninMethods className='login-section' flexDirection='column' justifyContent='flex-start' ipAccess={Props.isIpAccess}>
        <SigninForm id='login-container' className='login-section__signin-form signin-form' flexDirection='column'></SigninForm>
      </SigninMethods>
    </PageContainer>
  )
}

interface SigninMethodsProps {
  ipAccess?: boolean
}

const SigninMethods = styled(FlexContainer)<SigninMethodsProps>`
  max-width: 270px;
  min-width: 270px;
  flex: 1;
  .sa-login-widget-title {
    font-size: ${({ ipAccess }) => ipAccess ? '22px' : '24px'};
  }
`

const SigninForm = styled(FlexContainer)`
  margin-bottom: 0px;
  ${media.tabletUp`
    margin-bottom: 20px;
  `}
`

export default Login
