import React, { useCallback, useEffect, useState } from 'react'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'
import { media } from 'utils/style'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { AppState } from 'states/reducers'
import { ROSETTA_HOST, ROSETTA_PROTOCOL } from 'config/app.config'

import { Loading } from 'components/Loading'

import {
  triggerGtmEvent,
  triggerExternalGtmEvent,
  keyOfCustomizedParameters, triggerGA4GtmEvent,
} from 'utils/tracking'
import { DataLayerEventAction, DataLayerEventCategory, DataLayerGtmCustomEventName } from 'config/constants'

import { getUserLevel } from 'utils/user'

import YPSubscriptionPageContent from 'components/SubscriptionPage/ypSubscriptionPageContent'
import PostiesSubscriptionPageContent from 'components/SubscriptionPage/postiesSubscriptionPageContent'
import AutoRenewConfirmationPopup from 'components/SubscriptionBox/AutoRenewConfirmationPopup'
import DefaultSubscriptionPageContent from 'components/SubscriptionPage/defaultSubscriptionPageContent'
import RosettaSDK from '@product/rosetta-sdk'
interface zEInterface {
  show: any,
  hide: any,
}
declare global {
  interface Window {
    zE: zEInterface
    tp: Record<string, unknown> & {
      customVariables: Record<string, unknown>,
    }
  }
}
const Subscription: React.FunctionComponent = () => {
  const isPianoReady = useSelector((state: AppState) => state.application.isPianoReady)
  const isPianoSupported = useSelector((state: AppState) => state.application.isPianoSupported)
  const userInfo = useSelector((state: AppState) => state.userInfo)
  const subscriptionStatus = useSelector((state: AppState) => state.subscriptionStatus)
  const userLevel = getUserLevel(userInfo) || {}
  const [AutoRenewConfirmationPopupOpen, setAutoRenewConfirmationPopupOpen] = useState(false)
  const isShowRosetta = useSelector((state: AppState) => state.userInfo.isShowRosetta)

  const { isOverallChurned, hasActiveConversions } = subscriptionStatus
  const {
    isAutoRenew,
    isAutoRenewable,
  } = useSelector((state: AppState) => state.subscriptionStatus)
  // show piano iframe to both subscribers and churned users
  const showContent = hasActiveConversions || isOverallChurned

  const trackingGTM = useCallback(() => {
    triggerGtmEvent(
      DataLayerEventAction.SUBSCRIPTION_PAGE,
      DataLayerEventCategory.SUBSCRIPTION,
      DataLayerGtmCustomEventName.TRACKING,
      window.location.href,
    )
  }, [])

  useEffect(() => {
    const tp = (window as any).tp || []
    tp.push(['addHandler', 'loginRequired', (params: any) => {
      console.log('PianoScript::handler::loginRequired()', { params })
    }])
    if (isPianoReady && tp.user && tp.user.isUserValid()) {
      tp.myaccount.show({
        displayMode: 'inline',
        containerSelector: '#my-account',
      })
    }
    if (!isShowRosetta) {
      window.location.href = window.location.origin + '/settings'
    }
  }, [isPianoReady, isShowRosetta])
  useEffect(() => {
    const handleMessage = (event: any) => {
      const url = new URL(event.origin)
      if (url.hostname.includes('piano') || url.hostname.includes('tinypass')) {
        const data = _get(event, 'data', {})
        switch (data.type) {
          case 'view invoice':
            if (!_isNil(_get(event, 'data.payment_id', ''))) {
              window.open(`${ROSETTA_PROTOCOL}://${ROSETTA_HOST}/api/invoice/download/${_get(event, 'data.payment_id', '')}`)
            }
            break
          case 'ENABLE_AUTO_RENEW':
            setAutoRenewConfirmationPopupOpen(true)
            break
          case 'copy link':
            navigator.clipboard.writeText(`${ROSETTA_PROTOCOL}://${ROSETTA_HOST}/site-licensing/redeem?contractId=${_get(event, 'data.contractId', '')}`)
            break
          case 'update-credit-card':
            axios.post(`${ROSETTA_PROTOCOL}://${ROSETTA_HOST}/api/user/grace`, {
              uid: userInfo.uuid,
              isGracePeriod: false,
            })
              .catch(function (error) {
                console.log(error)
              })
            break
          case 'zendesk display':
            if (_get(event, 'data.isOpen', '')) {
              window.zE.show()
            } else {
              window.zE.hide()
            }
            break
          case 'tracking':
            if (!_isEmpty(_get(data, 'gtm', {}))) {
              triggerExternalGtmEvent(data.gtm)
            }
            break
          default: break
        }
      } else {
        // skip origin check
        if (_get(event, 'data.type', '') === 'tracking') {
          if (!_isEmpty(_get(event, 'data.gtm', {}))) {
            triggerExternalGtmEvent(event.data.gtm)
          }
        }
      }
    }
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [])

  useEffect(() => {
    // force redirect if not supporting piano country
    const displaySubscription = hasActiveConversions || isPianoSupported
    if (displaySubscription === false) {
      window.location.href = '/settings'
    }

    const baseParameters = RosettaSDK.rulesEngine.getOutcome('tracking.ga4.baseParameters')

    triggerGA4GtmEvent({
      action: 'imp',
      category: 'myaccount',
      subcategory: 'subscription',
      [keyOfCustomizedParameters]: {
        ...baseParameters,
        trigger_point: 'my account index',
      },
    })
  }, [hasActiveConversions, isPianoSupported, triggerGA4GtmEvent])

  return (
    <PageContainer id='subscription-page' isSubscriber={showContent} flexDirection='column' alignItem='center'>
      {!isPianoReady && <LoadingContainer></LoadingContainer>}
      {isPianoReady && !showContent && <SubscriptionTo flexDirection='column' alignItem='center'>
        {userLevel.isYPUser
          ? <YPSubscriptionPageContent trackingGTM={trackingGTM} />
          : userLevel.isPostiesUser
            ? <PostiesSubscriptionPageContent trackingGTM={trackingGTM} />
            : <DefaultSubscriptionPageContent trackingGTM={trackingGTM} />}
      </SubscriptionTo>}
      {AutoRenewConfirmationPopupOpen && !isAutoRenew && isAutoRenewable && <AutoRenewConfirmationPopup />}
      {isPianoReady && showContent && <IframeLoadingContainer></IframeLoadingContainer>}
      <SubscriptionContentIframe className={(isPianoReady && showContent) ? 'is-visible' : ''} id="my-account"></SubscriptionContentIframe>
    </PageContainer>
  )
}

interface subscriberPage {
  isSubscriber?: string
}

const PageContainer = styled(FlexContainer) <subscriberPage>`
  ${({ isSubscriber }) => isSubscriber ? 'width: 100%; padding: 25px 0 0 0;' : 'width: auto;'};
  margin: 0 16px;
  ${media.tabletUp`
    padding: 0px;
    margin: 0;
  `}
`

const SubscriptionContentIframe = styled.div`
  width: 100%;
  z-index: 100;
  max-height: 0;
  overflow: hidden;

  &.is-visible {
    max-height: none;
    min-height: 250px;
  }

  iframe {
    width: 100% !important; // override plugin css
    background: #fff;
  }
`

const SubscriptionTo = styled(FlexContainer)`
  width: 100%;
`

const LoadingContainer = styled(Loading)`
  margin-top:25px
`

const IframeLoadingContainer = styled(Loading)`
  position: absolute;
  margin-top:25px;
`

export default Subscription
