import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/style'
import EventItem from 'components/CorporateSubscription/Events/item'

type Props = {
  events: any
}

const List: React.FunctionComponent<Props> = ({ events }) => {
  const getEventItemList = (events: any) => {
    const list: any[] = []
    if (events && events.length > 0) {
      events.forEach((event: any, key: number) => {
        list.push(<EventItem key={key} item={event}/>)
      })
    }

    return list
  }

  return (
    <PageContainer id='corporate-subscription-events-list-container'>
      { getEventItemList(events) }
    </PageContainer>
  )
}

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  ${media.tabletUp`
    margin: 0px;
    width: 680px;
    margin-top: 40px;
    flex-direction: row;
    flex-wrap: wrap;
  `}
`

export default List
