import React from 'react'
import styled from 'styled-components'

import { media } from 'utils/style'
import { useDispatch } from 'react-redux'
import * as modalAction from 'states/actions/application'

type Props = {
  children?: any,
  title: string,
  customButton?: boolean,
  defaultButtonTitle?: string,
  cancelButtonTitle?: string,
  destinationUrl?: string,
  onShow: any,
  confirmHandler?: any,
  cancenlButtonHandler?: any,
}

const CustomModal: React.FunctionComponent<Props> = ({ ...Props }) => {
  const dispatch = useDispatch()
  const hideModal = () => {
    Props.onShow(false)
    const destination = Props.destinationUrl
    dispatch(modalAction.updateModalMessage('', ''))
    if (destination) {
      window.location.href = destination
    }
  }

  return (
    <ModalContainer>
      <ModalBackDrop onClick={hideModal} />
      <Modal>
        <ModalTitle dangerouslySetInnerHTML={{ __html: Props.title }} />
        { Props.customButton ? Props.children : <ModalDefaultButton onClick={Props.confirmHandler ? Props.confirmHandler : hideModal}>{Props.defaultButtonTitle}</ModalDefaultButton> }
        { Props.cancelButtonTitle ? <CancelButton onClick={Props.cancenlButtonHandler ? Props.cancenlButtonHandler : hideModal}>{Props.cancelButtonTitle}</CancelButton> : null}
      </Modal>
    </ModalContainer>
  )
}

export default CustomModal
const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  display: flex;
`

const ModalBackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.4);
  z-index: 1000000;
`

const Modal = styled.div`
  display: inline-block;
  margin: auto;
  background: #fff;
  padding: 30px;
  max-width: 220px;
  min-width: 220px;
  border-radius: 4px;
  z-index: 10000000000;
  ${media.tabletUp`
    padding: 36px;
  `}
`

const ModalDefaultButton = styled.div`
  padding: 12px 0;
  width: 100%;
  border-radius: 2px;
  background-color: #FFCA05;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
`

const CancelButton = styled.div`
  color: #4585FF;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
`

const ModalTitle = styled.div`
  color: #001246;
  font-family: Roboto;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
`
