import styled from 'styled-components'
import React, { useEffect } from 'react'
import { ReactComponent as AlertCircle } from 'assets/svg/graceperiod_alert.svg'
import { useSelector } from 'react-redux'
import { AppState } from 'states/reducers'
import { media } from 'utils/style'
import { triggerGtmEvent } from 'utils/tracking'
import {
  SITE_HOST,
  SITE_PROTOCOL,
} from 'config/app.config'
import { useTrackRosettaGA4Event } from '../Piano/hooks'

const GracePeriodWidget: React.FunctionComponent = () => {
  const { trackRosettaGA4Event } = useTrackRosettaGA4Event()
  const {
    gracePeriod,
  } = useSelector((state: AppState) => state.subscriptionStatus)
  const parseDate = (date: Date) => {
    const dateStrParts = date.toString().split(' ')
    return (date.getDate() + ' ' + dateStrParts[1] + ' ' + dateStrParts[3])
  }
  useEffect(() => {
    triggerGtmEvent(
      'Subscription Management - Account App/Update Payment/Impression',
      'Subscription Management - Account App',
      'tracking',
    )
    trackRosettaGA4Event({
      category: 'myaccount',
      subcategory: 'subscription',
      action: 'imp',
      customized_parameters: {
        trigger_point: 'update payment page',
        call_to_action: 'update payment',
        event_name: 'myaccount_subscription_imp',
      },
    })
  }, [])

  const triggerGAClickEvent = () => {
    triggerGtmEvent(
      'Subscription Management - Account App/Update Payment/Click',
      'Subscription Management - Account App',
      'tracking',
    )
    trackRosettaGA4Event({
      category: 'myaccount',
      subcategory: 'subscription',
      action: 'click',
      customized_parameters: {
        trigger_point: 'update payment page',
        call_to_action: 'update payment',
        event_name: 'myaccount_subscription_click',
      },
    })
  }

  return (
    <AutoRenewWrapper>
      <ContentInner>
        <AlertIcon />
        <div>
          { !gracePeriod.isWithinHalf
            ? <>
              <ContentHeading>There was a problem with your payment card.</ContentHeading>
              <>Please add a new payment card to avoid losing access on <Date>{parseDate(gracePeriod.halfExpiryDate)}</Date>.</>
            </>
            : <>
              <ContentHeading>There was a problem with your payment card.</ContentHeading>
              <>Please add a new payment card to reactivate access.</>
            </>
          }
        </div>
      </ContentInner>
      <EnableButton onClick={triggerGAClickEvent} href={`${SITE_PROTOCOL}://${SITE_HOST}/manage/subscription?showFirst=wallet`}>UPDATE PAYMENT</EnableButton>
    </AutoRenewWrapper>
  )
}

const AutoRenewWrapper = styled.div`
  color: #001246;
  font-family: Roboto;
  font-size: 14px;
  line-height: 140%;
  background: rgba(235, 66, 84, 0.05);
  border: 1px solid rgba(235, 66, 84, 0.5);
  box-sizing: border-box;
  padding: 20px;
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 16px;

  ${media.tabletUp`
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `}
`

const EnableButton = styled.a`
  padding: 9px 16px;
  color: white;
  font-size: 14px;
  line-height: 20px;
  background: #001246;
  border-radius: 3px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  margin-top: 8px;
  display: inline-block;
  flex-shrink: 0;

  ${media.tabletUp`
    margin-top: 0;
    margin-left: 16px;
  `}
`

const ContentInner = styled.div`
  line-height: 20px;

  ${media.tabletUp`
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
  `}
`

const AlertIcon = styled(AlertCircle)`
  width: 24px;
  height: 24px;
  vertical-align: top;
  margin-right: 10px;
  flex-shrink: 0;
  margin-bottom: 9.5px;

  ${media.tabletUp`
    margin-bottom: 0;
  `}
`

const ContentHeading = styled.div`
  font-weight: bold;
`

const Date = styled.span`
  text-transform: uppercase;
  display: inline-block;
`

export default GracePeriodWidget
