import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { AppState } from 'states/reducers'
import ReCAPTCHA from 'react-google-recaptcha'
import { InputComponent, StyledButton } from 'components/styled'
import { RECAPTCHA_SITE_KEY } from 'config/app.config'
import { ErrorMessage } from 'config/constants'

const ReCAPTCHAWithStyle = styled(ReCAPTCHA)`
  max-width: 272px;
  max-height: 70px;
`

type Props = {
  email?: string | null;
  disabled?: boolean;
  flowType?: string;
  updateEmail: (value: string) => void;
  updateErrorMessage: (value: string) => void;
  emailSubmitHandler: (token: string, create: boolean) => void;
  emailCheckHandler: () => void;
  onClickBackHandler: (name: string) => void;
};

const WithEmail: React.FunctionComponent<Props> = (Props) => {
  const {
    updateEmail,
    emailSubmitHandler,
    updateErrorMessage,
    onClickBackHandler,
    disabled,
  }: Props = Props
  const [recaptchaRef, updateRecaptchaRef] = useState<any>(null)

  const flowType = useSelector((state: AppState) => state.application.flowType)
  const emailInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateEmail(event.target.value)
  }

  const onSubmit = async () => {
    updateErrorMessage('')
    const token = await recaptchaRef.executeAsync()
    if (!token) {
      updateErrorMessage(ErrorMessage.FAIL_TO_GENERATE_RECAPTCHA)
      return false
    }
    emailSubmitHandler(token, false)
  }

  const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit()
    }
  }

  return (
    <>
      <InputComponent
        type="email"
        placeholder="your email"
        height="37px"
        maxWidth="272px"
        paddingLeft="10px"
        marginBottom="23px"
        onChange={emailInputHandler}
        // onBlur={() => emailCheckHandler()}
        onKeyPress={keyPressHandler}
      ></InputComponent>
      {flowType === 'Subscribe' && (
        <MessageContainer>
          This email will be used to create an account on scmp.com and will be
          linked to your subscription. A receipt of your subscription will be
          sent to this email address.
        </MessageContainer>
      )}
      <ReCAPTCHAWithStyle
        ref={(ref: any) => updateRecaptchaRef(ref)}
        size="invisible"
        sitekey={RECAPTCHA_SITE_KEY}
      />
      <StyledButton
        maxWidth="272px"
        backgroundColour="#FFCA05"
        borderColor="#FFCA05"
        color="#000"
        fontSize="16px"
        lineHeight="19px"
        padding="12px"
        marginBottom="16px"
        disabled={disabled}
        onClick={() => onSubmit()}
      >
        NEXT
      </StyledButton>
      <BackLink onClick={() => onClickBackHandler('email')} disabled={disabled}>
        Back
      </BackLink>
    </>
  )
}

const MessageContainer = styled.div`
  padding-bottom: 19px;
  max-width: 300px
  font-size: 13px;
  line-height: 16px;
  color: #757575;
  font-family: 'Roboto';
`
interface BackLinkStyle {
  marginTop?: string;
  disabled?: boolean;
}
const BackLink = styled.div<BackLinkStyle>`
  text-align: center;
  color: #4585FF
  font-size: 14px;
  line-height: 18px;
  font-family: 'Roboto';
  margin-top:  ${(props) => (props.marginTop ? props.marginTop : '2px')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  cursor: pointer;
`

export default WithEmail
