import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

const ReferRedirect: React.FunctionComponent = () => {
  const history = useHistory()
  const params = window.location.search || ''

  useEffect(() => {
    history.push('/manage/subscription/referral' + params)
  }, [])

  return (
    <>
    </>
  )
}

export default ReferRedirect
