import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: string,
}
const FormErrorLabel: React.FunctionComponent<Props> = ({ ...Props }) => {
  return Props.children
    ? (
      <MessageLabel className={ Props.className }>
        { Props.children }
      </MessageLabel>
      )
    : null
}

const MessageLabel = styled.li`
  width: 270px;
  color: #EB4254;
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  padding-left: 10px;
  position: relative;
  display: table;
  &::before {
    content: ' ';
    display: list-item;
    position: absolute;
    left: 14px;
  }
`

export default FormErrorLabel
