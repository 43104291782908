import { USER_PROFILE_PIC_PREFIX } from '../../config/app.config'

export function getUserPicture (scmpUser: any): string {
  // if cookie still old version, fallback to use that url
  if (scmpUser.userPic) {
    return scmpUser.userPic
  }

  if (!scmpUser.upic) {
    return ''
  }

  return `${USER_PROFILE_PIC_PREFIX}${scmpUser.upic}`
}
