import React from 'react'
import styled from 'styled-components'
import LoadingSrc from 'assets/gif/wheel_on_E5E5E5.gif'
import SpinnerSrc from 'assets/gif/spinner.gif'

type Props = {
  className?: any,
}
const Loading: React.FunctionComponent<Props> = ({ ...Props }) => {
  return (
    <LoadingIcon className={Props.className} src={ LoadingSrc }></LoadingIcon>
  )
}

const Spinner: React.FunctionComponent<Props> = ({ ...Props }) => {
  return (
    <SpinnerIcon className={Props.className} src={SpinnerSrc}></SpinnerIcon>
  )
}

export { Loading, Spinner }

const LoadingIcon = styled.img`
  margin: auto;
`
const SpinnerIcon = styled.img`
`
