import React, { useEffect, useState } from 'react'
import { Switch, Redirect, useHistory, useLocation } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'states/reducers'
import { Cookies } from 'react-cookie'
import routes from 'routes'
import { AuthOnlyRoute, UnauthOnlyRoute } from 'routes/RouteComponent'
import * as signinActions from 'states/actions/signin'
import * as registrationActions from 'states/actions/registration'
import * as userInfoActions from 'states/actions/user-info'
import * as appActions from 'states/actions/application'
import {
  DEFAULT_AUTH_CALLBACK_URL,
  DEFAULT_REGISTER_CALLBACK_URL,
  DEFAULT_DESTINATION_URL,
  OAUTH_LINKING_CALLBACK_URL,
  OAUTH_LINKING_ROUTE,
} from 'config/app.config'
import {
  AUTH_ONLY,
  JWT_EXPIRY_THRESHOLD,
} from 'config/constants'
import { CommonModal, UnFollowModal } from 'components/modal'
import Tracking from 'components/Tracking'
import PianoScript from 'components/Piano/PianoScript'
import { isScmpDomains } from 'utils/common/domain'
import { pushVirtualPageview } from 'utils/tracking'
import {
  isScmpUser,
  getUserLevel,
  refreshJWT,
} from 'utils/user'
import { getUserPicture } from 'utils/cookie'
import _isEmpty from 'lodash/isEmpty'
import Zendesk from 'components/zendesk'

import qs from 'qs'
import { useSentrySetup } from '../../utils/sentry'

const App: React.FunctionComponent = () => {
  useSentrySetup()
  const dispatch = useDispatch()
  const cookie = new Cookies()
  const scmpUser = cookie.get('scmp_user') || {}
  const isSignedIn = !!scmpUser && !_isEmpty(scmpUser)
  const userLevel = getUserLevel(scmpUser) || {}
  const location = Object.assign({}, useLocation())
  const params = new URLSearchParams(location.search)
  const history = useHistory()
  const corpSubscriberTier = useSelector((state: any) => state.application.corpSubscriberTier)
  const isCorpSubscriberState = useSelector((state: any) => state.application.isCorpSubscriber)
  const isYPSubscriberState = useSelector((state: any) => state.application.isYPSubscriber)
  const [userRoleState, updateUserRoleState] = useState('')
  const [userRoleStateOfGA4, updateUserRoleStateOfGA4] = useState('')
  const [lastPagePathname, updateLastPagename] = useState<string>('')
  const isGtmReady = useSelector((state: AppState) => state.application.isGtmReady)
  const isPianoReady = useSelector((state: AppState) => state.application.isPianoReady)
  const isAppReady = isPianoReady && (!isSignedIn || (isCorpSubscriberState !== null && corpSubscriberTier !== null) || isYPSubscriberState !== null)
  const initStore = (location: any) => {
    // page url
    if (lastPagePathname !== location.pathname) {
      if (isGtmReady) {
        pushVirtualPageview()
      }

      updateLastPagename(location.pathname)
    }

    dispatch(appActions.updatePageUrl(location.pathname))
  }

  useEffect(() => {
    initStore(location)
  }, [location])

  const isOAuthLinkingRoute = (location: any): boolean => {
    return location.pathname === OAUTH_LINKING_ROUTE
  }

  const getDestination = (location: any, urlParams: any) => {
    if (isOAuthLinkingRoute(location)) {
      return OAUTH_LINKING_CALLBACK_URL
    }

    return urlParams.get('destination') || ''
  }

  const setOAuthLinkingData = () => {
    const query = location && location.search !== '' ? qs.parse(location.search) : {}
    localStorage.setItem('oAuthLinkingData', JSON.stringify(query))
  }

  const setupStoreInitialValue = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const _destination: string = getDestination(location, urlParams)
    const redirectSource: string = decodeURIComponent(urlParams.get('redirectForm') || '')
    const callbackUrlSignin: string = decodeURIComponent(urlParams.get('callback_url_signin') || '')
    const callbackUrlRegister: string = decodeURIComponent(urlParams.get('callback_url_register') || '')

    const destination: string = decodeURIComponent(isScmpDomains(_destination) ? _destination : '')
    const signinCallbackUrl: string = callbackUrlSignin || DEFAULT_AUTH_CALLBACK_URL // set default url if value is not provided
    const registerCallbackUrl: string = callbackUrlRegister || DEFAULT_REGISTER_CALLBACK_URL
    const destinationUrl: string = destination || DEFAULT_DESTINATION_URL
    const {
      username = '',
      useremail = '',
      firstname = '',
      lastname = '',
      uid = '',
      uuid = '',
      source = '',
      created = '',
      lvl = [],
      scs = [],
    } = scmpUser

    // set callback urls
    dispatch(signinActions.updateSigninCallbackUrl(signinCallbackUrl))
    dispatch(registrationActions.updateRegisterCallbackUrl(registerCallbackUrl))

    dispatch(appActions.updateDestinationUrl(destinationUrl))
    dispatch(appActions.updateRedirectSource(redirectSource))
    // set user info
    dispatch(userInfoActions.updateUserUid(uid))
    dispatch(userInfoActions.updateUserUuid(uuid))
    dispatch(userInfoActions.updateUserUsername(username))
    dispatch(userInfoActions.updateUserEmail(useremail))
    dispatch(userInfoActions.updateUserFirstname(firstname))
    dispatch(userInfoActions.updateUserLastname(lastname))
    dispatch(userInfoActions.updateUserSigninStatus(isSignedIn))
    dispatch(userInfoActions.updateUserUserPic(getUserPicture(scmpUser)))
    dispatch(userInfoActions.updateUserUserLvl(lvl))
    dispatch(userInfoActions.updateUserUserScs(scs))
    dispatch(userInfoActions.updateUserUserCreated(created))
    dispatch(userInfoActions.updateUserUserSource(source))
    dispatch(userInfoActions.updateUserIsScmpUser(isScmpUser(scmpUser.useremail)))

    // OAuth account linking special handling
    // Add query string param to localstorage for callback usage
    if (isOAuthLinkingRoute(location)) {
      setOAuthLinkingData()
      if (scmpUser.uuid) {
        window.location.href = OAUTH_LINKING_CALLBACK_URL
      }
    }
  }

  const handleSocialMediaError = (): void => {
    if (params.get('error') && params.get('media')) {
      dispatch(appActions.updateSoicalMediaError(params.get('media') || '', params.get('error') || ''))

      // remove error and media on querystring
      params.delete('error')
      params.delete('media')

      location.search = params.toString()
      history.push(location)
    }
  }

  const handleRedirectBehaviour = (): void => {
    const destination = params.get('destination') || ''
    if (params.get('redirectFrom') && isScmpDomains(destination)) {
      const redirectFrom = params.get('redirectFrom') || ''
      dispatch(appActions.updateRedirectBehaviour(redirectFrom, destination))
    }
  }

  const handleRefreshToken = async () => {
    if (cookie.get('scmp_user')) {
      // Check if token refresh needed.
      const scmpAtExpiry: string = cookie.get('scmp_at_exp') || '0'
      const scmpPat: string = cookie.get('scmp_pat') || ''
      const now = Date.now() / 1000
      const timeLeft = parseInt(scmpAtExpiry, 10) - now
      if (scmpPat === '' || timeLeft < JWT_EXPIRY_THRESHOLD) {
        await refreshJWT()
      } else {
        // Setup timer for refresh
        setTimeout(async () => {
          await refreshJWT()
          // TODO: We need to think a proper way to refresh the JWT periodically.
        }, ((timeLeft - JWT_EXPIRY_THRESHOLD) * 1000))
      }
    }
  }

  useEffect(() => {
    try {
      setupStoreInitialValue()
      handleSocialMediaError()
      handleRedirectBehaviour()
      handleRefreshToken()
    } catch (error) {
      console.log('[DEBUG]', 'app init', error)
    }
  }, [])

  return (
    <>
      <Zendesk />
      <GlobalStyles />
      <PianoScript updateUserRoleState={updateUserRoleState} updateUserRoleStateOfGA4={updateUserRoleStateOfGA4} />
      <Tracking userRole={userRoleState} ga4UserRole={userRoleStateOfGA4} />
      <CommonModal></CommonModal>
      <UnFollowModal />
      { isAppReady && <Switch>
        {
          routes.map((route: any, index) => {
            const withContainer: boolean = typeof route.withContainer === 'undefined' ? true : route.withContainer
            return route.routeType === AUTH_ONLY
              ? <AuthOnlyRoute {...route}
                  authed={isSignedIn}
                  withContainer={withContainer}
                  isCorpSubscriber={isCorpSubscriberState}
                  corpSubscriberTier={corpSubscriberTier}
                  userLevel={userLevel}
                  key={index} />
              : <UnauthOnlyRoute {...route} authed={isSignedIn} key={index} noRedirect={route.noRedirect} isIpAccess={route.isIpAccess} />
          })
        }
        <Redirect to='/login' />
      </Switch>}
    </>
  )
}

const GlobalStyles = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Angkor:400|Fredoka:400,600|Merriweather:300,300i,400,400i,700,700i,900,900i|Roboto+Condensed:300,400,700|Roboto:300,400,500,500i,700|Material+Icons');
  @import url('https://use.typekit.net/gez5ksw.css');
  html {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    body {
      overflow-x: hidden;
      font-family: 'Roboto', sans-serif;
      width: 100%;
      height: 100%;
      min-width: 320px;
      margin: 0;
      #root {
        height: 100%;
      }
      a {
        text-decoration: none;
      }
    }
  }
`

export default App
