import React from 'react'
import styled from 'styled-components'
import { FlexContainer } from 'components/flex'
import PageContainer from 'components/PageContainer'
import { StyledButton } from 'components/styled'
import { DataLayerEventCategory, DataLayerGtmCustomEventName, DataLayerEventAction } from 'config/constants'
import { triggerGtmEvent } from 'utils/tracking'

type Props = {
  history: any
}

const ResetPasswordSuccessContainer: React.FunctionComponent<Props> = ({ history }) => {
  const redirectToLoginPage = () => {
    triggerGtmEvent(
      DataLayerEventAction.PASSWORD_UPDATED_LOGIN_CLICK,
      DataLayerEventCategory.LOGIN,
      DataLayerGtmCustomEventName.LOGIN,
      '',
    )
    history.push('/login')
  }

  return (
    <PageContainer id='resetpassword-page' title={'Password Updated'} isLogoLink footerText={true} isDisplayFooter={true}>
      <ResetPasswordSuccessSection flexDirection='column'>
        <Message>Your password has been updated, please login again.</Message>
        <StyledButton
          color='#000'
          fontSize='16px'
          lineHeight='19px'
          padding='12px'
          marginBottom='16px'
          onClick={redirectToLoginPage}>Login</StyledButton>
      </ResetPasswordSuccessSection>
    </PageContainer >
  )
}

const ResetPasswordSuccessSection = styled(FlexContainer)`
  max-width: 272px;
`

const Message = styled.div`
  color: ##333333;
  font-family: Roboto;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 1px;
`

export default ResetPasswordSuccessContainer
