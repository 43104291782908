import styled from 'styled-components'
import React from 'react'

import { ROSETTA_HOST, ROSETTA_PROTOCOL } from 'config/app.config'

const v6RetentionButton: React.FunctionComponent = () => {
  return (
    <RetentionButtonWrapper>Don't lose unlimited access.&nbsp;
      <SubscribeLink href={`${ROSETTA_PROTOCOL}://${ROSETTA_HOST}/?product=v6antichurn`}>Pre-order</SubscribeLink>
      &nbsp;a subscription now and enjoy up to 50% off
    </RetentionButtonWrapper>
  )
}

const RetentionButtonWrapper = styled.div`
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 12px;
`

const SubscribeLink = styled.a`
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 12px;
`

export default v6RetentionButton
