import { useMutation } from '@apollo/react-hooks'
import _get from 'lodash/get'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'
import { FormErrorLabel } from 'components/formErrorLabel'
import { GoBackButton } from 'components/goBackButton'
import { PasswordStrengthIndicator } from 'components/passwordStrengthIndicator'
import ShowPasswordButton from 'components/showPasswordButton'

import { ErrorMessage } from 'config/constants'

import * as modalAction from 'states/actions/application'

import UPDATE_PASSWORD from 'universal/apollo/d8/UPDATE_PASSWORD.gql'

import { hasValue } from 'utils/formValidation'
import { usePassword } from 'utils/hooks'
import { media } from 'utils/style'
import { triggerGA4GtmEvent } from 'utils/tracking'

type Props = {
  uid: any
}

const ChangePasswordPage: React.FunctionComponent<Props> = ({ ...Props }) => {
  const dispatch = useDispatch()
  const [password, confirmPassword, passwordLevel, passwordError, confirmPwError, updatePassword, updateConfirmPassword, validatePw, validateConfirmPw] = usePassword()
  const [oldPassword, updateOldPassword] = useState<string>('')
  const [oldPwError, updateOldPwError] = useState<string>('')
  const [isShowingPw, toggleSIshowPw] = useState(false)
  const [isShowingOldPw, toggleIsShowOldPw] = useState(false)
  const [isShowingConfirmPw, toggleIsShowConfirmPw] = useState(false)
  const errorMessage = oldPwError + passwordError + confirmPwError

  const valideOldPassword = (): void => {
    if (!hasValue(oldPassword)) {
      updateOldPwError(ErrorMessage.CHANGE_PASSWORD_MISSING_OLD_PASSWORD)
    } else {
      updateOldPwError('')
    }
  }

  const [callGetPassword] = useMutation(UPDATE_PASSWORD, {
    onCompleted: (response) => {
      if (_get(response.data, 'errors', []).length > 0) {
        dispatch(modalAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
      } else if (_get(response.data, 'violations[0].message')) {
        dispatch(modalAction.updateModalMessage(_get(response.data, 'violations[0].message')))
      } else {
        dispatch(modalAction.updateModalMessage(ErrorMessage.CHANGE_PASSWORD_UPDATE_SUCCESS))
      }
      triggerGA4GtmEvent({
        category: 'myaccount',
        subcategory: 'profile_update',
        action: 'sys',
        customized_parameters: {
          update_type: 'change_password',
        },
      })
    },
    onError: () => {
      dispatch(modalAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
    },
    variables: {
      uid: Props.uid,
      pass: password,
      existing: oldPassword,
    },
  })

  const onClickSave = () => {
    if (errorMessage || !password || !oldPassword || !confirmPassword) {
      return false
    }
    callGetPassword()
  }

  const toggleShowOldPw = (eventName?: string): void => {
    if (eventName === 'onMouseOut') {
      toggleIsShowOldPw(false)
      return
    }
    toggleIsShowOldPw(!isShowingOldPw)
  }

  const toggleShowPw = (eventName?: string): void => {
    if (eventName === 'onMouseOut') {
      toggleSIshowPw(false)
      return
    }
    toggleSIshowPw(!isShowingPw)
  }

  const toggleShowConfirmPw = (eventName?: string): void => {
    if (eventName === 'onMouseOut') {
      toggleIsShowConfirmPw(false)
      return
    }
    toggleIsShowConfirmPw(!isShowingConfirmPw)
  }

  const onOldPasswordUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => updateOldPassword(event.target.value)

  return (
    <PageContainer>
      <FormContainer flexDirection='column' alignItem='stretch'>
        <FormContent>
          <GoBackButton labelText="Change Password" goBackLink="/settings/profile" />
          <ErrorContainer>
            <ErrorMsgLabel>{oldPwError}</ErrorMsgLabel>
            <ErrorMsgLabel>{passwordError}</ErrorMsgLabel>
            <ErrorMsgLabel>{confirmPwError}</ErrorMsgLabel>
          </ErrorContainer>
          <FieldContainer>
            <FieldLabelContainer alignItem='center'>
              <FieldLabel>Current Password</FieldLabel>
            </FieldLabelContainer>
            <PasswordContainer>
              <PasswordInput
                type={isShowingOldPw ? 'text' : 'password'}
                placeholder='password'
                onChange={onOldPasswordUpdate}
                onBlur={valideOldPassword}
                error={oldPwError}
              />
              <ShowPasswordButton toggleHandler={toggleShowOldPw}></ShowPasswordButton>
            </PasswordContainer>

            <FieldLabelContainer alignItem='center'>
              <FieldLabel>New Password</FieldLabel>
            </FieldLabelContainer>
            <PasswordContainer>
              <PasswordInput
                type={isShowingPw ? 'text' : 'password'}
                placeholder='password'
                onChange={updatePassword}
                onBlur={validatePw}
                error={passwordError}
              />
              <ShowPasswordButton toggleHandler={toggleShowPw}></ShowPasswordButton>
            </PasswordContainer>

            <PasswordStrengthIndicator passwordStrendth={passwordLevel} />

            <FieldLabelContainer>
              <FieldLabel>Confirm Password</FieldLabel>
            </FieldLabelContainer>
            <PasswordContainer>
              <PasswordInput
                type={isShowingConfirmPw ? 'text' : 'password'}
                placeholder='confirm password'
                onChange={updateConfirmPassword}
                onBlur={validateConfirmPw}
                error={confirmPwError}
              />
              <ShowPasswordButton toggleHandler={toggleShowConfirmPw}></ShowPasswordButton>
            </PasswordContainer>

            <PasswordHelperText>Use 8 or more characters with a mix of letters, number & symbols</PasswordHelperText>
          </FieldContainer>

          <SaveButton alignItem='center' justifyContent='center' onClick={onClickSave}>SAVE</SaveButton>
        </FormContent>
      </FormContainer>
    </PageContainer>
  )
}

interface FieldChecker {
  error?: string
}

const PageContainer = styled.div`
  margin: 0 auto;
  width: 335px;
  padding: 0 12px 16px 12px;
  ${media.tabletUp`
    padding: 0 44px 0;
    max-width: 416px;
  `}
  ${media.smallDesktopUp`
    padding: 0 0 16px 0;
  `}
`

const FormContainer = styled(FlexContainer)`
  margin: 0 auto;
  border-bottom: none;
`

const FormContent = styled.div`
  max-width: 416px;
  margin: 20px auto 0;
  ${media.tabletUp`
    margin: 0;
    padding: 0;
    max-width: 416px;
    width: 100%;
  `}
`

const ErrorContainer = styled.div`
`

const ErrorMsgLabel = styled(FormErrorLabel)`
  display: block;
`

const FieldContainer = styled.div`
  margin-top: 32px;
  div:first-child{
    margin-top: 0;
  }
`

const FieldLabelContainer = styled(FlexContainer)`
  margin-top: 20px;
  margin-bottom: 7px;

`

const FieldLabel = styled.div`
  flex: 1;
  color: #000000;
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
`

const PasswordContainer = styled.div`
  position: relative;
  margin-bottom: 7px;
  width: 100%;
`

const PasswordInput = styled.input<FieldChecker>`
  height: 37px;
  width: 100%;
  border: 1px solid ${({ error }) => error ? '#EB4254' : '#CCCCCC'};
  border-radius: 2px;
  background-color: #FFF;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.19px;
  color: #333;
  font-family: Roboto;
  padding-left: 10px;
  box-sizing: border-box;
  padding-right: 50px;
`

const PasswordHelperText = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.14px;
  color: #333333;
  margin-bottom: 24px;
`

const SaveButton = styled(FlexContainer)`
  box-sizing: border-box;
  width: 251px
  height: 44px;
  border: 1px solid #4585FF;
  border-radius: 2px;
  cursor: pointer;
  color: #4585FF;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 28px auto 0;
`

export default ChangePasswordPage
