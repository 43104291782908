import React from 'react'
import styled from 'styled-components'

interface ToggleProps {
  value?: boolean,
  onToggle?: Function,
}

const Toggle: React.FunctionComponent<ToggleProps> = ({ value, onToggle }) => {
  return (
    <ToggleBtn value={value} onClick={() => onToggle && onToggle(!value)} />
  )
}

interface ToggleBtnProps {
  value?: boolean
}

const ToggleBtn = styled.div<ToggleBtnProps>`
  position: relative;
  margin-left: 20px;
  width: 51px;
  height: 31px;
  border-radius: 15.5px;
  transition: 0.4s;
  cursor: pointer;
  background: ${props => props.value ? '#4585FF' : '#CCCCCC'};

  &:before {
    position: absolute;
    top: 2px;
    left: ${props => props.value ? 22 : 2}px;
    display: block;
    content: ' ';
    width: 27px;
    height: 27px;
    border-radius: 50%;
    background: white;
    transition: 0.2s;
  }
`

export default Toggle
