import React, { useState } from 'react'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'
import FieldTitle from './FieldTitle'

type Props = {
  title: string,
  className?: string,
  value?: string,
  onChange?: (value: string) => void
}

const GenderField: React.FunctionComponent<Props> = ({ title, className, value, onChange }) => {
  const [gender, setGender] = useState(value)

  const onClickOption = (val: string) => {
    setGender(val)
    onChange && onChange(val)
  }

  return (
    <InputContainer className={className}>
      <FieldTitle>{ title }</FieldTitle>
      <FlexContainer>
        <GenderButton alignItem='center' justifyContent='center' className={gender === 'she_her' ? 'active' : ''} onClick={() => onClickOption('she_her')}>
          <WordContaner>
            She/her
          </WordContaner>
        </GenderButton>
        <GenderButton alignItem='center' justifyContent='center' className={gender === 'he_him' ? 'active' : ''} onClick={() => onClickOption('he_him')}>
          <WordContaner>
            He/him
          </WordContaner>
        </GenderButton>
        <GenderButton alignItem='center' justifyContent='center' className={gender === 'they_them' ? 'active' : ''} onClick={() => onClickOption('they_them')}>
          <WordContaner>
            They/ them
          </WordContaner>
        </GenderButton>
        <GenderButton alignItem='center' justifyContent='center' className={gender === 'not_say' ? 'active' : ''} onClick={() => onClickOption('not_say')}>
          <WordContaner>
            Rather not say
          </WordContaner>
        </GenderButton>
      </FlexContainer>
    </InputContainer>
  )
}

const InputContainer = styled.div`
  width: 100%;
  div {
    margin-right: 14px;
  }
  div:last-child {
    margin: 0;
  }
`

const GenderButton = styled(FlexContainer)`
  text-align: center;
  color: #000000;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  box-sizing: border-box;
  width: 62px;
  height: 62px;
  border: 1px solid #D7D7D7;
  border-radius: 2px;
  background-color: #FFFFFF;
  cursor: pointer;
  &.active {
    background-color: #F2F2F2;
  }
`
const WordContaner = styled.div`
  padding: 17px 10px;
`

export default GenderField
