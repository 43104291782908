import React from 'react'
import styled from 'styled-components'

const NewLabelComponent: React.FunctionComponent = () => {
  return (
    <NewLabel>NEW</NewLabel>
  )
}

const NewLabel = styled.span`
  background: #EB4254;
  display: inline-block;
  border-radius: 4px;
  padding: 1px 4px;

  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;

  color: #FFFFFF;
`

export default NewLabelComponent
