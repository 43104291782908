import _get from 'lodash/get'
import styled, { css } from 'styled-components'
import React from 'react'
import DefaultImg from 'assets/svg/img_user_default.svg'
import { AppState } from 'states/reducers'
import { useSelector } from 'react-redux'
import { getUserLevel } from 'utils/user'

type Props = {
  size: string,
}

const UserHaloIcon: React.FunctionComponent<Props> = ({ size = 'large' }) => {
  const userInfo = useSelector((state: AppState) => state.userInfo)
  const userLevel = getUserLevel(userInfo)
  const authorLogoSrc = _get(userInfo, 'userPic') || DefaultImg
  const isYPSubscriber = useSelector((state: any) => state.application.isYPSubscriber)
  const isSubscriber = useSelector((state: AppState) => state.subscriptionStatus.isSubscriber)

  return (
    <AuthorWrap>
      <StyledAuthorImgWrapper size={size} isMember={userLevel.isMember || isSubscriber} isStaff={userLevel.isStaff} isYPSubscriber={isYPSubscriber}>
        <StyledAuthorImage isStaff={userLevel.isStaff} src={authorLogoSrc} />
      </StyledAuthorImgWrapper>
    </AuthorWrap>
  )
}

type StyledAuthorImgWrapperProps = {
  size: string
  isMember: boolean,
  isStaff: boolean,
  isYPSubscriber: boolean,
  isVisible?: boolean,
}

type StyledAuthorImageProps = {
  isStaff: boolean,
}

const StyledAuthorImgWrapper = styled.div<StyledAuthorImgWrapperProps>`
  flex-shrink: 0;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;

  ${props => props.isMember
    ? css`
      padding: 2px;
      background: #4585FF;
      img {
        padding: 1px;
        background: white;
      }
    `
    : ''}
  ${props => props.size === 'large'
    ? css`
      width: 82px;
      height: 82px;
    `
    : ''}
  ${props => props.size === 'medium'
    ? css`
      width: 42px;
      height: 42px;
    `
    : ''}
  ${props => props.size === 'small'
    ? css`
      width: 29px;
      height: 29px;
    `
    : ''}
  ${props => props.size === 'large' && props.isMember
    ? css`
      width: 70px;
      height: 70px;
      padding: 5px;
      img {
        padding: 2px;
        background: white;
      }
    `
    : ''}
  ${props => props.isStaff
    ? css`
      background: linear-gradient(90deg, #FFC205 50%, #001246 50%);
    `
    : ''}
  ${props => props.isYPSubscriber
    ? css`
      background: linear-gradient(180deg, #9DD63F 0%, #DAE000 77.83%, #ECF200 100%);
    `
    : ''}
  ${props => props.isVisible
    ? css`
      img {
        pointer-events: none;
      }
    `
    : ''}
`
const StyledAuthorImage = styled.img<StyledAuthorImageProps>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-sizing: border-box;
  ${props => props.isStaff
    ? css`
      filter: grayscale(1);
    `
    : ''}
`
const AuthorWrap = styled.div`
  position: relative;
`

export default UserHaloIcon
