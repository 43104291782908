import React from 'react'
import styled, { css } from 'styled-components'

import { FlexContainer } from 'components/flex'

type Props = {
  image?: any,
  imageCenter?: boolean,
  nonBoldtext?: string,
  boldText?: string,
  backgroundColor?: string,
  textColor?: string,
  className?: any,
  onClick?: any,
}
const ButtonWithIcon: React.FunctionComponent<Props> = ({ image, imageCenter = false, nonBoldtext, boldText, backgroundColor, textColor, className, onClick }) => {
  return (
    <ButtonContainer justifyContent='center' alignItem='center' image={ image } imageCenter={ imageCenter } backgroundColor={ backgroundColor } className={className} onClick={ onClick }>
      <ButtonText textColor={ textColor }>
        { nonBoldtext }
        <ButtonTextBold>{ boldText }</ButtonTextBold>
      </ButtonText>
    </ButtonContainer>
  )
}

interface ButtonContainerProps {
  image?: string
  imageCenter?: boolean
  backgroundColor?: string
}

const ButtonContainer = styled(FlexContainer)<ButtonContainerProps>`
  background: ${({ backgroundColor }) => backgroundColor || '#1977F2'};
  height: 46px;
  position: relative;
  cursor: pointer;
  border-radius:2px;
  &::before {
    content: '';
    background: url(${({ image }) => image || ''}) center center no-repeat;
    width: 22px;
    height: 48px;
    ${({ imageCenter }) => imageCenter === false
    ? css`display: inline-block; position: absolute; left: 14px;`
    : css``
}
`

interface ButtonWithIconProps {
  textColor?: string
}
const ButtonText = styled.span<ButtonWithIconProps>`
  color: ${({ textColor }) => textColor || 'white'};
  font-size: 14px;
  font-weight: 200;
  font-family: Roboto;
`

const ButtonTextBold = styled.span`
  margin-left: 5px;
  font-weight: bold;
`

export default ButtonWithIcon
