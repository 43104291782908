export const formatTermName = (termName: string): string => {
  const [billingCycleAndResource] = termName.split(' | ')
  const [billingCycle, resource] = billingCycleAndResource.split(': ')
  if (!resource) return billingCycleAndResource
  const resourceDisplayNameMap: { [key: string]: string } = {
    'digital + daily epaper': 'Daily (Mon-Sun) Bundle',
    'digital + weekend print + weekend epaper': 'Weekend (Sat-Sun) Bundle',
    'digital + weekday print + weekday epaper': 'Weekday (Mon-Fri) Bundle',
    'digital + daily print + daily epaper': 'Daily (Mon-Sun) Bundle',
    'digital + scmp plus + daily epaper': 'Plus',
    'digital + plus + daily epaper': 'Plus',
    'digital + plus + weekend print + weekend epaper': 'Bundle Plus (Weekend)',
    'digital + plus + weekday print + weekday epaper': 'Bundle Plus (Weekday)',
    'digital + plus + daily print + daily epaper': 'Bundle Plus (Daily)',
  }
  return `${billingCycle}: ${resourceDisplayNameMap[resource.toLowerCase()] || resource}`
}
