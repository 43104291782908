import { combineReducers } from 'redux'
import registration from './registration'
import signin from './signin'
import userInfo from './user-info'
import manage from './manage'
import application from './application'
import subscriptionStatus from './subscription-status'

const rootReducer = combineReducers({
  registration,
  signin,
  userInfo,
  manage,
  application,
  subscriptionStatus,
})

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
