import React from 'react'
import styled from 'styled-components'
import _map from 'lodash/map'
import { media } from 'utils/style'
import { FlexContainer } from 'components/flex'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'states/reducers'
import { useMutation } from '@apollo/react-hooks'
import UPDATE_DISABLE_KEYWORD_ALERT from 'universal/apollo/d8/UPDATE_DISABLE_KEYWORD_ALERT.gql'
import * as userInfoActions from 'states/actions/user-info'

type Props = {
  keywordAlertsItems: any
}

const KeywordAlertsComponent: React.FunctionComponent<Props> = ({ keywordAlertsItems }) => {
  const dispatch = useDispatch()
  const {
    uid,
    keywordAlerts,
  } = useSelector((state: AppState) => state.userInfo)

  const [updateDisableKeywordAlert] = useMutation(UPDATE_DISABLE_KEYWORD_ALERT, {
    onCompleted: () => {
    },
    onError: () => {
      alert('Sorry, something is wrong. Please try again.')
    },
    variables: {
      uid: uid,
      disableKeywordAlerts: keywordAlerts ? '1' : '0',
    },
  })

  const onCheckboxToggleClick = async () => {
    await dispatch(userInfoActions.updateUserKeywordAlerts(!keywordAlerts))
    updateDisableKeywordAlert()
  }

  return (
    <PageContainer id='corporate-subscription-keywordAlerts-container'>
      <Header>
        Daily News Alerts
      </Header>
      <KeywordAlertControl flexDirection='row' alignItem='center'>
        <KeywordAlertControlLeft>
          Customizable keywords based on your company, competitors, or key industry leaders. Currently, you are signed up to receive alerts based on the below keywords. To turn the daily email alerts on/off please click the button to the right.        </KeywordAlertControlLeft>
        <KeywordAlertControlRight>
          <KeywordAlertControlLabel className="switch">
            <KeywordAlertControlInput
              type="checkbox"
              checked={keywordAlerts === undefined ? false : !keywordAlerts}
              onChange={() => onCheckboxToggleClick()}
              className="checkbox">
            </KeywordAlertControlInput>
            <KeywordAlertControlSlider className="slider"></KeywordAlertControlSlider>
          </KeywordAlertControlLabel>
        </KeywordAlertControlRight>
      </KeywordAlertControl>
      <KeywordContainer>
        {_map(keywordAlertsItems, item => {
          return (
          <KeywordItem>
            {item}
          </KeywordItem>
          )
        })}
      </KeywordContainer>
      <AdjustLinkText>
        To change your keywords please contact <AdjustLink href='mailto:csuite@scmp.com'>csuite@scmp.com</AdjustLink>.
      </AdjustLinkText>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 32px;
`

const Header = styled.div`
  font-family: "Roboto Condensed";
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
`

const KeywordContainer = styled.div`
  display: flex;
  margin-top: 20px;
  max-width: 600px;
  flex-wrap: wrap;
`

const KeywordItem = styled.div`
  font-family: "Roboto Condensed";
  font-size: 16px;
  padding: 8px;
  background: #F2F2F2;
  width: fit-content;
  margin-right: 12px;
  margin-top: 12px;
`

const AdjustLinkText = styled.div`
  margin-top: 32px;
  font-family: Roboto;
  font-size: 14px;
`

const AdjustLink = styled.a`
  cursor: pointer;
  color: #3f79e7;
`

const KeywordAlertControl = styled(FlexContainer)`
  margin-top: 16px;
  ${media.tabletUp`
    max-width: 100%;
  `}
  .switch input {
    display: none;
  }
  .switch {
    display: inline-block;
    position: relative;
    height: 31px;
    width: 51px;
    min-width: 51px;
  }
  .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
    border-radius: 34px;
  }
  .slider:before {
    background-color: #fff;
    bottom: 2px;
    content: "";
    height: 27px;
    left: 2px;
    position: absolute;
    transition: .2s;
    width: 27px;
    border-radius: 50%;
  }

  .checkbox:checked + .slider {
    background-color: #4585FF;
  }
  .checkbox:checked + .slider:before {
    transform: translateX(20px);
  }
`

const KeywordAlertControlRight = styled.div`
  margin-left: 24px;
  height: 31px;
`
const KeywordAlertControlLeft = styled.div`
  flex: 1 1 0;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
  font-family: Roboto;
  letter-spacing: 0;
`
const KeywordAlertControlLabel = styled.label``
const KeywordAlertControlInput = styled.input``
const KeywordAlertControlSlider = styled.span``

export default KeywordAlertsComponent
