import ForgotPassword from 'containers/ForgotPassword'
import SignIn from 'containers/Login'
import SignInSuccess from 'containers/Login/success'
import UserProfile from 'containers/MyProfile'
import Following from 'containers/Following'
import ResetPassword from 'containers/ResetPassword'
import ResetPasswordSuccess from 'containers/ResetPassword/success'
import Comments from 'components/activities/comments'
import AccountActivationFail from 'containers/AccountActivation/fail'
import AccountActivation from 'containers/AccountActivation'
import SocialConnectContainer from 'containers/Social'
import Subscription from 'containers/Subscription'
import { GetConnectedPanel } from 'components/manage/getConnected'
import NewsletterContainer from 'containers/Newsletter'
import ReferContainer from 'containers/Refer'
import ReferRedirect from 'containers/Refer/Redirect'
import SubScribe from 'containers/Subscribe'
import UnSubscribe from 'containers/UnSubscribeNewsletter'
import Notifications from 'containers/Notifications'
import ResultPageContainer from 'containers/ResultPage'
import ChangeEmail from 'components/settings/changeEmailAddress'
import ChangePassword from 'components/settings/changePassword'
import Profile from 'components/settings/EditProfile'
import BookmarkPageContainer from 'containers/Bookmark'
import Devices from 'containers/Devices'
import OAuthLinking from 'containers/OAuthLinking'
import DeviceCap from 'containers/Login/deviceCap'
import CorporateSubscription from 'containers/CorporateSubscription'

import { AUTH_ONLY, CORP_SUBCRIBER_TIER } from 'config/constants'

const routes = [
  {
    path: '/login/ip-access',
    component: SignIn,
    isIpAccess: true,
  },
  {
    path: '/login/:method/return',
    component: DeviceCap,
    noRedirect: true,
  },
  {
    path: '/login/success',
    component: SignInSuccess,
    routeType: AUTH_ONLY,
    withContainer: false,
  },
  {
    path: '/login',
    component: SignIn,
  },
  {
    path: '/user/login',
    component: SignIn,
  },
  {
    path: '/subscribe',
    component: SubScribe,
  },
  {
    path: '/user/subscribe',
    component: SubScribe,
  },
  {
    path: '/forgot-password',
    component: ForgotPassword,
  },
  {
    path: '/unsubscribe/:uid/:hash/:options',
    component: UnSubscribe,
    noRedirect: true,
  },
  {
    path: '/user/reset/:uid/:timestamp/:hash/login',
    component: AccountActivation,
  },
  {
    path: '/user/reset/:uid/:timestamp/:hash',
    component: ResetPassword,
  },
  {
    path: '/reset-password/success',
    component: ResetPasswordSuccess,
  },
  {
    path: '/activation/fail/:id',
    component: AccountActivationFail,
  },
  {
    path: '/manage/newsletter',
    component: NewsletterContainer,
    routeType: AUTH_ONLY,
  },
  {
    path: '/refer',
    component: ReferRedirect,
    noRedirect: true,
  },
  {
    path: '/manage/subscription/referral',
    component: ReferContainer,
    routeType: AUTH_ONLY,
    withContainer: false,
  },
  {
    path: '/manage/following',
    component: Following,
    routeType: AUTH_ONLY,
  },
  {
    path: '/manage/subscription',
    component: Subscription,
    routeType: AUTH_ONLY,
  },
  {
    path: '/manage/get-connected',
    component: GetConnectedPanel,
    routeType: AUTH_ONLY,
  },
  {
    path: '/activities/comments',
    component: Comments,
    routeType: AUTH_ONLY,
  },
  {
    path: '/social/connect',
    component: SocialConnectContainer,
    routeType: AUTH_ONLY,
  },
  {
    path: '/settings/profile',
    component: Profile,
    routeType: AUTH_ONLY,
  },
  {
    path: '/settings/change-password',
    component: ChangePassword,
    routeType: AUTH_ONLY,
  },
  {
    path: '/settings/change-email-address',
    component: ChangeEmail,
    routeType: AUTH_ONLY,
  },
  {
    path: '/settings',
    component: UserProfile,
    routeType: AUTH_ONLY,
  },
  {
    path: '/manage/notifications',
    component: Notifications,
    routeType: AUTH_ONLY,
  },
  {
    path: '/manage/bookmark',
    component: BookmarkPageContainer,
    routeType: AUTH_ONLY,
  },
  {
    path: '/manage/devices',
    component: Devices,
    routeType: AUTH_ONLY,
  },
  {
    path: '/result/:code/:httpStatusCode/:customCode',
    component: ResultPageContainer,
    noRedirect: true,
  },
  {
    path: '/result/:code/:httpStatusCode',
    component: ResultPageContainer,
    noRedirect: true,
  },
  {
    path: '/result/:code',
    component: ResultPageContainer,
    noRedirect: true,
  },
  {
    path: '/oauth/linking/return',
    component: OAuthLinking,
    routeType: AUTH_ONLY,
    withContainer: false,
  },
  {
    path: '/oauth/linking',
    component: SignIn,
    noRedirect: true,
  },
  {
    path: '/manage/group-subscriber-benefits',
    component: CorporateSubscription,
    routeType: AUTH_ONLY,
    isRequireCorpSub: true,
    requireCorpSubTier: [
      CORP_SUBCRIBER_TIER.T2,
      CORP_SUBCRIBER_TIER.T3,
      CORP_SUBCRIBER_TIER.T4,
      CORP_SUBCRIBER_TIER.C_SUITE,
      CORP_SUBCRIBER_TIER.EXPERT_NETWORK,
    ],
  },
]

export default routes
