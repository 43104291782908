import _get from 'lodash/get'
import _map from 'lodash/map'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { ReactComponent as IconBookmark } from 'assets/svg/icon_bookmark.svg'

import { BookmarkItem } from 'components/bookmark'
import { FlexContainer } from 'components/flex'

import { SCMP_COM_HOST, SCMP_COM_PROTOCOL } from 'config/app.config'

import { OnClickRemoveButton } from 'containers/Bookmark'

import * as modalAction from 'states/actions/application'

import { media } from 'utils/style'

type Props = {
  bookmarkedList: any,
  getBookmarkedList: (offset: number, limit: number) => void
  moreBookmarkList: any,
  onClickRemoveButton: OnClickRemoveButton,
}

const BookmarkList: React.FunctionComponent<Props> = ({
  bookmarkedList,
  getBookmarkedList,
  moreBookmarkList,
  onClickRemoveButton,
}) => {
  const dispatch = useDispatch()
  const [modalIndex, updateModelIndex] = useState(-1)
  const [offset, updateOffset] = useState(7)

  useEffect(() => {
    dispatch(modalAction.updateZendeskVisibility(false))
    return () => {
      dispatch(modalAction.updateZendeskVisibility(true))
    }
  }, [dispatch])

  const onClickLoadMoreBookmarks = () => {
    getBookmarkedList(offset, 7)
    updateOffset(offset + 7)
  }

  const BookmarkListItems = _map(bookmarkedList, (item: any, x: number) => {
    const articleItem = {
      authors: _get(item, 'authors', []),
      entityId: _get(item, 'entityId', ''),
      headline: _get(item, 'headline', ''),
      images: _get(item, 'images', ''),
      publishedDate: _get(item, 'publishedDate', ''),
      socialHeadline: _get(item, 'socialHeadline', ''),
      urlAlias: _get(item, 'urlAlias', ''),
    }
    return <BookmarkItem index={x} modalIndex={modalIndex} key={`${articleItem.entityId}_${x}`} article={articleItem} updateModelIndex={updateModelIndex} onClickRemoveButton={onClickRemoveButton}/>
  })

  const renderMoreButton = () => {
    return (
      <MoreExploringButton onClick={onClickLoadMoreBookmarks}>
        MORE BOOKMARKS
      </MoreExploringButton>
    )
  }
  return (
    <BookmarkListContainer>
      <BookmarksBackgroundContainer flexDirection='column' alignItem='center'>
        { BookmarkListItems.length > 0
          ? (<React.Fragment>
            {BookmarkListItems}
            {moreBookmarkList.length > 0
              ? renderMoreButton()
              : <div/>
            } </React.Fragment>)
          : (<React.Fragment>
            <BookmarkPlaceHolderText>Your Bookmarked articles will save here.</BookmarkPlaceHolderText>
            <BookmarkPlaceHolderTextBold>Tap the <IconBookmarkContainer /> to bookmark an article.</BookmarkPlaceHolderTextBold>
            <StartExploringButton href={`${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}`}>
              START EXPLORING
            </StartExploringButton>
          </React.Fragment>)}
      </BookmarksBackgroundContainer>
    </BookmarkListContainer>
  )
}

export default BookmarkList

const BookmarksBackgroundContainer = styled(FlexContainer)`
  background-color: #F2F2F2;
  padding: 4px 0px;
  width: 100%;
  ${media.tabletUp`
    max-width: 680px;
  `}
`

const BookmarkListContainer = styled.div`
  padding-bottom: 110px;
  ${media.mobileUp`
    width: 100%;
    padding-bottom: 0px;
  `}
`

const BookmarkPlaceHolderText = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin-top: 24px;
`

const BookmarkPlaceHolderTextBold = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin-top: 8px;
  font-weight: bold;
  display: flex;
`
const IconBookmarkContainer = styled(IconBookmark)`
  width:18px;
  height:21px;
  margin: 0 8px;
  path {
    fill: #000;
  }
`

const StartExploringButton = styled.a`
  border: 1px solid #4585FF;
  border-radius: 2px;
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  padding: 8px 15px;
  width: fit-content;
  margin-top: 24px;
  margin-bottom: 20px;
`

const MoreExploringButton = styled.div`
  display: block;
  border: 1px solid #4585FF;
  border-radius: 2px;
  color: #4585FF;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0;
  padding: 8px 15px;
  width: fit-content;
  margin-top: 16px;
  margin-bottom: 24px;
  cursor: pointer;
  ${media.tabletUp`
    display: block;
  `}
`
