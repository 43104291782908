import React from 'react'
import styled from 'styled-components'

import FieldTitle from './FieldTitle'
import Select from './Select'

type Props = {
  title: string,
  children?: React.ReactNode,
  mandatory?: boolean,
  value?: string,
  error?: string,
  onChange?: React.ChangeEventHandler<HTMLSelectElement>,
}

const SelectField: React.FunctionComponent<Props> = ({ title, children, mandatory, value, error, onChange }) => {
  return (
    <InputContainer>
      <FieldTitle mandatory={mandatory}>{ title }</FieldTitle>
      {error
        ? (
          <ErrorMsg>{error}</ErrorMsg>
          )
        : null}
      <Select className={error ? 'error' : ''} value={value} onChange={onChange}>
        { children }
      </Select>
    </InputContainer>
  )
}

const InputContainer = styled.div`
  text-align: left;
  & > .error {
    border: 1px solid #EB4254;
  }
`

const ErrorMsg = styled.div`
  margin: 1px 0 7px 0;
  color: #EB4254;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.14px;
  line-height: 14px;
`

export default SelectField
