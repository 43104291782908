import {
  UPDATE_USER_UID,
  UPDATE_USER_UUID,
  UPDATE_USER_EMAIL,
  UPDATE_USER_FRSTNAME,
  UPDATE_USER_LASTNAME,
  UPDATE_USER_USERNAME,
  UPDATE_USER_SIGNIN_STATUS,
  UPDATE_USER_USERPIC,
  UPDATE_USER_SUBSCRIPTION_DATA,
  UPDATE_USER_IS_CHURN_SUBSCRIBER,
  UPDATE_USER_USERLVL,
  UPDATE_USER_USERCREATED,
  UPDATE_USER_USERSOURCE,
  UPDATE_USER_USERSCS,
  UPDATE_USER_OPTIN,
  UPDATE_USER_OPTINID,
  UPDATE_USER_3RD_OPTIN,
  UPDATE_USER_ISSCMPUSER,
  UPDATE_ISPIANOSUBSCRIBER_DATA_READY,
  UPDATE_USER_KEYWORD_ALERTS,
  UPDATE_USER_ISSHOWROSETTA,
} from '../constants/action-types'

const initialState = {
  uid: '',
  uuid: '',
  email: '',
  firstname: '',
  lastname: '',
  username: '',
  isSignedIn: false,
  userPic: '',
  optIn: undefined,
  optInId: '',
  optIn3rd: undefined,
  keywordAlerts: undefined,
  subscriptionPackageName: '',
  subscriptionRenewDate: '',
  source: '',
  created: '',
  lvl: [],
  scs: [],
  isSubscriber: false,
  isChurnSubscriber: false,
  isScmpUser: false,
  isPianoSubscriberDataReady: false,
  isShowRosetta: true,
}

export default function userInfo (state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_USER_UID:
      return {
        ...state,
        uid: action.uid,
      }
    case UPDATE_USER_UUID:
      return {
        ...state,
        uuid: action.uuid,
      }
    case UPDATE_USER_EMAIL:
      return {
        ...state,
        email: action.email,
      }
    case UPDATE_USER_FRSTNAME:
      return {
        ...state,
        firstname: action.firstname,
      }
    case UPDATE_USER_LASTNAME:
      return {
        ...state,
        lastname: action.lastname,
      }
    case UPDATE_USER_USERNAME:
      return {
        ...state,
        username: action.username,
      }
    case UPDATE_USER_SIGNIN_STATUS:
      return {
        ...state,
        isSignedIn: action.isSignedIn,
      }
    case UPDATE_USER_USERPIC:
      return {
        ...state,
        userPic: action.userPic,
      }
    case UPDATE_USER_SUBSCRIPTION_DATA:
      return {
        ...state,
        subscriptionPackageName: action.packageName,
        subscriptionRenewDate: action.renewDate,
        isSubscriber: true,
        isChurnSubscriber: false,
      }
    case UPDATE_USER_IS_CHURN_SUBSCRIBER:
      return {
        ...state,
        isSubscriber: false,
        isChurnSubscriber: true,
      }
    case UPDATE_ISPIANOSUBSCRIBER_DATA_READY:
      return {
        ...state,
        isPianoSubscriberDataReady: true,
      }
    case UPDATE_USER_OPTIN:
      return {
        ...state,
        optIn: action.optIn,
      }
    case UPDATE_USER_OPTINID:
      return {
        ...state,
        optInId: action.optInId,
      }
    case UPDATE_USER_3RD_OPTIN:
      return {
        ...state,
        optIn3rd: action.optIn3rd,
      }
    case UPDATE_USER_KEYWORD_ALERTS:
      return {
        ...state,
        keywordAlerts: action.keywordAlerts,
      }
    case UPDATE_USER_USERCREATED:
      return {
        ...state,
        created: action.userCreated,
      }
    case UPDATE_USER_USERSOURCE:
      return {
        ...state,
        source: action.userSource,
      }
    case UPDATE_USER_USERLVL:
      return {
        ...state,
        lvl: action.userLvl,
      }
    case UPDATE_USER_USERSCS:
      return {
        ...state,
        scs: action.userScs,
      }
    case UPDATE_USER_ISSCMPUSER:
      return {
        ...state,
        isScmpUser: action.isScmpUser,
      }
    case UPDATE_USER_ISSHOWROSETTA:
      return {
        ...state,
        isShowRosetta: action.isShowRosetta,
      }
    default:
      return state
  }
}
