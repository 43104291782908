
import _filter from 'lodash/filter'

import { SocialLoginMethod } from 'utils/tracking'

import {
  REMOVE_MANAGE_NEWSLETTER_IDS,
  REMOVE_MANAGE_UNLINK_MEDIA,
  UPDATE_MANAGE_AUTH_CONTENT,
  UPDATE_MANAGE_NEWSLETTER_IDS,
  UPDATE_MANAGE_UNLINK_MEDIA,
} from '../constants/action-types'

const initialState = {
  getManagedNewsletterIdsReady: false,
  newsletterIds: [] as string[],
  authContent: [] as string[],
  media: { id: '', name: '' },
}

type ManageActionType = {
  type: typeof UPDATE_MANAGE_NEWSLETTER_IDS
  newsletterIds: string[]
} | {
  type: typeof REMOVE_MANAGE_NEWSLETTER_IDS
  newsletterIds: string
} | {
  type: typeof UPDATE_MANAGE_AUTH_CONTENT
  authContent: string[]
} | {
  type: typeof UPDATE_MANAGE_UNLINK_MEDIA
  id: string
  name: SocialLoginMethod
} | {
  type: typeof REMOVE_MANAGE_UNLINK_MEDIA
}

export default function manage (state = initialState, action: ManageActionType) {
  switch (action.type) {
    case UPDATE_MANAGE_NEWSLETTER_IDS:
      return {
        ...state,
        newsletterIds: action.newsletterIds,
        getManagedNewsletterIdsReady: true,
      }
    case REMOVE_MANAGE_NEWSLETTER_IDS:
      return {
        ...state,
        newsletterIds: _filter(state.newsletterIds, activeEtityId => activeEtityId !== action.newsletterIds),
      }
    case UPDATE_MANAGE_AUTH_CONTENT:
      return {
        ...state,
        authContent: action.authContent,
      }
    case UPDATE_MANAGE_UNLINK_MEDIA:
      return {
        ...state,
        media: {
          id: action.id,
          name: action.name,
        },
      }
    case REMOVE_MANAGE_UNLINK_MEDIA:
      return {
        ...state,
        media: { id: '', name: '' },
      }
    default:
      return state
  }
}
