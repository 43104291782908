import React, { useState } from 'react'
import styled from 'styled-components'
import { FlexContainer } from 'components/flex'
import { CustomModal } from 'components/modal'

import { useMutation } from '@apollo/react-hooks'
import { media } from 'utils/style'

import { useDispatch, useSelector } from 'react-redux'

import UPDATE_NEWSLETTER from 'universal/apollo/d8/UPDATE_NEWSLETTER.gql'
import { AppState } from 'states/reducers'
import * as manageAction from 'states/actions/manage'

import { triggerGtmEvent, triggerGA4GtmEvent } from 'utils/tracking'
import { DataLayerEventCategory, DataLayerEventAction, DataLayerGtmCustomEventName } from 'config/constants'

type Props = {
  className?: string
  newsletter: any
}
const NewsletterItemContainer: React.FunctionComponent<Props> = ({ ...Props }) => {
  const dispatch = useDispatch()
  const email = useSelector((state: AppState) => state.userInfo.email)
  const [showModal, isShowModal] = useState<boolean>(false)
  const modalTitle = `Are you sure you want to unsubscribe <strong>${Props.newsletter.name}</strong>`

  const ShowHideHandler = () => {
    isShowModal(!showModal)
  }

  const getSummaryData: any = (children: any) => {
    if (children[0] && children[0].data !== undefined) {
      return children[0].data
    } else if (children[0] && children[0].children !== undefined) {
      return getSummaryData(children[0].children)
    } else {
      return ''
    }
  }

  const getSummary = (summary: any) => {
    let result = ''
    if (summary[0] && summary[0].children !== undefined) {
      result = getSummaryData(summary[0].children)
    }
    return result
  }

  const [unSubscribeNewsletter] = useMutation(UPDATE_NEWSLETTER, {
    onCompleted: () => {
      isShowModal(false)
      triggerGtmEvent(
        DataLayerEventAction.NEWSLETTER_UNSUBSCRIBE,
        DataLayerEventCategory.MY_ACCOUNT,
        DataLayerGtmCustomEventName.TRACKING,
        Props.newsletter.name,
      )
      triggerGA4GtmEvent({
        category: 'nl',
        subcategory: 'unsub',
        action: 'sys',
        customized_parameters: {
          page_name: 'my_account',
          newsletter_name: Props.newsletter.name,
        },
      })
      dispatch(manageAction.removeManageNewsletterIds(Props.newsletter.entityId))
    },
    onError: () => {
      alert('Sorry, something is wrong. Please try again.')
    },
    variables: {
      input: {
        mail: email,
        subscriptions: [{ targetId: Props.newsletter.entityId, status: '0', source: 'web' }],
        append: true,
      },
    },

  })

  const onUnSubscribedButtonClicked = () => {
    isShowModal(true)
  }

  return (
    <NewsletterItem flexDirection='row' className={Props.className}>
      <Details flexDirection='column'>
        <Title> { getSummary(Props.newsletter.summary) } </Title>
        <SubTitle>{ Props.newsletter.name }</SubTitle>
      </Details>
      <Action flexDirection='row' alignItem='center' justifyContent='center'>
        <Subscribed onClick={onUnSubscribedButtonClicked}>SIGNED UP</Subscribed>
      </Action>
      { showModal
        ? <CustomModal title={modalTitle} customButton={true} onShow={ShowHideHandler}>
          <ModalTopButton onClick={() => unSubscribeNewsletter()}>UNSUBSCRIBE</ModalTopButton>
          <ModalBottomButton onClick={ShowHideHandler}>Cancel</ModalBottomButton>
        </CustomModal>
        : null}
    </NewsletterItem>
  )
}

const NewsletterItem = styled(FlexContainer)`
  padding: 12px;
  margin: 4px 8px;
  background-color: #FFFFFF;
  width: calc(100% - 40px);
`

const Title = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  margin: 0px 0px 4px 0px;
  text-transform: uppercase;
`

const SubTitle = styled.div`
  color: #000000;
  font-family: Merriweather;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  font-weight: normal;
  ${media.tabletUp`
    font-size: 21px;
    line-height: 27px;
  `}
`

const Action = styled(FlexContainer)`
  margin-left: 20px;
`

const Details = styled(FlexContainer)`
  flex: 1 1 0;
`

const Subscribed = styled.div`
  padding: 8px 15px;
  border-radius: 2px;
  background-color: #F2F2F2;
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
`

const ModalTopButton = styled.div`
  padding: 12px 0;
  width: 100%;
  border-radius: 2px;
  background-color: #FFCA05;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
`
const ModalBottomButton = styled.div`
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  color: #4585FF;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
`

export default NewsletterItemContainer
