import React from 'react'
import styled from 'styled-components'

type Props = {
  children?: React.ReactNode,
  mandatory?: boolean
}

const FieldTitle: React.FunctionComponent<Props> = ({ children, mandatory }) => {
  return (
    <Title className={mandatory ? 'mandatory' : ''}>{ children }</Title>
  )
}

const Title = styled.label`
  margin-bottom: 7px;
  padding-left: 1px;
  display: inline-block;
  color: #000000;
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  &.mandatory::after {
    content: '*';
  }
`

export default FieldTitle
