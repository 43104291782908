import React, { useState } from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

import { FlexContainer } from 'components/flex'
import { media } from 'utils/style'
import PhotoPlaceholder from 'assets/svg/photo-placeholder.svg'
import PlusIcon from 'assets/svg/icon_plus.svg'
import InfoIcon from 'assets/svg/icon_info.svg'

type Props = {
  isSocialConnected?: boolean,
  userPhoto?: any,
  onChange?: (value: string) => void
}

const UserImage: React.FunctionComponent<Props> = ({ isSocialConnected, userPhoto, onChange }) => {
  const { url = PhotoPlaceholder, alt = '' } = userPhoto || {}
  const [newPhoto, updateNewPhoto] = useState<string>('')

  const onChangeUserPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isSocialConnected) {
      return false
    }

    const img = new Image()
    const source = event.target.files && event.target.files[0]
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D
      const iw = img.width
      const ih = img.height
      const MAX_WIDTH = 300
      const MAX_HEIGHT = 300
      const scale = Math.min((MAX_WIDTH / iw), (MAX_HEIGHT / ih))
      const iwScaled = iw * scale
      const ihScaled = ih * scale

      canvas.width = iwScaled
      canvas.height = ihScaled

      ctx.drawImage(img, 0, 0, iwScaled, ihScaled)

      const result = canvas.toDataURL('image/jpeg', 1.0)

      updateNewPhoto(result)
      onChange && onChange(result)
    }
    if (source) {
      img.src = URL.createObjectURL(source)
    }
  }

  return (
    <ImageContainer alignItem='center' justifyContent='center'>
      <UserPhotoContainer htmlFor='userPhoto'>
        <UserPhoto src={newPhoto || url} alt={alt} />
        {!isSocialConnected
          ? (
            <AddButton justifyContent='center'>
              <img src={ PlusIcon } alt='' />
            </AddButton>
            )
          : (
            <>
              <InfoIconWrapper data-tip="user-image-tooltip" data-for="user-image-tooltip">
                <img src={ InfoIcon } alt='' />
              </InfoIconWrapper>
              <CustomReactTooltip
                id="user-image-tooltip"
                place="bottom"
                type="light"
                effect="solid"
                backgroundColor={'#f6f6f6'}
                className='custom-color'
                border={true}
                borderColor={'#ccc'}
                textColor={'#222'}
                clickable={true}
                offset={{ left: 50 }} // half of user photo width
                data-html={true}>
                Your <b>profile image</b> has been locked as part of our account verification process. To change this, first disconnect your linked Facebook or Google account. Then, edit and save your SCMP account profile.
              </CustomReactTooltip>
            </>
            )}
        <FileUpload type='file' id='userPhoto' onChange={onChangeUserPhoto} disabled={isSocialConnected} />
      </UserPhotoContainer>
    </ImageContainer>
  )
}

const ImageContainer = styled(FlexContainer)`
  position: absolute;
  width: 100vw;
  height: 150px;
  background-image: url('/public/bkg_profile-1.png');
  background-repeat: no-repeat;
  background-position: center;
  ${media.tabletUp`
    position: relative;
    width: 100%;
  `}
`

const UserPhotoContainer = styled.label<{ htmlFor: string }>`
  position: relative;
  width: 75px;
  height: 75px;
`

const UserPhoto = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

const AddButton = styled(FlexContainer)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #4585FF;
  cursor: pointer;
`

const InfoIconWrapper = styled.a`
  position: absolute;
  right: -8px;
  bottom: 0;
  line-height: 0;

  img {
    width: 16px;
    height: 16px;
  }
`

const CustomReactTooltip = styled(ReactTooltip)`
  width: 257px;
  border: 1px solid #ccc;
  font-size: 14px !important;
  line-height: 18px;
  padding: 20.5px !important;
  font-family: Roboto;
  text-align: left;
  opacity: 1 !important;
  ::before {
    left: 200px !important;
  }
  ::after {
    left: 200px !important;
  }
`

const FileUpload = styled.input`
  display: none;
`

export default UserImage
