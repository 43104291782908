import { useMutation } from '@apollo/react-hooks'
import _get from 'lodash/get'
import _startCase from 'lodash/startCase'
import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { refreshJWT } from 'utils/user'

import IconFacebook from 'assets/svg/icon_fb.svg'
import IconGoogle from 'assets/svg/icon_google.svg'
import IconApple from 'assets/svg/icon_apple.svg'

import { FlexContainer } from 'components/flex'
import { GoBackButton } from 'components/goBackButton'
import { MediaButton } from 'components/manage/getConnected'
import { CustomModal } from 'components/modal'

import { DataLayerEventAction, DataLayerEventCategory, DataLayerGtmCustomEventName, ErrorMessage, SocialMediaError } from 'config/constants'

import * as appAction from 'states/actions/application'
import * as manageAction from 'states/actions/manage'
import { AppState } from 'states/reducers'

import GET_MY_PROFILE from 'universal/apollo/d8/GET_MY_PROFILE.gql'
import DELETE_SOCIAL_AUTH from 'universal/apollo/d8/DELETE_SOCIAL_AUTH.gql'

import useSocialData from 'universal/data/useSocialData'

import { media } from 'utils/style'
import { SocialLoginMethod, triggerGA4GtmEvent, triggerGtmCustomEvent } from 'utils/tracking'

type Props = {
  authContent: any[]
}

const GetConnectedPanel: React.FunctionComponent<Props> = ({ ...Props }) => {
  const dispatch = useDispatch()
  const { socialMediaError } = useSelector((state: AppState) => state.application, shallowEqual)
  const { appleData, facebookData, googleData, setAppleData, setFacebookData, setGoogleData } = useSocialData()
  const unlinkMedia = useSelector((state: AppState) => state.manage.media, shallowEqual)
  const getModalTitle = (name: string) => {
    return `Disconnect ${_startCase(name)}?`
  }

  const cancelUnlinkButtonClick = () => {
    dispatch(manageAction.removeUnlinkMedia())
  }

  const tracking = (media: string, success: boolean) => {
    if (success) {
      media === 'Google' && triggerGtmCustomEvent(DataLayerEventAction.GOOGLE_DISCONNECT_SUCCESS, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
      media === 'Facebook' && triggerGtmCustomEvent(DataLayerEventAction.FACEBOOK_DISCONNECT_SUCCESS, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
      media === 'Apple' && triggerGtmCustomEvent(DataLayerEventAction.APPLE_DISCONNECT_SUCCESS, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
    } else {
      media === 'Google' && triggerGtmCustomEvent(DataLayerEventAction.GOOGLE_DISCONNECT_FAIL, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
      media === 'Facebook' && triggerGtmCustomEvent(DataLayerEventAction.FACEBOOK_DISCONNECT_FAIL, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
      media === 'Apple' && triggerGtmCustomEvent(DataLayerEventAction.APPLE_DISCONNECT_FAIL, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
    }
  }

  const [onUnlinkButtonClick] = useMutation(DELETE_SOCIAL_AUTH, {
    refetchQueries: () => [{ query: GET_MY_PROFILE }],
    onCompleted: () => {
      tracking(`${_startCase(unlinkMedia.name)}`, true)
      switch (unlinkMedia.name) {
        case 'google':
          setGoogleData({})
          break
        case 'facebook':
          setFacebookData({})
          break
        case 'apple':
          setAppleData({})
          break
      }
      dispatch(manageAction.removeUnlinkMedia())

      refreshJWT()
    },
    onError: () => {
      tracking(`${_startCase(unlinkMedia.name)}`, false)
      dispatch(manageAction.removeUnlinkMedia())
      switch (unlinkMedia.name) {
        case 'google':
          setGoogleData({})
          break
        case 'facebook':
          setFacebookData({})
          break
        case 'apple':
          setAppleData({})
          break
      }
      dispatch(appAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
    },
    variables: {
      id: unlinkMedia.id,
    },
  })

  useEffect(() => {
    if (socialMediaError.media) {
      if (socialMediaError.code) {
        const message = _get(SocialMediaError, `${socialMediaError.media}.${socialMediaError.code}`, null)
        if (message) {
          dispatch(appAction.updateModalMessage(message))
        }
      } else {
        dispatch(appAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
      }
    }
    // remove the error state on the store
    dispatch(appAction.removeSoicalMediaError())
  }, [socialMediaError.media, socialMediaError.code])

  const handleDisconnectButtonClick = () => {
    triggerGA4GtmEvent({
      category: 'login',
      subcategory: 'social_disconnect',
      action: 'click',
      customized_parameters: {
        method: unlinkMedia.name as SocialLoginMethod,
      },
    })
    onUnlinkButtonClick()
  }

  return (
    <Panel flexDirection='column'>
      <Content flexDirection='column' alignItem='center'>
        <GobackLink>
          <GoBackButton labelText="Get Connected" goBackLink="/settings/profile"/>
        </GobackLink>
        <SocialList>
          <MediaContainer>
            <MediaButton authContent={facebookData} connectedName='facebook' mediaName={'Facebook'} mediaIcon={IconFacebook} />
          </MediaContainer>
          <Separator />
          <MediaContainer>
            <MediaButton authContent={googleData} connectedName='google' mediaName={'Google'} mediaIcon={IconGoogle} />
          </MediaContainer>
          <Separator />
          <MediaContainer>
            <MediaButton authContent={appleData} connectedName='apple' mediaName={'Apple'} mediaIcon={IconApple} />
          </MediaContainer>
          <Separator />
        </SocialList>
        <Priority>
          For priority moderation, connect your profile to a Facebook or Google account.
        </Priority>
      </Content>
      {unlinkMedia.id
        ? <CustomModal title={getModalTitle(unlinkMedia.name)} customButton={true} onShow={unlinkMedia.id}>
        <ModalTopButton onClick={handleDisconnectButtonClick}>DISCONNECT</ModalTopButton>
        <ModalBottomButton onClick={() => cancelUnlinkButtonClick()}>Cancel</ModalBottomButton>
        </CustomModal>
        : null}
    </Panel>
  )
}

export default GetConnectedPanel

const Content = styled(FlexContainer)`
  border-bottom: 0px;
  margin: 20px 20px 0;
  padding: 0;
  ${media.tabletUp`
    margin: 0;
  `}
`

const Panel = styled(FlexContainer)`
  max-width: 416px;
`

const GobackLink = styled.div`
  width: 100%;
`

const MediaContainer = styled(FlexContainer)`
  width: 100%;
  margin: 24px 0;
  ${media.tabletUp`
    margin: 0;
  `}
  &:first-child{
    margin-top: 0;
  }
`

const Separator = styled.div`
  border-bottom: 1px solid #CCCCCC;
  width: 100%;
`

const SocialList = styled.div`
  width: 100%;
  margin-top: 32px;
`

const Priority = styled.div`
  margin-top: 24px;
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`

const ModalTopButton = styled.div`
  padding: 12px 0;
  width: 100%;
  border-radius: 2px;
  background-color: #FFCA05;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
`

const ModalBottomButton = styled.div`
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  color: #4585FF;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
`
