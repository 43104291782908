import React from 'react'
import styled from 'styled-components'
import { OutSideStyledButton } from 'components/styled'
import axios from 'axios'
import { FlexContainer } from 'components/flex'

type Props = {
  updateState: (name: string) => void,
  uid?: string,
  hash?: string,
  options?: string,
}

const UnScbscibeConfirm: React.FunctionComponent<Props> = ({ ...Props }) => {
  const { updateState, uid, hash, options } = Props

  const onClickUnscribeButton = async () => {
    // video.requestPictureInPicture()
    try {
      const response = await axios.post(`/newsletter/unsubscribe/${uid}`, {
        hash,
        options,
      })

      if (response) {
        updateState('unsubscribe-success')
      }
    } catch (error) {
      updateState('unsubscribe-fail')
    }
  }

  return (
    <>
      <ContentContainer alignItem='baseline' justifyContent="center">
        <Message>I would like to unsubscribe from <BoldText>Recommended by SCMP</BoldText> email alerts.</Message>
        <OutSideStyledButton
          backgroundColour="#fff"
          border="1px solid"
          borderColor="#4585FF"
          color="#4585FF"
          fontSize="16px"
          lineHeight="19px"
          marginBottom="40px"
          onClick={onClickUnscribeButton}
        >UNSUBSCRIBE</OutSideStyledButton>
      </ContentContainer>
    </>
  )
}

const BoldText = styled.span`
  font-weight:bold;
`

const ContentContainer = styled(FlexContainer)`
  width: 100%;
  border-bottom: 1px solid #D8D8D8;
`

const Message = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-family: Roboto;
  width: 100%;
  margin-bottom: 40px;
`

export default UnScbscibeConfirm
