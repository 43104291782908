import {
  UPDATE_SIGNIN_CALLBACK_URL,
} from '../constants/action-types'

const initialState = {
  callbackUrl: '',
}

export default function signin (state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_SIGNIN_CALLBACK_URL:
      return {
        ...state,
        callbackUrl: action.url,
      }
    default:
      return state
  }
}
