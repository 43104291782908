import React from 'react'
import styled from 'styled-components'
import { SCMP_COM_PROTOCOL, SCMP_COM_HOST } from 'config/app.config'

import { FlexContainer } from 'components/flex'

const FooterComponent: React.FunctionComponent = () => {
  const currentYear = new Date().getFullYear()
  const scmpUrl = `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}`
  const policiesAndStandardsUrl = `${scmpUrl}/policies-and-standards`
  const privacyUrl = `${scmpUrl}/privacy-policy`
  const termAndConditionsUrl = `${scmpUrl}/terms-conditions`
  const commentingGuidelinesUrl = `${scmpUrl}/Commenting-Submission-Guidelines`
  const faqsUrl = 'https://help.scmp.com/hc'
  const workForUsUrl = `${scmpUrl}/work-for-us`
  const contactUsUrl = `${scmpUrl}/contact-us`

  return (
    <FooterContainer>
      <FooterTextContainer>
        <FooterText>Copyright © {currentYear} South China Morning Post Publishers Ltd. All rights reserved.</FooterText>
      </FooterTextContainer>
      <TNContainer>
        <TNCUrl target="_blank" href={policiesAndStandardsUrl}>Policies and Standards</TNCUrl>
        <TNCUrl target="_blank" href={privacyUrl}>Privacy Policy</TNCUrl>
        <TNCUrl target="_blank" href={termAndConditionsUrl}>Terms & Conditions </TNCUrl>
        <TNCUrl target="_blank" href={commentingGuidelinesUrl}>Commenting Guidelines</TNCUrl>
        <TNCUrl target="_blank" href={faqsUrl}>Help Center</TNCUrl>
        <TNCUrl target="_blank" href={workForUsUrl}>Work For Us</TNCUrl>
        <TNCUrl target="_blank" href={contactUsUrl}>Contact Us</TNCUrl>
      </TNContainer>
    </FooterContainer>)
}

const FooterContainer = styled(FlexContainer)`
  justify-content: center;
  white-space: nowrap;
  border-top: 2px solid #f2f2f2;
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0 28px;
  width: 100%;
`

const FooterTextContainer = styled(FlexContainer)`
  margin-top: 8px;
  margin-bottom: 8px;
`

const TNContainer = styled(FlexContainer)`
  margin-top: 8px;
  margin-bottom: 8px;
`

const TNCUrl = styled.a`
  color: #999999;
  cursor: pointer;
  font-family: Roboto;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
  margin: 0 8px;
`

const FooterText = styled.div`
  color: #999999;
  font-family: Roboto;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 13px;
  text-align: center;
  display: block;
  margin: 0 10px;
`

export default FooterComponent
