import React from 'react'
import styled from 'styled-components'
import { FlexContainer } from 'components/flex'

import { media } from 'utils/style'
import { SCMP_COM_HOST, SCMP_COM_PROTOCOL } from 'config/app.config'

const BookmarkWidget: React.FunctionComponent = () => {
  return (
    <a href={`${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}/newsletters`}>
      <NewsletterWidgetContainer flexDirection='row' justifyContent='space-between'>
        <WidgetContent>
          <WidgetLeft flexDirection='column'>
            <WidgetTitle>Sign up for more Newsletters</WidgetTitle>
          </WidgetLeft>
          <WidgetRight alignItem='end'>
            <WidgetButton>FIND OUT MORE</WidgetButton>
          </WidgetRight>
        </WidgetContent>
      </NewsletterWidgetContainer>
    </a>
  )
}

export default BookmarkWidget

const NewsletterWidgetContainer = styled(FlexContainer)`
  background-color: #FFCA05;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  ${media.tabletUp`
    position: relative;
    bottom: auto;
    left: auto;
    right: auto;
  `}
`

const WidgetContent = styled.div`
  margin: 17px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const WidgetLeft = styled(FlexContainer)`
  width: auto;
  float: left;
  align-self: center;
`

const WidgetRight = styled(FlexContainer)`
  white-space: nowrap;
  align-self: center;
  margin-left: 20px;
`

const WidgetTitle = styled.div`
  color: #001246;
  font-family: Merriweather;
  font-size: 18px;
  line-height: 23px;
  margin-top: 2px;
  ${media.tabletUp`
    font-size: 21px;
    line-height: 27px;
  `}
`
const WidgetButton = styled.div`
  background-color: #001246;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  letting-spacing: 0;
  padding: 8px 15px;
  width: 100%;
  font-weight: 300;
`
