import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { CustomModal } from 'components/modal'
import { AppState } from 'states/reducers'

const CommonModal: React.FunctionComponent = () => {
  const modalMessage = useSelector((state: AppState) => state.application.modalMessage)
  const modalDestinationUrl = useSelector((state: AppState) => state.application.modalDestinationUrl)
  const [showModal, updateShowModal] = useState<boolean>(false)

  const ShowHideHandler = () => {
    updateShowModal(!showModal)
  }

  useEffect(() => {
    if (modalMessage) {
      updateShowModal(true)
    }
  }, [modalMessage])

  return showModal
    ? <CustomModal title={modalMessage}
      defaultButtonTitle={'OK'}
      destinationUrl={modalDestinationUrl}
      onShow={ShowHideHandler}></CustomModal>
    : null
}

export default CommonModal
