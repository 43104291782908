import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'
import _find from 'lodash/find'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { Cookies } from 'react-cookie'
import { FlexContainer } from 'components/flex'

import EditProfile from 'components/editProfile/index'
import { Loading } from 'components/Loading'

import GET_PROFILE from 'universal/apollo/d8/GET_PROFILE.gql'
import { media } from 'utils/style'

import * as userInfoActions from 'states/actions/user-info/index'

import { ErrorMessage } from 'config/constants'
import { getUserPicture } from 'utils/cookie'
import * as modalAction from 'states/actions/application'

const ProfileComponent: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const cookie = new Cookies()

  const { loading, data } = useQuery(GET_PROFILE, { fetchPolicy: 'network-only' })
  if (loading) {
    return <LoadingContainer></LoadingContainer>
  }

  const profileIds: {
    uid?: string
    profileMainId?: string,
    profileProfileId?: string,
  } = {}
  profileIds.uid = _get(data, 'user.uid')
  const profileEntities = _get(data, 'user.profile.entities', [])

  const profileMain = _find(profileEntities, entity => entity && entity.__typename === 'ProfileMain') || {}
  profileIds.profileMainId = profileMain.entityId
  const profileProfile = _find(profileEntities, entity => entity && entity.__typename === 'ProfileProfile') || {}
  profileIds.profileProfileId = profileProfile.entityId

  const userCreateDate = _get(data, 'user.created', '')
  const isSocialConnected = _get(data, 'user.isSocialAccountConnected')
  const email = _get(data, 'user.mail', '')
  const userPhoto = _get(profileMain, 'fieldUserPhoto', {})
  const profile = profileProfile

  const onSaveHandler = (response: any) => {
    // TODO: MAKE IT AS A FUNCTION
    if (response.updateProfileMain || response.updateProfileProfile) {
      if (_get(response.updateProfileMain, 'errors', []).length > 0) {
        dispatch(modalAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
        return
      } else if (_get(response.updateProfileMain, 'violations[0].message')) {
        dispatch(modalAction.updateModalMessage(_get(response.data, 'violations[0].message')))
        return
      }

      if (_get(response.updateProfileProfile, 'errors', []).length > 0) {
        dispatch(modalAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
        return
      } else if (_get(response.updateProfileProfile, 'violations[0].message')) {
        dispatch(modalAction.updateModalMessage(_get(response.data, 'violations[0].message')))
        return
      }
    }

    const scmpUser = cookie.get('scmp_user') || {}
    const {
      firstname = '',
      lastname = '',
    } = scmpUser
    // if (response.createProfileMain || response.updateProfileMain) {
    //   const profileMain = (response.createProfileMain) ? 'createProfileMain' : 'updateProfileMain'
    //   const photoUrl = _get(response, `${profileMain}.entity.fieldUserPhoto.url`, '')
    //   const userCookie: any = cookie.get('scmp_user')
    dispatch(userInfoActions.updateUserUserPic(getUserPicture(scmpUser)))
    //   cookie.set('scmp_user', { ...userCookie, upic: photoUrl }, {
    //     secure: false,
    //     path: '/',
    //     maxAge: 60 * 60 * 24 * 365 * 1000,
    //     domain: process.env.USER_COOKIE_DOMAIN,
    //   })
    // }
    // if (response.updateProfileProfile) {
    //   const firstname = _get(response, 'updateProfileProfile.entity.fieldFirstName', '')
    //   const lastname = _get(response, 'updateProfileProfile.entity.fieldLastName', '')
    //   const userCookie: any = cookie.get('scmp_user')
    dispatch(userInfoActions.updateUserFirstname(firstname))
    dispatch(userInfoActions.updateUserLastname(lastname))
    //   cookie.set('scmp_user', { ...userCookie, firstname, lastname }, {
    //     secure: false,
    //     path: '/',
    //     maxAge: 60 * 60 * 24 * 365 * 1000,
    //     domain: process.env.USER_COOKIE_DOMAIN,
    //   })
    // }
    dispatch(modalAction.updateModalMessage(ErrorMessage.PROFILE_UPDATE_SUCCESS, '/settings'))
  }

  return (
    <PageContainer id='manage-container' flexDirection='row' alignItem='stretch' justifyContent='flex-start' wrap='no-wrap'>
      <SettingsContentContainer>
        <EditProfilePage>
          <EditProfile email={email} userCreateDate={userCreateDate} userPhoto={userPhoto} profile={profile} profileIds={profileIds} isSocialConnected={isSocialConnected} onSaveHandler={onSaveHandler} />
        </EditProfilePage>
      </SettingsContentContainer>
    </PageContainer>
  )
}

const PageContainer = styled(FlexContainer)`
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin: auto;
`

const SettingsContentContainer = styled(FlexContainer)`
  flex: 1 1 0;
`

const EditProfilePage = styled.div`
  width: 100%;
  height: 100%;
`

const LoadingContainer = styled(Loading)`
  margin-top: 20px;
  ${media.tabletUp`
    margin-top: 0px;
  `}
`

export default ProfileComponent
