import { SCMP_DOMAINS, VALID_AMP_DOMAINS_REGEX } from 'config/constants'
import _map from 'lodash/map'

function getIsSubdomain (testURL: string) {
  const decodedTestURL = decodeURIComponent(testURL)
  try {
    const newURL = decodedTestURL.startsWith('http') ? decodedTestURL : `https://${decodedTestURL}`
    const { hostname } = new URL(newURL)
    return Object.values(SCMP_DOMAINS).some(validDomain => hostname === validDomain || hostname.endsWith(`.${validDomain}`))
  } catch {
    return false
  }
}

/**
 * Determine the url is valid scmp redirect link
 *
 * AMP
 * https://amp-scmp-com.cdn.ampproject.org/wp/s/amp.scmp.com/news/hong-kong/health-environment/[…]no-8-typhoon-signal?usqp=mq331AQKKAFQArABIIACAw%3D%3D
 * https://www.google.com/amp/s/amp.scmp.com/news/china/diplomacy/article/3152125/china-wants-non-discriminatory-treatment-huawei-survey-says-76
 * https://www.google.com/amp/s/amp.scmp.com/news/china/diplomacy/article/3152113/china-overly-optimistic-relations-us-beijing-adviser-says
 *
 * Bing AMP, this one not sure the usage (Ref: https://project.scmp.tech/product/web/scmp-account/-/commit/9b1aca51db938691906cec1ad10bacdc89f1b073)
 * www.bing-amp.com
 * @param url string
 * @returns boolean
 */
export function isScmpDomains (url: string) {
  let isValidDomain = false

  if (getIsSubdomain(url)) {
    isValidDomain = true
  }

  // check special AMP pattern
  _map(VALID_AMP_DOMAINS_REGEX, (pattern: string) => {
    const regex = new RegExp(pattern, 'i')
    if (regex.test(url)) {
      isValidDomain = true
    }
  })

  return isValidDomain
}
