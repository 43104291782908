import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as EventIcon } from 'assets/svg/icon-event.svg'
import { ReactComponent as DownloadIcon } from 'assets/svg/arrow-right.svg'
import { ReactComponent as ShowLessArrowUp } from 'assets/svg/show-less-arrow-up.svg'
import { ReactComponent as ShowMoreArrowDown } from 'assets/svg/show-more-arrow-down.svg'

import { media } from 'utils/style'
import moment from 'moment'
import dayjs from 'dayjs'
import _toUpper from 'lodash/toUpper'

import NewLabel from 'components/CorporateSubscription/newLabel'

import { triggerGA4GtmEvent, triggerGtmEvent } from 'utils/tracking'
import { DataLayerEventAction, DataLayerEventCategory, DataLayerGtmCustomEventName } from 'config/constants'

type Event = {
  entityId: string
  entityUuid: string
  name: string
  registrationLink: string
  startDate: number
  endDate: number
  expireDate: number
  published: number
  eventType: string
  eventLabel: string
  description: string
}

type Props = {
  item: Event
}

const EventItemComponent: React.FunctionComponent<Props> = ({ item }) => {
  const descriptionRef = useRef<HTMLDivElement>(null)
  const [showMore, updateShowMore] = useState(false)
  const [showToggle, updateShowToggle] = useState(false)

  const getDisplayDate = (startDate: number, endDate: number) => {
    let date: string = ''

    const formattedStartDate = moment(startDate).format('DD MMM YYYY')
    const formattedEndDate = moment(endDate).format('DD MMM YYYY')

    if (startDate && endDate && formattedStartDate === formattedEndDate) {
      date = formattedStartDate
    } else if (startDate && endDate) {
      date = `${formattedStartDate} - ${formattedEndDate}`
    } else if (startDate) {
      date = `FROM ${formattedStartDate}`
    }

    return date
  }

  const getLabelComponent = (item: Event) => {
    let bgColor: string = '#E5E5E5'

    switch (_toUpper(item.eventLabel)) {
      case 'COMING SOON': bgColor = '#CCEDEC'; break
      case 'REGISTER NOW': bgColor = '#FBDCDF'; break
      case 'WATCH PLAYBACK': bgColor = '#E5E5E5'; break
    }
    return <LabelContainer>
      <Label onClick={() => onEventLinkClicked(item.name, item.registrationLink)} bgColor={bgColor} url={item.registrationLink}>{item.eventLabel}</Label>
    </LabelContainer>
  }

  const onEventLinkClicked = (name: string, url: string) => {
    if (!url) {
      return false
    }

    triggerGtmEvent(
      DataLayerEventAction.GROUP_SUBSCRIPTION_EVENT_CLICK,
      DataLayerEventCategory.MY_ACCOUNT,
      DataLayerGtmCustomEventName.TRACKING,
      name,
    )
    triggerGA4GtmEvent({
      category: 'myaccount',
      subcategory: 'group_benefits',
      action: 'click',
      customized_parameters: {
        action_type: 'event',
        event_name: name,
      },
    })
    window.open(url)
  }

  const observer = React.useRef(
    new ResizeObserver(entries => {
      // Only care about the first element, we expect one element ot be watched
      const firstElement: any = entries[0]
      const contentRect = firstElement.contentRect
      const target = firstElement.target

      if (target.scrollHeight > contentRect.height) {
        updateShowToggle(true)
      }
    }),
  )

  React.useEffect(() => {
    if (descriptionRef.current) {
      observer.current.observe(descriptionRef.current)
    }

    return () => {
      if (descriptionRef.current) {
        (observer.current as any).unobserve(descriptionRef.current)
      }
    }
  }, [descriptionRef, observer])

  const getIsShowNewLabel = (event: Event) => {
    return dayjs(event.endDate).valueOf() > dayjs().valueOf()
  }

  const getIsShowDate = (event: Event) => {
    return dayjs(event.expireDate).valueOf() > dayjs().valueOf()
  }

  const toggleHandler = () => {
    updateShowMore(!showMore)
  }

  return (
    <Item key={item.name}>
      { item.eventLabel ? getLabelComponent(item) : <EventIcon /> }
      { item.startDate && item.endDate && getIsShowDate(item) && <EventDateContainer>
          <EventDate>{ getDisplayDate(item.startDate, item.endDate) }</EventDate>
          { getIsShowNewLabel(item) && <NewLabel />}
      </EventDateContainer> }
      { item.registrationLink && <EventTitle onClick={() => onEventLinkClicked(item.name, item.registrationLink)}>
          {item.name}
        </EventTitle> }
      { !item.registrationLink && <EventTitleStatic>{item.name}</EventTitleStatic> }
      { item.description &&
        <>
          <EventContent dangerouslySetInnerHTML={{ __html: item.description }} showMore={showMore} ref={descriptionRef} />
          <ShowMoreButton onClick={() => toggleHandler()}>
            { showToggle && !showMore && <><ShowMoreText>show more</ShowMoreText><ShowMoreArrowDown /></> }
            { showToggle && showMore && <><ShowMoreText>show less</ShowMoreText><ShowLessArrowUp /></> }
          </ShowMoreButton>
        </> }
      { item.registrationLink && <DownloadIconComponent onClick={() => onEventLinkClicked(item.name, item.registrationLink)} /> }
    </Item>
  )
}

const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed #000000;
  padding: 20px 0 16px;

  &:last-child {
    border-bottom: 0px;
    margin-right: 0px;
    margin-bottom: 8px;
  }

  ${media.tabletUp`
    width: 320px;
    margin-right: 40px;
    padding-top: 0;
    padding-bottom: 20px;
    margin-bottom: 20px;
    &:nth-child(even) {
      margin-right: 0px;
    }
    &:last-child {
      border-bottom: 0px;
      margin-right: 0px;
      margin-bottom: 20px;
    }
    &:nth-last-child(2):nth-child(odd) {
      border-bottom: 0px;
    }
  `}
`

const EventTitle = styled.a`
  cursor: pointer;
  width: 100%;
  font-family: Merriweather;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  margin-top: 8px;
  ${media.tabletUp`
    width: 307px;
  `}
`

const EventTitleStatic = styled.span`
  width: 100%;
  font-family: Merriweather;
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 140%;
  color: #000000;
  margin-top: 8px;
  ${media.mobileUp`
    width: 307px;
  `}
`

type EventContentProps = {
  showMore: any
}

const EventContent = styled.div<EventContentProps>`
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: #000000;
  margin-top: 16px;
  height: ${(props: any) => props.showMore ? 'auto' : 'calc(6em + 5px)'};

  display: -webkit-box;
  -webkit-line-clamp: ${(props: any) => props.showMore ? 'unset' : '4'};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  ${media.tabletUp`
    width: 307px;
  `}
`

const EventDateContainer = styled.div`
  margin-top: 8px;
`

const EventDate = styled.span`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 140%;
  margin-right: 12px;
`

const DownloadIconComponent = styled(DownloadIcon)`
  cursor: pointer;
  margin-top: 15.75px;
`

const ShowMoreButton = styled.label`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: #4585FF;
  margin-top: 8px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const ShowMoreText = styled.div`
  margin-right: 6px;
`

const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

type LabelProps = {
  bgColor: string
  url: string | null
}

const Label = styled.span<LabelProps>`
  border-radius: 4px;
  padding: 2px 4px;
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  color: #000000;
  background-color: ${(props: any) => props.bgColor};
  cursor: ${(props: any) => props.url ? 'pointer' : 'auto'};
`

export default EventItemComponent
