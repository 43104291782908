import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import PageContainer from 'components/PageContainer'
import { AddProfile, SetUpPassword } from 'components/registerPage'

import { DataLayerEventCategory, DataLayerEventAction, DataLayerGtmCustomEventName, ErrorMessage } from 'config/constants'

import { AppState } from 'states/reducers'

import { getPasswordStrength } from 'utils/common'
import { triggerGA4GtmEvent, triggerGtmCustomEvent } from 'utils/tracking'

type Props = {
  history: any
  match: any
  location: any
}

const AccountActivation: React.FunctionComponent<Props> = ({ history, match, location }) => {
  const callbackUrl = useSelector((state: AppState) => state.signin.callbackUrl)
  const destination = useSelector((state: AppState) => state.application.redirectDestinationUrl)

  const [firstName, updateFirstName] = useState<string>('')
  const [lastName, updateLastName] = useState<string>('')
  const [, updateErrorMessage] = useState<string>('')
  const [passwordError, updatePasswordError] = useState<string>('')
  const [confirmPwError, updateConfirmPwError] = useState<string>('')
  const [errorProfileMessage, updateErrorProfileMessage] = useState<string>('')

  const [email, updateEmail] = useState<string>('')
  const [token, updateToken] = useState<string>('')

  const [password, updatePassword] = useState<string>('')
  const [confirmPassword, updateConfirmPassword] = useState<string>('')

  const queryParams = new URLSearchParams(useLocation().search)

  const processActivate = async () => {
    const passwordLevel = getPasswordStrength(password)
    const { uid, timestamp, hash }: any = match.params
    let nwe = 0
    if (!firstName.length || !lastName.length) {
      return updateErrorProfileMessage(ErrorMessage.ACTIVATION_MISSING_FIRST_AND_LAST_NAME)
    }
    if (queryParams.has('nwe')) {
      const nweFromQuery = queryParams.get('nwe')
      nwe = nweFromQuery ? parseInt(nweFromQuery) : 0
    }
    try {
      if (password.length &&
        confirmPassword.length &&
        passwordLevel >= 3 &&
        !passwordError &&
        !confirmPwError) {
      // request
        const response: any = await axios.post(`/user/activation/activate/${uid}/${timestamp}/${hash}`, {
          token,
          password,
          firstName,
          lastName,
          nwe,
        })

        if (!queryParams.has('subscribe')) {
          triggerGtmCustomEvent(DataLayerEventAction.ACTIVATION_CLICK, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
          const path = `${callbackUrl}${response.data.nonce}${destination ? '&onboard=true&destination=' + encodeURIComponent(destination) : ''}`
          window.location.href = path
        } else {
          triggerGtmCustomEvent(DataLayerEventAction.ACTIVATION_NEWSLETTER_CLICK, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)

          // TODO: ?? unknown usage
          const getToken: any = await axios.post('/token/nonce', {
            nonce: response.data.nonce,
          })

          if (getToken) {
            const path = `${window.location.origin}/manage/newsletter`
            window.location.href = path
          }
        }
      }
    } catch (e) {
      history.push(`/activation/fail/${match.params.uid}`)
    }
  }

  const handleAccountCreateButtonClick = () => {
    triggerGA4GtmEvent({
      category: 'login',
      subcategory: 'set_password',
      action: 'click',
      customized_parameters: {
        method: 'email',
      },
    })
    processActivate()
  }

  const validateAccountByUid = async () => {
    const { uid, timestamp, hash }: any = match.params
    if (!uid || !timestamp || !hash) {
      window.location.href = '/login'
    }

    try {
      const response: any = await axios.post('/user/activation/validate', {
        uid,
        timestamp,
        hash,
      })
      updateEmail(response.data.email)
      updateToken(response.data.token)
    } catch (error) {
      history.push(`/activation/fail/${uid}`)
    }
  }

  useEffect(() => {
    validateAccountByUid()
  }, [])

  return (
    <PageContainer id='accountactivation-page' isLogoLink footerText={false} isDisplayFooter={false}>
      <SetUpPassword
        updatePassword={updatePassword}
        updatePasswordError={updatePasswordError}
        updateConfirmPassword={updateConfirmPassword}
        updateErrorMessage={updateErrorMessage}
        updateConfirmPwError={updateConfirmPwError}
        password={password}
        confirmPassword={confirmPassword}
        passwordError={passwordError}
        confirmPwError={confirmPwError}
      />
      <AddProfile errorProfileMessage={errorProfileMessage} updateFirstName={updateFirstName} updateLastName={updateLastName} email={email} />

      <CreateAccountButton onClick={handleAccountCreateButtonClick}>SAVE</CreateAccountButton>

    </PageContainer>
  )
}

export default AccountActivation

const CreateAccountButton = styled.div`
  min-width: 270px;
  max-width: 272px;
  border-radius: 2px;
  border-color: #FFCA05;
  background-color: #FFCA05;
  padding: 12px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-family: Roboto;
  margin-bottom: 20px;
  box-sizing: border-box;
  cursor: pointer;
`
