import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Cookies } from 'react-cookie'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'
import { FormErrorLabel } from 'components/formErrorLabel'
import PageContainer from 'components/PageContainer'
import { PasswordStrengthIndicator } from 'components/passwordStrengthIndicator'
import ShowPasswordButton from 'components/showPasswordButton'

import { ErrorMessage, DataLayerEventCategory, DataLayerGtmCustomEventName, DataLayerEventAction } from 'config/constants'

import { getPasswordStrength } from 'utils/common'
import { hasValue } from 'utils/formValidation'
import { triggerGA4GtmEvent, triggerGtmEvent } from 'utils/tracking'

interface FieldChecker {
  error?: string
}

type Props = {
  history: any
  match: any
}
const ResetPasswordContainer: React.FunctionComponent<Props> = ({ history, match }) => {
  const cookies = new Cookies()
  const [password, updatePassword] = useState<string>('')
  const [passwordError, updatePasswordError] = useState<string>('')
  const [confirmPassword, updateConfirmPassword] = useState<string>('')
  const [confirmPwError, updateConfirmPwError] = useState<string>('')
  const [isShowingPw, toggleShowPw] = useState<boolean>(false)
  const [isShowingConfirmPw, toggleShowConfirmPw] = useState<boolean>(false)
  const [passwordLevel, updatePasswordLevel] = useState<number>(0)
  const [errorMessage, updateErrorMessage] = useState<string>('')

  // build field checking message
  useEffect(() => {
    updateErrorMessage(passwordError + confirmPwError)
  }, [passwordError, confirmPwError])

  useEffect(() => {
    triggerGA4GtmEvent({
      category: 'login',
      subcategory: 'reset_password',
      action: 'imp',
      customized_parameters: {
        method: 'email',
      },
    })
  }, [])

  const passwordOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updatePassword(event.target.value)
    updatePasswordLevel(getPasswordStrength(event.target.value))
  }

  const passwordCheckHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!hasValue(event.target.value)) {
      updatePasswordError(ErrorMessage.CHANGE_PASSWORD_MISSING_NEW_PASSWORD + '. ')
    } else if (hasValue(event.target.value) && passwordLevel > 3) {
      updatePasswordError('')
    } else if (hasValue(event.target.value) && passwordLevel < 3) {
      updatePasswordError(ErrorMessage.CHANGE_PASSWORD_NOT_STRONG_ENOUGH + '. ')
    } else if (password && confirmPassword && password !== confirmPassword) {
      updateConfirmPwError(ErrorMessage.CHANGE_PASSWORD_DOES_NOT_MATCH)
      if (passwordLevel < 3) {
        updatePasswordError(ErrorMessage.CHANGE_PASSWORD_NOT_STRONG_ENOUGH + '. ')
      } else {
        updatePasswordError('')
      }
    }
  }

  const confirmPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updateConfirmPassword(event.target.value)
  }

  const confirmPasswordCheckHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (hasValue(password) && !hasValue(event.target.value)) {
      updateConfirmPwError('Please enter your password again.')
    } else if (!hasValue(password) && !hasValue(event.target.value)) {
      updateConfirmPwError('')
    } else if (password && confirmPassword && password !== confirmPassword) {
      updateConfirmPwError(ErrorMessage.CHANGE_PASSWORD_DOES_NOT_MATCH)
    } else {
      updateConfirmPwError('')
    }
  }

  const showPasswordHandler = (eventName?: string): void => {
    if (eventName === 'onMouseOut') {
      toggleShowPw(false)
      return
    }
    toggleShowPw(!isShowingPw)
  }

  const showConfirmPasswordHandler = (eventName?: string): void => {
    if (eventName === 'onMouseOut') {
      toggleShowConfirmPw(false)
      return
    }
    toggleShowConfirmPw(!isShowingConfirmPw)
  }

  const isShowingPassword = (isShowing: boolean): string => {
    return isShowing ? 'text' : 'password'
  }

  const saveResetPasswordHandler = async () => {
    triggerGA4GtmEvent({
      category: 'login',
      subcategory: 'reset_password',
      action: 'click',
      customized_parameters: {
        method: 'email',
      },
    })

    const { uid, hash, timestamp } = match.params

    if (password === confirmPassword && !errorMessage.length && passwordLevel >= 3) {
      try {
        const response = await axios.post('/reset-password', {
          uid,
          hash,
          timestamp,
          password,
          token: cookies.get('resetPasswordToken'),
        })

        // handle login success case
        if (response.status === 200) {
          triggerGtmEvent(
            DataLayerEventAction.RESET_PASSWORD_SUCCESS,
            DataLayerEventCategory.LOGIN,
            DataLayerGtmCustomEventName.LOGIN,
            '',
          )
          history.push('/reset-password/success')
        }
      } catch (error) {
        triggerGtmEvent(
          DataLayerEventAction.RESET_PASSWORD_FAIL,
          DataLayerEventCategory.LOGIN,
          DataLayerGtmCustomEventName.LOGIN,
          '',
        )
        console.log('an error has been thrown', error)
        // updateEmailSent('')
      }
    }
  }

  const cancelLinkHandler = (): void => {
    triggerGtmEvent(
      DataLayerEventAction.RESET_PASSWORD_CANCEL_CLICK,
      DataLayerEventCategory.LOGIN,
      DataLayerGtmCustomEventName.LOGIN,
      '',
    )
    history.push('/login')
  }
  return (
    <PageContainer id='resetpassword-page' title='Reset password'>
      <ResetContent flexDirection='column' alignItem='flex-start'>
        <ErrorMsgLabel>{passwordError}</ErrorMsgLabel>
        <ErrorMsgLabel>{confirmPwError}</ErrorMsgLabel>
        <PasswordContainer>
          <PasswordInput
            type={isShowingPassword(isShowingPw)}
            placeholder='new password'
            onChange={passwordOnChangeHandler}
            onBlur={passwordCheckHandler}
            error={passwordError}></PasswordInput>
          <ShowPasswordButton toggleHandler={showPasswordHandler}></ShowPasswordButton>
        </PasswordContainer>

        <PasswordStrengthIndicator passwordStrendth={passwordLevel} />

        <PasswordContainer>
          <PasswordInput
            type={isShowingPassword(isShowingConfirmPw)}
            placeholder='confirm new password'
            onChange={confirmPasswordHandler}
            onBlur={confirmPasswordCheckHandler}
            error={confirmPwError}></PasswordInput>
          <ShowPasswordButton toggleHandler={showConfirmPasswordHandler}></ShowPasswordButton>
        </PasswordContainer>

        <PasswordHelperText>Use 8 or more characters with a mix of letters, number & symbols</PasswordHelperText>

        <SaveResetPasswordButton onClick={ saveResetPasswordHandler }>SAVE</SaveResetPasswordButton>

        <CancelLink onClick={ cancelLinkHandler }>Cancel</CancelLink>
      </ResetContent>
    </PageContainer>
  )
}

const ErrorMsgLabel = styled(FormErrorLabel)`
  margin-bottom: 11px;
`

const CancelLink = styled.a`
  text-decoration: none;
  height: 16px;
  width: 100%;
  color: #4585FF;
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`
const PasswordContainer = styled.div`
  position: relative;
  margin-bottom: 7px;
  max-width: 272px;
  width: 100%;
`

const ResetContent = styled(FlexContainer)`
  width: 271px;
  margin: 0 auto;
  flex: 1;
`

const PasswordInput = styled.input<FieldChecker>`
  height: 37px;
  width: 100%;
  border: 1px solid ${({ error }) => error ? '#EB4254' : '#CCCCCC'};
  border-radius: 2px;
  background-color: #FFF;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.19px;
  color: #333;
  font-family: Roboto;
  padding-left: 10px;
  box-sizing: border-box;
  padding-right: 50px;
`

const PasswordHelperText = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.14px;
  color: #333333;
  margin-bottom: 24px;
`

const SaveResetPasswordButton = styled.div`
  min-width: 270px;
  max-width: 272px;
  border-radius: 2px;
  border-color: #FFCA05;
  background-color: #FFCA05;
  padding: 12px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-family: Roboto;
  margin-bottom: 17px;
  box-sizing: border-box;
  cursor: pointer;
`

export default ResetPasswordContainer
