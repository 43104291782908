import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import _get from 'lodash/get'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import UPDATE_USER_CONTEXT from 'universal/apollo/d8/UPDATE_USER_CONTEXT.gql'
import GET_USER_CONTEXT from 'universal/apollo/d8/GET_USER_CONTEXT.gql'

import { media } from 'utils/style'
import { triggerGA4GtmEvent } from 'utils/tracking'

import { FlexContainer } from 'components/flex'
import { ErrorMessage } from 'config/constants'
import * as modalAction from 'states/actions/application'

import NotificationToggle from '../../components/activities/comments/NotificationToggle'

const Notifications: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const [entityId, updateEntityId] = useState<string>('')
  const [notificationChecked, updateNotificationChecked] = useState<boolean>(false)
  const [repliedComment, updateRepliedComment] = useState<boolean>(false)
  const [contentRelatedOptIn, updateContentRelatedOptIn] = useState<boolean>(false)

  const profile = useQuery(GET_USER_CONTEXT, { variables: { typeValue: 'terms_and_preferences' } })

  useEffect(() => {
    const profileEntityId: string = _get(profile, 'data.currentUserContext.reverseUidProfile.entities[0].entityId')
    const fieldCommentEmailNotification: boolean = _get(profile, 'data.currentUserContext.reverseUidProfile.entities[0].fieldCommentEmailNotification')
    const fieldRepliedComment: boolean = _get(profile, 'data.currentUserContext.reverseUidProfile.entities[0].fieldRepliedComment')
    const fieldContentRelatedOptIn: boolean = _get(profile, 'data.currentUserContext.reverseUidProfile.entities[0].fieldContentRelatedOptIn')
    updateEntityId(profileEntityId ? profileEntityId.toString() : '')
    updateNotificationChecked(fieldCommentEmailNotification)
    updateRepliedComment(fieldRepliedComment)
    updateContentRelatedOptIn(fieldContentRelatedOptIn)
  }, [profile])

  useEffect(() => {
    dispatch(modalAction.updateZendeskVisibility(false))
    return () => {
      dispatch(modalAction.updateZendeskVisibility(true))
    }
  }, [dispatch])

  const [callUpdateNotification] = useMutation(UPDATE_USER_CONTEXT, {
    onCompleted: (response) => {
      const updatedStatus = response.updateProfileTermsAndPreferences.entity.fieldCommentEmailNotification ? 'on' : 'off'
      triggerGA4GtmEvent({
        category: 'myaccount',
        subcategory: 'notification',
        action: 'sys',
        customized_parameters: {
          notification_type: 'comment moderation',
          status: updatedStatus,
        },
      })
    },
    onError: () => {
      dispatch(modalAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
    },
    variables: {
      uid: entityId,
      input: {
        fieldCommentEmailNotification: notificationChecked ? '0' : '1',
      },
    },
  })

  const [callUpdateRepliedComment] = useMutation(UPDATE_USER_CONTEXT, {
    onCompleted: (response) => {
      const updatedStatus = response.updateProfileTermsAndPreferences.entity.fieldRepliedComment ? 'on' : 'off'
      triggerGA4GtmEvent({
        category: 'myaccount',
        subcategory: 'notification',
        action: 'sys',
        customized_parameters: {
          notification_type: 'replied comment',
          status: updatedStatus,
        },
      })
    },
    onError: () => {
      dispatch(modalAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
    },
    variables: {
      uid: entityId,
      input: {
        fieldRepliedComment: repliedComment ? '0' : '1',
      },
    },
  })

  const [callUpdateRelatedOptIn] = useMutation(UPDATE_USER_CONTEXT, {
    onCompleted: (response) => {
      const updatedStatus = response.updateProfileTermsAndPreferences.entity.fieldContentRelatedOptIn ? 'on' : 'off'
      triggerGA4GtmEvent({
        category: 'myaccount',
        subcategory: 'notification',
        action: 'sys',
        customized_parameters: {
          notification_type: 'follow or reminders',
          status: updatedStatus,
        },
      })
    },
    onError: () => {
      dispatch(modalAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
    },
    variables: {
      uid: entityId,
      input: {
        fieldContentRelatedOptIn: contentRelatedOptIn ? '0' : '1',
      },
    },
  })

  return (
    <PageContainer alignItem='center' flexDirection='column'>
      <SectionContainer>
        <SectionHeader>Email Notifications</SectionHeader>
        <SectionHeaderLine />
        <SubHeader>CONVERSATIONS</SubHeader>
        <NotificationItemContainer>
          <NotificationToggle checked={notificationChecked} labelText={'Comment Moderation'} updateCheckedValue={updateNotificationChecked} callUpdateNotification={callUpdateNotification}/>
        </NotificationItemContainer>
        <NotificationItemContainer>
          <NotificationToggle checked={repliedComment} labelText={'Replied comment'} updateCheckedValue={updateRepliedComment} callUpdateNotification={callUpdateRepliedComment}/>
        </NotificationItemContainer>
        <Separator />
        <SubHeader>CONTENT</SubHeader>
        <NotificationItemContainer>
          <NotificationToggle checked={contentRelatedOptIn} labelText={'Follow / Reminders'} updateCheckedValue={updateContentRelatedOptIn} callUpdateNotification={callUpdateRelatedOptIn}/>
        </NotificationItemContainer>
      </SectionContainer>
    </PageContainer>
  )
}

export default Notifications

const PageContainer = styled(FlexContainer)`
  max-width: 680px;
  min-width: 320px;
  width: 100%;
`

const SectionContainer = styled.div`
  width: 335px;
  padding: 20px;
  ${media.tabletUp`
    width: 416px;
  `}
  ${media.smallDesktopUp`
    width: 416px;
    padding: 0px 0px;
  `}
`

const SectionHeader = styled.div`
  color: #4585FF;
  font-family: Roboto Condensed;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 21px;
`
const SectionHeaderLine = styled.div`
  height: 1px;
  width: 100%;
  margin: 20px 0px;
  background-color: #4585FF;
`
const SubHeader = styled.div`
  color: #000000;
  font-family: Roboto Condensed;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 10px;
`

const NotificationItemContainer = styled.div`
  padding: 10px 0px;
`

const Separator = styled.div`
  margin: 20px 0;
  width: 100%;
  border-bottom: 1px solid #E5E5E5
`
