import styled from 'styled-components'
import React from 'react'
import { media } from 'utils/style'
import { ReactComponent as PostiesTickSvg } from '../../assets/svg/posties-tick.svg'
import { NODE_ENV } from 'config/app.config'
import { FlexContainer } from 'components/flex'
import BackgroundMobile from 'assets/images/posties-background-mobile.jpg'
import BackgroundDesktop from 'assets/images/posties-background-desktop.jpg'

type Props = {
  trackingGTM: () => void,
}

const PostiesSubscriptionContent: React.FunctionComponent<Props> = ({ ...Props }) => {
  const { trackingGTM } = Props

  const subscriptionLink = NODE_ENV === 'production' ? 'https://subscribe.scmp.com/posties' : 'https://staging-rosetta.product-web.dev-2.scmp.tech/posties'

  return (
    <SubscriptionContent flexDirection='row' justifyContent='space-between'>
      <BackgroundMobileContainer />
      <BackgroundDesktopContainer />
      <SubscriptionInfo flexDirection='column'>
        <SubscriptionHeadline>Subscribe to Posties to access all our great content</SubscriptionHeadline>
        <MobileSubscriptionButton href={subscriptionLink} onClick={trackingGTM}>
          <SubscriptionTextWrapper>
            <SubscriptionText >SUBSCRIBE</SubscriptionText>
          </SubscriptionTextWrapper>
        </MobileSubscriptionButton>
        <SubscriptionInfoList>
          <SubscriptionInfoItem wrap='no-wrap'>
            <StyledTick />
            <SubscriptionInfoWording>
              Unlimited access to Posties website
            </SubscriptionInfoWording>
          </SubscriptionInfoItem>
          <SubscriptionInfoItem wrap='no-wrap'>
            <StyledTick />
            <SubscriptionInfoWording>
              Build up weekly reading habits
            </SubscriptionInfoWording>
          </SubscriptionInfoItem>
          <SubscriptionInfoItem wrap='no-wrap'>
            <StyledTick />
            <SubscriptionInfoWording>
              Eligible to apply for events
            </SubscriptionInfoWording>
          </SubscriptionInfoItem>
        </SubscriptionInfoList>
      </SubscriptionInfo>
      <DesktopSubscriptionButton href={subscriptionLink} onClick={trackingGTM}>
        <SubscriptionTextWrapper>
          <SubscriptionText >SUBSCRIBE</SubscriptionText>
        </SubscriptionTextWrapper>
      </DesktopSubscriptionButton>
    </SubscriptionContent>
  )
}

const SubscriptionContent = styled(FlexContainer)`
  overflow: hidden;
  border: 2px solid #000000;
  border-radius: 10px;
  width: 100%;
  position: relative;
`

const BackgroundContainer = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
`

const BackgroundMobileContainer = styled(BackgroundContainer)`
  background-image: url(${BackgroundMobile});
  display: block;
  ${media.tabletUp`
    display: none;
  `}
`

const BackgroundDesktopContainer = styled(BackgroundContainer)`
  display: none;
  ${media.tabletUp`
    display: block;
    background-image: url(${BackgroundDesktop});
  `}
`

const StyledTick = styled(PostiesTickSvg)`
  height: 20px;
  width: 24px;
  ${media.tabletUp`
    height: 25px;
    width: 31px;
  `}
`

const SubscriptionInfo = styled(FlexContainer)`
  padding: 24px 16px 150px;
  width: 100%;
  ${media.tabletUp`
    width: auto;
    padding: 48px 150px 48px 157px;
  `}
`

const SubscriptionHeadline = styled.div`
  color: #FF7C1D;
  font-family: Angkor;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.54px;
  text-align: center;
  ${media.tabletUp`
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.66px;
    text-align: start;
  `}
`

const SubscriptionInfoList = styled.div`
  margin-top: 16px;
  div:first-child {
      margin-top: 0;
    }
  ${media.tabletUp`
    margin-top: 10px;
    div:first-child {
      margin-top: 0;
    }
  `}
`

const SubscriptionInfoItem = styled(FlexContainer)`
  padding-left: 10px;
  margin-bottom: 8px;
  align-items: center;
  ${media.tabletUp`
    padding: 0;
    margin-top: 10px;
    margin-bottom: 0;
  `}
`

const SubscriptionInfoWording = styled.div`
  color: #555;
  font-family: Fredoka;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px; 
  width: 100%;
  margin-left: 8px;
  ${media.tabletUp`
    max-width: 100%;
    font-size: 18px;
    line-height: 25px; 
  `}
`

const SubscriptionButton = styled.a`
  cursor: pointer;
  --offset-container-border-background-color: #fecc17;
  --offset-container-border-radius: 100px;
  --offset-container-border-width: 2px;
  --offset-container-offset: 4;
  display: inline-block;
  border-radius: var(--offset-container-border-radius);
  background-color: #ffffff;
  padding-block: 12px;
  padding-inline: 24px;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  font-family: Fredoka,sans-serif;
  line-height: 20px;
  text-transform: uppercase;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    inline-size: 100%;
    block-size: 100%;
    border-radius: var(--offset-container-border-radius);
    box-sizing: border-box;
  }

  &::before {
    inset-block-start: calc(var(--offset-container-offset) * 1px);
    inset-inline-start: calc(var(--offset-container-offset) * -1px);
    background-color: var(--offset-container-border-background-color);
  }

  &::after {
    border: var(--offset-container-border-width) #000000 solid;
    pointer-events: none;
  }
`

const MobileSubscriptionButton = styled(SubscriptionButton)`
  position: relative;
  width: 82px;
  align-self: center;
  display: block;
  margin-top: 16px;
  ${media.tabletUp`
    display: none;
  `}
`

const DesktopSubscriptionButton = styled(SubscriptionButton)`
  display: none;
  ${media.tabletUp`
    display: block;
    position: absolute;
    right: 24px;
    bottom: 30px;
  `}
`

const SubscriptionTextWrapper = styled.div`
  position: relative;
`

const SubscriptionText = styled.div`
  color: #001246;
  cursor: pointer;
`

export default PostiesSubscriptionContent
