import React, { useState } from 'react'
import styled from 'styled-components'
import { media } from 'utils/style'
import { FlexContainer } from 'components/flex'
import ScmpLogo from 'assets/svg/logo-scmp-horizontal.svg'
import UnScbscibeConfirm from './confirm'
import UnScbscibeSuccess from './success'
import UnScbscibeFail from './fail'

type Props = {
  history: any
  match: any
}

const UnSubscribe: React.FunctionComponent<Props> = ({ match }) => {
  const [state, updateState] = useState<string>('')
  const { uid, hash, options }: any = match.params

  const onClickManageNewsletter = () => {
    const path = `${window.location.origin}/manage/newsletter`
    window.location.href = path
  }

  const renderComponent = () => {
    switch (state) {
      case 'unsubscribe-success':
        return <UnScbscibeSuccess />
      case 'unsubscribe-fail':
        return <UnScbscibeFail />
      default:
        return <UnScbscibeConfirm updateState={updateState} uid={uid} hash={hash} options={options}/>
    }
  }
  return (
    <PageContainer>
      <HeaderContainer>
        <LongHeaderContainer justifyContent='center' wrap='nowrap' >
          <Logo src={ScmpLogo} alt='scmpLogo' />
        </LongHeaderContainer>
      </HeaderContainer>
      <PageContent flexDirection='row' alignItem='baseline' justifyContent="center">
        {renderComponent()}
        <RedirectLink marginTop="20px" onClick={onClickManageNewsletter}>
          Manage newsletter preferences
        </RedirectLink>
      </PageContent>
    </PageContainer>
  )
}

interface functioningProps {
  hideOn?: string[],
}

interface redirectLinkProps {
  marginTop?: string,
}
const PageContainer = styled.div`
  padding-left 28px;
  padding-right: 28px
  height: 100%;
`

const Logo = styled.img`
  height: 25px;
  margin-left: 20px;
  ${media.smallDesktopUp`
    margin-left: 42px;
  `}
  margin-right: 60px;
`

const LongHeaderContainer = styled(FlexContainer)<functioningProps>`
  width: 100vw;
  margin: auto;
  max-width: 1560px;
`

const HeaderContainer = styled(FlexContainer)<functioningProps>`
  min-height: 63px;
  top: 0px;
  background-color: #fff;
  z-index: 999;
  margin: auto;
  border-bottom: 3px solid #f2f2f2;
  left: 28px;
  right: 28px;
  position: static;
  ${media.tabletUp`
    position: inherit;
  `}
`

const PageContent = styled(FlexContainer)`
  position: relative;
  max-width: 504px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
`

const RedirectLink = styled.div<redirectLinkProps>`
  text-align: center;
  color: #4585FF;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Roboto';
  margin-top:  ${(props) => (props.marginTop ? props.marginTop : '2px')};
  cursor: pointer;
`

export default UnSubscribe
