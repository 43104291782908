import { ApolloClient, HttpLink, InMemoryCache, from, split } from '@apollo/client'
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { onError } from 'apollo-link-error'

import introspectionQueryResultData from './d8/fragmentTypes.json'
import {
  DRUPAL_API_GRAPHQL_PROTOCOL,
  DRUPAL_API_GRAPHQL_HOST,
  C3PO_MIDDLEWARE_API_URI,
  C3PO_MIDDLEWARE_API_KEY,
  GRAPHQL_CONTENTSERVICE_APIKEY,
  GRAPHQL_CONTENTSERVICE_API_URI,
  GRAPHQL_D8_API_URI,
} from 'config/app.config'
import { forceLogOutChecking } from 'utils/common/index'

const fragmentMatcher: any = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    )

    // only exclude register page
    if (window && window.location.pathname !== '/register') {
      forceLogOutChecking()
    }
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const csLinks = {
  d8: new HttpLink({
    uri: `${DRUPAL_API_GRAPHQL_PROTOCOL}://${DRUPAL_API_GRAPHQL_HOST}/api/graphql/public`,
    credentials: 'include',
  }),
  c3po: new HttpLink({
    // switch handling for local dev between SSR and CSR
    uri: C3PO_MIDDLEWARE_API_URI,
    headers: {
      apikey: C3PO_MIDDLEWARE_API_KEY,
    },
    credentials: 'include',
  }),
  d8config: new HttpLink({
    uri: GRAPHQL_D8_API_URI,
    headers: {
      apikey: GRAPHQL_CONTENTSERVICE_APIKEY,
    },
  }),
  contentservice: new HttpLink({
    uri: GRAPHQL_CONTENTSERVICE_API_URI,
    headers: {
      apikey: GRAPHQL_CONTENTSERVICE_APIKEY,
    },
    credentials: 'include',
  }),
  contentserviceNoCredentials: new HttpLink({
    uri: GRAPHQL_CONTENTSERVICE_API_URI,
    headers: {
      apikey: GRAPHQL_CONTENTSERVICE_APIKEY,
    },
  }),
}

const splittedLinks = split(
  operation => operation.getContext().clientName === 'c3po',
  csLinks.c3po,
  split(
    operation => operation.getContext().clientName === 'd8config',
    csLinks.d8config,
    split(
      operation => operation.getContext().clientName === 'contentservice',
      csLinks.contentservice,
      split(
        operation => operation.getContext().clientName === 'contentserviceNoCredentials',
        csLinks.contentserviceNoCredentials,
        csLinks.d8,
      ),
    ),
  ),
)

// TODO: ts-lint: Argument of type '{ fragmentMatcher: any; }' is not assignable to parameter of type 'InMemoryCacheConfig'. Object literal may only specify known properties, and 'fragmentMatcher' does not exist in type 'InMemoryCacheConfig'.ts
const cache = new InMemoryCache({ fragmentMatcher })

// TODO: ts-lint: Type 'ApolloLink' is not assignable to type 'ApolloLink | RequestHandler'. Type 'ApolloLink' is missing the following properties from type 'ApolloLink': onError, setOnErrorts(2322)
export default new ApolloClient({
  link: from([
    errorLink,
    splittedLinks,
  ]),
  cache,
})
