import React, { useState } from 'react'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'

import ShowPasswordButton from 'components/showPasswordButton'
import { InputComponent, StyledButton } from 'components/styled'
type Props = {
  email?: string | undefined,
  password?: string | null,
  disabled?: boolean,
  loginFail?: boolean,
  isEmail?: boolean,
  userAccess?: boolean,
  updatePassword: (value: string) => void,
  loginHandler: () => void,
  onClickBackHandler: (name: string) => void,
  onForgetPasswordLinkClick: () => void,
  updateEmail: (value: string) => void,
}

const PasswordComponent :React.FunctionComponent<Props> = ({ ...Props }) => {
  const { email, disabled, updatePassword, loginHandler, onForgetPasswordLinkClick, loginFail, isEmail, updateEmail, userAccess } = Props
  const [isShowingPassword, toggleIsShowingPassword] = useState<boolean>(false)
  const getIsShowingPassword = () => {
    return isShowingPassword ? 'text' : 'password'
  }

  const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      loginHandler()
    }
  }

  const passwordInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    updatePassword(event.target.value)
  }

  const emailInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateEmail(event.target.value)
  }

  const showPasswordHandler = (eventName?: string): void => {
    if (eventName === 'onMouseOut') {
      toggleIsShowingPassword(false)
      return
    }
    toggleIsShowingPassword(!isShowingPassword)
  }

  return (
    <>
    {
      loginFail && !isEmail
        ? <InputComponent
          type='email'
          placeholder='your email'
          height='37px'
          maxWidth='272px'
          paddingLeft='10px'
          marginBottom='12px'
          value={email}
          onChange={emailInputHandler}></InputComponent>
        : <InputEmailComponent> {email}</InputEmailComponent>
    }
      <PasswordContainer>
        <InputComponent
          type={getIsShowingPassword()}
          placeholder='Password'
          height='37px'
          paddingLeft='10px'
          onChange={passwordInputHandler}
          onKeyPress={keyPressHandler}></InputComponent>
        <ShowPasswordButton toggleHandler={showPasswordHandler} top={'14px'}></ShowPasswordButton>
      </PasswordContainer>
      <StyledButton
        color='#000'
        fontSize='16px'
        lineHeight='19px'
        padding='9px'
        marginBottom='16px'
        disabled={disabled}
        onClick={loginHandler}>LOG IN</StyledButton>
      {
        userAccess
          ? <FlexContainer className='signin-form__helper helper' justifyContent='center'>
            <ForgotPasswordLink className='helper__forgot-password forgot-password' onClick={() => onForgetPasswordLinkClick()} disabled={disabled}>Forgot password</ForgotPasswordLink>
          </FlexContainer>
          : null
      }
    </>
  )
}

interface ForgotPasswordLinkStyle {
  disabled?:boolean
}

const ForgotPasswordLink = styled.div<ForgotPasswordLinkStyle>`
  text-decoration: none;
  height: 16px;
  width: 100%;
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding-top: 7px;
  cursor: pointer;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`
const InputEmailComponent = styled.div`
  color: #333333
  font-size: 16px;
  line-height: 19px;
  font-family: 'Roboto';
  padding-bottom: 20px;
  padding-left: 10px
`
const PasswordContainer = styled.div`
  position: relative;
  margin-bottom: 11px;
  max-width: 272px;
`

export default PasswordComponent
