import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import P2PBanner from 'assets/svg/p2pbanner.svg'
import { media } from 'utils/style'

import { triggerGtmEvent } from 'utils/tracking'

const P2PPanel: React.FunctionComponent = () => {
  const LinkTracking = () => {
    triggerGtmEvent(
      'tracking',
      'Referral',
      'Referral/Share SCMP',
      window.location.href,
    )
  }

  return (
    <Link to='manage/subscription/referral' onClick={LinkTracking}>
      <P2PPanelContainer>
        <h3>Share SCMP, Get 1-month free</h3>
        <p>Invite friends to enjoy the SCMP. Receive a <strong>one-month extension</strong> on your subscription with every 2 successful referrals.</p>
        <StyledLink to='manage/subscription/referral' onClick={LinkTracking}>
          Learn more
        </StyledLink>
      </P2PPanelContainer>
    </Link>
  )
}

const P2PPanelContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #D7D7D7;
  border-radius: 2px;
  margin-top: 28px;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  position: relative;
  background-image: url(${P2PBanner});
  background-position: 100% 100%;
  background-size: auto;
  background-repeat: no-repeat;
  padding: 27px 62px 43px 17px;

  ${media.tabletUp`
    padding: 32px 156px 25px 24px;
  `}

  &:before {
    position: absolute;
    content: "";
    display: block;
    background: #FFCA05;
    border-radius: 50%;
    z-index: 0;
    height: 32px;
    width: 32px;
    left: 8px;
    top: 8px;

    ${media.tabletUp`
      left: 13px;
      top: 14px;
    `}
  }

  h3 {
    margin: 0 0 8px;
    font-family: Roboto Condensed;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    color: #02287C;
    position: relative;
  }

  p {
    margin: 0 0 16px;
  }
`

const StyledLink = styled(Link)`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #4585FF;
`
export default P2PPanel
