import _isEmpty from 'lodash/isEmpty'
import React from 'react'
import styled, { keyframes, css } from 'styled-components'

import { FlexContainer } from 'components/flex'

import { MyAccountClickCategory } from 'utils/tracking'

export interface DropDownMenuItem {
  key: string
  value: string
  url?: string
  target?: string
  activeUrls?: string[]
  ga4Category?: MyAccountClickCategory
}

export type ClickableItem = DropDownMenuItem & Required<Pick<DropDownMenuItem, 'url' | 'ga4Category'>>

export interface DropDownMenuProps {
  activeItem?: DropDownMenuItem
  customStyle?: any
  isShow: boolean
  items: DropDownMenuItem[]
  onClickHandler: any
  onChangeHandler: any
}

export interface DropDownMenuCustomStyle {
  top?: string
  right?: string
  bottom?: string
  left?: string
  marginTop?: string
  marginRight?: string
  marginBottom?: string
  marginLeft?: string
  minWidth?: string
}

const DropDownMenu: React.FunctionComponent<DropDownMenuProps> = ({
  isShow,
  items,
  activeItem,
  onClickHandler,
  onChangeHandler,
  customStyle,
}) => {
  const onClick = (event: any, item: ClickableItem) => {
    event.preventDefault()
    const isChanged = activeItem !== item

    // updateSelectItem(item)
    if (onClickHandler) {
      onClickHandler(item)
    }

    if (isChanged && onChangeHandler) {
      onChangeHandler(item)
    }
  }

  const menuItems = []
  if (!_isEmpty(items)) {
    for (const item of items) {
      if (item.value === '_separateLine_') {
        menuItems.push(<SeparateLine key={item.key}/>)
        continue
      }

      const clickableItem = item as ClickableItem

      menuItems.push(<MenuItem key={clickableItem.key}
        isSelected={activeItem === clickableItem}
        onClick={(event) => onClick(event, clickableItem)}
        customStyle={customStyle}
      >{clickableItem.value}</MenuItem>)
    }
  }

  return (
    <DropDownMenuContainer menuOpen={isShow} customStyle={customStyle}>
      <MenuItemContainer>
        {menuItems}
      </MenuItemContainer>
    </DropDownMenuContainer>
  )
}

const fadeIn = keyframes`
from {
  transform: scale(1);
  opacity: 0;
}

to {
  transform: scale(1);
  opacity: 1;
}
`
const fadeOut = keyframes`
from {
  transform: scale(1);
  opacity: 0;
}

to {
  transform: scale(1);
  opacity: 1;
}
`

interface MenuItemProps {
  isSelected: boolean
  customStyle?: any
}

interface dropDownMenuProps {
  menuOpen?: boolean
  customStyle?: any
}

const DropDownMenuContainer = styled(FlexContainer)<dropDownMenuProps>`
  position: absolute;
  left: ${({ customStyle }) => customStyle && customStyle.left ? customStyle.left : 'auto'};
  right: ${({ customStyle }) => customStyle && customStyle.right ? customStyle.right : 'auto'};
  margin-top: ${({ customStyle }) => customStyle && customStyle.marginTop ? customStyle.marginTop : '35px'};
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.14), 0 2px 2px 0 rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
  ${({ menuOpen }) => menuOpen ? 'display:block' : 'display:none;'}
  animation: ${({ menuOpen }) => menuOpen ? css`0.5s ${fadeIn} linear;` : css`0.5s ${fadeOut} linear;`};
  padding: 12px 0;
  max-height: 500px;
  overflow: scroll;
  z-index: 9999;
`

const MenuItemContainer = styled(FlexContainer)`
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
`

const MenuItem = styled.div<MenuItemProps>`
  font-family: 'Roboto';
  font-size: 16px;
  min-width: ${({ customStyle }) => customStyle && customStyle.minWidth ? customStyle.minWidth : '280px'};
  line-height: 19px;
  text-align: left;
  cursor: pointer;
  ${({ isSelected }) => isSelected ? 'color: #000' : 'color: #999999'};
  padding: 8px 20px;
`

const SeparateLine = styled.div`
  height: 1px;
  width: 100%;
  margin: 8px 0;
  background-color: #DDDDDD;
`

export default DropDownMenu
