import React from 'react'
import styled from 'styled-components'

import { media, hideOnMedia } from 'utils/style'

import { FlexContainer } from 'components/flex'
import { Header } from '../../components/header'
import { Footer } from '../../components/footer'
import { Menu } from '../../components/menu'

type Props = {
  id: string,
  props: any,
}
const CommonPage: React.FunctionComponent<Props> = ({ id, component: Component, props }) => {
  const activeItem = props.match.url
  return (
    <PageContainer id={id}>
      <Header activeItem={activeItem} />
      <BodyContainer>
        <MenuContainer hideOn={['mobile', 'tablet']}>
          <Menu activeItem={activeItem}/>
        </MenuContainer>
        <PageContent>
          <Component { ...props } />
        </PageContent>
      </BodyContainer>
      <FooterContainer alignItem='center' wrap='nowrap' hideOn={['mobile']}>
        <Footer />
      </FooterContainer>
    </PageContainer>
  )
}

interface functioningProps {
  hideOn?: string[],
}

const PageContainer = styled(FlexContainer)`
  width: 100%;
  min-height: 580px;
  padding: 0px;
  justify-content: center;
  ${media.tabletUp`
    height: 100%;
    max-width: 1280px;
    margin: 0 auto;
  `}
`

const BodyContainer = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  margin-top: 50px;
  padding: 0;
  justify-content: center;
  ${media.smallDesktopUp`
    justify-content: space-between;
    max-width: 1034px;
    width: 100%;
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 122px;
  `}
  ${media.tabletUp`
    height: auto;
    margin-top: 99px;
  `}
`

const PageContent = styled(FlexContainer)`
  flex: 1;
  width: 100%;
  margin: 0;
  justify-content: center;
  ${media.smallDesktopUp`
    justifty-content: flex-start;
  `}
  ${media.tabletUp`
    max-width: 700px;
  `}
`

const MenuContainer = styled(FlexContainer)<functioningProps>`
  width: 242px;
  ${({ hideOn }) => hideOn ? hideOnMedia(hideOn) : ''}
`

const FooterContainer = styled(FlexContainer)<functioningProps>`
  max-width: 1280px;
  width: 100%;
  background-color: #fff;
  z-index: 1000;
  ${media.tabletUp`
    align-self: flex-end;
    margin-top: 60px;
  `}
  ${({ hideOn }) => hideOn ? hideOnMedia(hideOn) : ''}
`

export default CommonPage
