import {
  UPDATE_REGISTRATION_STEP,
  UPDATE_REGISTRATION_OPT_IN_AGREEMENT,
  UPDATE_REGISTRATION_EMAIL,
  UPDATE_REGISTRATION_PASSWORD,
  UPDATE_REGISTER_CALLBACK_URL,
} from '../constants/action-types'

const initialState = {
  step: 1,
  optIn: false,
  userEmail: '',
  userPassword: '',
  callbackUrl: '',
}

export default function registration (state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_REGISTRATION_STEP:
      return {
        ...state,
        step: action.step,
      }
    case UPDATE_REGISTRATION_OPT_IN_AGREEMENT:
      return {
        ...state,
        optIn: action.optIn,
      }
    case UPDATE_REGISTRATION_EMAIL:
      return {
        ...state,
        userEmail: action.email,
      }
    case UPDATE_REGISTRATION_PASSWORD:
      return {
        ...state,
        userPassword: action.password,
      }
    case UPDATE_REGISTER_CALLBACK_URL:
      return {
        ...state,
        callbackUrl: action.url,
      }
    default:
      return state
  }
}
