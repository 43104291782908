import { useWindowWidth } from '@react-hook/window-size'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'

import { FlexContainer } from 'components/flex'
import { media } from 'utils/style'
import { useSelector } from 'react-redux'
import { AppState } from 'states/reducers'
import { Loading } from 'components/Loading'
import { NODE_ENV } from 'config/app.config'
import DefaultSubscriptionContent from './defaultSubscriptionContent'
import YPSubscriptionContent from './ypSubscriptionContent'
import PostiesSubscriptionContent from './postiesSubscriptionContent'
import V6RetentionButton from './V6RetentionButton'
import { getUserLevel } from 'utils/user'
import rosettaSdk from '@product/rosetta-sdk'

import { triggerGtmEvent } from 'utils/tracking'
import {
  DataLayerEventCategory,
  DataLayerGtmCustomEventName,
  DataLayerEventAction,
} from 'config/constants'
import { formatTermName } from 'utils/term'

type Props = {
  className?: any,
}

// TODO move type to type declaration file (SCMPWEB-6335)
type Conversion = {
  term: {
    name: string;
    // eslint-disable-next-line camelcase
    term_id: string;
    type: string;
  };
  type: string;
};
const SubscriptionBox: React.FunctionComponent<Props> = ({ ...Props }) => {
  const onlyWidth = useWindowWidth()
  const userInfo = useSelector((state: AppState) => state.userInfo)
  const userLevel = getUserLevel(userInfo) || {}
  const isPianoReady = useSelector((state: AppState) => state.application.isPianoReady)
  const subscriptionPackageName = useSelector((state: AppState) => state.userInfo.subscriptionPackageName)
  const activeConversions: Conversion[] = rosettaSdk.rulesEngine.getFact('activeConversions')
  const isSubscriber = useSelector((state: AppState) => state.subscriptionStatus.isSubscriber)
  const isV6ThreeMonths = subscriptionPackageName === 'V6 - 3 months free access'
  const tooltipOffset = useCallback(() => {
    return onlyWidth < 768 ? 0 : -25
  }, [onlyWidth])

  const trackingGTM = useCallback(() => {
    triggerGtmEvent(
      DataLayerEventAction.SUBSCRIPTION_MY_ACCOUNT_INDEX,
      DataLayerEventCategory.SUBSCRIPTION,
      DataLayerGtmCustomEventName.TRACKING,
      window.location.href,
    )
  }, [])

  const impressionTracking = useCallback(() => {
    triggerGtmEvent(
      DataLayerEventAction.SUBSCRIPTION_MY_ACCOUNT_INDEX + '/Impression',
      DataLayerEventCategory.SUBSCRIPTION,
      DataLayerGtmCustomEventName.TRACKING,
      window.location.href,
    )
  }, [])

  const subscriptionContent = () => {
    if (userLevel.isYPUser) return <YPSubscriptionContent trackingGTM={trackingGTM} />
    if (userLevel.isPostiesUser) return <PostiesSubscriptionContent trackingGTM={trackingGTM} />
    return <DefaultSubscriptionContent trackingGTM={trackingGTM} impressionTracking={impressionTracking} />
  }

  const renderSubscriptionAccessNames = () => {
    return (
      <>
        <BoxTitle>{activeConversions.length > 1 ? 'SUBSCRIPTIONS' : 'SUBSCRIPTION'}</BoxTitle>
        {Object.values(
          activeConversions.reduce(
            (acc, conversion) => {
              const termId = conversion.term.term_id
              if (acc[termId]) return acc

              const formattedTermName =
                conversion.term.type.toLowerCase() === 'licensee contract redemption'
                  ? conversion.term.name
                  : formatTermName(conversion.term.name)
              acc[termId] = <SubscriptionAccess key={termId}>{formattedTermName}</SubscriptionAccess>

              return acc
            },
            {} as Record<string, React.ReactNode>,
          ),
        )}
      </>
    )
  }

  return (
    <SubscriptionBoxContainer flexDirection='column'>
      <Subscription>
        {!isPianoReady && <LoadingContainer></LoadingContainer>}
        {isPianoReady && !isSubscriber && <>
          {subscriptionContent()}
        </>
        }
        {isPianoReady && isSubscriber && <SubscriptedBoxContent flexDirection='column'>
          {renderSubscriptionAccessNames()}
          { isV6ThreeMonths
            ? <V6RetentionButton></V6RetentionButton>
            : <SubscriptionManageButton to="/manage/subscription">Manage your subscription</SubscriptionManageButton>
          }
        </SubscriptedBoxContent>
        }
      </Subscription>
      {isPianoReady && !isSubscriber && !userLevel.isYPUser && !userLevel.isPostiesUser
        ? <>
          <SubscriptionTips data-tip="subscription-tooltip" data-event='click' data-for="subscription-tooltip">
            Are you a print-only Subscriber?
          </SubscriptionTips>
          <CustomReactTooltip
            id="subscription-tooltip"
            globalEventOff='click'
            place="bottom"
            type="light"
            effect="solid"
            backgroundColor={'#f6f6f6'}
            className='custom-color'
            border={true}
            borderColor={'#ccc'}
            textColor={'#222'}
            clickable={true}
            offset={{ left: tooltipOffset() }} // half of user photo width
            data-html={true}>
            <TipsHeader>Print-only subscribers</TipsHeader>
            <TipsText>Please contact Customer Support at 2680 8822 or at <a className="tipsATag" href="mailto:printsupport@scmp.com">printsupport@scmp.com</a> to manage your subscription.</TipsText>
            <TipsText>Or try our&nbsp;
              <a className="tipsATag"
                href={NODE_ENV === 'production' ? 'https://subscribe.scmp.com/' : 'https://staging-rosetta.product-web.dev-2.scmp.tech/'}
              >digital plan.</a></TipsText>
          </CustomReactTooltip>
        </>
        : null
      }
    </SubscriptionBoxContainer>
  )
}

const SubscriptionBoxContainer = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
  ${media.tabletUp`
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 40px;
  `}
`

const Subscription = styled(FlexContainer)`
  justify-content: center;
  align-items: center;
  width: 100%;
  ${media.tabletUp`
    justify-content: flex-start;
    align-items: flex-start;
  `}
`

const BoxTitle = styled.div`
  color: #000;
  font-size: 14px;
  line-height: 19px;
  font-family: "Roboto Condensed";
  font-weight: bold;
  margin-bottom: 12px;
  ${media.tabletUp`
    font-size: 16px;
  `}
`

const SubscriptedBoxContent = styled(FlexContainer)`
  width: 100%;
  padding: 16px 0px 20px;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
  ${media.tabletUp`
    padding: 16px 0px 20px 24px;
    width: 680px;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`

const SubscriptionTips = styled.div`
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  margin-top: 15px;
  text-align: center;
  cursor: pointer;
  ${media.tabletUp`
    text-align: left;
  `}
`

const SubscriptionAccess = styled.div`
  color: #222222;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 12px;
`

const SubscriptionManageButton = styled(Link)`
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 8px;
`

const CustomReactTooltip = styled(ReactTooltip)`
  width: 257px;
  border: 1px solid #ccc;
  font-size: 14px !important;
  line-height: 18px;
  padding: 20.5px 20.5px 10.5px 20.5px !important;
  font-family: Roboto;
  text-align: left;
  opacity: 1 !important;
  ::before {
    left: 145px !important;
  }
  ::after {
    left: 145px !important;
  }
`

const TipsHeader = styled.div`
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin-bottom: 4px;
`

const TipsText = styled.div`
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
  .tipsATag {
    color: #222222;
    text-decoration: underline;
  }
`

const LoadingContainer = styled(Loading)`
  width: 40px;
  height: 40px;
`

export default SubscriptionBox
