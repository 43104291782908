import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Swiper from 'react-id-swiper'
import { SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import { media } from 'utils/style'
import ReportItem from './reportItem'

import './styles.css'

const RenderReportItemList = (reports: any) => {
  const reportList: any[] = []
  const reportsCount = reports.length
  for (let i = 0; i < reportsCount; i += 3) {
    reportList.push(
      <ReportItemRow key={i}>
        <ReportItem report={reports[i]} />
        { (i + 1 < reportsCount) && <ReportItem report={reports[i + 1]} /> }
        { (i + 2 < reportsCount) && <ReportItem report={reports[i + 2]} /> }
      </ReportItemRow>)
  }
  return reportList
}

const RenderReportItemSlide = (reports: any) => {
  const reportsList: any[] = []
  reports.forEach((report: any) => {
    reportsList.push(
      <SwiperSlide key={report.entityId}>
        <ReportItem report={report} />
      </SwiperSlide>)
  })
  return reportsList
}

const RenderReportItemListSwiper = (reports: any) => {
  return (
    <Swiper slidesPerView={'auto'} spaceBetween={28}>
      {RenderReportItemSlide(reports)}
    </Swiper>
  )
}

type Props = {
  reports: any
}

function getWindowDimensions () {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const ReportItemContainer: React.FunctionComponent<Props> = ({ reports }) => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize () {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ReportItemList>
      {(windowDimensions.width > 768) ? RenderReportItemList(reports) : RenderReportItemListSwiper(reports)}
    </ReportItemList>
  )
}

const ReportItemList = styled.div`
  display: flex;
  width: 288px;
  flex-direction: column;
  ${media.mobileUp`
    min-width: 100%;
  `}
  ${media.tabletUp`
    width: 100%;
  `}
`

const ReportItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  ${media.tabletUp`
    margin-top: 32px;
  `}

  &:first-child {
    margin-top: 0;
  }
`

export default ReportItemContainer
