import * as types from '../../constants/action-types'

interface SubscriptionStatus {
  clientName: string | null,
  isCorpSubscriber: boolean | null,
  isFreeSubscriber: boolean | null,
  isInternalStaff: boolean | null,
  isSCMPChurned: boolean | null,
  isSCMPSubscriber: boolean | null,
  isSiteLicenseSubscriber: boolean | null,
  isVipExclusive: boolean | null,
  isYPChurned: boolean | null,
  isYPSubscriber: boolean | null,
  scmpWinback: {
    isEligible: boolean,
    lastPaidDigitalPeriod: string | null
  },
  subscriptionProducts: string[],
  isAutoRenew: boolean | null,
  expiryReminder: string[],
  subscriptions: string[],
  tier: string | null,
  gracePeriod: string[],
}

export const updateSubscriptionStatus = (data: SubscriptionStatus) => ({ type: types.UPDATE_SUBSCRIPTION_STATUS, data })
export const updateAutoRenew = (isAutoRenew: boolean) => ({ type: types.UPDATE_SUBSCRIPTION_ISAUTORENEW, isAutoRenew })
