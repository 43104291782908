import React from 'react'
import styled from 'styled-components'

import { SCMP_COM_PROTOCOL, SCMP_COM_HOST } from 'config/app.config'
import { media } from 'utils/style'

import ScmpLogo from 'assets/svg/flag.svg'
import VerticalLogo from 'assets/svg/logo-scmp-vertical.svg'

import { AnimatedFlexContainer } from 'components/animation'
import { FlexContainer } from 'components/flex'

type Props = {
  id: string,
  children: any,
  title?: string,
  isLogoLink?: boolean
  isDisplayFooter?: boolean
  verticalLogo?: boolean
  footerText?: boolean
}
const Page: React.FunctionComponent<Props> = ({ id, children, title, footerText, isLogoLink = false, isDisplayFooter = true, verticalLogo = false }) => {
  const displayLogo = () => {
    return verticalLogo ? VerticalLogo : ScmpLogo
  }
  const privacyUrl = `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}/privacy-policy`

  const termAndConditionsUrl = `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}/terms-conditions`

  return (
    <PageContainer id={id} animationType='fadeIn' flexDirection='column' alignItem='center' justifyContent='center'>
      <ContainerBox flexDirection='column' alignItem='center' wrap='nowrap'>
        { isLogoLink
          ? (<a href={`${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}`}>
            <img src={displayLogo()} alt='scmp-logo' />
          </a>)
          : (<img src={displayLogo()} alt='scmp-logo' />)}
        { title &&
          <Title>{ title }</Title>
        }
        <PageContent flexDirection='column' alignItem='center'>
          { children }
        </PageContent>
        { isDisplayFooter &&
          <Footer className='signin-page__footer footer'>
            {footerText &&
              <FooterText>By creating an account, you agree to our <TNCUrl target="_blank" href={termAndConditionsUrl}>Terms of Service </TNCUrl> and acknowledge our <TNCUrl target="_blank" href={privacyUrl}>Privacy Policy</TNCUrl>.</FooterText>
            }
          </Footer>
        }
      </ContainerBox>
    </PageContainer>
  )
}

const PageContainer = styled(AnimatedFlexContainer)`
  background-color: #F6F6F6;
  min-width: 320px;
  min-height: 750px;
  height: 100%;
  padding: 0px;
  ${media.tabletUp`
    background-color: transparent;
    min-height: 732px;
  `}
  ${media.smallDesktopUp`
    min-height: 580px;
  `}
`

const ContainerBox = styled(FlexContainer)`
  box-sizing: border-box;
  width: 100%;
  max-width: 272px;
  min-height: 100%;
  padding-top: 40px;
  border: 0;
  border-radius: 8px;
  ${media.tabletUp`
    background-color: #F6F6F6;
    max-width: 400px;
    min-height: 620px;
    padding-top: 32px;
    border: 1px solid #e5e5e5;
  `}
`

const Title = styled.span`
  color: #001246;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 23px;
  white-space: pre-line;
`

const PageContent = styled(FlexContainer)`
  flex: 1;
`

const Footer = styled.div`
  margin: 12px 0 20px 0;
`

const TNCUrl = styled.a`
  text-decoration: underline;
  color: #999;
  cursor: pointer;
`

const FooterText = styled.div`
  color: #999999;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  display:block;
  padding-bottom: 12px;
  max-width: 270px;
  font-family: 'Roboto';
`

export default Page
