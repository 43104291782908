import styled, { keyframes } from 'styled-components'
import animationCustomizedProps from 'typings/animation'
import { FlexContainer } from '../flex'

function keyframesBuilder (type: string | undefined) {
  switch (type) {
    case 'fadeIn':
      return keyframes`
        from {
          opacity: 0;
        }
      
        to {
          opacity: 1;
        }
      `
  }
}

const AnimatedFlexContainer = styled(FlexContainer)<animationCustomizedProps>`
  animation: ${({ animationType }) => keyframesBuilder(animationType)} ${({ duration }) => duration || '0.5s'} ${({ timingFunction }) => timingFunction || 'linear'} ${({ iterationCount }) => iterationCount || '1'};
`

export default AnimatedFlexContainer
