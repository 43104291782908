import React from 'react'
import styled from 'styled-components'

import { FormErrorLabel } from 'components/formErrorLabel'
import { AnimatedFlexContainer } from 'components/animation'

import { InputComponent } from 'components/styled'

type Props = {
  email?: string,
  errorProfileMessage?: string,
  updateFirstName: (value: string) => void,
  updateLastName: (value: string) => void,
}

const AddProfileComponent: React.FunctionComponent<Props> = ({ ...Props }) => {
  const { updateFirstName, updateLastName, email, errorProfileMessage } = Props

  const updateFirstNameHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updateFirstName(event.target.value)
  }

  const updateLastNameHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updateLastName(event.target.value)
  }

  return (
    <ComponentContainer id="activate-account-add-profile" animationType='fadeIn' flexDirection='column' alignItem='center' justifyContent='flex-start'>
      <Title>Add your profile</Title>
      <ErrorMsgLabel>{errorProfileMessage}</ErrorMsgLabel>

      <InputComponent
        type='text'
        placeholder='first name'
        marginBottom='10px'
        onChange={updateFirstNameHandler}></InputComponent>
      <InputComponent type='text' placeholder='last name' marginBottom='10px' onChange={updateLastNameHandler}></InputComponent>
      <UserEmailContainer>
        <UserEmailLabel>{ email }</UserEmailLabel>
      </UserEmailContainer>
    </ComponentContainer>
  )
}

const ComponentContainer = styled(AnimatedFlexContainer)`
  margin-top: 24px;
  min-width: 375px;
`
const Title = styled.span`
  color: #001246;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  margin-bottom: 24px;
`

const ErrorMsgLabel = styled(FormErrorLabel)`
  margin-bottom: 12px;
`
const UserEmailContainer = styled.div`
  padding: 8px 9px 15px;
  max-width: 272px;
  width: 100%
`

const UserEmailLabel = styled.span`
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.19px;
  line-height: 19px;
  color: #333333;
`

export default AddProfileComponent
