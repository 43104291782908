import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { AnimatedFlexContainer } from 'components/animation'
import { FlexContainer } from 'components/flex'
import { FormErrorLabel } from 'components/formErrorLabel'
import { PasswordStrengthIndicator } from 'components/passwordStrengthIndicator'
import ShowPasswordButton from 'components/showPasswordButton'

import { ErrorMessage } from 'config/constants'

import { getPasswordStrength } from 'utils/common'
import { hasValue } from 'utils/formValidation'
import { inputPlaceholderStyle } from 'utils/style'
import { triggerGA4GtmEvent } from 'utils/tracking'

type Props = {
  password: string,
  confirmPassword?: string,
  passwordError?: string,
  confirmPwError?: string,
  updatePassword: (value: string) => void,
  updatePasswordError: (text: string) => void,
  updateConfirmPassword: (value: string) => void,
  updateConfirmPwError: (text: string) => void,
  updateErrorMessage: (text: string) => void
}

const SetUpPassword: React.FunctionComponent<Props> = ({ ...Props }) => {
  const { password, confirmPassword, passwordError, confirmPwError, updatePassword, updatePasswordError, updateConfirmPassword, updateErrorMessage, updateConfirmPwError } = Props
  const [isShowingPw, toggleShowPw] = useState<boolean>(false)
  const [isShowingConfirmPw, toggleShowConfirmPw] = useState<boolean>(false)
  const [passwordLevel, updatePasswordLevel] = useState<number>(0)

  useEffect(() => {
    triggerGA4GtmEvent({
      category: 'login',
      subcategory: 'set_password',
      action: 'imp',
      customized_parameters: {
        method: 'email',
      },
    })
  }, [])

  const passwordOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updatePassword(event.target.value)
    updatePasswordLevel(getPasswordStrength(event.target.value))
  }

  const confirmPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updateConfirmPassword(event.target.value)
  }

  const showPasswordHandler = (eventName?: string): void => {
    if (eventName === 'onMouseOut') {
      toggleShowPw(false)
      return
    }
    toggleShowPw(!isShowingPw)
  }

  const passwordCheckHandler = (): void => {
    if (hasValue(password) && passwordLevel < 3) {
      return updatePasswordError(ErrorMessage.REGISTER_PASSWORD_NOT_STRONG_ENOUGH)
    } else {
      updatePasswordError('')
    }
    if (password && confirmPassword && password !== confirmPassword) {
      return updateConfirmPwError(ErrorMessage.REGISTER_PASSWORD_DOES_NOT_MATCH)
    }
    updateErrorMessage('')
  }

  const confirmPasswordCheckHandler = (): void => {
    if (password && confirmPassword && password !== confirmPassword) {
      return updateConfirmPwError(ErrorMessage.REGISTER_PASSWORD_DOES_NOT_MATCH)
    } else {
      updateConfirmPwError('')
    }

    updateErrorMessage('')
  }

  const showConfirmPasswordHandler = (eventName?: string): void => {
    if (eventName === 'onMouseOut') {
      toggleShowConfirmPw(false)
      return
    }
    toggleShowConfirmPw(!isShowingConfirmPw)
  }

  const isShowingPassword = (isShowing: boolean): string => {
    return isShowing ? 'text' : 'password'
  }
  return (
    <ComponentContainer id='create-account-with-email' animationType='fadeIn' flexDirection='column' alignItem='center' justifyContent='flex-start'>
      <RegisterContainer flexDirection='column' alignItem='stretch' justifyContent='flex-start'>
        <Title>Set up password</Title>
        <ErrorMsgBox>
          <ErrorMsgLabel>{passwordError}</ErrorMsgLabel>
          <ErrorMsgLabel>{confirmPwError}</ErrorMsgLabel>
        </ErrorMsgBox>
        <PasswordContainer>
          <PasswordInput
            type={isShowingPassword(isShowingPw)}
            placeholder="password"
            maxLength={128}
            onChange={passwordOnChangeHandler}
            onBlur={passwordCheckHandler}
            error={passwordError}></PasswordInput>
          <ShowPasswordButton toggleHandler={showPasswordHandler}></ShowPasswordButton>
        </PasswordContainer>

        <PasswordStrengthIndicator passwordStrendth={passwordLevel} />

        <PasswordContainer>
          <PasswordInput
            type={isShowingPassword(isShowingConfirmPw)}
            placeholder='confirm password'
            onChange={confirmPasswordHandler}
            onBlur={confirmPasswordCheckHandler}
            error={confirmPwError}></PasswordInput>
          <ShowPasswordButton toggleHandler={showConfirmPasswordHandler}></ShowPasswordButton>
        </PasswordContainer>

        <PasswordHelperText>Use 8 or more characters with a mix of letters, number & symbols</PasswordHelperText>
      </RegisterContainer>
    </ComponentContainer>
  )
}

interface FieldChecker {
  error?: string
}

const ComponentContainer = styled(AnimatedFlexContainer)`
  min-width: 375px;
`

const RegisterContainer = styled(FlexContainer)`
  min-width: 272px;
  width: 272px;
`

const Title = styled.span`
  color: #001246;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
`

const ErrorMsgLabel = styled(FormErrorLabel)`
`

const ErrorMsgBox = styled.span`
  margin-bottom: 12px;
  :empty {
    display: none;
  }
`

const PasswordContainer = styled.div`
  position: relative;
  margin-bottom: 7px;
  max-width: 272px;
  width: 100%;
`

const PasswordInput = styled.input<FieldChecker>`
  height: 37px;
  width: 100%;
  border: 1px solid ${({ error }) => error ? '#EB4254' : '#CCCCCC'};
  border-radius: 2px;
  background-color: #FFF;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.19px;
  color: #333;
  font-family: Roboto;
  padding-left: 10px;
  box-sizing: border-box;
  padding-right: 50px;
  ${inputPlaceholderStyle('color: #999')}
`

const PasswordHelperText = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.14px;
  color: #333333;
  width: 100%;
`

export default SetUpPassword
