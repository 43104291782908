import useMyProfile from './useMyProfile'
import { useEffect, useState } from 'react'
import _get from 'lodash/get'
import _sizes from 'lodash/size'
import { USER_PLUGIN_ID } from 'config/constants'

interface SocialData {
  name?: string,
  mediaId?: string,
}

interface AdditionalData {
  /* eslint-disable-next-line */
  first_name: string
  /* eslint-disable-next-line */
  last_name: string
}

export default function useSocialData () {
  const { data } = useMyProfile()

  const [hasSocialData, setHasSocialData] = useState<boolean>(false)
  const [facebookData, setFacebookData] = useState<SocialData>({ name: '' })
  const [googleData, setGoogleData] = useState<SocialData>({ name: '' })
  const [appleData, setAppleData] = useState<SocialData>({ name: '' })

  useEffect(() => {
    const authContents = _get(data, 'user.auth.entities', [])

    if (_sizes(authContents) === 0) {
      setHasSocialData(false)
    } else {
      setHasSocialData(true)
      authContents.forEach((plugin: any) => {
        const userAdditionalData: AdditionalData = JSON.parse(_get(plugin, 'additionalData', '{}'))
        const userName = userAdditionalData ? `${userAdditionalData.first_name} ${userAdditionalData.last_name}` : ''

        switch (plugin.pluginId) {
          case USER_PLUGIN_ID.SOCIAL_AUTH_APPLE:
            setAppleData({
              name: userName,
              mediaId: plugin.id,
            })
            break
          case USER_PLUGIN_ID.SOCIAL_AUTH_FACEBOOK:
            setFacebookData({
              name: userName,
              mediaId: plugin.id,
            })
            break
          case USER_PLUGIN_ID.SOCIAL_AUTH_GOOGLE:
            setGoogleData({
              name: userName,
              mediaId: plugin.id,
            })
            break
        }
      })
    }
  }, [data])

  return {
    appleData,
    facebookData,
    hasSocialData,
    googleData,
    setHasSocialData,
    setFacebookData,
    setGoogleData,
    setAppleData,
  }
}
