import React from 'react'
import { ReactComponent as EpaperIconSvg } from 'assets/svg/epaper.svg'
import { ReactComponent as MobileIconSvg } from 'assets/svg/phone.svg'
import { ReactComponent as ArrowRightSvg } from 'assets/svg/phone_arrow_right.svg'
import macDesktop from 'assets/images/macDesktop.png'
import { FlexContainer } from 'components/flex'
import styled from 'styled-components'
import { media } from 'utils/style'

type Props = {
  hasActiveEpaperConversions?: boolean
}

const BannerContainer = styled(FlexContainer)`
  height: 96px;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
  flex-wrap: nowrap;
  position: relative;
`

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 78px;
  border-radius: 3px;
  left: 0;
  top: 9px;
  background: #4585ff;
  z-index: 1;
`

const CaptionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 2;
`

const Container = styled.div`
  display: flex;
  margin-left: 20px;
  z-index: 2;
`

const MobileIcon = styled(MobileIconSvg)`
  flex: 1;
  justify-self: flex-end;
  ${media.tabletUp`
    display: none;
  `}
`

const ArrowRight = styled(ArrowRightSvg)`
  margin-left: 12px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`

const MobileCaption = styled.span`
  font-size: 18px;
  font-style: normal;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  color: #ffffff;
  flex-wrap: wrap;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  ${media.tabletUp`
    display: none;
  `}
`

const DesktopCaption = styled(MobileCaption)`
  display: none;
  ${media.tabletUp`
    display: block;
  `}
`

const ReadMoreText = styled.span`
  font-weight: 700;
`

const EpaperIcon = styled(EpaperIconSvg)`
  padding-left: 16px;
  display: none;
  ${media.tabletUp`
    display: block;
  `}
`

const DesktopIcon = styled.div`
  z-index: 2;
  width: 173px;
  height: 97px;
  background-image: url(${macDesktop});
  background-size: 100%;
  display: none;
  ${media.tabletUp`
    display: block;
  `}
`

const BannerButton: React.FunctionComponent<Props> = ({ ...Props }) => {
  const { hasActiveEpaperConversions } = Props

  return (
    <BannerContainer>
      <Background></Background>
      <CaptionContainer>
        <MobileCaption>
          <ReadMoreText>Read more in</ReadMoreText>
          <span>the SCMP App</span>
        </MobileCaption>
        {hasActiveEpaperConversions && (
          <>
            <DesktopCaption>
              <span>Read the </span>
              <ReadMoreText>SCMP ePaper edition</ReadMoreText>
            </DesktopCaption>
            <EpaperIcon></EpaperIcon>
          </>
        )}
        <ArrowRight></ArrowRight>
      </CaptionContainer>
      <Container>
        <MobileIcon></MobileIcon>
        {hasActiveEpaperConversions && <DesktopIcon></DesktopIcon>}
      </Container>
    </BannerContainer>
  )
}

export default BannerButton
