import { useQuery, useMutation } from '@apollo/react-hooks'
import _get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'

import { DataLayerEventCategory, DataLayerGtmCustomEventName, DataLayerEventAction, ErrorMessage } from 'config/constants'

import * as modalAction from 'states/actions/application'

import { media } from 'utils/style'
import { triggerGtmEvent, triggerGA4GtmEvent } from 'utils/tracking'

import UPDATE_USER_CONTEXT from 'universal/apollo/d8/UPDATE_USER_CONTEXT.gql'
import GET_USER_CONTEXT from 'universal/apollo/d8/GET_USER_CONTEXT.gql'

import NotificationToggle from '../../components/activities/comments/NotificationToggle'

const EmailAlertsContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const [entityId, updateEntityId] = useState<string>('')
  const [contentRelatedOptIn, updateContentRelatedOptIn] = useState<boolean>(false)
  const profile = useQuery(GET_USER_CONTEXT, { variables: { typeValue: 'terms_and_preferences' } })

  useEffect(() => {
    const profileEntityId: string = _get(profile, 'data.currentUserContext.reverseUidProfile.entities[0].entityId')
    const fieldContentRelatedOptIn: boolean = _get(profile, 'data.currentUserContext.reverseUidProfile.entities[0].fieldContentRelatedOptIn')
    updateEntityId(profileEntityId ? profileEntityId.toString() : '')
    updateContentRelatedOptIn(fieldContentRelatedOptIn)
  }, [profile])

  const [callUpdateRelatedOptIn] = useMutation(UPDATE_USER_CONTEXT, {
    onCompleted: (response) => {
    },
    onError: () => {
      dispatch(modalAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
    },
    variables: {
      uid: entityId,
      input: {
        fieldContentRelatedOptIn: contentRelatedOptIn ? '0' : '1',
      },
    },
  })

  const trackEventDailyEmail = (receiveDailyEmail: boolean) => {
    const updatedStatus = receiveDailyEmail ? 'on' : 'off'
    triggerGtmEvent(
      receiveDailyEmail ? DataLayerEventAction.NOTIFICATION_CENTER_DAILY_EMAIL_ON : DataLayerEventAction.NOTIFICATION_CENTER_DAILY_EMAIL_OFF,
      DataLayerEventCategory.NOTIFICATION_CENTER,
      DataLayerGtmCustomEventName.TRACKING,
    )
    triggerGA4GtmEvent({
      category: 'follow',
      subcategory: 'email_alert',
      action: 'sys',
      customized_parameters: {
        alert: updatedStatus,
      },
    })
  }

  const updateCheckedValueHandler = (value: boolean) => {
    updateContentRelatedOptIn(value)
    trackEventDailyEmail(value)
  }

  return (
    <EmailAlertsBlock>
      <ToggleBlock>
        <NotificationToggle checked={contentRelatedOptIn} labelText={'Daily email alerts'} updateCheckedValue={updateCheckedValueHandler} callUpdateNotification={callUpdateRelatedOptIn}/>
      </ToggleBlock>
      <Description>
        To make sure you're always up-to-date, we recommend that you enable our email alerts.
      </Description>
    </EmailAlertsBlock>
  )
}

const EmailAlertsBlock = styled(FlexContainer)`
  height: 100px;
  padding: 20px 20px 0;
  ${media.tabletUp`
    padding: 0;
  `}
`

const ToggleBlock = styled.div`
  width: 100%;
`

const Description = styled.div`
  color: #666666;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
`

export default EmailAlertsContainer
