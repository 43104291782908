import * as types from '../../constants/action-types'

export const updateModalMessage = (message: string, destination?: string) => ({ type: types.UPDATE_MODAL_MESSAGE, message, destination })
export const updateUnFollowModalMessage = (uuid: string, unFollowModalMessage: string, actionType: string, id: string) => ({ type: types.UPDATE_UNFOLLOW_MODAL_MESSAGE, uuid, unFollowModalMessage, actionType, id })
export const updateDestinationUrl = (destination: string) => ({ type: types.UPDATE_REDIRECT_DESTINATION_URL, destination })
export const updateRedirectSource = (redirectSoucre: string) => ({ type: types.UPDATE_REDIRECT_SOURCE, redirectSoucre })
export const updateSoicalMediaError = (media: string, code: string) => ({ type: types.UPDATE_SOICAL_MEDIA_CONNECTION_ERROR, media, code })
export const removeSoicalMediaError = () => ({ type: types.UPDATE_SOICAL_MEDIA_CONNECTION_ERROR })
export const updateRedirectBehaviour = (from: string, destination: string) => ({ type: types.UPDATE_REDIRECT_BEHAVIOUR, from, destination })
export const updateFlowType = (flowType: string) => ({ type: types.UPDATE_FLOW_TYPE, flowType })

export const updatePianoStatus = (isPianoReady: boolean) => ({ type: types.UPDATE_PIANO_STATUS, isPianoReady })
export const updatePianoSupportedStatus = (isPianoSupported: boolean) => ({ type: types.UPDATE_PIANO_IS_SUPPORTED, isPianoSupported })
export const updatePianoConversions = (pianoConversions: object[]) => ({ type: types.UPDATE_PIANO_CONVERSIONS, pianoConversions })
export const updatePianoIsCorpSubscriber = (isCorpSubscriber: boolean) => ({ type: types.UPDATE_PIANO_IS_CORP_SUBSCRIBER, isCorpSubscriber })
export const updateCorpSubscriberTier = (corpSubscriberTier: string) => ({ type: types.UPDATE_PIANO_CORP_SUBSCRIBER_TIER, corpSubscriberTier })
export const updatePianoIsYPubscriber = (isYPSubscriber: boolean) => ({ type: types.UPDATE_PIANO_IS_YP_SUBSCRIBER, isYPSubscriber })

export const updateZendeskStatus = (isZendeskReady: boolean) => ({ type: types.UPDATE_ZENDESK_STATUS, isZendeskReady })
export const updateZendeskVisibility = (isZendeskVisible: boolean) => ({ type: types.UPDATE_ZENDESK_VISIBILITY, isZendeskVisible })

export const updatePageUrl = (pageUrl: string) => ({ type: types.UPDATE_PAGE_URL, pageUrl })
export const updateFollowList = (userFollowList: any) => ({ type: types.UPDATE_FOLLOW_LIST, userFollowList })

export const updateIsGtmReady = (isGtmReady: boolean) => ({ type: types.UPDATE_IS_GTM_READY, isGtmReady })
