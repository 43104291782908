import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import styled from 'styled-components'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch } from 'react-redux'
import _get from 'lodash/get'

import { FlexContainer } from 'components/flex'
import { AnimatedFlexContainer } from 'components/animation'
import { getPasswordStrength } from 'utils/common'
import { inputPlaceholderStyle, media } from 'utils/style'

import {
  hasValue,
  isValidEmail,
} from 'utils/formValidation'
import { PasswordStrengthIndicator } from 'components/passwordStrengthIndicator'
import { FormErrorLabel } from 'components/formErrorLabel'
import ShowPasswordButton from 'components/showPasswordButton'

import CHECK_IF_EMAIL_EXISTS from 'universal/apollo/d8/CHECK_IF_EMAIL_EXISTS.gql'
import { REGISTRATION_STEP } from 'states/constants/registration'
import * as registrationActions from 'states/actions/registration'
import { ErrorMessage } from 'config/constants'

const CreateAccountWithEmailComponent: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const [email, updateEmail] = useState<string>('')
  const [emailError, updateEmailError] = useState<string>('')
  const [password, updatePassword] = useState<string>('')
  const [passwordError, updatePasswordError] = useState<string>('')
  const [confirmPassword, updateConfirmPassword] = useState<string>('')
  const [confirmPwError, updateConfirmPwError] = useState<string>('')
  const [reCaptchaError, updateReCaptchaError] = useState<string>('')
  const [isShowingPw, toggleShowPw] = useState<boolean>(false)
  const [isShowingConfirmPw, toggleShowConfirmPw] = useState<boolean>(false)
  const [passwordLevel, updatePasswordLevel] = useState<number>(0)
  const [recaptchaToken, updateRecaptchaToken] = useState<string | null>(null)
  const [errorMessage, updateErrorMessage] = useState<string>('')
  const { data, loading } = useQuery(CHECK_IF_EMAIL_EXISTS, {
    variables: { email },
    skip: !email.length || !isValidEmail(email),
  })

  useEffect(() => {
    // when use email already registered
    if (_get(data, 'userQuery.count', 0)) {
      updateEmailError(ErrorMessage.REGISTER_EMAIL_ALREADY_EXIST)
    } else {
      updateEmailError('')
    }
  }, [data])

  const updateEmailHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updateEmail(event.target.value)
  }

  const emailCheckHandler = (): void => {
    if (emailError === ErrorMessage.REGISTER_EMAIL_ALREADY_EXIST) {
      return
    } else if (!isValidEmail(email)) {
      return updateEmailError(ErrorMessage.REGISTER_WRONG_EMAIL)
    } else {
      updateEmailError('')
    }

    updateErrorMessage('')
  }

  const passwordOnChangeHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updatePassword(event.target.value)
    updatePasswordLevel(getPasswordStrength(event.target.value))
  }

  const passwordCheckHandler = (): void => {
    if (hasValue(password) && passwordLevel < 3) {
      return updatePasswordError(ErrorMessage.REGISTER_PASSWORD_NOT_STRONG_ENOUGH)
    } else {
      updatePasswordError('')
    }
    if (password && confirmPassword && password !== confirmPassword) {
      return updateConfirmPwError(ErrorMessage.REGISTER_PASSWORD_DOES_NOT_MATCH)
    }

    updateErrorMessage('')
  }

  const confirmPasswordHandler = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updateConfirmPassword(event.target.value)
  }

  const confirmPasswordCheckHandler = (): void => {
    if (password && confirmPassword && password !== confirmPassword) {
      return updateConfirmPwError(ErrorMessage.REGISTER_PASSWORD_DOES_NOT_MATCH)
    } else {
      updateConfirmPwError('')
    }

    updateErrorMessage('')
  }

  const showPasswordHandler = (eventName?: string): void => {
    if (eventName === 'onMouseOut') {
      toggleShowPw(false)
      return
    }
    toggleShowPw(!isShowingPw)
  }

  const showConfirmPasswordHandler = (eventName?: string): void => {
    if (eventName === 'onMouseOut') {
      toggleShowConfirmPw(false)
      return
    }
    toggleShowConfirmPw(!isShowingConfirmPw)
  }

  const isShowingPassword = (isShowing: boolean): string => {
    return isShowing ? 'text' : 'password'
  }

  const recaptchaOnChangeHandler = (token: string | null): void => {
    updateReCaptchaError('')
    updateErrorMessage('')
    updateRecaptchaToken(token)
  }

  const processNextStep = (): void => {
    if (loading) {
      return
    }

    if (!email.length || !password.length || !confirmPassword.length) {
      return updateErrorMessage(ErrorMessage.REGISTER_MISSING_EMAIL_OR_PASSWORD)
    }

    if (!recaptchaToken) {
      updateReCaptchaError(ErrorMessage.FAIL_TO_VERIFY_RECAPTCHA)
      return updateErrorMessage(ErrorMessage.FAIL_TO_VERIFY_RECAPTCHA)
    }

    if (email.length &&
        password.length &&
        confirmPassword.length &&
        !emailError &&
        !passwordError &&
        !confirmPwError &&
        !errorMessage &&
        passwordLevel >= 3 &&
        recaptchaToken) {
      dispatch(registrationActions.updateRegistrationEmail(email))
      dispatch(registrationActions.updateRegistrationPassword(password))
      dispatch(registrationActions.updateRegistrationStep(REGISTRATION_STEP.ADD_PRODILE))
    }
  }

  return (
    <ComponentContainer id='create-account-with-email' animationType='fadeIn' flexDirection='column' alignItem='center' justifyContent='flex-start'>
      <RegisterContainer flexDirection='column' alignItem='stretch' justifyContent='flex-start'>
        <Title>Create an account</Title>
        <ErrorMsgBox>
          <ErrorMsgLabel>{errorMessage}</ErrorMsgLabel>
          <ErrorMsgLabel>{emailError}</ErrorMsgLabel>
          <ErrorMsgLabel>{passwordError}</ErrorMsgLabel>
          <ErrorMsgLabel>{confirmPwError}</ErrorMsgLabel>
        </ErrorMsgBox>
        <EmailInput type='email' placeholder='your email' onChange={updateEmailHandler} onBlur={emailCheckHandler} error={emailError}></EmailInput>
        <PasswordContainer>
          <PasswordInput
            type={isShowingPassword(isShowingPw)}
            placeholder="password"
            maxLength={128}
            onChange={passwordOnChangeHandler}
            onBlur={passwordCheckHandler}
            error={passwordError}></PasswordInput>
          <ShowPasswordButton toggleHandler={showPasswordHandler}></ShowPasswordButton>
        </PasswordContainer>

        <PasswordStrengthIndicator passwordStrendth={passwordLevel} />

        <PasswordContainer>
          <PasswordInput
            type={isShowingPassword(isShowingConfirmPw)}
            placeholder='confirm password'
            onChange={confirmPasswordHandler}
            onBlur={confirmPasswordCheckHandler}
            error={confirmPwError}></PasswordInput>
          <ShowPasswordButton toggleHandler={showConfirmPasswordHandler}></ShowPasswordButton>
        </PasswordContainer>

        <PasswordHelperText>Use 8 or more characters with a mix of letters, number & symbols</PasswordHelperText>
        <ReCAPTCHAContainer error={reCaptchaError}>
          <ReCAPTCHAWithStyle onChange={recaptchaOnChangeHandler} sitekey="6LcD47QZAAAAAN8JXiDIvKn_1mzF8cMfHpRE91YV" />
        </ReCAPTCHAContainer>

        <NextStepButton onClick={() => processNextStep()}>NEXT</NextStepButton>

      </RegisterContainer>
    </ComponentContainer>
  )
}

interface FieldChecker {
  error?: string
}

const ComponentContainer = styled(AnimatedFlexContainer)`
  min-width: 375px;
`

const RegisterContainer = styled(FlexContainer)`
  min-width: 272px;
  width: 272px;
`

const Title = styled.span`
  color: #001246;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 24px;
`

const ErrorMsgLabel = styled(FormErrorLabel)`
`

const ErrorMsgBox = styled.span`
  margin-bottom: 12px;
  :empty {
    display: none;
  }
`

const EmailInput = styled.input<FieldChecker>`
  height: 37px;
  width: 100%;
  max-width: 272px;
  border: 1px solid ${({ error }) => error ? '#EB4254' : '#CCCCCC'};
  border-radius: 2px;
  background-color: #FFF;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.19px;
  color: #333;
  font-family: Roboto;
  padding-left: 10px;
  margin-bottom: 10px;
  margin-top: 2px;
  box-sizing: border-box;
  ${inputPlaceholderStyle('color: #999')}
`

const PasswordContainer = styled.div`
  position: relative;
  margin-bottom: 7px;
  max-width: 272px;
  width: 100%;
`

const PasswordInput = styled.input<FieldChecker>`
  height: 37px;
  width: 100%;
  border: 1px solid ${({ error }) => error ? '#EB4254' : '#CCCCCC'};
  border-radius: 2px;
  background-color: #FFF;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.19px;
  color: #333;
  font-family: Roboto;
  padding-left: 10px;
  box-sizing: border-box;
  padding-right: 50px;
  ${inputPlaceholderStyle('color: #999')}
`

const PasswordHelperText = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.14px;
  color: #333333;
  margin-bottom: 24px;
  width: 100%;
`

const ReCAPTCHAWithStyle = styled(ReCAPTCHA)<FieldChecker>`
  transform: scale(0.894);
  transform-origin:0 0;
  max-width: 272px;
  max-height: 70px;
`

const ReCAPTCHAContainer = styled.div<FieldChecker>`
  margin-bottom: 22px;
  border-radius: 3px;
  border: 1px solid ${({ error }) => error ? 'red' : 'transparent'};
`

const NextStepButton = styled.div`
  min-width: 270px;
  max-width: 272px;
  border-radius: 2px;
  border-color: #FFCA05;
  background-color: #FFCA05;
  padding: 12px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-family: Roboto;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 0px;
  ${media.tabletUp`
    margin-bottom: auto;
  `}
`

export default CreateAccountWithEmailComponent
