import React from 'react'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'
import ArrowDownIcon from 'assets/svg/icon_arrow_down.svg'

type Props = {
  className?: string,
  value?: string | number | string[] | undefined,
  children?: React.ReactNode,
  onChange?: React.ChangeEventHandler<HTMLSelectElement>
}

const Select: React.FunctionComponent<Props> = ({ className, value, children, onChange }) => {
  return (
    <SelectContainer className={className}>
      <SelectBackground justifyContent='center'>
        <img src={ArrowDownIcon} alt='' />
      </SelectBackground>
      <StyledSelect value={value} onChange={onChange}>
        {children}
      </StyledSelect>
    </SelectContainer>
  )
}

const SelectContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 37px;
  border: 1px solid #D7D7D7;
  border-radius: 2px;
  background-color: #FFFFFF;
`

const SelectBackground = styled(FlexContainer)`
  position: absolute;
  right: 0;
  width: 35px;
  height: 100%;
  background-color: rgba(242, 242, 242, 0.5);
`

const StyledSelect = styled.select`
  position: relative;
  box-sizing: border-box;
  padding: 0 48px 0 13px;
  width: 100%;
  height: 100%;
  border: 0;
  appearance: none;
  outline: none;
  background: transparent;
  color: #666666;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.19px;
  line-height: 19px;
`

export default Select
