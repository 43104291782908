import React from 'react'
import styled from 'styled-components'

import { ManageButton } from 'components/manage'
import { ReactComponent as IconNewsletters } from 'assets/svg/icon_newsletters.svg'
import { ReactComponent as IconGetConnect } from 'assets/svg/icon_getconnect.svg'
import { ReactComponent as IconArticles } from 'assets/svg/smallicon_article.svg'
import IconArrowBack from 'assets/svg/icon_arrow_back_grey.svg'
import { Link } from 'react-router-dom'
import { FlexContainer } from 'components/flex'
import { useSelector } from 'react-redux'
import { AppState } from 'states/reducers'

type Props = {
  activeItem: string
}

const ManageMenu: React.FunctionComponent<Props> = ({ ...Props }) => {
  const isPianoSupported = useSelector((state: AppState) => state.application.isPianoSupported)
  const isSubscriber = useSelector((state: AppState) => state.subscriptionStatus.isSubscriber)

  const displaySubscription = () => {
    return isSubscriber || isPianoSupported
  }

  return (
    <ManageMenuContainer>
      <MenuTitle>Manage</MenuTitle>
      { displaySubscription() && <Link to='/manage/subscription'>
        <ManageButtonItem active={Props.activeItem === 'subscription' } buttonTitle='Subscription'><IconArticles className='article-icon'/></ManageButtonItem>
      </Link> }
      <Link to='/manage/newsletter'>
        <ManageButtonItem active={Props.activeItem === 'newsletter' } buttonTitle='Newletters'><IconNewsletters /></ManageButtonItem>
      </Link>
      <Link to='/manage/get-connected'>
        <ManageButtonItem active={Props.activeItem === 'get-connected' } buttonTitle='Get Connected'><IconGetConnect /></ManageButtonItem>
      </Link>
      <Link to='/settings'>
        <BackMyAccountContainer alignItem='center' justifyContent='space-between'>
          <BackButton src={IconArrowBack}/>
          <span>My account</span>
        </BackMyAccountContainer>
      </Link>
    </ManageMenuContainer>
  )
}

export default ManageMenu

const ManageMenuContainer = styled.div`
  width: 100%;
`

const MenuTitle = styled.div`
  color: #001246;
  font-family: 'Roboto Condensed';
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 35px;
`

const ManageButtonItem = styled(ManageButton)`
  cursor: pointer;
  margin: 0;
  height: auto;
  margin-bottom: 28px;
  padding: 0px;
  border-top: none;
  padding-top: 0px;
  color: ${({ active }) => active ? '#001246' : '#6F6F6F'};
`

const BackButton = styled.img`
  height: 17px;
  width: 17px;
  cursor: pointer;
  background: url("${IconArrowBack}");
`

const BackMyAccountContainer = styled(FlexContainer)`
  width: 100%;
  line-height: 24px;
  color: #ccc;
  font-family: Roboto;
  font-size: 16px;
  max-width: 110px;
  cursor: pointer;
  margin-top: 56px;
`
