import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { FlexContainer } from 'components/flex'
import { DROP_DOWN_MENU_ITEMS, SCMP_COM_PROTOCOL, SCMP_COM_HOST } from 'config/app.config'
import {
  CORP_SUBCRIBER_TIER,
  DataLayerEventCategory,
  DataLayerEventAction,
  DataLayerGtmCustomEventName,
} from 'config/constants'
import { useSelector } from 'react-redux'
import { AppState } from 'states/reducers'
import { onSignOutClick } from 'utils/common'
import { getUserLevel } from 'utils/user'
import { CustomModal } from 'components/modal'
import { MyAccountClickCategory, triggerGA4GtmEvent, triggerGtmEvent } from 'utils/tracking'

import _find from 'lodash/find'
import _includes from 'lodash/includes'

const Menu: React.FunctionComponent = () => {
  const { pageUrl } = useSelector((state: AppState) => state.application)
  const scmpUrl = `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}`
  const faqsUrl = 'https://help.scmp.com/hc'
  const destination = useSelector((state: AppState) => state.application.redirectDestinationUrl)
  const userInfo = useSelector((state: AppState) => state.userInfo)
  const userLevel = getUserLevel(userInfo) || {}
  const [showModal, isShowModal] = useState<boolean>(false)

  const isSubscriber = useSelector((state: AppState) => state.subscriptionStatus.isSubscriber)
  const contactUsUrl = userLevel.isYPUser ? `${scmpUrl}/yp/contact-us` : `${scmpUrl}/contact-us`

  // const pianoConversions = useSelector((state: any) => state.application.pianoConversions)
  const corpSubscriberTier = useSelector((state: any) => state.application.corpSubscriberTier)
  const isCorpSubscriber = useSelector((state: any) => state.application.isCorpSubscriber)
  const isShowRosetta = useSelector((state: any) => state.userInfo.isShowRosetta)
  const [showGroupSubscriberBenefitsBtn, updateShowGroupSubscriberBenefitsBtn] = useState<boolean>(false)
  const modalTitle = 'Are you sure you want to <strong>log out</strong>?'

  const getActiveItemUrl = () => {
    const activeItem = _find(DROP_DOWN_MENU_ITEMS, (item: any) => _includes(item.activeUrls, pageUrl))
    return activeItem ? activeItem.url : ''
  }

  const ShowHideHandler = () => {
    isShowModal(!showModal)
    triggerGtmEvent(
      DataLayerEventAction.ACCOUNT_LOGOUT_CANCEL,
      DataLayerEventCategory.MY_ACCOUNT,
      DataLayerGtmCustomEventName.TRACKING,
      '',
    )
  }

  const handleLogOutButtonClick = () => {
    isShowModal(true)
    triggerGtmEvent(
      DataLayerEventAction.ACCOUNT_LOGOUT_CLICK,
      DataLayerEventCategory.MY_ACCOUNT,
      DataLayerGtmCustomEventName.TRACKING,
      '',
    )
    triggerGA4GtmEvent({
      category: 'myaccount',
      subcategory: '',
      action: 'click',
      customized_parameters: {
        category: 'log out',
      },
    })
  }

  const getMenuItemClickHandler = (category: MyAccountClickCategory) => () => {
    triggerGA4GtmEvent({
      category: 'myaccount',
      subcategory: '',
      action: 'click',
      customized_parameters: {
        category,
      },
    })
  }

  useEffect(() => {
    if (isCorpSubscriber && corpSubscriberTier !== null) {
      const showCorpSubscriberPage = [
        CORP_SUBCRIBER_TIER.T2,
        CORP_SUBCRIBER_TIER.T3,
        CORP_SUBCRIBER_TIER.T4,
        CORP_SUBCRIBER_TIER.C_SUITE,
        CORP_SUBCRIBER_TIER.EXPERT_NETWORK,
      ]

      if (_includes(showCorpSubscriberPage, corpSubscriberTier)) {
        updateShowGroupSubscriberBenefitsBtn(true)
      }
    }

    if (userLevel.isStaff) {
      updateShowGroupSubscriberBenefitsBtn(true)
    }
  }, [isCorpSubscriber, corpSubscriberTier])

  return (
    <MenuContainer>
      <MenuItemContainer>
        <MenuItemList flexDirection='column'>
          <MenuItem isSelected={getActiveItemUrl() === '/settings'} onClick={getMenuItemClickHandler('my account')} to='/settings'>My Account</MenuItem>
          { isShowRosetta && <MenuItem isSelected={getActiveItemUrl() === '/manage/subscription'} onClick={getMenuItemClickHandler('subscription')} to='/manage/subscription'>Subscription</MenuItem> }
          { showGroupSubscriberBenefitsBtn && isShowRosetta ? <MenuItem isSelected={getActiveItemUrl() === '/manage/group-subscriber-benefits'} onClick={getMenuItemClickHandler('group subscriber benefits')} to='/manage/group-subscriber-benefits'>Group Subscriber Benefits</MenuItem> : null }
          <MenuItem isSelected={getActiveItemUrl() === '/activities/comments'} onClick={getMenuItemClickHandler('my conversations')} to='/activities/comments'>My Conversations</MenuItem>
          <MenuItem isSelected={getActiveItemUrl() === '/manage/newsletter'} onClick={getMenuItemClickHandler('newsletters')} to={'/manage/newsletter'}>Newsletters</MenuItem>
          <MenuItem isSelected={getActiveItemUrl() === '/manage/bookmark'} onClick={getMenuItemClickHandler('bookmarks')} to='/manage/bookmark'>Bookmarks</MenuItem>
          <MenuItem isSelected={getActiveItemUrl() === '/manage/following'} onClick={getMenuItemClickHandler('following')} to={'/manage/following'}>Following</MenuItem>
          <MenuItem isSelected={getActiveItemUrl() === '/manage/notifications'} onClick={getMenuItemClickHandler('notifications')} to='/manage/notifications'>Notifications</MenuItem>
          { isSubscriber && !userLevel.isStaff
            ? <MenuItem isSelected={getActiveItemUrl() === '/manage/devices'} onClick={getMenuItemClickHandler('manage devices')} to='/manage/devices'>Manage Devices</MenuItem>
            : null
          }
          <MenuItem isSelected={getActiveItemUrl() === '/settings/profile'} onClick={getMenuItemClickHandler('edit profile')} to='/settings/profile'>Edit Profile</MenuItem>
          <TNUrl isSelected={false} onClick={getMenuItemClickHandler('contact us')} target="_blank" href={contactUsUrl}>Contact Us</TNUrl>
          <SeparateLine />
          <LogOutButton onClick={handleLogOutButtonClick}>Log Out</LogOutButton>
          { showModal
            ? <CustomModal title={modalTitle} customButton={true} onShow={ShowHideHandler}>
                <ModalTopButton onClick={ShowHideHandler}>Cancel</ModalTopButton>
                <ModalBottomButton onClick={() => onSignOutClick(destination)}>Log Out</ModalBottomButton>
              </CustomModal>
            : null}
        </MenuItemList>
      </MenuItemContainer>
      <NeedHelpContainer>
        <NeedHelpContent>
          <NeedHelpTitle>Need help?</NeedHelpTitle>
            <NeedHelpText><NeedHelpFAQs target="_blank" href={faqsUrl}>Visit our Help Center</NeedHelpFAQs> or <NeedHelpContactUs target="_blank" href={contactUsUrl}>contact us</NeedHelpContactUs>.</NeedHelpText>
        </NeedHelpContent>
      </NeedHelpContainer>
    </MenuContainer>
  )
}

interface dropDownMenuProps {
  menuOpen?: boolean,
}

const MenuContainer = styled(FlexContainer)<dropDownMenuProps>`
  flex-direction: column;
`

const MenuItemContainer = styled(FlexContainer)`
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  width: 242px;
  border: 1px solid #DDDDDD;
  border-radius: 5px;
  background-color: #FFFFFF;
`

const MenuItemList = styled(FlexContainer)`
  margin: 33px 0 33px 33px;
`

const MenuItem = styled(({ isSelected, ...rest }) => <Link {...rest} />)`
  margin-top: 20px;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 19px;
  &:first-child {
    margin-top: 0;
  }
  ${({ isSelected }) => isSelected ? 'color: #000' : 'color: #999999'}
`

type TNUrlProps = {
  isSelected: boolean
}

const TNUrl = styled.a<TNUrlProps>`
  margin-top: 20px;
  font-family: 'Roboto';
  font-size: 16px;
  line-height: 19px;
  ${({ isSelected }) => isSelected ? 'color: #000' : 'color: #999999'}
`

const SeparateLine = styled.div`
  margin-top: 20px;
  height: 1px;
  width: 176px;
  background-color: #DDDDDD;
`

const NeedHelpContent = styled.div`
  margin: 16px;
`

const NeedHelpContainer = styled(FlexContainer)<dropDownMenuProps>`
  margin-top: 27px;
  height: 104px;
  width: 240px;
  background-color: #F2F2F2;
`

const NeedHelpTitle = styled.div`
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
`
const NeedHelpText = styled.div`
  margin-top: 8px;
  color: #222222;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
`

const NeedHelpFAQs = styled.a`
  cursor: pointer;
  color: #222222;
  text-decoration: underline;
`

const NeedHelpContactUs = styled.a`
  color: #222222;
  text-decoration: underline;
`

const LogOutButton = styled.div`
  margin-top: 20px;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #999999;
  cursor: pointer;
`

const ModalTopButton = styled.div`
  padding: 12px 0;
  width: 100%;
  border-radius: 2px;
  background-color: #FFCA05;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
`
const ModalBottomButton = styled.div`
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  color: #4585FF;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
`

export default Menu
