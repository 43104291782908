import React from 'react'
import styled from 'styled-components'
import { FlexContainer } from 'components/flex'
import axios from 'axios'

import PageContainer from 'components/PageContainer'
import { StyledButton } from 'components/styled'
import { ResultPageDetails } from 'config/constants'

import reactStringReplace from 'react-string-replace'
import _replace from 'lodash/replace'

type Props = {
  history: any
  match: any
}

const ResultPageContainer: React.FunctionComponent<Props> = ({ history, match }) => {
  const { code, httpStatusCode, customCode } = match.params
  let title: string = 'An error occurred.'
  let buttonText: string = 'Back To Home Page'
  let resultMessage = <Message>Please try again later or contact us at <a href="mailto: digitalsupport@scmp.com">digitalsupport@scmp.com.</a></Message>
  let redirectUrl = '/'
  let logout = false
  const resultPageDetail = ResultPageDetails[code]
  if (resultPageDetail) {
    title = resultPageDetail.title
    buttonText = resultPageDetail.buttonText

    let message: string = resultPageDetail.message

    // handle if there are error code
    if (httpStatusCode || customCode) {
      message = _replace(message, '[[errorCode]]', customCode ? `${httpStatusCode}-${customCode}` : `${httpStatusCode}`)
    } else {
      message = _replace(message, '([[errorCode]])', '')
    }

    // handle Email tag
    if (resultPageDetail.mailTo.length > 0) {
      const mailTo: string = `mailto:${resultPageDetail.mailTo}`
      const resultText: any = reactStringReplace(message, '[[email]]', (match: string, i: number) => (
        <a key={i} className="tipsATag" href={mailTo}>{resultPageDetail.mailTo}</a>
      ))
      resultMessage = <Message>{resultText}</Message>
    } else {
      resultMessage = <Message>{message}</Message>
    }
    redirectUrl = resultPageDetail.redirectUrl
    if (typeof resultPageDetail.logout !== 'undefined') {
      logout = resultPageDetail.logout
    }
  }

  const nexStepHandler = async () => {
    if (!logout) {
      window.location.href = redirectUrl
      return
    }
    try {
      const response = await axios.post('/logout')
      // handle logout success case
      if (response.status === 200) {
        // logout piano instance
        const tp = (window as any).tp || []
        tp.push(['setExternalJWT', null])

        // redirect
        window.location.href = redirectUrl
      }
    } catch (e) {
      console.log('an error has been thrown', e)
      window.location.href = redirectUrl
    }
  }

  return (
    <>
      <PageContainer id='change-email-address-result-page' title={title} isLogoLink footerText={false} isDisplayFooter={true}>
        <ChangeEmailAddressResultSection flexDirection='column' alignItem='center'>
          {resultMessage}
          <StyledButton
            color='#000'
            fontSize='16px'
            lineHeight='19px'
            padding='12px'
            marginBottom='16px'
            onClick={nexStepHandler}>{buttonText}</StyledButton>
        </ChangeEmailAddressResultSection>
      </PageContainer>
    </>
  )
}

const ChangeEmailAddressResultSection = styled(FlexContainer)`
  max-width: 272px;
`

const Message = styled.div`
  color: #333333;
  font-family: Roboto;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 1px;
`

export default ResultPageContainer
