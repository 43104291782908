import axios from 'axios'
import _isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import PageContainer from 'components/PageContainer'
import { InputComponent, StyledButton } from 'components/styled'

import { RECAPTCHA_SITE_KEY } from 'config/app.config'
import { DataLayerEventCategory, DataLayerEventAction, DataLayerGtmCustomEventName, ErrorMessage, HttpStatusCodes } from 'config/constants'

import * as modalAction from 'states/actions/application'
import { AppState } from 'states/reducers'

import { isValidEmail } from 'utils/formValidation'
import { media } from 'utils/style'
import { triggerGA4GtmEvent, triggerGtmCustomEvent } from 'utils/tracking'

const ForgotPasswordContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const [email, updateEmail] = useState<string>('')
  const [recaptchaToken, updateRecaptchaToken] = useState<string | null>(null)
  const [emailSent, updateEmailSent] = useState<string>('')
  const callbackUrl = useSelector((state: AppState) => state.signin.callbackUrl)
  const destination = useSelector((state: AppState) => state.application.redirectDestinationUrl)
  const flowType = useSelector((state: AppState) => state.application.flowType)
  const history = useHistory()

  useEffect(() => {
    if (!_isEmpty(emailSent)) {
      triggerGA4GtmEvent({
        category: 'login',
        subcategory: 'forgot_password_reset_link',
        action: 'imp',
        customized_parameters: {
          method: 'email',
        },
      })
    }
  }, [emailSent])

  const sendLinkToResetPasswordHandler = async () => {
    if (!email) {
      dispatch(modalAction.updateModalMessage(ErrorMessage.FORGOT_PASSWORD_MISSING_EMAIL))
      return false
    }
    if (!isValidEmail(email)) {
      dispatch(modalAction.updateModalMessage(ErrorMessage.FORGOT_PASSWORD_WRONG_EMAIL))
      return false
    }

    if (!recaptchaToken) {
      return false
    }

    try {
      await axios.post('/forgot-password', { email })
      triggerGtmCustomEvent(DataLayerEventAction.FORGET_PASSWORD, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
      updateEmailSent(email)
    } catch (error: any) {
      console.log('an error has been thrown', error.response)
      if (error.response.data && error.response.data.errorMessage) {
        dispatch(modalAction.updateModalMessage(error.response.data.errorMessage))
      } else if (error.response.status === HttpStatusCodes.UNAUTHORIZED) {
        dispatch(modalAction.updateModalMessage(ErrorMessage.FORGOT_PASSWORD_NO_EXISTING_EMAIL))
      } else {
        dispatch(modalAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
      }

      updateEmailSent('')
    }
  }

  const recaptchaOnChangeHandler = (token: string | null): void => {
    updateRecaptchaToken(token)
  }

  const emailInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateEmail(event.target.value)
  }

  const sendAgain = (): void => {
    updateEmail(emailSent)
    updateEmailSent('')
  }

  const onClickOKButton = (): void => {
    triggerGA4GtmEvent({
      category: 'login',
      subcategory: 'forgot_password_reset_link',
      action: 'click',
      customized_parameters: {
        method: 'email',
      },
    })
    if (flowType === 'Subscribe') {
      window.location.href = `${callbackUrl}${destination ? '&destination=' + encodeURIComponent(destination) : ''}`
    } else {
      history.push('/login')
    }
  }

  const getTitle = (): string => emailSent ? 'Reset link has been sent' : 'Forgot password'

  return (
    <PageContainer id='forgotpassword-page' title={getTitle()} isDisplayFooter={false}>
      {
        !emailSent && (
          <>
            <Description>Enter your account email address below and we'll email you a link so you can reset your password.</Description>
            <InputComponent
              type='email'
              placeholder='email address'
              marginBottom='10px'
              onChange={emailInputHandler} />
            <ReCAPTCHAWithStyle onChange={recaptchaOnChangeHandler} sitekey={RECAPTCHA_SITE_KEY} />
            <SendLinkToResetPasswordButton onClick={sendLinkToResetPasswordHandler}>Send Link To Reset Password</SendLinkToResetPasswordButton>
            <CancelLink to='/login'>Cancel</CancelLink>
          </>
        )
      }
      {
        emailSent && (
          <>
            <Description>We sent an email to you at <EmailLabel>{emailSent}.</EmailLabel><DescriptionSpan>Please check your mail box.</DescriptionSpan></Description>
            <Description>Not receive any email? <SendAgain onClick={sendAgain}>Send Again</SendAgain></Description>
            <StyledButton
              maxWidth='272px'
              backgroundColour='#FFCA05'
              borderColor='#FFCA05'
              color='#000'
              fontSize='16px'
              lineHeight='19px'
              padding='9px'
              onClick={onClickOKButton}>OK</StyledButton>
          </>
        )
      }
    </PageContainer>
  )
}

const ReCAPTCHAWithStyle = styled(ReCAPTCHA)`
  transform: scale(0.894);
  transform-origin:0 0;
  max-width: 272px;
  max-height: 70px;
  margin-bottom: 22px;
`

const Description = styled.div`
  color: #222222;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 19px;
  padding: 0px;
  ${media.tabletUp`
    padding: 0px 66px;
  `}
`
const DescriptionSpan = styled.span`
  display: block;
`

const EmailLabel = styled.span`
  color: #4685FF;
  cursor: pointer;
  display:block;
`

const SendLinkToResetPasswordButton = styled.div`
  width: 272px;
  background-color: #FFCA05;
  border-radius: 2px;
  border-color: #FFCA05;
  box-sizing: border-box;
  cursor: pointer;
  padding: 12px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 18px;
  text-transform: uppercase;
`

const CancelLink = styled(Link)`
  text-decoration: none;
  height: 16px;
  width: 100%;
  color: #4585FF;
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
`

const SendAgain = styled.a`
  color: #EB4254;
  font-weight: bold;
  cursor: pointer;
`

export default ForgotPasswordContainer
