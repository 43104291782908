import axios from 'axios'
import _filter from 'lodash/filter'
import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { CustomModal } from 'components/modal'

import { FOLLOW_SERVICE_ENDPOINT } from 'config/app.config'
import { DataLayerEventCategory, DataLayerEventAction, DataLayerGtmCustomEventName } from 'config/constants'

import * as modalAction from 'states/actions/application'
import { AppState } from 'states/reducers'

import { triggerGtmEvent, triggerGA4GtmEvent, getFollowOptionalCustomizedParameters } from 'utils/tracking'

const getDataTrackType = (actionType: string) => {
  switch (actionType) {
    case 'author':
      return DataLayerEventAction.UNFOLLOW_AUTHOR
    case 'topic':
      return DataLayerEventAction.UNFOLLOW_TOPIC
    case 'knowledge':
      return DataLayerEventAction.UNFOLLOW_KNOWLEDGE
    default:
      return ''
  }
}

const CommonModal: React.FunctionComponent = () => {
  const { userFollowList } = useSelector((state: AppState) => state.application, shallowEqual)
  const unFollowModalMessage = useSelector((state: AppState) => state.application.unFollowModalMessage)
  const actionType = useSelector((state: AppState) => state.application.actionType)
  const id = useSelector((state: AppState) => state.application.id)
  const uuid = useSelector((state: AppState) => state.application.uuid)
  const [showModal, updateShowModal] = useState<boolean>(false)
  const dispatch = useDispatch()

  const ShowHideHandler = () => {
    updateShowModal(!showModal)
  }

  useEffect(() => {
    if (unFollowModalMessage) {
      updateShowModal(true)
    }
  }, [unFollowModalMessage])

  const updateFollowListState = (type: string) => {
    const childList = `${type}List`
    const list = userFollowList
    const newList = _filter(userFollowList[childList], (item) => item.entityId !== id)
    list[childList] = newList
    dispatch(modalAction.updateFollowList(list))
  }

  const unFollowHandler = () => {
    updateFollowListState(actionType)
    const data = {
      messageItem: {
        action: 'unfollow',
        id,
        type: 'sections_topics_authors',
        uuid,
      },
    }
    axios.post(`${FOLLOW_SERVICE_ENDPOINT}/follow`,
      data, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      },
    ).then(function (response) {
      if (response.status === 200) {
        const dataTrackType = getDataTrackType(actionType)
        triggerGtmEvent(
          dataTrackType,
          DataLayerEventCategory.MY_ACCOUNT,
          DataLayerGtmCustomEventName.TRACKING,
          unFollowModalMessage,
        )

        const optionalCustomizedParameters = getFollowOptionalCustomizedParameters({
          followType: actionType,
          entityId: id,
        })
        triggerGA4GtmEvent({
          category: 'follow',
          subcategory: 'result',
          action: 'sys',
          customized_parameters: {
            action_type: 'unfollow',
            trigger_point: 'my_account',
            ...optionalCustomizedParameters,
          },
        })
      }
    })
      .catch(function (error) {
        console.log(error)
      })
    dispatch(modalAction.updateUnFollowModalMessage('', '', '', ''))
    updateShowModal(false)
  }

  const cancelHandler = () => {
    dispatch(modalAction.updateUnFollowModalMessage('', '', '', ''))
    updateShowModal(false)
  }

  return showModal
    ? <CustomModal title={unFollowModalMessage}
    defaultButtonTitle={'UNFOLLOW'}
    cancelButtonTitle={'Cancel'}
    destinationUrl={''}
    onShow={ShowHideHandler}
    confirmHandler={unFollowHandler}
    cancenlButtonHandler={cancelHandler}
  />
    : null
}

export default CommonModal
