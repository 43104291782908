import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import ScmpLogo from 'assets/svg/logo-scmp-horizontal.svg'
import YPLogo from 'assets/svg/logo_yp_horizontal.svg'
import PostiesLogo from 'assets/svg/logo-posties.svg'
import { hideOnMedia, media } from 'utils/style'
import SmallScmpLogo from 'assets/svg/scmp-flag.svg'
import SmallYPLogo from 'assets/svg/yp-flag.svg'
import { FlexContainer } from 'components/flex'
import { UserHaloIcon } from 'components/myProfile'
import { AppState } from 'states/reducers'
import { DROP_DOWN_MENU_ITEMS, SCMP_COM_PROTOCOL, SCMP_COM_HOST, YP_PROTOCOL, YP_HOST, NODE_ENV } from 'config/app.config'
import {
  CORP_SUBCRIBER_TIER,
  DataLayerEventCategory,
  DataLayerEventAction,
  DataLayerGtmCustomEventName,
} from 'config/constants'
import DropDown from 'components/menu/DropDown'
import { ClickableItem, DropDownMenuItem } from 'components/menu/DropDownMenu'
import _find from 'lodash/find'
import _includes from 'lodash/includes'
import _reject from 'lodash/reject'
import _findIndex from 'lodash/findIndex'
import _set from 'lodash/set'
import { useHistory, Link } from 'react-router-dom'
import { onSignOutClick } from 'utils/common'
import { getUserLevel } from 'utils/user'
import { CustomModal } from 'components/modal'
import { triggerGA4GtmEvent, triggerGtmEvent } from 'utils/tracking'

const HeaderComponent: React.FunctionComponent = () => {
  const [isShowingMenu, toggleShowingMenu] = useState<boolean>(false)
  const scmpUrl = `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}`
  const { pageUrl } = useSelector((state: AppState) => state.application)
  const destination = useSelector((state: AppState) => state.application.redirectDestinationUrl)
  const isPianoSubscriberDataReady = useSelector((state: AppState) => state.userInfo.isPianoSubscriberDataReady)
  const isSubscriber = useSelector((state: AppState) => state.subscriptionStatus.isSubscriber)
  const userInfo = useSelector((state: AppState) => state.userInfo)
  const userLevel = getUserLevel(userInfo) || {}
  const history = useHistory()
  const corpSubscriberTier = useSelector((state: any) => state.application.corpSubscriberTier)
  const isCorpSubscriber = useSelector((state: any) => state.application.isCorpSubscriber)
  const contactUsUrl = userLevel.isYPUser ? `${scmpUrl}/yp/contact-us` : `${scmpUrl}/contact-us`
  const [showModal, isShowModal] = useState<boolean>(false)
  const modalTitle = 'Are you sure you want to <strong>log out</strong>?'

  const ShowHideHandler = () => {
    isShowModal(!showModal)
    triggerGtmEvent(
      DataLayerEventAction.ACCOUNT_LOGOUT_CANCEL,
      DataLayerEventCategory.MY_ACCOUNT,
      DataLayerGtmCustomEventName.TRACKING,
      '',
    )
  }

  const logOutButtonClicked = () => {
    isShowModal(true)
    triggerGtmEvent(
      DataLayerEventAction.ACCOUNT_LOGOUT_CLICK,
      DataLayerEventCategory.MY_ACCOUNT,
      DataLayerGtmCustomEventName.TRACKING,
      '',
    )
  }

  const onClickHandler = (item: ClickableItem) => {
    triggerGA4GtmEvent({
      category: 'myaccount',
      subcategory: '',
      action: 'click',
      customized_parameters: {
        category: item.ga4Category,
      },
    })

    if (item.key === 'logout') {
      logOutButtonClicked()
    } else if (item.target === '_blank') {
      window.open(item.url)
    } else {
      history.push(item.url)
    }
  }

  const getActiveItem = () => {
    return _find(DROP_DOWN_MENU_ITEMS, (item) => _includes(item.activeUrls, pageUrl))
  }

  const renderLogo = () => {
    if (userLevel.isYPUser) {
      return (
        <YPLogoLink href={`${YP_PROTOCOL}://${YP_HOST}`}>
          <LogoYP src={YPLogo} alt='Young Post Logo' hideOn={['mobile']}/>
          <LogoYP src={SmallYPLogo} alt='Young Post Logo' hideOn={['tablet', 'smallDesktop', 'mediumDesktop', 'largeDesktop']}/>
        </YPLogoLink>
      )
    }
    if (userLevel.isPostiesUser) {
      return (
        <PostiesLogoLink href={NODE_ENV === 'production' ? 'https://www.scmp.com/posties' : 'https://staging-scmp-pwa-v2.product-web.dev-2.scmp.tech/posties'}>
          <LogoPosties src={PostiesLogo} alt='Posties Logo'/>
        </PostiesLogoLink>
      )
    }
    return (
      <LogoLink href={`${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}`}>
        <Logo src={ScmpLogo} alt='scmpLogo' hideOn={['mobile']}/>
        <Logo src={SmallScmpLogo} alt='scmpLogo' hideOn={['tablet', 'smallDesktop', 'mediumDesktop', 'largeDesktop']}/>
      </LogoLink>
    )
  }

  const processedDropDownMenuItems: DropDownMenuItem[] = useMemo(() => {
    if (!DROP_DOWN_MENU_ITEMS || !isPianoSubscriberDataReady) return []

    let items = DROP_DOWN_MENU_ITEMS
    if (!isSubscriber || userLevel.isStaff) {
      items = _reject(items, { key: 'devices' })
    }

    if (userLevel.isYPUser) {
      const contactUsIndex = _findIndex(items, { key: 'contactUs' })
      if (contactUsIndex !== -1) {
        _set(items, `[${contactUsIndex}].url`, contactUsUrl)
      }
    }

    const showCorpSubscriberPage = [
      CORP_SUBCRIBER_TIER.T2,
      CORP_SUBCRIBER_TIER.T3,
      CORP_SUBCRIBER_TIER.T4,
      CORP_SUBCRIBER_TIER.C_SUITE,
      CORP_SUBCRIBER_TIER.EXPERT_NETWORK,
    ]

    if (!userLevel.isStaff && !(isCorpSubscriber && (_includes(showCorpSubscriberPage, corpSubscriberTier)))) {
      items = _reject(items, { key: 'groupSubscriberBenefits' })
    }

    return items
  }, [DROP_DOWN_MENU_ITEMS, isPianoSubscriberDataReady, isSubscriber, isCorpSubscriber, corpSubscriberTier])

  return (
    <HeaderContainer alignItem='center' wrap='nowrap'>
      <LongHeaderContainer justifyContent='space-between' wrap='nowrap'>
        <LHSContainer alignItem='center'>
        {renderLogo()}
        </LHSContainer>
        <RHSContainer>
          <DropDownMenuContainer hideOn={['smallDesktop', 'mediumDesktop', 'largeDesktop']}>
            <DropDown activeItem={getActiveItem()}
              items={processedDropDownMenuItems}
              menuCustomStyle={{
                marginTop: '40px',
                left: 'auto',
                right: '-33px',
              }}
              isOpen={isShowingMenu}
              updateIsOpen={toggleShowingMenu}
              onClickHandler={onClickHandler} />
          </DropDownMenuContainer>
          <Link to='/settings'>
            <UserHaloIcon size='small'/>
          </Link>
        </RHSContainer>
      </LongHeaderContainer>
      { showModal
        ? <CustomModal title={modalTitle} customButton={true} onShow={ShowHideHandler}>
            <ModalTopButton onClick={ShowHideHandler}>Cancel</ModalTopButton>
            <ModalBottomButton onClick={() => onSignOutClick(destination)}>Log Out</ModalBottomButton>
          </CustomModal>
        : null}
    </HeaderContainer>)
}

interface functioningProps {
  hideOn?: string[],
}

const HeaderContainer = styled(FlexContainer)<functioningProps>`
  max-width: 1280px;
  width: 100%;
  position: fixed;
  top: 0px;
  background-color: #fff;
  z-index: 1001;
`

const LongHeaderContainer = styled(FlexContainer)<functioningProps>`
  width: 100%;
  margin: 0;
  min-height: 49px;
  background-color: #fff;
  z-index: 1000;
  border-bottom: 2px solid #f2f2f2;
  ${media.tabletUp`
    margin: 0 28px;
  `}
`

const LHSContainer = styled(FlexContainer)`
`

const LogoLink = styled.a`
  height: 25px;
`

const RHSContainer = styled(FlexContainer)`
  align-items: center;
  margin-right: 14px;
`

const Logo = styled.img<functioningProps>`
  margin-left: 16px;
  ${({ hideOn }) => hideOn ? hideOnMedia(hideOn) : ''}
  ${media.tabletUp`
    height: 25px;
  `}
`

const YPLogoLink = styled.a`
  ${media.tabletUp`
    height: 25px;
  `}
`

const LogoYP = styled.img<functioningProps>`
  margin-left: 16px;
  ${({ hideOn }) => hideOn ? hideOnMedia(hideOn) : ''}
  ${media.tabletUp`
    height: 32px;
  `}
`

const LogoPosties = styled.img`
  height: 24px;
  ${media.tabletUp`
    height: 25px;
  `}
`

const PostiesLogoLink = styled.a`
  margin-left: 16px;
  display: flex;
`

const DropDownMenuContainer = styled(FlexContainer)<functioningProps>`
  position: relative;
  margin-right: 16px;
  ${({ hideOn }) => hideOn ? hideOnMedia(hideOn) : ''}
`

const ModalTopButton = styled.div`
  padding: 12px 0;
  width: 100%;
  border-radius: 2px;
  background-color: #FFCA05;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
`
const ModalBottomButton = styled.div`
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
  color: #4585FF;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
`

export default HeaderComponent
