import { css } from 'styled-components'

const mediaRanges: any = {
  mobile: {
    min: 0,
    max: 767,
  },
  tablet: {
    min: 768,
    max: 1023,
  },
  smallDesktop: {
    min: 1024,
    max: 1279,
  },
  mediumDesktop: {
    min: 1280,
    max: 1559,
  },
  largeDesktop: {
    min: 1560,
    max: 99999,
  },
}

const mediaMinSizes: any = {
  largeDesktopUp: 1560,
  mediumDesktopUp: 1280,
  smallDesktopUp: 1024,
  tabletUp: 768,
  mobileUp: 374,
  mobileUpRefer: 480,
}

const mediasAry: string[] = ['mobile', 'tablet', 'smallDesktop', 'mediumDesktop', 'largeDesktop']

const media = Object.keys(mediaMinSizes).reduce(
  (acc: any, label: any) => {
    acc[label] = (literals: TemplateStringsArray, ...placeholders: any[]) =>
      css`
        @media (min-width: ${mediaMinSizes[label]}px) {
          ${css(literals, ...placeholders)};
        }
      `.join('')
    return acc
  },
  {} as Record<
    keyof typeof mediaMinSizes,
    (l: TemplateStringsArray, ...p: any[]) => string
    >,
)

const hideOnMedia = (medias: (string| undefined)[]) => {
  if (medias) {
    return medias.reduce((acc: any, media: any) => {
      acc += `
      @media (min-width: ${mediaRanges[media].min}px) and (max-width: ${mediaRanges[media].max}px) {
        display none
      };`
      return acc
    }, '')
  }
}

const showOnMedia = (targetMedia: (string | undefined)[]) => {
  if (targetMedia) {
    return mediasAry.reduce((acc: any, media: any) => {
      if (targetMedia.includes(media)) {
        return acc
      }
      acc += `
      @media (min-width: ${mediaRanges[media].min}px) and (max-width: ${mediaRanges[media].max}px) {
        display none
      };`
      return acc
    }, '')
  }
}

const inputPlaceholderStyle = (style: string) => {
  if (style) {
    return `
      ::-webkit-input-placeholder { /* Edge */
        ${style}
      }
      :-ms-input-placeholder { /* Internet Explorer */
        ${style}
      }
      ::placeholder {
        ${style}
      }
    `
  }
}

export {
  media,
  hideOnMedia,
  showOnMedia,
  inputPlaceholderStyle,
}
