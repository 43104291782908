import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'
import { DataLayerEventCategory, DataLayerEventAction, DataLayerGtmCustomEventName } from 'config/constants'
import { triggerGA4GtmEvent, triggerGtmEvent } from 'utils/tracking'

type Props = {
  match: any,
  history: any,
  location: any,
}

const LoginSuccess: React.FunctionComponent<Props> = ({ ...Props }: Props) => {
  const location = Object.assign({}, useLocation())
  const queryString = new URLSearchParams(location.search)
  const signinCallbackUrl = queryString.get('signinCallbackUrl') || 'www.scmp.com'
  const destination = queryString.get('destination')
  const firstTimeRegister = queryString.get('firstTimeRegister')

  useEffect(() => {
    triggerGtmEvent(
      firstTimeRegister ? DataLayerEventAction.GOOGLE_REGISTER : DataLayerEventAction.GOOGLE_LOGIN_SUCCESS,
      DataLayerEventCategory.LOGIN,
      DataLayerGtmCustomEventName.LOGIN,
    )

    triggerGA4GtmEvent({
      category: 'login',
      subcategory: 'result',
      action: 'sys',
      customized_parameters: {
        method: 'google',
        result: firstTimeRegister ? 'register_success' : 'login_success',
      },
    })

    let redirectUrl = signinCallbackUrl
    if (destination) {
      redirectUrl += `&destination=${destination}`
    }

    setTimeout(() => {
      window.location.href = redirectUrl
    }, 3000)
  }, [])

  return (
    <LoginSuccessContainer>
      Loading...
    </LoginSuccessContainer>
  )
}

const LoginSuccessContainer = styled(FlexContainer)`
`

export default LoginSuccess
