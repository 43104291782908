import styled from 'styled-components'
import { inputPlaceholderStyle } from 'utils/style'

interface InputStyle {
  height?: string
  width?: string
  maxWidth?: string
  borderRadius?: string
  backgroundColour?: string
  fontSize?: string
  fontWeight?: string
  lineHeight?: string
  color?: string
  fontFamily?: string
  paddingLeft?: string
  marginBottom?: string
  marginTop?: string
  error?: string
}

interface DefaultButtonStyle {
  minWidth?: string
  maxWidth?: string
  borderRadius?: string
  borderColor?: string
  backgroundColour?: string
  padding?: string
  lineHeight?: string
  fontFamily?: string
  paddingLeft?: string
  marginBottom?: string
  color?: string
  fontSize?: string
  disabled?:boolean
  border?: string
}

export const InputComponent = styled.input<InputStyle>`
  height: ${props => (props.height ? props.height : '37px')};
  width:  ${props => (props.width ? props.width : '100%')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '272px')};
  border: 1px solid ${({ error }) => error ? '#EB4254' : '#CCCCCC'};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '2px')};
  background-color: ${props => (props.backgroundColour ? props.backgroundColour : '#fff')};
  font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '300')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '19px')};
  letter-spacing: 0.19px;
  color: ${props => (props.color ? props.color : '#333')};
  font-family: ${props => (props.fontFamily ? props.fontFamily : 'Roboto')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '10px')};
  margin-bottom:  ${props => (props.marginBottom ? props.marginBottom : '12px')};
  margin-top:  ${props => (props.marginTop ? props.marginTop : '2px')};
  box-sizing: border-box;
  ${inputPlaceholderStyle('color: #999')}
`
export const StyledButton = styled.div<DefaultButtonStyle>`
  min-width: ${props => (props.minWidth ? props.minWidth : '270px')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '272px')};;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '2px')};
  border-color: ${props => (props.borderColor ? props.borderColor : '#FFCA05')};
  background-color: ${props => (props.backgroundColour ? props.backgroundColour : '#FFCA05')};
  padding: ${props => (props.padding ? props.padding : '12px')};
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '16px')};
  text-align: center;
  font-family: ${props => (props.fontFamily ? props.fontFamily : 'Roboto')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '17px')};
  box-sizing: border-box;
  cursor: pointer;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  color: ${props => (props.color ? props.color : '#333')};
`

export const OutSideStyledButton = styled.div<DefaultButtonStyle>`
  border: ${props => (props.border ? props.border : 'none')};
  min-width: ${props => (props.minWidth ? props.minWidth : '270px')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '272px')};;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '2px')};
  border-color: ${props => (props.borderColor ? props.borderColor : '#FFCA05')};
  background-color: ${props => (props.backgroundColour ? props.backgroundColour : '#FFCA05')};
  padding: ${props => (props.padding ? props.padding : '12px')};
  font-size: ${props => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '16px')};
  text-align: center;
  font-family: ${props => (props.fontFamily ? props.fontFamily : 'Roboto')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '17px')};
  box-sizing: border-box;
  cursor: pointer;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  color: ${props => (props.color ? props.color : '#333')};
`
