import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'

import { FlexContainer } from 'components/flex'
import { FormErrorLabel } from 'components/formErrorLabel'

import { AppState } from 'states/reducers'
import { DataLayerEventCategory, DataLayerEventAction, DataLayerGtmCustomEventName, ErrorMessage } from 'config/constants'
import { media } from 'utils/style'
import { triggerGtmCustomEvent } from 'utils/tracking'

import PageContainer from 'components/PageContainer'
import { isValidEmail, isEmailWithPlusSign } from 'utils/formValidation'

import ConfirmEmailComponent from 'components/SignIn/ConfirmEmail'
import PasswordComponent from 'components/SignIn/Password'
import WithEmail from 'components/SignIn/WithEmail'
import SignInWithOption from 'components/SignIn/WithOption'
import ForgetPasswordComponent from 'components/SignIn/ForgetPassword'

import * as appAction from 'states/actions/application'
const maskEmailPhone = require('mask-email-phone')

type Props = {
  match: any,
  history: any,
}

const SubScribe: React.FunctionComponent<Props> = ({ ...Props }) => {
  const callbackUrl = useSelector((state: AppState) => state.signin.callbackUrl)
  const destination = useSelector((state: AppState) => state.application.redirectDestinationUrl)
  const callbackRegisterUrl = useSelector((state: AppState) => state.registration.callbackUrl)

  const dispatch = useDispatch()

  // const [isShowingPassword, toggleIsShowingPassword] = useState<boolean>(false)
  const [email, updateEmail] = useState<string>('')
  const [password, updatePassword] = useState<string>('')
  const [isLogining, updateIsLoginStatus] = useState<boolean>(false)
  const [isEmail, updateIsEmail] = useState<boolean>(false)
  const [description, updateDescription] = useState<string>('')
  const [withFacebook, updateWithFacebook] = useState<boolean>(true)
  const [withGoogle, updateWithGoogle] = useState<boolean>(true)
  const [errorMessage, updateErrorMessage] = useState<string>('')
  const [loginFail, updateLoginFail] = useState<boolean>(false)
  const [state, updateState] = useState<string>('')
  const [title, updateTitle] = useState<string>('Sign In / Register')

  const socialLoginHeader = 'From our records, you previously signed up using your social account. To log in, please connect using your social account below:'

  useEffect(() => {
    dispatch(appAction.updateFlowType('Subscribe'))
  }, [dispatch])

  const reset = () => {
    updateState('')
    updateErrorMessage('')
    updateDescription('')
  }

  const loginHandler = async () => {
    triggerGtmCustomEvent(DataLayerEventAction.EMAIL_ENTER_PASSWORD, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
    if (isLogining || !email || !password) {
      // block it if the input is empty, enhance later
      if (!email && password) {
        updateErrorMessage(ErrorMessage.LOGIN_MISSING_EMAIL)
      } else if (email && !password) {
        updateErrorMessage(ErrorMessage.LOGIN_MISSING_PASSWORD)
      } else if (!email && !password) {
        updateErrorMessage(ErrorMessage.LOGIN_MISSING_EMAIL_AND_PASSWORD)
      }
      return false
    }

    // avoid button spamming
    updateIsLoginStatus(true)

    try {
      // triggerGtmCustomEvent(DataLayerEventAction.EMAIL_LOGIN, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
      const response = await axios.post('/login', {
        username: email,
        password: password,
      })

      // handle login success case
      if (response.status === 200 && response.data.nonce) {
        const path = `${callbackUrl}${response.data.nonce}${destination ? '&destination=' + encodeURIComponent(destination) : ''}`
        // updateIsLoginStatus(false)
        updateLoginFail(false)
        window.location.href = path
        triggerGtmCustomEvent(DataLayerEventAction.EMAIL_ENTER_PASSWORD_SUCCESS, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN, path)
        triggerGtmCustomEvent(DataLayerEventAction.EMAIL_AUTH_SUCCESS, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN, path)
      } else {
        updateIsLoginStatus(false)
        triggerGtmCustomEvent(DataLayerEventAction.EMAIL_AUTH_FAIL, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
      }
    } catch (e: any) {
      console.log('an error has been thrown', e.response)
      triggerGtmCustomEvent(DataLayerEventAction.EMAIL_AUTH_FAIL, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
      triggerGtmCustomEvent(DataLayerEventAction.EMAIL_ENTER_PASSWORD_FAIL, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
      updateLoginFail(true)
      if (!isValidEmail(email)) { // check if not email. use username login
        updateIsEmail(false)
      }
      if (e.response.data && e.response.data.errorMessage) {
        updateErrorMessage(e.response.data.errorMessage)
      } else {
        updateErrorMessage(ErrorMessage.LOGIN_WRONG_EMAIL_AND_PASSWORD)
      }
      updateIsLoginStatus(false)
    }
  }

  const emailCheckHandler = () => {
    // not allow sign email with plus
    if (email && isEmailWithPlusSign(email)) {
      updateErrorMessage(ErrorMessage.REGISTER_WRONG_EMAIL)
      return false
    }
    updateErrorMessage('')
    return true
  }

  const handleRegisteredAccount = (code: any) => {
    reset()

    switch (code) {
      case 717:
        updateTitle('Connect using social')
        updateState('social')
        updateWithFacebook(false)
        break
      case 718:
        updateTitle('Connect using social')
        updateState('social')
        updateWithGoogle(false)
        break
      case 720:
        updateTitle('Connect using social')
        updateState('social')
        break
      case 721:
        updateState('email')
        updateTitle('Sign In / Register')
        updateIsLoginStatus(false)
        updateErrorMessage(ErrorMessage.LOGIN_EMAIL_DOMAIN_BLOCKED)
        break
      case 723:
        updateState('password')
        updateTitle('Enter your password')
        break
      case 724:
        triggerGtmCustomEvent(DataLayerEventAction.EMAIL_CHECK_INBOX, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
        window.location.href = `${callbackRegisterUrl}${maskEmailPhone(email)}&destination=${destination}`
        break
      case 725:
        updateTitle('Connect using social')
        updateState('social')
        updateWithGoogle(false)
        break
      case 726:
        updateTitle('Connect using social')
        updateState('social')
        updateWithFacebook(false)
        break
      case 727:
      case 728:
        updateTitle('Connect using social')
        updateState('social')
        break
      case 729:
        updateState('confirmEmail')
        updateTitle('Confirm your email')
        updateEmail(email)
        break
      default:
        break
    }
  }

  const emailSubmitHandler = async (recaptchaToken: string, create: boolean) => {
    if (create) {
      triggerGtmCustomEvent(DataLayerEventAction.EMAIL_CONFIRM_INPUT, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
    } else {
      triggerGtmCustomEvent(DataLayerEventAction.EMAIL_INPUT, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
    }

    if (isLogining || email === '') {
      updateErrorMessage(ErrorMessage.LOGIN_MISSING_EMAIL)
      return false
    }

    if (!emailCheckHandler()) {
      return false
    }

    if (!isValidEmail(email)) { // check if not email. use username login
      updateState('password')
      updateTitle('Enter your password')
      return false
    }

    // avoid button spamming
    updateIsLoginStatus(true)
    updateIsEmail(true)
    try {
      const response = await axios.post('/email/login', {
        username: email,
        recaptchaToken,
        create,
        channel: destination,
      }).catch((e) => {
        if (e.response.status === 401) {
          if (e.response.data.code === 'recaptcha-failed') {
            updateErrorMessage(ErrorMessage.FAIL_TO_VERIFY_RECAPTCHA)
          }
        }
        return e
      })
      if (response.status === 202 || response.status === 200) {
        updateIsLoginStatus(false)
        reset()

        switch (response.status) {
          case 202:
            handleRegisteredAccount(response.data.code)
            break
          case 200:
            triggerGtmCustomEvent(DataLayerEventAction.EMAIL_REGISTER, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
            triggerGtmCustomEvent(DataLayerEventAction.EMAIL_CHECK_INBOX_FIRST_TIME, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
            window.location.href = `${callbackRegisterUrl}${maskEmailPhone(email)}&destination=${destination}`
            break
        }
      } else {
        updateIsLoginStatus(false)
        triggerGtmCustomEvent(DataLayerEventAction.EMAIL_AUTH_FAIL, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
      }
    } catch (e) {
      updateIsLoginStatus(false)
      console.log('an error has been thrown')
    }
  }

  const onClickOKButton = () => {
    if (state === 'forgetPassword') {
      window.location.reload()
    }
  }

  const onClickBackHandler = (option: string) => {
    reset()

    switch (option) {
      case 'email':
        window.open(`${callbackUrl}${destination ? '&destination=' + encodeURIComponent(destination) : ''}`, '_self')
        break
      case 'confirmEmail':
      case 'password':
        updateState('email')
        updateTitle('Sign In / Register')
        break
    }
  }

  const onForgetPasswordLinkClick = () => {
    if (!isValidEmail(email)) { // check if not email. use username login
      dispatch(appAction.updateModalMessage(ErrorMessage.FORGOT_PASSWORD_WRONG_EMAIL))
      return false
    }
    updateState('forgetPassword')
    updateTitle('Forgot password')
  }

  const renderComponent = () => {
    switch (state) {
      case 'email':
        return <WithEmail email={email} disabled={isLogining} updateErrorMessage={updateErrorMessage} emailSubmitHandler={emailSubmitHandler} updateEmail={updateEmail} emailCheckHandler={emailCheckHandler} onClickBackHandler={onClickBackHandler} />
      case 'confirmEmail':
        return <ConfirmEmailComponent email={email} updateDescription={updateDescription} disabled={isLogining} emailSubmitHandler={emailSubmitHandler} onClickBackHandler={onClickBackHandler} updateErrorMessage={updateErrorMessage} />
      case 'password':
        return <PasswordComponent disabled={isLogining} loginHandler={loginHandler} email={email} updatePassword={updatePassword} password={password} onClickBackHandler={onClickBackHandler} onForgetPasswordLinkClick={onForgetPasswordLinkClick} loginFail={loginFail} updateEmail={updateEmail} isEmail={isEmail} />
      case 'social':
        return <SignInWithOption withEmail={false} headerText={socialLoginHeader} withFacebook={withFacebook} withGoogle={withGoogle} />
      case 'forgetPassword':
        return <ForgetPasswordComponent email={email} onClickOKButton={onClickOKButton} />
      default:
        return <WithEmail email={email} disabled={isLogining} updateErrorMessage={updateErrorMessage} emailSubmitHandler={emailSubmitHandler} updateEmail={updateEmail} emailCheckHandler={emailCheckHandler} onClickBackHandler={onClickBackHandler} />
    }
  }

  const generateFooter = () => {
    return true
  }

  return (
    <PageContainer id='subscribe-page' title={title} isLogoLink footerText={generateFooter()}>
      <SigninMethods className='subscribe-section' flexDirection='column' justifyContent='flex-start'>
        <SigninForm className='subscribe-section__signin-form signin-form' flexDirection='column'>
          {description && <Description>{description}</Description>}
          <ErrorMsgLabel>{errorMessage}</ErrorMsgLabel>
          {renderComponent()}
        </SigninForm>
      </SigninMethods>
    </PageContainer>
  )
}

interface DescriptionStyle {
  marginBottom?: string
}

const Description = styled.div<DescriptionStyle>`
  color: #222222;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '20px')};
  padding: 0px;
  ${media.tabletUp`
    padding: auto;
  `}
`

const SigninMethods = styled(FlexContainer)`
  max-width: 270px;
  min-width: 270px;
  flex: 1;
`

const ErrorMsgLabel = styled(FormErrorLabel)`
  z-index: 1;
  margin-bottom: 12px;
`
const SigninForm = styled(FlexContainer)`
  margin-bottom: 0px;
  ${media.tabletUp`
    margin-bottom: 20px;
  `}
`

export default SubScribe
