import styled from 'styled-components'
import React from 'react'
import { media } from 'utils/style'
import { NODE_ENV } from 'config/app.config'
import { ReactComponent as YpPlane } from '../../assets/svg/yp_plane.svg'

type Props = {
  trackingGTM: () => void,
}

const YPSubscriptionPageContent: React.FunctionComponent<Props> = ({ ...Props }) => {
  const { trackingGTM } = Props
  return (
    <>
      <YpWrapper>
        <StyledYpPlane />
        <YpHeader>Start your subscription today</YpHeader>
        <YpContent>Explore fun articles, news, learning and games, plus enjoy special discounts on <i>Young Post</i> events.</YpContent>
        <YpButton
          onClick={trackingGTM}
          href={NODE_ENV === 'production' ? 'https://subscribe.scmp.com/yp' : 'https://staging-rosetta.product-web.dev-2.scmp.tech/yp'} >Subscribe</YpButton>
      </YpWrapper>
    </>
  )
}

const YpWrapper = styled.div`
  max-width: 327px;
  margin: 0 auto;
  text-align: center;

  ${media.tabletUp`
    max-width: 542px;
  `}
`

const StyledYpPlane = styled(YpPlane)`
  margin-bottom: 16px;
  width: 71px;
  height: 80px;
  margin: 45px auto 16px;
  display: block;

  ${media.tabletUp`
    width: 94px;
    height: 106px;
    margin-top: 55px;
    margin-bottom: 24px;
  `}
`

const YpButton = styled.a`
  background: #FCFF79;
  border: 2px solid #000000;
  box-shadow: 0px 3px 0px #000000;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 51px;
  width: 182px;
  height: 51px;
  display: block;
  margin: 0 auto;
  text-align: center;
  color: black;
`

const YpHeader = styled.div`
  font-family: 'all-round-gothic', Merriweather;
  font-size: 24px;
  line-height: 120%;
  margin-bottom: 8px;
  font-weight: bold;
  color: black;

  ${media.tabletUp`
    font-size: 32px;
  `}
`

const YpContent = styled.div`
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 16px;
  color: #444;

  ${media.tabletUp`
    margin-bottom: 24px;
    font-size: 18px;
  `}
`

export default YPSubscriptionPageContent
