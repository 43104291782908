import React from 'react'
import styled from 'styled-components'

type Props = {
  passwordStrendth: number
}
const PasswordStrengthIndicator: React.FunctionComponent<Props> = ({ passwordStrendth = 0 }) => {
  const getPasswordStrength = (level: number): string => {
    if (level === 0) {
      return ''
    } else if (level === 1) {
      return 'Weak'
    } else if (level === 2) {
      return 'Medium'
    } else if (level >= 3) {
      return 'Strong'
    }

    return ''
  }
  const getPasswordLevelColor = (level: number): string => {
    if (level <= 1) {
      return 'red'
    } else if (level === 2) {
      return 'orange'
    } else if (level >= 3) {
      return '#34B8B5'
    }

    return 'red'
  }

  return (
    <>
      <PasswordCheckerBarContainer>
        <PasswordCheckerBar passwordStrendth={passwordStrendth} strengthColor={getPasswordLevelColor(passwordStrendth)} />
      </PasswordCheckerBarContainer>
      <PasswordCheckerLabel>
        password strength: <PasswordLevel strengthColor={getPasswordLevelColor(passwordStrendth)}>{getPasswordStrength(passwordStrendth)}</PasswordLevel>
      </PasswordCheckerLabel>
    </>
  )
}

const PasswordCheckerBarContainer = styled.div`
  min-width: 135px;
  width: 135px;
  min-height: 2px;
  height: 2px;
  background-color: #D8D8D8;
  margin-bottom: 4px;
`

interface PasswordCheckerBarProps {
  passwordStrendth: number
  strengthColor?: string
}
const PasswordCheckerBar = styled.div<PasswordCheckerBarProps>`
  min-height: 2px;
  height: 2px;
  width: ${({ passwordStrendth }) => passwordStrendth * 33}%;
  background: ${({ strengthColor }) => strengthColor || 'red'};
`

const PasswordCheckerLabel = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 0.14px;
  margin-bottom: 12px;
`

interface PasswordLevelProps {
  strengthColor?: string
}
const PasswordLevel = styled.span<PasswordLevelProps>`
  color: ${({ strengthColor }) => strengthColor || 'red'};
`

export default PasswordStrengthIndicator
