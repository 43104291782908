import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import map from 'lodash/map'
import get from 'lodash/get'
import fromUnixTime from 'date-fns/fromUnixTime'
import format from 'date-fns/format'
import { Link } from 'react-router-dom'

import UPDATE_PROFILE from 'universal/apollo/d8/UPDATE_PROFILE.gql'
import { useMutation } from '@apollo/react-hooks'

import { FlexContainer } from 'components/flex'
import { ReactComponent as Edit } from 'assets/svg/icon_edit.svg'
import { hasValue } from 'utils/formValidation'
import { media } from 'utils/style'
import { ConnectedPanel } from 'components/ConnectedPanel'

import UserImage from './UserImage'
import InputField from './form/InputField'
import SelectField from './form/SelectField'
import GenderField from './form/GenderField'
import DateSelectField from './form/DateSelectField'

import { AppState } from 'states/reducers'

import { ErrorMessage } from 'config/constants'
import * as modalAction from 'states/actions/application'

import { refreshJWT } from 'utils/user'

type Props = {
  userCreateDate: any,
  email: string,
  userPhoto: any,
  profile: any,
  onSaveHandler: (response: any) => void,
  profileIds: any,
  className?: string,
  isSocialConnected: boolean,
}
const ProfileComponent: React.FunctionComponent<Props> = ({ className, userCreateDate, email, userPhoto, profile, profileIds, isSocialConnected, onSaveHandler }) => {
  const dispatch = useDispatch()
  const sinceJoin = format(fromUnixTime(userCreateDate), 'MMM yyyy')
  const options = useSelector((state: AppState) => state.application.selectOption)
  const isScmpUser = useSelector((state: AppState) => state.userInfo.isScmpUser)
  const [newUserPhoto, setNewUserPhoto] = useState('')
  const [value, setValue] = useState<any>({
    uid: { targetId: profileIds.uid && profileIds.uid.toString() },
    fieldPronouns: profile.fieldPronouns || '',
    fieldFirstName: profile.fieldFirstName || '',
    fieldLastName: profile.fieldLastName || '',
    fieldMobile: profile.fieldMobile || '',
    fieldBirthday: {
      value: get(profile, 'fieldBirthday.value', ''),
    },
    fieldCountry: profile.fieldCountry || '',
    fieldQualification: profile.fieldQualification || '',
    fieldPosition: profile.fieldPosition || '',
    fieldJobFunction: profile.fieldJobFunction || '',
    fieldIndustry: profile.fieldIndustry || '',
    fieldAnnualIncome: profile.fieldAnnualIncome || '',
    fieldLastSocialLoginTime: profile.fieldLastSocialLoginTime || 0,
  })
  const [error, setError] = useState<any>({})

  const [updateProfile, { loading: mutationLoading }] = useMutation(UPDATE_PROFILE, {
    onCompleted: (data) => {
      // refresh user token to present latest user profile
      refreshJWT()
      onSaveHandler(data)
    },
    onError: () => {
      dispatch(modalAction.updateModalMessage(ErrorMessage.SOMETHING_GOES_WRONG))
    },
    variables: {
      uid: profileIds.uid && profileIds.uid.toString(),

      // for updating ProfileMain
      profileMainId: profileIds.profileMainId || '',
      createMain: !profileIds.profileMainId && !!newUserPhoto,
      updateMain: !!profileIds.profileMainId && !!newUserPhoto,
      createProfile: !profileIds.profileProfileId,
      updateProfile: !!profileIds.profileProfileId,
      userPhotoB64: newUserPhoto,

      // for updating ProfileProfile
      profileProfileId: profileIds.profileProfileId || '',
      createInput: value,
      updateInput: value,
    },
  })

  const onChange = (field: string, newVal: string, mandatory: boolean) => {
    setValue({ ...value, [field]: newVal })
    setError({ ...error, [field]: mandatory && !hasValue(newVal) ? 'This field is mandatory' : null })
  }

  const getSelectOptions = (options: any[]) => {
    const selectOptions: any[] = []
    selectOptions.push(<option key='' value=''>- Please select -</option>)
    return selectOptions.concat(map(options, option => (
      <option key={option.key} value={option.key}>{option.label}</option>
    )))
  }

  const onClickSave = () => {
    if (!value.fieldFirstName || !value.fieldLastName || !value.fieldCountry) {
      dispatch(modalAction.updateModalMessage(ErrorMessage.EDIT_PROFILE_MANDATORY_FIELD))
      return false
    }

    // avoid clicking too fast, will not fire again if mutate in process
    if (mutationLoading) {
      return false
    }

    updateProfile()
  }

  return (
    <PageContainer id='edit-profile' flexDirection='column' alignItem='center' justifyContent='space-between'>
      <UserImage userPhoto={userPhoto} onChange={val => setNewUserPhoto(val)} isSocialConnected={isSocialConnected} />
      <UpperContainer flexDirection='column' alignItem='center'>
        <EmailTitle>E-mail Address</EmailTitle>
        <EmailAndEditContainer><Email>{email}</Email> { !isScmpUser && <Link to='/settings/change-email-address'><IconEdit></IconEdit></Link> }</EmailAndEditContainer>
        <Sincejoined> Joined since { sinceJoin }</Sincejoined>
        <ChangePw href="/settings/change-password"> Change password </ChangePw>
        <ConnectedPanel className='edit-profile'/>
      </UpperContainer>
      <FormContainer>
        <InfoContainer className="edit-profile__personal">
          <FormTitle>
            PERSONAL INFORMATION
          </FormTitle>
          <InputContainer>
            <InputField
              title="First Name"
              mandatory
              value={value.fieldFirstName}
              error={error.fieldFirstName}
              disabled={isSocialConnected}
              onChange={e => onChange('fieldFirstName', e.target.value, true)}
            />
            <InputField
              title="Last Name"
              mandatory
              value={value.fieldLastName}
              error={error.fieldLastName}
              disabled={isSocialConnected}
              onChange={e => onChange('fieldLastName', e.target.value, true)}
            />
            <DateSelectField
              title="Birthday"
              value={value.fieldBirthday.value}
              onChange={val => setValue({ ...value, fieldBirthday: { value: val } })}
            />
            <SelectField
              title="Country / Region"
              mandatory
              value={value.fieldCountry}
              error={error.fieldCountry}
              onChange={e => onChange('fieldCountry', e.target.value, true)}
            >
              { getSelectOptions(options.country) }
            </SelectField>
            <GenderField
              className="edit-profile__gender"
              title="Pronouns"
              value={value.fieldPronouns}
              onChange={val => onChange('fieldPronouns', val, false)}
            />
          </InputContainer>
        </InfoContainer>
        <InfoContainer className="edit-profile__qualification">
          <FormTitle>EDUCATION</FormTitle>
          <InputContainer>
            <SelectField
              title="Qualification"
              value={value.fieldQualification}
              onChange={e => onChange('fieldQualification', e.target.value, false)}
            >
              { getSelectOptions(options.qualification) }
            </SelectField>
          </InputContainer>
        </InfoContainer>
        <InfoContainer className="edit-profile__job">
          <FormTitle>PROFESSIONAL</FormTitle>
          <InputContainer>
            <SelectField
              title="Position"
              value={value.fieldPosition}
              error={error.fieldPosition}
              onChange={e => onChange('fieldPosition', e.target.value, false)}
            >
              { getSelectOptions(options.position) }
            </SelectField>
            <SelectField
              title="Job Function"
              value={value.fieldJobFunction}
              error={error.fieldJobFunction}
              onChange={e => onChange('fieldJobFunction', e.target.value, false)}
            >
              { getSelectOptions(options.jobFunction) }
            </SelectField>
            <SelectField
              title="Company Industry"
              value={value.fieldIndustry}
              error={error.fieldIndustry}
              onChange={e => onChange('fieldIndustry', e.target.value, false)}
            >
              { getSelectOptions(options.industry) }
            </SelectField>
            <SelectField
              title="Annual Income"
              value={value.fieldAnnualIncome}
              onChange={e => onChange('fieldAnnualIncome', e.target.value, false)}
            >
              { getSelectOptions(options.annualIncome) }
            </SelectField>
          </InputContainer>
        </InfoContainer>
        <SaveButton alignItem='center' justifyContent='center' onClick={() => onClickSave()}>SAVE</SaveButton>
      </FormContainer>
    </PageContainer>
  )
}

const PageContainer = styled(FlexContainer)`
  max-width: 425px;
  min-width: 320px;
  background-color: #f2f2f2;
  margin: 0 auto;
  position: relative;
  ${media.tabletUp`
    max-width: 100%;
    background-color: #ffffff;
    position: static;
  `}
`

const UpperContainer = styled(FlexContainer)`
  width: 100%;
  padding: 20px 0 20px 0;
  text-align: center;
  background-color: #ffffff;
  margin-top: 145px;
  ${media.tabletUp`
    margin-top: 20px;
    padding: 0;
  `}
  ${media.smallDesktopUp`
    margin-top: 20px;
  `}
`

const EmailAndEditContainer = styled(FlexContainer)`
  margin-top: 8px;
  ${media.tabletUp`
    margin-top: 12px;
  `}
`

const EmailTitle = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`

const Email = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.19px;
  line-height: 19px;
`

const Sincejoined = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
  color: #999999;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`

const ChangePw = styled.a`
  cursor: pointer;
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
`

const FormContainer = styled.div`
  box-sizing: border-box;
  margin: 8px 8px 0 8px;
  ${media.tabletUp`
    padding: 0 0 48.5px 0;
    margin: 40px 0 0 0;
    max-width: 359px;
  `}
`

const FormTitle = styled.div`
  color: #222222;
  font-family: 'Roboto Condensed';
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  text-align: left;
`

const InfoContainer = styled.div`
  padding: 12px 12px 20px 12px;
  background-color: #ffffff;
  box-sizing: border-box;
  width: 100%;
  margin-top: 8px;
  &.edit-profile__personal,
  &.edit-profile__qualification,
  &.edit-profile__job {
    ${media.tabletUp`
      margin-top: 40px;
      padding: 0;
  `}
  }
  &.edit-profile__personal {
    margin-top: 0;
  }
`

const InputContainer = styled(FlexContainer)`
  & > * {
    width: 100%;
    margin-top: 20px;
  }

  flex-direction: column;
  align-items: center;
  ${media.tabletUp`

    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    &.center {
      justify-content: center;
    }
  `}
`

const SaveButton = styled(FlexContainer)`
  margin: 40px auto 20px auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 251px;
  height: 44px;
  border: 1px solid #4585FF;
  border-radius: 2px;
  cursor: pointer;
  color: #4585FF;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
`

const IconEdit = styled(Edit)`
  height: 19px;
  width: 19px;
  margin-left: 4px;
`

export default ProfileComponent
