import _isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'
import { Spinner } from 'components/Loading'

import { DataLayerEventAction, DataLayerEventCategory, DataLayerEventLabel, DataLayerGtmCustomEventName } from 'config/constants'

import * as manageAction from 'states/actions/manage'

import { media } from 'utils/style'
import { SocialLoginMethod, triggerGA4GtmEvent, triggerGtmEvent } from 'utils/tracking'

type Props = {
  mediaIcon?: string,
  mediaName?: string,
  indent?: string,
  connectedName: SocialLoginMethod,
  authContent?: any,
}

const MediaButton: React.FunctionComponent<Props> = ({ ...Props }) => {
  const dispatch = useDispatch()
  const [isConnected, toogleConnected] = useState<boolean>(false)

  useEffect(() => {
    if (!_isEmpty(Props.authContent) && Props.authContent.name) {
      toogleConnected(true)
    } else {
      toogleConnected(false)
    }
  }, [Props.authContent])

  const onLinkageClick = (connectedName: SocialLoginMethod) => {
    const query = window.location.search
    const path = `/connect/${connectedName}`
    connectedName === 'facebook' && triggerGtmEvent(
      DataLayerEventAction.SUMBIT_COMMENT,
      DataLayerEventCategory.COMMENT,
      DataLayerGtmCustomEventName.COMMENT,
      DataLayerEventLabel.CLICK_CONNECT_FACEBOOK,
    )
    connectedName === 'google' && triggerGtmEvent(
      DataLayerEventAction.SUMBIT_COMMENT,
      DataLayerEventCategory.COMMENT,
      DataLayerGtmCustomEventName.COMMENT,
      DataLayerEventLabel.CLICK_CONNECT_GOOGLE,
    )
    connectedName === 'apple' && triggerGtmEvent(
      DataLayerEventAction.SUMBIT_COMMENT,
      DataLayerEventCategory.COMMENT,
      DataLayerGtmCustomEventName.COMMENT,
      DataLayerEventLabel.CLICK_CONNECT_APPLE,
    )

    window.location.href = `${path}${query}`
  }

  const onUnlinkClick = (id: string, name: SocialLoginMethod) => {
    dispatch(manageAction.updateUnlinkMedia(id, name))
  }

  const handleConnectButtonClick = () => {
    triggerGA4GtmEvent({
      category: 'login',
      subcategory: 'social_connect',
      action: 'click',
      customized_parameters: {
        method: Props.connectedName,
      },
    })
    onLinkageClick(Props.connectedName)
  }

  return (
    <MediaButtonContainer justifyContent='space-between'>
      <MediaLeft justifyContent='flex-end' alignItem='center'>
        <MediaIcon connected={isConnected} svg={Props.mediaIcon}></MediaIcon>
        <MediaName connected={isConnected}>{Props.mediaName}</MediaName>
      </MediaLeft>
      <MediaRight alignItem='center'>
        { isConnected
          ? <Connected onClick={() => onUnlinkClick(Props.authContent.mediaId, Props.connectedName)}>
            {Props.authContent && Props.authContent.name}
          </Connected>
          : <ConnectButton onClick={handleConnectButtonClick}>
            { Props.authContent ? 'CONNECT' : <SpinnerIcon /> }
          </ConnectButton>
        }
      </MediaRight>
    </MediaButtonContainer>
  )
}

export default MediaButton

interface CommonStyle {
  connected: boolean
  svg?: string
}

const MediaButtonContainer = styled(FlexContainer)`
  width: 100%;
  min-height: 32px;
  border-bottom: 0px;
  justify-content: space-between;
  margin: 20px;
  ${media.tabletUp`
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 24px 0px;
  `}
`

const MediaLeft = styled(FlexContainer)`
  justify-content: flex-start;
`

const MediaRight = styled(FlexContainer)`
  justify-content: flex-end;
  min-width: 113px;
`

const MediaName = styled.div<CommonStyle>`
  color: #001246;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  color: ${props => props.connected ? '#001246' : '#B2B2B24C'};
`

const MediaIcon = styled.div<CommonStyle>`
  background-color: ${props => props.connected ? '#001246' : '#0000004C'};
  mask: url(${props => props.svg}) no-repeat 100% 100%;
  -webkit-mask-image: url(${props => props.svg}) no-repeat 100% 100%;
  mask-size: contain;
  -webkit-mask-size: contain;
  width: 20px;
  height: 20px;
  margin-right: 19px;
`

const ConnectButton = styled.div`
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  height: 16px;
  width: 100%;
  text-align: center;
  border: 1px solid #4585FF;
  border-radius: 2px;
  padding: 7px 24px 7px 23px;
  font-weight: 100;
  cursor: pointer;
`

const Connected = styled(FlexContainer)`
  color: #4585FF;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  font-weight: 100;
  display: inline-block;
  cursor: pointer;
  &:after {
    content: '';
    display: inline-block;
    margin-left: 20px;
    margin-right: 4px;
    margin-bottom: 1px;
    width: 7px;
    height: 14px;
    border: solid #4585FF;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`

const SpinnerIcon = styled(Spinner)`
  height: 16px;
`
