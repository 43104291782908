import { useQuery } from '@apollo/react-hooks'
import dayjs from 'dayjs'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import Events from 'components/CorporateSubscription/Events'
import KeywordAlerts from 'components/CorporateSubscription/KeywordAlerts'
import Reports from 'components/CorporateSubscription/Reports'

import { CORP_SUBCRIBER_TIER, DataLayerEventCategory, DataLayerGtmCustomEventName, DataLayerEventAction } from 'config/constants'

import { AppState } from 'states/reducers'
import * as userInfoActions from 'states/actions/user-info'

import GET_CORP_SUBSCRIBER_EVENTS from 'universal/apollo/contentService/GET_CORP_SUBSCRIBER_EVENTS.gql'
import GET_CORP_SUBSCRIBER_PDF from 'universal/apollo/contentService/GET_CORP_SUBSCRIBER_PDF.gql'
import GET_USER_CONTEXT from 'universal/apollo/d8/GET_USER_CONTEXT.gql'

import { media } from 'utils/style'
import { triggerGtmEvent } from 'utils/tracking'
import { getUserLevel } from 'utils/user'

import { GetCorpSubscriberEventsQuery, SortOrderEnum } from 'typings/contentService/graphql'

const CorporateSubscriptionContainer: React.FunctionComponent = () => {
  const [events, updateEvents] = useState<NonNullable<GetCorpSubscriberEventsQuery['events']>>([])
  const [roundTables, updateRoundTables] = useState<NonNullable<GetCorpSubscriberEventsQuery['roundTables']>>([])
  const [reports, updateReports] = useState<any[]>([])
  const [keywordAlertsItems, updateKeywordAlertsItems] = useState<any[]>([])

  const corpSubscriberTier = useSelector((state: any) => state.application.corpSubscriberTier)
  const isCorpSubscriber = useSelector((state: any) => state.application.isCorpSubscriber)
  const userInfo = useSelector((state: AppState) => state.userInfo)
  const userLevel = getUserLevel(userInfo) || {}

  const [loading, updateLoading] = useState<boolean>(true)

  const dispatch = useDispatch()

  // Query
  const { data: eventsResponse, loading: eventsLoading } = useQuery<GetCorpSubscriberEventsQuery>(GET_CORP_SUBSCRIBER_EVENTS, {
    context: {
      clientName: 'contentserviceNoCredentials',
    },
    variables: {
      startExpireDate: dayjs().format(),
      orderBy: SortOrderEnum.Desc,
    },
  })

  const { data: reportsResponse, loading: reportsLoading } = useQuery(GET_CORP_SUBSCRIBER_PDF, {
    context: {
      clientName: 'contentserviceNoCredentials',
    },
    variables: {
      limit: 100,
    },
  })

  const { data: keywordAlertsResponse, loading: keywordAlertsLoading } = useQuery(GET_USER_CONTEXT, {
    variables: {
      typeValue: 'preferences',
    },
  })

  useEffect(() => {
    updateReports(_get(reportsResponse, 'fileDownloads', []))
  }, [reportsResponse])

  useEffect(() => {
    if (keywordAlertsResponse !== undefined) {
      dispatch(userInfoActions.updateUserKeywordAlerts(_get(keywordAlertsResponse, 'currentUserContext.disableKeywordAlerts', {})))
      updateKeywordAlertsItems(_get(keywordAlertsResponse, 'currentUserContext.reverseUidProfile.entities[0].fieldKeywordAlerts', []))
    }
  }, [keywordAlertsResponse])

  useEffect(() => {
    updateEvents(eventsResponse?.events ?? [])
    updateRoundTables(eventsResponse?.roundTables ?? [])
  }, [eventsResponse])

  useEffect(() => {
    if (isCorpSubscriber !== null && corpSubscriberTier !== null && !eventsLoading && !reportsLoading && !keywordAlertsLoading) {
      updateLoading(false)
    }
  }, [isCorpSubscriber, corpSubscriberTier, eventsLoading, reportsLoading, keywordAlertsLoading])

  useEffect(() => {
    triggerGtmEvent(
      DataLayerEventAction.GROUP_SUBSCRIPTION_IMPRESSION,
      DataLayerEventCategory.MY_ACCOUNT,
      DataLayerGtmCustomEventName.TRACKING)
  }, [])

  const isShowEventAndWebinar = (corpSubscriberTier: string): boolean => {
    return _includes([
      CORP_SUBCRIBER_TIER.T3,
      CORP_SUBCRIBER_TIER.T4,
      CORP_SUBCRIBER_TIER.C_SUITE,
      CORP_SUBCRIBER_TIER.EXPERT_NETWORK,
    ], corpSubscriberTier)
  }

  const isShowRoundTable = (corpSubscriberTier: string): boolean => {
    return _includes([
      CORP_SUBCRIBER_TIER.C_SUITE,
    ], corpSubscriberTier)
  }

  const isShowKeyWordAlert = (corpSubscriberTier: string): boolean => {
    return _includes([
      CORP_SUBCRIBER_TIER.C_SUITE,
      CORP_SUBCRIBER_TIER.EXPERT_NETWORK,
    ], corpSubscriberTier)
  }

  return (
    <PageContainer id='corporate-subscription-container'>
      {/* <Newsletters /> */}
      {/* <SeparateLine /> */}
      { !loading && <>
        {isShowKeyWordAlert(corpSubscriberTier) && keywordAlertsItems.length > 0 &&
          <>
            <KeywordAlertsContainer>
              <KeywordAlerts keywordAlertsItems={keywordAlertsItems}/>
            <SeparateLine />
            </KeywordAlertsContainer>
          </>
        }
        <ReportsContainer>
          <Reports reports={reports} />
        </ReportsContainer>
        { (userLevel.isStaff || isShowEventAndWebinar(corpSubscriberTier)) && events.length > 0 && <>
          <SeparateLine />
          <EventsContainer>
            <Events events={events} title='Flagship Events & Webinars' />
          </EventsContainer>
        </> }
        { (isShowRoundTable(corpSubscriberTier)) && roundTables.length > 0 && <>
            <SeparateLine />
            <EventsContainer>
              <Events events={roundTables} title='The C-suite' />
            </EventsContainer>
          </> }
      </>}
    </PageContainer>
  )
}

const KeywordAlertsContainer = styled.div`
  margin-top: 24px;
  padding-bottom: 40px;
  ${media.tabletUp`
    margin-top: 0;
    padding-bottom: 49.1px;
  `}
`

const ReportsContainer = styled.div`
  padding-bottom: 40.5px;
`

const PageContainer = styled.div`
  display:flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 16px;
  padding-right: 16px;
  width: 320px;
  ${media.mobileUp`
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
  `}
  ${media.tabletUp`
    margin: 0;
    padding: 0;
    width: 680px;
  `}
`

const EventsContainer = styled.div`
  margin-top: 32px;
  ${media.tabletUp`
    margin-top: 45px;
  `}
`

const SeparateLine = styled.div`
  position: static;
  width: 100%;
  border: 1px solid #000000;
  transform: rotate(-0.08deg);
`

export default CorporateSubscriptionContainer
