import { useMutation, useQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'
import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'
import { NewsletterItem, NewsletterWidget } from 'components/manage/newsletter'
import { Loading } from 'components/Loading'

import { SCMP_COM_HOST, SCMP_COM_PROTOCOL } from 'config/app.config'

import GET_NEWSLETTERS_BY_IDS from 'universal/apollo/contentService/GET_NEWSLETTERS_BY_IDS.gql'
import UPDATE_TERMS_AND_PREFERENCES from 'universal/apollo/d8/UPDATE_TERMS_AND_PREFERENCES.gql'

import { AppState } from 'states/reducers'
import * as userInfoActions from 'states/actions/user-info'

import { GetNewslettersByIdsQuery } from 'typings/contentService/graphql'

import { media } from 'utils/style'
import { triggerGA4GtmEvent } from 'utils/tracking'

const NewsletterList: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const {
    optIn,
    optInId,
    optIn3rd,
  } = useSelector((state: AppState) => state.userInfo)
  const activeNewsletterIds = useSelector((state: AppState) => state.manage.newsletterIds, shallowEqual)
  const getManagedNewsletterIdsReady = useSelector((state: AppState) => state.manage.getManagedNewsletterIdsReady)
  const [updateTermsAndPreferences] = useMutation(UPDATE_TERMS_AND_PREFERENCES, {
    onCompleted: () => {
    },
    onError: () => {
      alert('Sorry, something is wrong. Please try again.')
    },
    variables: {
      userId: optInId,
      fieldOptInScmp: optIn ? '1' : '0',
      fieldOptIn3rd: optIn3rd ? '1' : '0',
    },
  })

  const NewsletterListItem: any[] = []
  const { loading, data } = useQuery<GetNewslettersByIdsQuery>(GET_NEWSLETTERS_BY_IDS, {
    variables: {
      filter: {
        entityIds: activeNewsletterIds,
      },
    },
    context: {
      clientName: 'contentserviceNoCredentials',
    },
    skip: activeNewsletterIds.length === 0,
  })
  if (!getManagedNewsletterIdsReady || loading) {
    return <LoadingContainer></LoadingContainer>
  }
  const newsletters = _get(data, 'newsletters', [])

  if (newsletters) {
    newsletters.forEach((newsletter: any) => {
      if (!newsletter.hideInAccApp) {
        const newsletterItem = {
          entityId: newsletter.entityId,
          entityLabel: newsletter.entityLabel,
          name: newsletter.name,
          summary: newsletter.summary,
        }
        NewsletterListItem.push(<NewsletterItem key={newsletter.entityId} newsletter={newsletterItem}></NewsletterItem>)
      }
    })
  }

  const onCheckboxOptInClick = async () => {
    await dispatch(userInfoActions.updateUserOptIn(!optIn))
    updateTermsAndPreferences()
    const updatedStatus = optIn ? 'on' : 'off'
    triggerGA4GtmEvent({
      category: 'myaccount',
      subcategory: 'notification',
      action: 'sys',
      customized_parameters: {
        notification_type: 'marketing message from scmp',
        status: updatedStatus,
      },
    })
  }
  const onCheckboxOptIn3rdClick = async () => {
    await dispatch(userInfoActions.updateUser3rdOptIn(!optIn3rd))
    updateTermsAndPreferences()
    const updatedStatus = optIn3rd ? 'on' : 'off'
    triggerGA4GtmEvent({
      category: 'myaccount',
      subcategory: 'notification',
      action: 'sys',
      customized_parameters: {
        notification_type: 'marketing message from scmp business partners',
        status: updatedStatus,
      },
    })
  }

  return (
    <NewsletterListContainer>
      <NewslettersBackgroundContainer flexDirection='column' alignItem='center'>
        {NewsletterListItem.length > 0
          ? (NewsletterListItem)
          : (<React.Fragment>
            <NewsletterPlaceHolderText>Your newsletters will show here.</NewsletterPlaceHolderText>
            <StartExploringButton
              href={`${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}/newsletters`}
              onClick={() => {
                triggerGA4GtmEvent({
                  category: 'nl',
                  subcategory: 'entry',
                  action: 'click',
                  customized_parameters: {
                    trigger_point: 'my_account_banner',
                    internal_campaign: '',
                  },
                })
              }}
            >
              START EXPLORING
            </StartExploringButton>
          </React.Fragment>)}
      </NewslettersBackgroundContainer>
      <NewsletterOptsIn flexDirection='row' alignItem='center'>
        <NewsletterOptsLeft>I consent to receive marketing messages from South China Morning Post.</NewsletterOptsLeft>
        <NewsletterOptsRight>
          <NewsletterOptsLabel className="switch">
            <NewsletterOptsInput
              type="checkbox"
              checked={optIn === undefined ? false : !optIn}
              onChange={() => onCheckboxOptInClick()}
              className="checkbox"></NewsletterOptsInput>
            <NewsletterOptsSlider className="slider"></NewsletterOptsSlider>
          </NewsletterOptsLabel>
        </NewsletterOptsRight>
      </NewsletterOptsIn>
      <Divider />
      <NewsletterOptsIn flexDirection='row' alignItem='center'>
        <NewsletterOptsLeft>I consent to receive marketing messages from South China Morning Post business partners.</NewsletterOptsLeft>
        <NewsletterOptsRight>
          <NewsletterOptsLabel className="switch">
            <NewsletterOptsInput
              type="checkbox"
              checked={optIn3rd === undefined ? false : !optIn3rd}
              onChange={() => onCheckboxOptIn3rdClick()}
              className="checkbox"></NewsletterOptsInput>
            <NewsletterOptsSlider className="slider"></NewsletterOptsSlider>
          </NewsletterOptsLabel>
        </NewsletterOptsRight>
      </NewsletterOptsIn>
      <NewsletterWidget></NewsletterWidget>
    </NewsletterListContainer>
  )
}

export default NewsletterList

const NewslettersBackgroundContainer = styled(FlexContainer)`
  max-width: 680px;
  background-color: #F2F2F2;
  padding: 4px 0px;
  ${media.tabletUp`
    width: 100%;
  `}
`

const NewsletterListContainer = styled.div`
  padding-bottom: 110px;
  ${media.tabletUp`
    width: 100%;
    padding-bottom: 0px;
  `}
`

const NewsletterOptsIn = styled(FlexContainer)`
  margin: 20px;
  ${media.tabletUp`
    max-width: 100%;
  `}
  .switch input {
    display: none;
  }
  .switch {
    display: inline-block;
    position: relative;
    height: 31px;
    width: 51px;
    min-width: 51px;
  }
  .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
    border-radius: 34px;
  }
  .slider:before {
    background-color: #fff;
    bottom: 2px;
    content: "";
    height: 27px;
    left: 2px;
    position: absolute;
    transition: .2s;
    width: 27px;
    border-radius: 50%;
  }

  .checkbox:checked + .slider {
    background-color: #4585FF;
  }
  .checkbox:checked + .slider:before {
    transform: translateX(20px);
  }
`

const NewsletterOptsRight = styled.div`
  margin-left: 24px;
  height: 31px;
`
const NewsletterOptsLeft = styled.div`
  flex: 1 1 0;
  font-size: 14px;
  line-height: 16px;
  color: #222222;
  font-family: Roboto;
  letter-spacing: 0;
`
const NewsletterOptsLabel = styled.label``
const NewsletterOptsInput = styled.input``
const NewsletterOptsSlider = styled.span``

const LoadingContainer = styled(Loading)`
  margin-top: 20px;
`

const NewsletterPlaceHolderText = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin-top: 20px;
`

const StartExploringButton = styled.a`
  border: 1px solid #4585FF;
  border-radius: 2px;
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  padding: 8px 15px;
  width: fit-content;
  margin-top: 20px;
  margin-bottom: 20px;
`

const Divider = styled.div`
  border: 1px solid #F2F2F2;
`
