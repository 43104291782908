import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FlexContainer } from 'components/flex'
import { media } from 'utils/style'
import { useSelector } from 'react-redux'
import { AppState } from 'states/reducers'
import DeviceList from './deviceList'

const DevicesContainer: React.FunctionComponent = () => {
  const isSubscriber = useSelector((state: AppState) => state.subscriptionStatus.isSubscriber)
  useEffect(() => {
    if (!isSubscriber) {
      window.location.href = '/settings'
    }
  }, [])

  return (
    <PageContainer id="devices-container" flexDirection="column" alignItem="stretch" justifyContent="flex-start" wrap="no-wrap">
      {isSubscriber && (
        <>
          <PageTitle>Manage your devices</PageTitle>
          <PageDescription>
            Each SCMP Digital Subscription allows you to log in with a maximum of{' '}
            <DevicesMaxNumber>five devices</DevicesMaxNumber>. You can manage your devices below.
          </PageDescription>
          <DeviceList />
        </>
      )}
    </PageContainer>
  )
}

const PageContainer = styled(FlexContainer)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding-bottom: 20px;
  max-width: 572px;
  margin: 0 24px;
  ${media.mobileUp`
    padding: 0;
  `}
  ${media.smallDesktopUp`
    max-width: 680px;
  `}
`

const PageTitle = styled.div`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
  display: block;
  color: #001149;
  margin-top: 31px;
  ${media.tabletUp`
    margin-top: 12px;
  `}
`

const PageDescription = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  display: inline-block;
  color: #666666;
  max-width: 460px;
  align-self: center;
  margin: 8px 8px 0;
  ${media.tabletUp`
    margin-top: 8px
  `}
`

const DevicesMaxNumber = styled.span`
  font-weight: 700;
  display: inline-block;
`

export default DevicesContainer
