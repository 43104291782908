import React from 'react'
import { media } from 'utils/style'
import styled from 'styled-components'

const NoResultPageContainer: React.FunctionComponent = () => {
  return (
    <PageContainer id='corporate-subscription-no-result-page-container'>
      <Description>No result found</Description>
    </PageContainer>
  )
}

const PageContainer = styled.div`
  width: 100%;
  height: 150px;
  background: #F6F6F6;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  ${media.tabletUp`
    margin: 40px 0px;
  `}
`
const Description = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #A1A1A1;
`

export default NoResultPageContainer
