import React, { useEffect } from 'react'
import { Cookies } from 'react-cookie'
import _isEmpty from 'lodash/isEmpty'
import _isFunction from 'lodash/isFunction'
import _get from 'lodash/get'

const OAuthLinking: React.FunctionComponent = () => {
  const cookies = new Cookies()

  useEffect(() => {
    const hasPianoTp = () => {
      return typeof window !== 'undefined' && _isFunction((window as any).tp.amp.login)
    }

    // #access_token=example_access_token_value&token_token=bearer&state=opaque_stat
    const oAuthLinkingData: any = JSON.parse(localStorage.getItem('oAuthLinkingData') || '{}')
    const ampReaderId = _get(oAuthLinkingData, 'ampReaderId', '')
    const accessToken = cookies.get('scmp_pat')

    if ((oAuthLinkingData as any).redirect_uri && accessToken) {
      const redirectUrl = `${oAuthLinkingData.redirect_uri}#access_token=${accessToken}&token_type=bearer&state=${oAuthLinkingData.state}`

      // for amp will wait tp ready
      if (!_isEmpty(ampReaderId)) {
        setTimeout(() => {
          const provider = (window as any).tp.user.getProvider().getName()
          if (hasPianoTp()) {
            (window as any).tp.amp.login({
              readerId: ampReaderId,
              userToken: accessToken,
              userProvider: provider,
              userState: { loggedIn: true },
              loginSuccess: function () {
                window.location.href = redirectUrl
              },
              loginFail: function () {
                window.location.href = redirectUrl
              },
            })
          } else {
            window.location.href = redirectUrl
          }
        }, 2000)
      } else {
        window.location.href = redirectUrl
      }
    } else {
      window.location.href = '/'
    }
  }, [])
  return (
    <div>Loading...</div>
  )
}

export default OAuthLinking
