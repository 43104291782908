import React from 'react'
import styled from 'styled-components'
import { FlexContainer } from '../flex'
import { media } from 'utils/style'

type Props = {
  className?: string
  buttonTitle: string
  // buttonIcon?: string
  buttonCount?: string
  noBorder? : boolean
  active?: boolean
  fontColor?: string
  children?: any
  onClick?: () => Promise<void>
}

const ManageButton: React.FunctionComponent<Props> = ({ ...Props }) => {
  return (
    <ManageWidgetContainer className={ Props.className } active={ Props.active } flexDirection='row' alignItem='center' justifyContent='flex-start' noBorder={Props.noBorder} onClick={Props.onClick}>
      { Props.children }
      <ButtonTitle fontColor={ Props.fontColor } active={ Props.active }>{ Props.buttonTitle }</ButtonTitle>
      <ButtonCount isShow={ Props.buttonCount } flexDirection='row' justifyContent='flex-end'></ButtonCount>
    </ManageWidgetContainer>
  )
}

export default ManageButton

interface ButtonIconProps {
  isShow?: string
  noBorder?: boolean
  active?: boolean
}

interface ButtonCountProps {
  isShow?: string
}

interface ButtonTitleProps {
  active?: boolean,
  fontColor?: string,
}

const ManageWidgetContainer = styled(FlexContainer)<ButtonIconProps>`
  position: relative;
  cursor: pointer;
  padding-top: 19px;
  border-top: ${({ noBorder }) => !noBorder ? '1px solid #CECECE;' : 'none;'}
  margin 0 12px;
  padding-left: 9px;
  padding-right: 8px;
  ${media.smallDesktopUp`
    padding-left: 0;
    padding-right: 0;
  `}
  .article-icon {
    margin-left: 4px;
    margin-right: 13px;
  }
  > svg {
    margin-right: 12px;
    path {
      fill: ${({ active }) => active ? '#001246' : '#ccc'};
    }
  }
`

const ButtonTitle = styled.div<ButtonTitleProps>`
  font-size: 16px;
  line-height: 19px;
  font-family: Roboto;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  color: ${({ active }) => active ? '#001246' : '#ccc'};
  ${({ fontColor }) => `color: ${fontColor}`}
`

const ButtonCount = styled(FlexContainer)<ButtonCountProps>`
  color: #fff;
  padding: 1px 5px;
  background: #B2B2B2;
  border-radius: 50%;
  font-size: 14px;
  line-height: 16px;
  font-family: Roboto;
  display: ${({ isShow }) => !isShow ? 'none' : ''}
`
