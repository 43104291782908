import styled from 'styled-components'
import React from 'react'
import { hideOnMedia, media, showOnMedia } from 'utils/style'
import { ReactComponent as TickSvg } from '../../assets/svg/icon_signedup_tick.svg'
import { ReactComponent as LargePeopleSvg } from '../../assets/svg/yp-illustration-sub-large.svg'
import { ReactComponent as SmallPeopleSvg } from '../../assets/svg/yp-illustration-sub-small.svg'
import { NODE_ENV } from 'config/app.config'
import { FlexContainer } from 'components/flex'

type Props = {
  trackingGTM: () => void,
}

const YPSubscriptionContent: React.FunctionComponent<Props> = ({ ...Props }) => {
  const { trackingGTM } = Props
  return (
    <SubscriptionContent flexDirection='row' justifyContent='space-between'>
      <StyledLargePeopleSvg hideOn='mobile'/>
      <SubscriptionInfo flexDirection='column'>
        <SubscriptionStatus>Subscribe to YP to access all our great content</SubscriptionStatus>
        <SubscriptionInfoList>
          <SubscriptionInfoItem wrap='no-wrap'>
            <TickWrapper>
              <StyledTick />
            </TickWrapper>
            <SubscriptionInfoWording>
              Unlimited access to <WordItalic>YP</WordItalic> website
            </SubscriptionInfoWording>
          </SubscriptionInfoItem>
          <SubscriptionInfoItem wrap='no-wrap'>
            <TickWrapper>
              <StyledTick />
            </TickWrapper>
            <SubscriptionInfoWording>
              Special discounts on <WordItalic>YP</WordItalic> events
            </SubscriptionInfoWording>
          </SubscriptionInfoItem>
          <SubscriptionInfoItem wrap='no-wrap'>
            <TickWrapper>
              <StyledTick />
            </TickWrapper>
            <SubscriptionInfoWording>
              Eligible to apply for workshops
            </SubscriptionInfoWording>
          </SubscriptionInfoItem>
        </SubscriptionInfoList>
      </SubscriptionInfo>
      <SubscriptionButtonContainer hideOn='mobile'>
        <SubscriptionButton href={NODE_ENV === 'production' ? 'https://subscribe.scmp.com/yp' : 'https://staging-rosetta.product-web.dev-2.scmp.tech/yp'} onClick={trackingGTM}>SUBSCRIBE</SubscriptionButton>
      </SubscriptionButtonContainer>
      <SubscriptionButtonContainer showOn='mobile'>
        <SubscriptionButton href={NODE_ENV === 'production' ? 'https://subscribe.scmp.com/yp' : 'https://staging-rosetta.product-web.dev-2.scmp.tech/yp'} onClick={trackingGTM}>SUBSCRIBE</SubscriptionButton>
        <SmallPeopleSvg />
      </SubscriptionButtonContainer>
    </SubscriptionContent>
  )
}

interface CommonStyle {
  background?: string
  showOn?: string
  hideOn?: string
}

const TickWrapper = styled.div`
  background: #97B100;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  width: 24px;
  padding-top: 3px;
`

const StyledLargePeopleSvg = styled(LargePeopleSvg)<CommonStyle>`
  ${({ showOn }) => showOn ? showOnMedia([showOn]) : ''}
  ${({ hideOn }) => hideOn ? hideOnMedia([hideOn]) : ''}
`

const StyledTick = styled(TickSvg)`
  height: 16px;
  path {
    fill: white;
  }
`

const WordItalic = styled.span`
  font-style: italic;
`

const SubscriptionInfo = styled(FlexContainer)`
  padding-top: 24px;
  width: 100%;
  ${media.tabletUp`
    width: auto;
    padding: 0;
  `}
`

const SubscriptionInfoList = styled.div`
  margin-top: 20px;
  div:first-child {
      margin-top: 0;
    }
  ${media.tabletUp`
    margin-top: 20px;
    div:first-child {
      margin-top: 0;
    }
  `}
`

const SubscriptionInfoItem = styled(FlexContainer)`
  padding-left: 25px;
  padding-right: 25px;
  margin-bottom: 12px;
  align-items: center;
  ${media.tabletUp`
    padding: 0;
    margin-top: 12px;
    margin-bottom: 0;
  `}
`

const SubscriptionInfoWording = styled.div`
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  width: 100%;
  margin-left: 8px;
  ${media.tabletUp`
    max-width: 100%;
  `}
`

const SubscriptionContent = styled(FlexContainer)`
  border: 2px solid #000000;
  border-radius: 8px;
  width: 100%;
  position: relative;
  ${media.tabletUp`
    border-radius: 4px;
    padding: 18px 29px 0 14px;
  `}
`

const SubscriptionStatus = styled.div`
  color: #000000;
  font-family: 'all-round-gothic', Merriweather;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin: 0 25px;
  ${media.tabletUp`
    margin: 0;
    text-align: left;
    width: 483px;
    line-height: 26px;
    font-size: 18px;
  `}
`

const SubscriptionButtonContainer = styled.div<CommonStyle>`
  text-align: center;
  margin: 0 25px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  ${media.tabletUp`
    text-align: left;
    margin-top: 10px;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    position: absolute;
    right: 24px;
    bottom: 32px;
    display: inline-block;
    width: initial;
  `}
  ${({ showOn }) => showOn ? showOnMedia([showOn]) : ''}
  ${({ hideOn }) => hideOn ? hideOnMedia([hideOn]) : ''}
`

const SubscriptionButton = styled.a`
  background: #FCFF79;
  color: #001246;
  font-family: Roboto;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  line-height: 19px;
  cursor: pointer;
  padding: 12.5px 19px;
  border: 2px solid #000000;
  box-shadow: 0px 4px 0px #000000;
  border-radius: 4px;
`

export default YPSubscriptionContent
