import React from 'react'
import { useSelector } from 'react-redux'

import Page from './page'
import { AppState } from 'states/reducers'

const ChangePasswordComponent: React.FunctionComponent = () => {
  const uid = useSelector((state: AppState) => state.userInfo.uid)

  return (
    <Page uid={uid}/>
  )
}

export default ChangePasswordComponent
