import React, { useState } from 'react'

import { getPasswordStrength } from 'utils/common'
import { hasValue } from 'utils/formValidation'

import { ErrorMessage } from 'config/constants'

function usePassword (): [string, string, number, string, string, (event: React.ChangeEvent<HTMLInputElement>) => void, (event: React.ChangeEvent<HTMLInputElement>) => void, () => void, () => void, () => void] {
  const [password, updatePassword] = useState<string>('')
  const [passwordError, updatePasswordError] = useState<string>('')
  const [confirmPassword, updateConfirmPassword] = useState<string>('')
  const [confirmPwError, updateConfirmPwError] = useState<string>('')
  const [passwordLevel, updatePasswordLevel] = useState<number>(0)

  const onUpdatePassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updatePassword(event.target.value)
    updatePasswordLevel(getPasswordStrength(event.target.value))
  }

  const onUpdateConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>): void => {
    updateConfirmPassword(event.target.value)
  }

  const validatePw = (): void => {
    if (!hasValue(password)) {
      return updatePasswordError(ErrorMessage.CHANGE_PASSWORD_MISSING_NEW_PASSWORD)
    } else if (passwordLevel < 3) {
      return updatePasswordError(ErrorMessage.CHANGE_PASSWORD_NOT_STRONG_ENOUGH)
    } else if (password && confirmPassword && password !== confirmPassword) {
      return updateConfirmPwError(ErrorMessage.CHANGE_PASSWORD_DOES_NOT_MATCH)
    }

    return updatePasswordError('')
  }

  const validateConfirmPw = (): void => {
    if (!hasValue(password) && !hasValue(confirmPassword)) {
      return updateConfirmPwError('')
    } else if (hasValue(password) && !hasValue(confirmPassword)) {
      return updateConfirmPwError('Please enter your password again.')
    } if (password && confirmPassword && password !== confirmPassword) {
      return updateConfirmPwError(ErrorMessage.CHANGE_PASSWORD_DOES_NOT_MATCH)
    }

    return updateConfirmPwError('')
  }

  const runCheck = (): void => {
    validatePw()
    validateConfirmPw()
  }

  return [password, confirmPassword, passwordLevel, passwordError, confirmPwError, onUpdatePassword, onUpdateConfirmPassword, validatePw, validateConfirmPw, runCheck]
}

function useToggleSwitch (defaultValue?: boolean | null): [boolean, () => void] {
  const [booleanValue, toggleValue] = useState<boolean>(defaultValue || false)
  return [booleanValue, () => toggleValue(!booleanValue)]
}

export {
  usePassword,
  useToggleSwitch,
}
