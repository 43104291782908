import axios from 'axios'
import _get from 'lodash/get'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'
import { FormErrorLabel } from 'components/formErrorLabel'
import { GoBackButton } from 'components/goBackButton'
import ShowPasswordButton from 'components/showPasswordButton'
import { InputComponent } from 'components/styled'

import { DataLayerEventCategory, DataLayerEventAction, DataLayerGtmCustomEventName, ErrorMessage, ChangeEmailAddressMessage } from 'config/constants'

import * as modalAction from 'states/actions/application'
import { AppState } from 'states/reducers'

import { hasValue, isValidEmail } from 'utils/formValidation'
import { media } from 'utils/style'
import { triggerGA4GtmEvent, triggerGtmCustomEvent } from 'utils/tracking'

const ChangeEmailAddressPage: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const [password, updatePassword] = useState<string>('')
  const [pwError, updatePwError] = useState<string>('')
  const [isShowingConfirmPw, toggleIsShowConfirmPw] = useState(false)
  const [newEmailAddress, updateNewEmailAddress] = useState<string>('')
  const [newEmailAddressError, updateNewEmailError] = useState<string>('')
  const [confirmNewEmailAddress, updateConfirmNewEmailAddress] = useState<string>('')
  const [confirmNewEmailAddressError, updateConfirmNewEmailAddressError] = useState<string>('')
  const userEmail = useSelector((state: AppState) => state.userInfo.email)
  const errorMessage = pwError + newEmailAddressError + confirmNewEmailAddressError

  const validatePassword = (): void => {
    if (!hasValue(password)) {
      updatePwError(ErrorMessage.CHANGE_PASSWORD_MISSING_NEW_PASSWORD)
    } else {
      updatePwError('')
    }
  }

  const toggleShowConfirmPw = (eventName?: string): void => {
    if (eventName === 'onMouseOut') {
      toggleIsShowConfirmPw(false)
      return
    }
    toggleIsShowConfirmPw(!isShowingConfirmPw)
  }

  const sendForgetPasswordEmail = async () => {
    if (userEmail === '') {
      return false
    }
    try {
      await axios.post('/forgot-password', { email: userEmail })
      dispatch(modalAction.updateModalMessage(`We sent an email to you at ${userEmail}. Please check your mail box and follow the instruction to reset your password.`))
    } catch (error) {
      dispatch(modalAction.updateModalMessage('Please contact support or try again.'))
    }
  }

  const submitChangeEmailAddressRequest = async () => {
    try {
      const response = await axios.post('/change-email', { email: confirmNewEmailAddress, password: password })
      if (response) {
        dispatch(modalAction.updateModalMessage(`We sent an email to you at ${confirmNewEmailAddress}. Please check your mail box and follow the instruction to change your email address.`))
        triggerGA4GtmEvent({
          category: 'myaccount',
          subcategory: 'profile_update',
          action: 'sys',
          customized_parameters: {
            update_type: 'change_email',
          },
        })
      }
    } catch (error) {
      const code = _get(error, 'response.data.code', 'SomethingWentWrong')
      dispatch(modalAction.updateModalMessage(ChangeEmailAddressMessage[code]))
    }
  }

  const onForgetPasswordLinkClick = () => {
    sendForgetPasswordEmail()
    triggerGtmCustomEvent(DataLayerEventAction.FORGET_PASSWORD, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
    triggerGA4GtmEvent({
      category: 'myaccount',
      subcategory: 'forgot_password',
      action: 'click',
    })
  }

  const onPasswordUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => updatePassword(event.target.value)

  const onNewEmailAddressUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => updateNewEmailAddress(event.target.value)

  const onConfirmNewEmailAddressUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => updateConfirmNewEmailAddress(event.target.value)

  const validateNewEmailAddress = () => {
    if (!hasValue(newEmailAddress)) {
      return updateNewEmailError(ErrorMessage.CHANGE_EMAIL_ADDRESS_MISSING_EMAIL)
    } else if (!isValidEmail(newEmailAddress)) {
      return updateNewEmailError(ErrorMessage.CHANGE_EMAIL_ADDRESS_WRONG_EMAIL)
    } else if (newEmailAddress && confirmNewEmailAddress && newEmailAddress !== confirmNewEmailAddress) {
      return updateConfirmNewEmailAddressError(ErrorMessage.CHANGE_EMAIL_ADDRESS_DOES_NOT_MATCH)
    }

    return updateNewEmailError('')
  }

  const validateConfirmNewEmailAddress = () => {
    if (!hasValue(newEmailAddress) && !hasValue(confirmNewEmailAddress)) {
      return updateConfirmNewEmailAddressError('')
    } else if (hasValue(newEmailAddress) && !hasValue(confirmNewEmailAddress)) {
      return updateConfirmNewEmailAddressError(ErrorMessage.CHANGE_EMAIL_ADDRESS_MISSING_CONFIRM_EMAIL)
    } else if (newEmailAddress && newEmailAddress && newEmailAddress !== confirmNewEmailAddress) {
      return updateConfirmNewEmailAddressError(ErrorMessage.CHANGE_EMAIL_ADDRESS_DOES_NOT_MATCH)
    }

    return updateConfirmNewEmailAddressError('')
  }

  const onClickSave = () => {
    if (errorMessage || !password || !newEmailAddress || !confirmNewEmailAddress) {
      return false
    }
    submitChangeEmailAddressRequest()
  }

  const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onClickSave()
    }
  }

  return (
    <PageContainer>
      <FormContainer flexDirection='column' alignItem='flex-start'>
        <FormContent>
          <GoBackButton labelText="Change Email Address" goBackLink="/settings/profile" />
          <CurrentEmailAddressContainer>
            Your current email address:
            <div>
              <strong>{userEmail}</strong>
            </div>
          </CurrentEmailAddressContainer>
          <ErrorMsgLabel>{newEmailAddressError}</ErrorMsgLabel>
          <ErrorMsgLabel>{confirmNewEmailAddressError}</ErrorMsgLabel>
          <ErrorMsgLabel>{pwError}</ErrorMsgLabel>
          <FieldLabelContainer alignItem='center'>
            <FieldLabel>New Email Address</FieldLabel>
          </FieldLabelContainer>
          <NewEmailContainer>
            <InputComponent
              type='text'
              placeholder='email address'
              marginBottom='0px'
              maxWidth='100%'
              height='37px'
              paddingLeft='10px'
              error={newEmailAddressError}
              onChange={onNewEmailAddressUpdate}
              onBlur={validateNewEmailAddress}
              autoComplete='off' />
          </NewEmailContainer>

          <FieldLabelContainer alignItem='center'>
            <FieldLabel>Confirm New Email Address</FieldLabel>
          </FieldLabelContainer>
          <ConfirmNewMailContainer>
            <InputComponent
              type='text'
              placeholder='email address'
              marginBottom='0px'
              maxWidth='100%'
              height='37px'
              paddingLeft='10px'
              error={confirmNewEmailAddressError}
              onChange={onConfirmNewEmailAddressUpdate}
              onBlur={validateConfirmNewEmailAddress}
              autoComplete='off' />
          </ConfirmNewMailContainer>

          <FieldLabelContainer>
            <FieldLabel>Current Password</FieldLabel>
          </FieldLabelContainer>
          <PasswordContainer>
            <PasswordInput
              type={isShowingConfirmPw ? 'text' : 'password'}
              placeholder='password'
              onChange={onPasswordUpdate}
              onBlur={validatePassword}
              error={pwError}
              onKeyPress={keyPressHandler}
              autoComplete='off'/>
            <ShowPasswordButton toggleHandler={toggleShowConfirmPw}></ShowPasswordButton>
          </PasswordContainer>

          <FlexContainer>
            <ForgotPasswordLink onClick={onForgetPasswordLinkClick} disabled={false}>Forgot password</ForgotPasswordLink>
          </FlexContainer>

          <SaveButton alignItem='center' justifyContent='center' onClick={onClickSave}>SAVE</SaveButton>
        </FormContent>
      </FormContainer>
    </PageContainer>
  )
}

interface FieldChecker {
  error?: string
}

const PageContainer = styled.div`
  width: 100%;
`

const FormContainer = styled(FlexContainer)`
`

const FormContent = styled.div`
  max-width: 416px;
  padding: 0px 0 40px;
  margin: 20px auto 0;
  ${media.tabletUp`
    width: 100%;
    padding: 0;
    margin: 0 auto;
    div:nth-child(3) {
      margin-top: 32px;
    }
  `}
`

const ErrorMsgLabel = styled(FormErrorLabel)`
  display: block;
`

const FieldLabelContainer = styled(FlexContainer)`
  margin-top: 20px;
  margin-bottom: 7px;
`

const FieldLabel = styled.div`
  flex: 1;
  color: #000000;
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
`

const NewEmailContainer = styled.div`
  position: relative;
  width: 100%;
`

const ConfirmNewMailContainer = styled.div`
  position: relative;
  width: 100%;
`

const PasswordContainer = styled.div`
  position: relative;
  margin-bottom: 7px;
  width: 100%;
`

const PasswordInput = styled.input<FieldChecker>`
  height: 37px;
  width: 100%;
  border: 1px solid ${({ error }) => error ? '#EB4254' : '#CCCCCC'};
  border-radius: 2px;
  background-color: #FFF;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.19px;
  color: #333;
  font-family: Roboto;
  padding-left: 10px;
  box-sizing: border-box;
  padding-right: 50px;
`
interface ForgotPasswordLinkStyle {
  disabled?: boolean
}

const ForgotPasswordLink = styled.div<ForgotPasswordLinkStyle>`
  text-decoration: none;
  height: 16px;
  width: 100%;
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  padding-top: 7px;
  cursor: pointer;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
`

const CurrentEmailAddressContainer = styled.div`
  height: 42px;
  width: 335px;
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 20px;
  margin-top: 32px;
  ${media.tabletUp`
    width: 100%;
  `}
`

const SaveButton = styled(FlexContainer)`
  box-sizing: border-box;
  max-width: 250px;
  width: 100%;
  height: 44px;
  border: 1px solid #4585FF;
  border-radius: 2px;
  cursor: pointer;
  color: #4585FF;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 27.5px auto 0;
`

export default ChangeEmailAddressPage
