import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { AnimatedFlexContainer } from 'components/animation'
import { FlexContainer } from 'components/flex'
import {
  CreateAnAccount,
  CreateAccountWithEmail,
} from 'components/registerPage'
import { AppState } from 'states/reducers'
import { SCMP_COM_PROTOCOL, SCMP_COM_HOST } from 'config/app.config'

const RegisterPageContentFrameComponent: React.FunctionComponent = () => {
  const privacyUrl = `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}/privacy-policy`
  const termAndConditionsUrl = `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}/terms-conditions`

  const registrationStep = useSelector((state: AppState) => state.registration.step)

  const registrationContentControl = (currentStep: number) => {
    switch (currentStep) {
      case 1:
        return <CreateAnAccount />
      case 2:
        return <CreateAccountWithEmail />
      case 3:
      default:
        return <CreateAnAccount />
    }
  }

  return (
    <ComponentContainer id='register-page-content-frame' animationType='fadeIn' flexDirection='column' alignItem='center' justifyContent='space-between'>
      { registrationContentControl(registrationStep) }
      <ContainerBottom flexDirection="column" alignItem="center">
        <TermsAndCons flexDirection='row' alignItem='center' justifyContent='center'>
          By creating an account, you agree to our&nbsp;<TNCUrl target="_blank" href={termAndConditionsUrl}>Terms of Service</TNCUrl> and acknowledge our&nbsp;<TNCUrl target="_blank" href={privacyUrl}>Privacy Policy</TNCUrl>.
        </TermsAndCons>
      </ContainerBottom>
    </ComponentContainer>
  )
}

const ComponentContainer = styled(AnimatedFlexContainer)`
  min-width: 375px;
  flex: 1;
  a {
    text-align: center;
  }
`

const ContainerBottom = styled(FlexContainer)`
`

const TermsAndCons = styled(FlexContainer)`
  width: 351px;
  height: 28px;
  color: #666666;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 200;
  line-height: 14px;
  text-align: center;
  margin-top: 20px;
  text-align: center;
  color: #999;
`

const TNCUrl = styled.a`
  text-decoration: underline;
  color: #999;
  cursor: pointer;
`

export default RegisterPageContentFrameComponent
