import React, { useState } from 'react'
import DropDownMenu, { DropDownMenuCustomStyle, DropDownMenuItem } from 'components/menu/DropDownMenu'
import styled from 'styled-components'
import { FlexContainer } from 'components/flex'
import ArrowUp from 'assets/svg/icon_arrow_up.svg'
import DoubleArrowUp from 'assets/svg/icon_quicklinks.svg'

interface DropDownLabelCustomStyle {
  marginRight?: string
}

type Props = {
  activeItem?: DropDownMenuItem,
  items: DropDownMenuItem[],
  label?: string
  labelTheme?: string
  labelArrowStyle?: string
  labelCustomStyle?: DropDownLabelCustomStyle,
  menuCustomStyle?: DropDownMenuCustomStyle,
  onChangeHandler?: Function,
  onClickHandler?: Function,
  isOpen: boolean,
  updateIsOpen: Function,
}

export enum LabelTheme {
  WHITE = 'white',
  BLACK = 'black',
}

export enum LabelArrowStyle {
  ARROW = 'arrow',
  DOUBLE_ARROW = 'double-arrow'
}

const DropDown: React.FunctionComponent<Props> = (Props) => {
  const { activeItem, items, isOpen, labelCustomStyle, menuCustomStyle, onClickHandler, onChangeHandler, updateIsOpen } = Props
  const [label] = useState<string>(Props.label || '')
  const [labelArrowStyle] = useState<string>(Props.labelArrowStyle || LabelArrowStyle.ARROW)
  const [labelTheme] = useState<string>(Props.labelTheme || LabelTheme.WHITE)

  const arrowDirection = () => {
    return isOpen ? 'up' : 'bottom'
  }

  const onClickLabel = () => {
    updateIsOpen(!isOpen)
  }

  return (
    <DropDownContainer>
      <Label labelTheme={labelTheme} labelCustomStyle={labelCustomStyle} onClick={onClickLabel}>
        <LabelText labelTheme={labelTheme}>{activeItem ? activeItem.value : label}</LabelText>
        {labelArrowStyle === LabelArrowStyle.ARROW && <LabelArrow src={ArrowUp} direction={arrowDirection()} onClick={onClickLabel} />}
        {labelArrowStyle === LabelArrowStyle.DOUBLE_ARROW && <LabelDoubleArrow src={DoubleArrowUp} onClick={onClickLabel} />}
      </Label>
      <DropDownMenu isShow={isOpen}
        activeItem={activeItem}
        items={items}
        onChangeHandler={onChangeHandler}
        onClickHandler={onClickHandler}
        customStyle={menuCustomStyle} />
    </DropDownContainer>
  )
}

interface labelArrowProps {
  direction: string,
}

interface labelProps {
  labelTheme?: string
  labelCustomStyle?: DropDownLabelCustomStyle
}

const DropDownContainer = styled(FlexContainer)`
  position: relative;
`

const Label = styled(FlexContainer)<labelProps>`
  cursor: pointer;
  align-self: center;
  justify-content: center;
  align-items: center;
  background: ${({ labelTheme }) => labelTheme === LabelTheme.BLACK ? '#000000' : '#FFFFFF'};
  padding: ${({ labelTheme }) => labelTheme === LabelTheme.BLACK ? '6px 8px' : '0'};
  margin-right: ${({ labelCustomStyle }) => labelCustomStyle && labelCustomStyle.marginRight ? labelCustomStyle.marginRight : '0'};
`

const LabelText = styled.label<labelProps>`
  cursor: pointer;
  margin-right: 5px;
  padding: 0;
  font-size: 15px;
  font-family: Roboto;
  line-height: 19px;
  color: ${({ labelTheme }) => labelTheme === LabelTheme.BLACK ? '#FFFFFF' : '#000000'};
`

const LabelArrow = styled.img<labelArrowProps>`
  cursor: pointer;
  align-self: center;
  ${({ direction }) => {
    let transform: string
    switch (direction) {
      case 'right': transform = 'transform: rotateX(90deg);'; break
      case 'bottom': transform = 'transform: rotateX(180deg);'; break
      case 'left': transform = 'transform: rotateX(270deg);'; break
      default: transform = ''
    }

    return transform
  }}
`

const LabelDoubleArrow = styled.img`
  cursor: pointer;
`

export default DropDown
