import _isEmpty from 'lodash/isEmpty'
import React from 'react'
import { Cookies } from 'react-cookie'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { FlexContainer } from 'components/flex'

import * as modalAction from 'states/actions/application'
import { AppState } from 'states/reducers'

import { media } from 'utils/style'
import { FollowType, getFollowOptionalCustomizedParameters, triggerGA4GtmEvent } from 'utils/tracking'

import { ReactComponent as FollowTick } from '../../assets/svg/follow-tick.svg'

const FollowingListContainer: React.FunctionComponent = () => {
  const { userFollowList } = useSelector((state: AppState) => state.application, shallowEqual)
  const dispatch = useDispatch()
  const cookie = new Cookies()
  const scmpUser = cookie.get('scmp_user') || {}
  let authorListTitle
  let authorListComp
  let topicListTitle
  let topicListComp
  let emptyMsgBlk
  let authorListLength
  let topicListLength
  let knowledgeListTitle
  let knowledgeListComp
  let knowledgeListLength
  let sectionListTitle
  let sectionListComp
  let sectionListLength

  const onClickShow = (name: string, actionType: FollowType, entityId: string) => {
    const optionalCustomizedParameters = getFollowOptionalCustomizedParameters({
      followType: actionType,
      entityId,
    })
    triggerGA4GtmEvent({
      category: 'follow',
      subcategory: '',
      action: 'click',
      customized_parameters: {
        action_type: 'unfollow',
        trigger_point: 'my_account',
        ...optionalCustomizedParameters,
      },
    })
    dispatch(modalAction.updateUnFollowModalMessage(scmpUser.uuid, name, actionType, entityId))
  }

  // render Logic for Author
  if (!_isEmpty(userFollowList.authorList)) {
    authorListTitle = <CategoryTitle>Authors</CategoryTitle>
    authorListLength = <CategoryTitle>{userFollowList.authorList.length}/100</CategoryTitle>
    authorListComp =
    userFollowList.authorList.map((author: any) =>
      <ItemContainer key={author.name} justifyContent="space-between">
        <ItemWrapper>
          <ItemDetails flexDirection="column">
            <ItemCategory>Author</ItemCategory>
            <ItemTitle href={`https://www.scmp.com${author.urlAlias}`} target="_blank">{author.name}</ItemTitle>
          </ItemDetails>
        </ItemWrapper>
        <StyledFollowTick onClick={() => onClickShow(author.name, 'author', author.entityId)}/>
      </ItemContainer>)
  }

  // render Logic for Topic
  if (!_isEmpty(userFollowList.topicList)) {
    topicListTitle = <CategoryTitle>Topics</CategoryTitle>
    topicListLength = <CategoryTitle>{userFollowList.topicList.length}/100</CategoryTitle>
    topicListComp =
    userFollowList.topicList.map((topic: any) =>
      <ItemContainer key={topic.name} justifyContent="space-between">
        <ItemWrapper>
          <ItemDetails flexDirection="column">
            <ItemCategory>Topic</ItemCategory>
            <ItemTitle href={`https://www.scmp.com${topic.urlAlias}`} target="_blank">{topic.name}</ItemTitle>
          </ItemDetails>
        </ItemWrapper>
        <StyledFollowTick onClick={() => onClickShow(topic.name, 'topic', topic.entityId)}/>
      </ItemContainer>)
  }

  // render Logic for Section
  if (!_isEmpty(userFollowList.sectionList)) {
    sectionListTitle = <CategoryTitle>Sections</CategoryTitle>
    sectionListLength = <CategoryTitle>{userFollowList.sectionList.length}/100</CategoryTitle>
    sectionListComp =
    userFollowList.sectionList.map((section: any) =>
      <ItemContainer key={section.name} justifyContent="space-between">
        <ItemWrapper>
          <ItemDetails flexDirection="column">
            <ItemCategory>Section</ItemCategory>
            <ItemTitle href={`https://www.scmp.com${section.urlAlias}`} target="_blank">{section.name}</ItemTitle>
          </ItemDetails>
        </ItemWrapper>
        <StyledFollowTick onClick={() => onClickShow(section.name, 'section', section.entityId)}/>
      </ItemContainer>)
  }

  // render Logic for Knowledge
  if (!_isEmpty(userFollowList.knowledgeList)) {
    knowledgeListTitle = <CategoryTitle>Knowledge</CategoryTitle>
    knowledgeListLength = <CategoryTitle>{userFollowList.knowledgeList.length}/100</CategoryTitle>
    knowledgeListComp =
    userFollowList.knowledgeList.map((knowledge: any) =>
      <ItemContainer key={knowledge.name} justifyContent="space-between">
        <ItemWrapper>
          <ItemDetails flexDirection="column">
            <ItemCategory>Knowledge</ItemCategory>
            <ItemTitle href={`https://www.scmp.com${knowledge.urlAlias}`} target="_blank">{knowledge.name}</ItemTitle>
          </ItemDetails>
        </ItemWrapper>
        <StyledFollowTick onClick={() => onClickShow(knowledge.name, 'knowledge', knowledge.entityId)}/>
      </ItemContainer>)
  }

  if (_isEmpty(userFollowList.authorList) && _isEmpty(userFollowList.topicList) && _isEmpty(userFollowList.knowledgeList) && _isEmpty(userFollowList.sectionList)) {
    emptyMsgBlk =
    <EmptyMessageContainer flexDirection="column" alignItem="center">
      <EmptyMessage>
        Your authors, topics and knowledge will show here.
      </EmptyMessage>
      <EmptyMessageButton href="https://www.scmp.com/" target="_blank">
        START EXPLORING
      </EmptyMessageButton>
    </EmptyMessageContainer>
  }

  return (
    <FollowingListBlock>
      <FollowTitle>
        FOLLOWING
      </FollowTitle>
      { !_isEmpty(userFollowList.authorList) && <>
          <FollowingHeaderWrapper justifyContent="space-between">
            {authorListTitle}
            {authorListLength}
          </FollowingHeaderWrapper>
          <ItemsContainer flexDirection="row" justifyContent="space-between">
            {authorListComp}
          </ItemsContainer>
        </>
      }
      { !_isEmpty(userFollowList.topicList) && <>
          <FollowingHeaderWrapper justifyContent="space-between">
            {topicListTitle}
            {topicListLength}
          </FollowingHeaderWrapper>
          <ItemsContainer flexDirection="row" justifyContent="space-between">
            {topicListComp}
          </ItemsContainer>
        </>
      }
      { !_isEmpty(userFollowList.sectionList) && <>
          <FollowingHeaderWrapper justifyContent="space-between">
            {sectionListTitle}
            {sectionListLength}
          </FollowingHeaderWrapper>
          <ItemsContainer flexDirection="row" justifyContent="space-between">
            {sectionListComp}
          </ItemsContainer>
        </>
      }
      { !_isEmpty(userFollowList.knowledgeList) && <>
          <FollowingHeaderWrapper justifyContent="space-between">
            {knowledgeListTitle}
            {knowledgeListLength}
          </FollowingHeaderWrapper>
          <ItemsContainer flexDirection="row" justifyContent="space-between">
            {knowledgeListComp}
          </ItemsContainer>
        </>
      }
      {emptyMsgBlk}
    </FollowingListBlock>
  )
}

const FollowingListBlock = styled(FlexContainer)`
  width: 100%;
`

const FollowingHeaderWrapper = styled(FlexContainer)`
  width: 100%;
  margin: 16px 20px 0;
  ${media.tabletUp`
    margin: 20px 0 0 0;
  `}
`

const FollowTitle = styled.div`
  color: #001246;
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #D7D7D7;
  padding-bottom: 8px;
  width: 100%;
  margin: 20px 20px 0;
  ${media.tabletUp`
    margin: 0;
  `}
`

const CategoryTitle = styled.div`
  color: #001246;
  font-family: "Roboto Condensed";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
`

const ItemsContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  ${media.mobileUp`
    flex-direction: column;
    width: 100%;
    margin: 0 20px 0;
  `}
  ${media.tabletUp`
    flex-direction: row;
    width: 100%;
    margin: 8px 0 0 0;
  `}
`

const ItemContainer = styled(FlexContainer)`
  border-radius: 2px;
  background-color: #FFCA05;
  margin: 8px auto 0;
  max-width: 93%;
  width: 100%;
  ${media.mobileUp`
    width: 100%;
    margin-top: 8px;
    max-width: 100%;
  `}
  ${media.tabletUp`
    width: 100%;
    max-width: 100%;
    margin: 8px 0 0 0;
  `}
  ${media.smallDesktopUp`
    width: 335px;
    margin: 8px 0 0 0;
  `}
`

const ItemWrapper = styled(FlexContainer)`
  margin: 8px 9px 9px 16px;
`

const ItemDetails = styled(FlexContainer)`
`

const ItemCategory = styled.div`
  color: #000000;
  font-family: "Roboto Condensed";
  font-size: 12px;
`

const ItemTitle = styled.a`
  color: rgba(0,0,0,0.87);
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  margin-top: 2px;
  width: 230px;
  ${media.mobileUp`
    width: 100%;
  `}
  ${media.smallDesktopUp`
    width: 230px;
  `}
`

const StyledFollowTick = styled(FollowTick)`
  align-self: center;
  cursor: pointer;
  margin-right: 9px;
`

const EmptyMessageContainer = styled(FlexContainer)`
  background-color: #F2F2F2;
  height: 100vh;
  width: 100%;
  ${media.tabletUp`
    height: auto;
  `}
`

const EmptyMessage = styled.div`
  color: #001246;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin: 24px 0 0;
`

const EmptyMessageButton = styled.a`
  border: 1px solid #4585FF;
  border-radius: 2px;
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0;
  padding: 8px 15px;
  width: fit-content;
  margin-top: 20px;
  margin-bottom: 24px;
`

export default FollowingListContainer
