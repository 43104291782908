import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { InputComponent, StyledButton } from 'components/styled'
import { DataLayerEventCategory, DataLayerGtmCustomEventName, DataLayerEventAction, ErrorMessage } from 'config/constants'
import { triggerGtmEvent } from 'utils/tracking'
import ReCAPTCHA from 'react-google-recaptcha'
import { RECAPTCHA_SITE_KEY } from 'config/app.config'

const ReCAPTCHAWithStyle = styled(ReCAPTCHA)`
  max-width: 272px;
  max-height: 70px;
`

type Props = {
  email: string | undefined;
  updateDescription: (description: string) => void;
  disabled: boolean;
  emailSubmitHandler: (token: string, create: boolean) => void;
  onClickBackHandler: (option: string) => void;
  updateErrorMessage: (value: string) => void;
}

const ConfirmEmailComponent :React.FunctionComponent<Props> = ({ ...Props }) => {
  const { disabled, email, emailSubmitHandler, onClickBackHandler, updateErrorMessage, updateDescription } = Props
  const [recaptchaRef, updateRecaptchaRef] = useState<any>(null)
  const [confirmEmail, updateConfirmEmail] = useState<any>('')

  const traceGaImpression = () => {
    triggerGtmEvent(
      DataLayerEventAction.EMAIL_CONFIRM_PAGE,
      DataLayerEventCategory.LOGIN,
      DataLayerGtmCustomEventName.LOGIN,
      window.location.href,
    )
  }

  useEffect(() => {
    updateDescription('Welcome new user, please confirm your email address')
    traceGaImpression()
  }, [])

  const emailInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateConfirmEmail(event.target.value)
  }

  const onSubmit = async () => {
    if (!emailSubmitHandler) {
      return
    }

    if (confirmEmail !== email) {
      updateErrorMessage(ErrorMessage.LOGIN_INCORRECT_CONFIRM_EMAIL)
      return false
    }

    const token = await recaptchaRef.executeAsync()
    if (!token) {
      updateErrorMessage(ErrorMessage.FAIL_TO_GENERATE_RECAPTCHA)
      return false
    }
    emailSubmitHandler(token, true)
  }

  const keyPressHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit()
    }
  }

  return (
    <>
      <EmailComponentContainer>
        <EmailComponent>{email}</EmailComponent>
      </EmailComponentContainer>
      <ConfirmEmailContainer>
        <InputComponent
          type='text'
          placeholder='your email'
          height='37px'
          paddingLeft='10px'
          onChange={emailInputHandler}
          onKeyPress={keyPressHandler}></InputComponent>
      </ConfirmEmailContainer>
      <StyledButton
        color='#000'
        fontSize='16px'
        lineHeight='19px'
        padding='9px'
        marginBottom='16px'
        disabled={disabled}
        onClick={onSubmit}>NEXT</StyledButton>
       <BackLink onClick={() => onClickBackHandler('confirmEmail')} disabled={disabled}>Back</BackLink>
      <ReCAPTCHAWithStyle
        ref={(ref: any) => updateRecaptchaRef(ref)}
        size="invisible"
        sitekey={RECAPTCHA_SITE_KEY}
      />
    </>
  )
}

const EmailComponentContainer = styled.div`
  padding: 8px 9px;
  margin-bottom: 11px;
`

const EmailComponent = styled.div`
  color: #333333;
  font-size: 16px;
  line-height: 19px;
  font-family: 'Roboto';
`
const ConfirmEmailContainer = styled.div`
  position: relative;
  margin-bottom: 11px;
  max-width: 272px;
`
interface BackLinkStyle {
  marginTop?: string
  disabled?:boolean
}
const BackLink = styled.div<BackLinkStyle>`
  text-align: center;
  color: #4585FF
  font-size: 14px;
  line-height: 18px;
  font-family: 'Roboto';
  margin-top:  ${props => (props.marginTop ? props.marginTop : '2px')};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  cursor: pointer;
`

export default ConfirmEmailComponent
