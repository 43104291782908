import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'states/reducers'
import _includes from 'lodash/includes'
import _isEmpty from 'lodash/isEmpty'

import { media } from 'utils/style'

import { FlexContainer } from 'components/flex'
import { UserHaloIcon } from 'components/myProfile'

import { ReactComponent as Yellow } from 'assets/svg/yellow.svg'
import { ReactComponent as Blue } from 'assets/svg/blue.svg'
import { ReactComponent as YellowM } from 'assets/svg/yellow-m.svg'
import { ReactComponent as BlueM } from 'assets/svg/blue-m.svg'
import { ReactComponent as Buddies } from 'assets/svg/buddies.svg'
import { ReactComponent as Earn } from 'assets/svg/earn.svg'
import { ReactComponent as Friend } from 'assets/svg/friend.svg'
import { ReactComponent as Share } from 'assets/svg/share.svg'
import { ReactComponent as Logo } from 'assets/svg/referlogo.svg'
import { ReactComponent as ScmpFlag } from 'assets/svg/scmp-flag.svg'
import Arrow, { ReactComponent as ArrowSVG } from 'assets/svg/icon-arrow.svg'
import ArrowUp from 'assets/svg/icon-arrow-up.svg'
import LoadingGif from 'assets/gif/buttonspin.gif'

import * as appActions from 'states/actions/application'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import { triggerGtmEvent } from 'utils/tracking'

type Props = {
  id: string,
  component: any,
  props: any,
}

function ScrollToTopOnMount () {
  useEffect(() => {
    document.body.scrollTo(0, 0)
  }, [])

  return null
}

const ReferContainer: React.FunctionComponent<Props> = ({ id, component: Component, props }) => {
  const {
    firstname,
    lastname,
    email,
  } = useSelector((state: AppState) => state.userInfo)

  const subscriptionPackageName = useSelector((state: AppState) => state.userInfo.subscriptionPackageName)
  const isSubscripted = subscriptionPackageName !== ''

  const displaySubscription = () => {
    return isSubscripted
  }

  const dispatch = useDispatch()

  const [userFirstName, setFirstUserName] = useState<string>('')
  const [userLastName, setLastUserName] = useState<string>('')
  const [userEmail, setEmail] = useState<string>('')

  const checkUserAndShowOverlay = () => {
    if (!_isEmpty(userFirstName)) {
      triggerGtmEvent(
        'tracking',
        'Referral',
        'Referral/Refer a friend',
        window.location.href,
      )
      window.parent.postMessage({ gatherEvent: 'remote-gather-login', name: userFirstName + ' ' + userLastName, email: userEmail }, '*')
    }
  }

  const toggleGather = () => {
    checkUserAndShowOverlay()
  }

  const [gatherReady, setGatherReady] = useState<boolean>(false)

  useEffect(() => {
    // embed gather script
    const script = document.createElement('script')
    // script.src = SETTING_ENV === 'production' ? 'https://s3.amazonaws.com/gatherapp-interfaces.gathercustomers.com/8d3958f40c2dfb234101.js' : 'https://s3.amazonaws.com/gatherapp-interfaces-staging.gathercustomers.com/08640d2fe2e554fa465f.js'
    // staging script.src = 'https://s3.amazonaws.com/gatherapp-interfaces-staging.gathercustomers.com/08640d2fe2e554fa465f.js'
    // force to prod for now..for
    script.src = 'https://s3.amazonaws.com/gatherapp-interfaces.gathercustomers.com/8d3958f40c2dfb234101.js'
    script.async = true
    document.body.appendChild(script)
    document.body.classList.add('refer-init')

    const mouseScript = document.createElement('script')
    mouseScript.text = `
      (function() {
        // Init
        var container = document.querySelector(".top-wrap");
        var yellow = document.querySelector(".yellow");
        var blue = document.querySelector(".blue");

        // Mouse
        var mouse = {
          _x: 0,
          _y: 0,
          x: 0,
          y: 0,
          updatePosition: function(event) {
            var e = event || window.event;
            this.x = (e.clientX - this._x) * -1;
            this.y = (e.clientY - this._y) * -1;
          },
          setOrigin: function(e) {
            this._x = e.offsetLeft + Math.floor(e.offsetWidth);
            this._y = e.offsetTop + Math.floor(e.offsetHeight);
          },
          show: function() {
            return "(" + this.x + ", " + this.y + ")";
          }
        };

        // Track the mouse position relative to the center of the container.
        mouse.setOrigin(container);

        //-----------------------------------------

        var counter = 0;
        var updateRate = 10;
        var isTimeToUpdate = function() {
          return counter++ % updateRate === 0;
        };

        //-----------------------------------------

        var onMouseEnterHandler = function(event) {
          update(event);
        };

        var onMouseMoveHandler = function(event) {
          if (isTimeToUpdate()) {
            update(event);
          }
        };

        //-----------------------------------------


        var updateTransformStyle = function(x, y) {
          var blueStyle = "translate(" + x + "px, " + y + "px)";
          var yellowStyle = "translate(-" + x + "px, -" + y + "px)";
          yellow.style.transform = yellowStyle;
          blue.style.transform = blueStyle;
        };

        var update = function(event) {
          mouse.updatePosition(event);

          var pxAmount = 60

          updateTransformStyle(
            (mouse.x / container.offsetWidth * pxAmount).toFixed(2),
            (mouse.y / container.offsetHeight * pxAmount).toFixed(2)
          );
        };

        //-----------------------------------------

        container.onmouseenter = onMouseEnterHandler;
        container.onmousemove = onMouseMoveHandler;
      })();
     `
    document.body.appendChild(mouseScript)

    dispatch(appActions.updateZendeskVisibility(false))

    window.addEventListener('message', (event) => {
      if (event.data && _includes(event.data, 'gatherAppStarted')) {
        setGatherReady(true)
      }
    }, false)

    return () => {
      document.body.removeChild(script)
      document.body.removeChild(mouseScript)
      dispatch(appActions.updateZendeskVisibility(true))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (window.location.search.indexOf('tally') !== -1) {
      setTimeout(toggleGather, 3000)
    }
  }, [gatherReady])

  useEffect(() => {
    setFirstUserName(firstname)
    setLastUserName(lastname)
    setEmail(email)
  }, [firstname, lastname, email])

  const [accordionStatus, setAccordionStatus] = useState<boolean>(false)

  const showAllAccordion = () => {
    setAccordionStatus(true)
  }

  const FAQItems: any[] = [
    {
      heading: 'How do I refer a friend? Where can I find my referral code?',
      panel: (
        <>
          <ol>
            <li>Log in to SCMP.com, then go to “My Account Page” and select “My Referral”.</li>
            <li>Select the option to send referrals via email or your preferred social network. If you choose the email option, we will send your recipient an email with a unique link that directs them to set up their SCMP account and which is attributed back to you once their digital subscription is confirmed. If you choose the social network option, you will need to log in to your social account to manually post your referral link.</li>
            <li>The recipient(s) must use your unique email referral link OR enter your referral promo code when purchasing a new SCMP digital subscription. If they enter a different code, or do not enter a code at all, the subscription will be processed without referral benefits and will not be attributed back to you. </li>
          </ol>
        </>
      ),
    },
    {
      heading: 'How many people can I refer? ',
      panel: (
        <>
          You can refer as many people as you’d like. You will be rewarded with a 1-month extension to your active SCMP digital subscription for every 2 successful completed referrals, i.e. the recipient received your unique link and went on to purchase a digital subscription. Recipients can subscribe to any of our available plan terms via your unique referral link or your unique referral promo code.
        </>
      ),
    },
    {
      heading: 'Can I refer friends to upgrade their subscriptions?',
      panel: (
        <>
          No, the referral promotion is only eligible for those seeking new digital subscriptions and are not applicable to subscription renewal, upgrade or reactivation.
        </>
      ),
    },
    {
      heading: 'When will I receive my referral reward?',
      panel: (
        <>
          We will notify you via email for every successful referral to help you keep track of your referral progress. For every 2 successful referral subscriptions, you will receive an email to confirm your referral reward. The 1-month extension – awarded to an active subscription for every 2 referrals – will be applied within 10 business days.
        </>
      ),
    },
    {
      heading: 'Who is eligible for the SCMP digital subscription referral program? Can I refer someone if I am not a SCMP digital subscriber? ',
      panel: (
        <>
          No. You must be an active SCMP digital subscriber in order to make a referral. Your subscription must be active and without any subscription cancellation request.
        </>
      ),
    },
    {
      heading: 'Can I make a referral via the SCMP app?',
      panel: (
        <>
          Unfortunately, our referral program is not yet available on the SCMP app. You will need to sign in to your SCMP.com account via a web browser to take part in our referral program.<br /><br />
To do this, log in to SCMP.com using the username and password you used to purchase your subscription. Go to “My Account Page” then select “My Referral” to refer your friends.
        </>
      ),
    },
    {
      heading: 'How come some of my recipients cannot receive my referral invitation via email?',
      panel: (
        <>
          If your friend has previously requested to opt-out from our non-editorial emails (e.g. a newsletter), or the particular email address is on our marketing and promotion opt-out list, we will not be able to send a referral invitation to your friend via email.<br /><br /> You can still refer your friends using your personal referral link by sending them a personal email or via your preferred social network. You can also share your referral promo code with your friend to apply directly to their shopping cart at checkout.
        </>
      ),
    },
  ]

  return (
    <PageContainer id={id} className={displaySubscription() ? '' : 'non-subscriber'}>
      <ScrollToTopOnMount />
      <img src="https://pubads.g.doubleclick.net/activity;dc_iu=/8134/DFPAudiencePixel;ord=1;dc_seg=6558992371?" alt="" />
      <TopWrap className="top-wrap">
        <SvgsWrapper>
          <YellowWrap className="yellow">
            <Yellow className="yellow-svg" />
            <YellowM className="mobile" />
          </YellowWrap>
          <BlueWrap className="blue">
            <Blue className="blue-svg" />
            <BlueM className="mobile" />
          </BlueWrap>
        </SvgsWrapper>
        <Column>
          <PageHeader>
            <BackToScmp><a href="http://scmp.com">&lt; SCMP</a></BackToScmp>
            <div><StyledLogo height="64" /></div>
            <div>
              <Link to='/settings'>
                <UserHaloIcon size="medium" />
              </Link>
            </div>
          </PageHeader>
        </Column>
        <Column><PageHeading>Share SCMP,<br/>Get 1-month free</PageHeading></Column>
        <Column><Subheading>Invite <strong>2 people</strong> to subscribe to the SCMP and they’ll enjoy an <strong>extra 10% off</strong> introductory prices – and you’ll get <strong>1-month free</strong>!</Subheading></Column>
        <Column><GatherButton className={!gatherReady ? 'loading' : ''} onClick={toggleGather}>Refer A Friend Now</GatherButton></Column>
        <Column>
          <SimpleSteps>
            <h2>3 Simple Steps</h2>
            <Steps>
              <div><SvgWrap><Share /></SvgWrap>1. Share your <strong>personal referral link</strong> with friends and family through email or on social media</div>
              <div><SvgWrap><Friend /></SvgWrap>2. <strong>Your friend saves money</strong> by subscribing with your referral link</div>
              <div><SvgWrap><Earn /></SvgWrap>3. For <strong>every 2 people</strong> who subscribe, you'll <strong>get a free 1-month</strong> extension on your subscription</div>
              <ReferNow className={!gatherReady ? 'loading' : ''} onClick={toggleGather}>Refer Now</ReferNow>
            </Steps>
          </SimpleSteps>
        </Column>
        <BuddiesColumn>
          <StyledBuddies />
        </BuddiesColumn>
      </TopWrap>
      <FAQColumn>
        <div className={accordionStatus ? 'show-all-items' : ''}>
          <h2>Frequently Asked Questions</h2>

          <Accordion allowZeroExpanded={true}>
            {FAQItems.map((item) => (
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {item.heading}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {item.panel}
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
          {!accordionStatus &&
            <span onClick={showAllAccordion}>Show More</span>
          }
        </div>
      </FAQColumn>
      <Column>
        <Terms>
          <Accordion allowZeroExpanded={true}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h2>Referral Program Terms and Conditions <ArrowSVG width="24" height="24"/></h2>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  The SCMP Digital Subscription Referral Program allows SCMP digital subscribers (“Subscribers”) to earn subscription rewards by referring their friends to become new SCMP digital subscribers.
                </p>
                <p>
                  To participate, subscribers must agree to these terms, which become part of the SCMP Terms of Service.
                </p>
                <p>
                  <strong>Programme</strong>
                </p>
                <p>
                  1. By participating in this Referral Program, Subscribers are deemed to accept all the Terms and Conditions (“T&Cs”) below.
                </p>
                <p>
                  <strong>Referral Program - Eligibility</strong>
                </p>
                <p>
                  2. To be eligible for the“Referral Program, the SCMP digital subscriber (“ Subscriber”) must:
                </p>
                <p>
                  2.1 have an active SCMP digital subscription; and
                </p>
                <p>
                  2.2 the subscription has been active in the past 30 days without any subscription cancellation / refund request in process.
                </p>
                <p>
                  3. The “Subscriber” refers a person (“Referee”) to start a new SCMP digital subscription by using the unique referral promo code or a unique referral link generated via the Referral Program. The use of of promo code is subjected to the promo code T&amp;C, available at <a href="https://www.scmp.com/promotions-terms-conditions">https://www.scmp.com/promotions-terms-conditions</a>
                </p>
                <p>
                  3.1 The Referee must enter the referral promo code to complete the purchase of a SCMP digital subscription
                </p>
                <p>
                  <strong>Referral Program - Rewards</strong>
                </p>
                <p>
                  4. Subscribers must not refer themselves as Referees. A Referee who has been successfully referred by one subscriber cannot be repeatedly referred by the same subscriber or other subscriber(s).
                </p>
                <p>
                  5. All referral rewards must be used towards an active SCMP digital subscription, and cannot be transferred, returned, exchanged or converted into other form. SCMP will not entertain any request to alter the referral rewards.
                </p>
                <p>
                  6. The referral reward can only be credited to the Referer’s SCMP digital subscription account . If the account has expired or has not been active 30 days from the date of subscription reward redemption, more than 1 day from day of referral reward.
                </p>
                <p>
                  <strong>Referral Program - General Terms</strong>
                </p>
                <p>
                  7. The offering of Referral Program is subject to technical feasibility and related conditions. SCMP may exercise it's reasonable discretion to put daily limitations on the number of redemption of invitation code.
                </p>
                <p>
                  8. SCMP reserves the right to adjust, withdraw or cancel the Referral Programme, the reward, and to amend or revise any of these T&amp;Cs from time to time without prior notice and without assigning any reasons whatsoever to the extent permitted by applicable law.
                </p>
                <p>
                  If the Participant or the Referee has any enquiries towards the SCMP Referral Programme, please refer to our Referral Program FAQ or contact our Customer Support team at <a href="mailto:support@scmp.com">support@scmp.com</a>.
                </p>
                <p>
                  9. In case of any disputes, SCMP reserves the right of final decisions.
                </p>
                <p>
                  10. All digital subscriptions, including those purchased under this “SCMP Digital Subscription Referral Program” shall also be subject to the related terms and conditions in <a href="http://www.scmp.com/terms-conditions">www.scmp.com/terms-conditions</a>.
                </p>
                <p>
                  11. These Terms and Conditions shall be governed by the laws of Hong Kong.
                </p>
                <p>
                  12. Personal data collected in respect of this Referral Program shall be handled in accordance with our Privacy Policy at <a href="www.scmp.com/privacy-policy">www.scmp.com/privacy-policy</a>.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </Terms>
      </Column>
      <PageFooter>
        <div>
          <nav><a href="https://www.scmp.com/privacy-policy">Privacy Policy</a>|<a href="https://www.scmp.com/promotions-terms-conditions#Readers-Referal-2020">Terms &amp; Conditions</a></nav>
          <div>© 2020 South China Morning Post all rights reserved.</div>
        </div>
      </PageFooter>
      <NonSubscribedOverlay />
      <NonSubscribedContent>
        <header><ScmpFlag /></header>
        <div className="title">
         Our referral program is available for <strong>subscribers only</strong>.
        </div>
        <div className="subtitle">
         Please subscribe to continue.
        </div>
        <SubscribeButton href="https://subscribe.scmp.com">
         Explore Our Plans
        </SubscribeButton>
      </NonSubscribedContent>
    </PageContainer>
  )
}

const NonSubscribedOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  opacity: 0;
  transition: opacity 500ms ease 0s;
  background: black;
  pointer-events: none;

  .refer-init .non-subscriber & {
    opacity: 50%;
    transition: opacity 500ms ease 5s;
  }
`

const NonSubscribedContent = styled.div`
  padding: 0 0 32px 0;
  color: #001149;
  background: #FFF;
  width: 100%;
  max-width: 343px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 500ms ease 0s;
  pointer-events: none;

  .refer-init .non-subscriber & {
    opacity: 1;
    pointer-events: auto;
    transition: opacity 500ms ease 5s;
  }

  header {
    background: #F2F2F2;
    padding: 10px 0;
    text-align: center;
    width: 100%;
  }

  .title {
    margin: 32px 24px 16px;
    font-weight: 300;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
  }

  .subtitle {
    margin: 0 24px 24px;
    font-size: 14px;
    line-height: 125%;
    text-align: center;
    color: #001149;
  }

`

const SubscribeButton = styled.a`
  color: #001246;
  padding: 10px 20px;
  background: #FFCA05;
  text-transform: uppercase;
  border-radius: 2px;
  cursor: pointer;
  margin: 0 auto;
  font-size: 14px;
  line-height: 16px;
  display: block;
  width: 138px;
  display: block;

  ${media.mobileUpRefer`
    font-size: 16px;
    line-height: 19px;
    width: 158px;
  `}

  &:hover {
    background-color: #4585FF;
    color: white;
  }
`

const PageContainer = styled(FlexContainer)`
  width: 100%;
  min-height: 580px;
  padding: 0px;
  justify-content: center;
  font-family: Roboto, sans-serif;

  &.non-subscriber {
    overflow: hidden;
    height: 100vh;
  }
`

const GatherButton = styled.span`
  color: #001246;
  padding: 14px 20px;
  background: #FFCA05;
  text-transform: uppercase;
  border-radius: 2px;
  cursor: pointer;
  margin: 0 auto;
  font-size: 14px;
  line-height: 16px;
  width: 140px;
  display: block;

  &.loading {
    background-image: url(${LoadingGif});
    background-position: 50% 50%;
    color: transparent;
    background-repeat: no-repeat;
    background-size: auto 75%;
    pointer-events: none;
  }

  ${media.mobileUpRefer`
    font-size: 16px;
    line-height: 19px;
    width: 160px;
  `}

  &:hover {
    background-color: #4585FF;
    color: white;
  }
`

const ReferNow = styled(GatherButton)`
  width: 115px;
  margin-top: 25px;

  ${media.mobileUpRefer`
    display: none;
  `}
`

const SvgsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 98px;
  z-index: -1;
  overflow: visible;
  left: 50%;
  transform: translateX(-50%);
  min-width: 383px;


  ${media.mobileUpRefer`
    bottom: 42px;
    min-width: 1326px;
  `}

  svg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    will-change: transform;
    display: none;
    margin: 0 auto;
    max-width: 383px;

    ${media.mobileUpRefer`
      display: block;
      max-width: 1326px;
    `}
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: all 500ms ease;
    will-change: transform;
    mix-blend-mode: multiply;
  }

  .blue svg {
    ${media.mobileUpRefer`
      left: 0;
      right: -7.5px;
    `}
  }

  .yellow svg {
    left: -17px;

    ${media.mobileUpRefer`
      left: -7.5px;
    `}
  }

  .mobile {
    display: block;

    ${media.mobileUpRefer`
      display: none;
    `}
  }
`

const PageHeading = styled.div`
  font-size: 40px;
  line-height: 44px;
  margin: 32px auto 0;
  font-family: Roboto Condensed;
  text-align: center;
  text-transform: uppercase;
  color: #001246;
  max-width: 579px;

  ${media.mobileUpRefer`
    font-size: 48px;
    line-height: 54px;
    margin: 56px auto 0;
  `}

  ${media.tabletUp`
    font-size: 60px;
    line-height: 66px;
  `}
`

const Subheading = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #222834;
  margin: 20px auto;
  max-width: 524px;
  text-align: center;

  ${media.mobileUpRefer`
    font-size: 18px;
    line-height: 28px;
    margin: 24px auto;
  `}
`

const SimpleSteps = styled.div`
  background: rgba(255, 250, 230, 0.8);
  border: 2px solid rgba(255, 202, 5, 0.4);
  box-sizing: border-box;
  border-radius: 8px;
  max-width: 960px;
  padding: 32px 32px 40px;
  margin: 26px auto 0;
  text-align: center;

  ${media.mobileUpRefer`
    padding: 32px 7px 53px;
    margin-top: 64px;
  `}

  h2 {
    font-family: Roboto Condensed;
    font-weight: normal;
    font-size: 20px;
    line-height: 22px;
    margin: 0 auto 25px;
    text-transform: uppercase;
    color: #001246;

    ${media.mobileUpRefer`
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 14px;
    `}
  }

  svg {
    width: 34px;
    margin: 0 auto;

    ${media.mobileUpRefer`
      width: 48px;
    `}
  }
`

const Column = styled.div`
  padding: 0 24px;
  box-sizing: border-box;
  width: 100%;
  transform: translateZ(1px);
`

const Steps = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 792px;
  margin: 0 auto;
  justify-content: space-between;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;

  ${media.mobileUpRefer`
    flex-direction: row;
    font-size: 16px;
    line-height: 22px;
  `}

  > div {
    padding: 0 0 32px;
    margin: 0 auto;
    max-width: 240px;

    ${media.mobileUpRefer`
      padding: 0 12px;
      margin: 0;
      max-width: 270px;
      width: 33%;
    `}
  }
`

const SvgWrap = styled.div`
  height: 40px;
  margin: 0 auto;

  ${media.mobileUpRefer`
    height: 70px;
  `}
`

const FAQColumn = styled(Column)`
  background: #F2F2F2;

  h2 {
    font-family: Roboto Condensed;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;
    color: #001246;
    margin: 0 auto 24px;

    ${media.mobileUpRefer`
      font-size: 28px;
      line-height: 33px;
      margin-bottom: 32px;
    `}
  }

  > div {
    max-width: 960px;
    margin: 48px auto 64px;

    ${media.mobileUpRefer`
      margin: 72px auto;
    `}
  }

  .accordion__item {
    background: white;
    margin-bottom: 8px;
    padding: 16px;

    ${media.mobileUpRefer`
      padding: 24px;
    `}

    &:nth-child(n+5) {
      display: none;
    }

    .accordion__heading {
      font-size: 16px;
      line-height: 140%;
      font-weight: normal;
      position: relative;

      ${media.mobileUpRefer`
        font-size: 20px;
        line-height: 140%;
      `}

      svg {
        vertical-align: middle;
      }
    }

    .accordion__button {
      color: #000000;
      padding: 12px 45px 12px 12px;
      margin: -12px;
      outline: none !important;
      cursor: pointer;
      position: relative;

      &:after {
        content: "";
        height: 32px;
        width: 32px;
        background-image: url(${Arrow});
        position: absolute;
        right: 0;
        top: 7px;
        display: block;
      }

      &:hover, &:focus, &:active {
        outline: none !important;
      }

      &[aria-expanded='true'],
      &[aria-selected='true'],
      &:hover {
        color: #4585FF;
      }

      &[aria-expanded='true'],
      &[aria-selected='true'] {
        &:after {
          background-image: url(${ArrowUp});
        }
      }
    }

    .accordion__panel {
      margin-top: 18px;
      font-size: 14px;
      line-height: 20px;
      color: #757575;

      ${media.mobileUpRefer`
        margin-top: 24px;
        font-size: 16px;
        line-height: 24px;
      `}

      ol {
        padding-inline-start: 12px;
      }

      li {
        margin: 0 0 12px;
        padding-left: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .show-all-items .accordion__item:nth-child(n+5) {
    display: block;
  }

  a {
    padding: 0;
    display: block;
    width: 185px;
    background: #A1A1A1;
    border-radius: 4px;
    margin: 32px auto 0;
    font-size: 16px;
    line-height: 51px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;

    &:hover {
      background-color: #666;
    }
  }
`

const BuddiesColumn = styled(Column)`
  text-align: center;
  line-height: 0;
`

const StyledBuddies = styled(Buddies)`
  max-width: 680px;
  max-height: 126px;
  margin: -36px auto 0;
  line-height: 0;
  width: 100%;

  ${media.mobileUpRefer`
    max-height: 280px;
  `}
`

const Terms = styled.div`
  max-width: 930px;
  margin: 40px auto 40px;
  font-size: 12px;
  line-height: 15px;
  color: #222834;

  h2 {
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
    color: #222834;

    svg {
      vertical-align: middle;
      margin-top: -3px;
      margin-left: 9px;

      path {
        fill: black;
      }
    }
  }

  ol {
    padding-inline-start: 12px;
    padding-bottom: 20px;
  }

  a {
    color: #2d84ff;
  }

  li {
    margin: 0 0 12px;
    padding-left: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p {
    margin: 0 0 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion__button {
    cursor: pointer;

    &:hover, &:focus, &:active {
      outline: none !important;
    }
    &[aria-expanded='true'],
    &[aria-selected='true'] {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`

const PageFooter = styled.footer`
  background: black;
  width: 100%;

  ${media.mobileUpRefer`
    height: 68px;
  `}

  > div {
    max-width: 1172px;
    padding: 27px 25px;
    margin: 0 auto;
    font-size: 13px;
    line-height: 20px;
    color: #FFFFFF;
    display: flex;
    flex-direction: column

    ${media.mobileUpRefer`
      padding: 20px 28px;
      line-height: 28px;
      justify-content: space-between;
      flex-direction: row-reverse;
    `}
  }

  nav {
    color: #979797;
    margin-left: -14px;
    margin-bottom: 8px;

    ${media.mobileUpRefer`
      margin-bottom: 0;
      margin-left: 0;
      margin-right: -14px;
    `}

    a {
      color: #FFF;
      margin: 0 14px;
    }
  }
`

const PageHeader = styled.header`
  height: 88px;
  margin-top: 16px;
  max-width: 1155px;
  display:flex;
  justify-content: space-between;
  margin: 15px auto 0;
  font-size: 14px;
  line-height: 16px;
  color: #A1A1A1;

  a {
    color: #A1A1A1;
  }
`

const BackToScmp = styled.div`
  margin-top: 8px;
`

const StyledLogo = styled(Logo)`
  margin-top: 24px;
`

const blueAnimation = keyframes`
 0% { transform: rotate(0deg) }
 100% { transform: rotate(-360deg) }
`

const yellowAnimation = keyframes`
 0% { transform: rotate(0deg) }
 100% { transform: rotate(360deg) }
`

const BlueWrap = styled.div`
  svg {
   animation-name: ${blueAnimation};
   animation-duration: 300s;
   animation-iteration-count: infinite;
  }
`

const YellowWrap = styled.div`
  svg {
   animation-name: ${yellowAnimation};
   animation-duration: 300s;
   animation-iteration-count: infinite;
  }
`

const TopWrap = styled.div`
  position: relative;
  width: 100%;
`

export default ReferContainer
