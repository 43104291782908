import styled from 'styled-components'
import React from 'react'
import { media } from 'utils/style'
import { NODE_ENV } from 'config/app.config'
import PostiesSubBox from 'assets/images/posties_sub_box.png'
import PostiesSubBoxMobile from 'assets/images/posties_sub_box_mobile.png'
import { ReactComponent as PostiesTickSvg } from '../../assets/svg/posties-tick.svg'

type Props = {
  trackingGTM: () => void,
}

const PostiesSubscriptionPageContent: React.FunctionComponent<Props> = ({ ...Props }) => {
  const { trackingGTM } = Props
  return (
    <>
      <StartSubscription>Start your subscription today</StartSubscription>
      <PostiesButton
        onClick={trackingGTM}
        href={NODE_ENV === 'production' ? 'https://subscribe.scmp.com/posties' : 'https://staging-rosetta.product-web.dev-2.scmp.tech/posties'} >
        <SubscriptionTextWrapper>
          <SubscriptionText >SUBSCRIBE</SubscriptionText>
        </SubscriptionTextWrapper>
      </PostiesButton>
      <PostiesWrapper>
        <PostiesHeader>Subscribe to Posties to access all our great content</PostiesHeader>
        <PostiesContent>
          <li><StyledTick /> Unlimited access to Posties website</li>
          <li><StyledTick /> Build up weekly reading habits</li>
          <li><StyledTick /> Eligible to apply for events</li>
        </PostiesContent>
      </PostiesWrapper>
    </>
  )
}

const PostiesWrapper = styled.div`
  display: flex;
  width: 344px;
  height: 401px;
  padding: 40px 16px;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  flex-shrink: 0;
  margin-top: 26px;
  border: 3px solid #333;
  border-radius: 10px;
  display: flex;
  background-image: url(${PostiesSubBoxMobile});
  background-size: 100%;

  ${media.tabletUp`
    background-image: url(${PostiesSubBox});
    background-size: 100%;
    width: 600px;
    height: 376px;
    padding: 32px 40px;
  `}
`

const PostiesButton = styled.a`
  position: relative;
  cursor: pointer;
  --offset-container-border-background-color: #fecc17;
  --offset-container-border-radius: 100px;
  --offset-container-border-width: 2px;
  --offset-container-offset: 4;
  display: inline-block;
  border-radius: var(--offset-container-border-radius);
  background-color: #ffffff;
  padding-block: 12px;
  padding-inline: 24px;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  font-family: Fredoka,sans-serif;
  line-height: 20px;
  text-transform: uppercase;

  &::before,
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    inline-size: 100%;
    block-size: 100%;
    border-radius: var(--offset-container-border-radius);
    box-sizing: border-box;
  }

  &::before {
    inset-block-start: calc(var(--offset-container-offset) * 1px);
    inset-inline-start: calc(var(--offset-container-offset) * -1px);
    background-color: var(--offset-container-border-background-color);
  }

  &::after {
    border: var(--offset-container-border-width) #000000 solid;
    pointer-events: none;
  }
`

const PostiesHeader = styled.div`
  color: #FF7C1D;
  text-align: center;
  font-family: Angkor;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
  letter-spacing: -0.54px;
`

const StartSubscription = styled.h1`
  font-family: Angkor;
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: center;
  padding: 8px 0;
  margin-bottom: 8px;
  color: #333;
  margin-top: 30px;
  max-width: 300px;

  ${media.tabletUp`
    font-size: 32px;
    margin-top: 0;
    padding-top: 0;
    max-width: 100%;
  `}
`

const PostiesContent = styled.ul`
  color: #555;
  text-align: left;
  font-family: Fredoka;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  list-style: none;
  padding-left: 0;

  li {
    margin-bottom: 8px;
    display: flex;
  }
`

const SubscriptionTextWrapper = styled.div`
  position: relative;
`

const SubscriptionText = styled.div`
  color: #001246;
  cursor: pointer;
`

const StyledTick = styled(PostiesTickSvg)`
  height: 20px;
  width: 24px;
  margin-right: 10px;
`

export default PostiesSubscriptionPageContent
