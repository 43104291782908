import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { media } from 'utils/style'
import { StyledButton } from 'components/styled'
import { DataLayerEventCategory, DataLayerEventAction, DataLayerGtmCustomEventName } from 'config/constants'
import axios from 'axios'
import { triggerGtmCustomEvent } from 'utils/tracking'

type Props = {
  email?: string,
  onClickOKButton: () => void,
  }

const ForgetPasswordComponent: React.FunctionComponent<Props> = ({ ...Props }) => {
  const { email, onClickOKButton } = Props
  const [sent, updateSent] = useState<boolean>(false)

  const sendForgetPasswordEmail = async () => {
    if (email === '') {
      return false
    }
    try {
      await axios.post('/forgot-password', { email })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    sendForgetPasswordEmail()
    triggerGtmCustomEvent(DataLayerEventAction.FORGET_PASSWORD, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN)
  }, [email])

  const sendAgain = () => {
    if (email === '' || sent) {
      return false
    }
    sendForgetPasswordEmail()
    updateSent(true)
  }
  return (
    <>
      <Description marginBottom='20px'>We sent an email to you at <EmailLabel>{email}.</EmailLabel>Please check your mail box and follow the instruction to reset your password.</Description>
      <Description marginBottom='24px'>Not receive any email? <SendAgain onClick={() => sendAgain()} displayed={sent}>Send again</SendAgain>.</Description>
      <StyledButton
        maxWidth='272px'
        backgroundColour='#FFCA05'
        borderColor='#FFCA05'
        color='#000'
        fontSize='16px'
        lineHeight='19px'
        padding='12px'
        onClick={() => onClickOKButton()}>OK</StyledButton>
    </>
  )
}

interface DescriptionStyle {
  marginBottom?: string
}

interface SendAgainStyle {
  displayed?: boolean
}

const Description = styled.div<DescriptionStyle>`
  color: #222222;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '19px')};
  padding: 0px;
  ${media.tabletUp`
    padding: auto;
  `}
`
const EmailLabel = styled.span`
  color: #000;
  font-weight: bold;
`

const SendAgain = styled.a<SendAgainStyle>`
  color: ${props => (props.displayed ? '#999' : '#EB4254')};
  font-weight: bold;
  cursor: pointer;
`

export default ForgetPasswordComponent
