import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { ReactComponent as AlertCircle } from 'assets/svg/alert_circle.svg'
import { media } from 'utils/style'
import axios from 'axios'
import { triggerGtmEvent } from 'utils/tracking'
import { SCMP_COM_HOST } from 'config/app.config'
import RosettaSDK from '@product/rosetta-sdk'
import AutoRenewConfirmationPopup from 'components/SubscriptionBox/AutoRenewConfirmationPopup'
import { useTrackRosettaGA4Event } from '../Piano/hooks'

const AutoRenewWidget: React.FunctionComponent = () => {
  const { trackRosettaGA4Event } = useTrackRosettaGA4Event()
  const engine = RosettaSDK.rulesEngine
  const dayLeft: number = engine.getOutcome('user.overallSubscriptionDaysLeft')
  const showAutoRenewWidget: boolean = engine.getOutcome('ui.account.showAutoRenewWidget')
  const subscriptionId: string = engine.getOutcome('user.overallAutoRenewSubscriptionId')
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const turnOnAutoRenew = async (subscriptionId: any, callback: any) => {
    try {
      const response = await axios.post(`https://${SCMP_COM_HOST}/piano/renew`, { subscriptionId })
      const { code, data } = response.data
      if (code === 0 && data === true) {
        callback()
      }
    } catch (err) {
      console.log('response err', err)
    }
  }

  const tryEnableAndShowDialog = async () => {
    triggerGtmEvent(
      'Subscription Management - Account App/Expiry Reminders/Impression/Enable',
      'Subscription Management',
      'tracking',
      dayLeft.toString(),
    )
    trackRosettaGA4Event({
      category: 'myaccount',
      subcategory: 'subscription',
      action: 'imp',
      customized_parameters: {
        trigger_point: 'expiry reminder',
        call_to_action: 'enable auto-renew',
        event_name: 'myaccount_subscription_click',
      },
    })
    const callback = () => {
      setIsShowModal(true)
      triggerGtmEvent(
        'Subscription Management - Account App/Expiry Reminders/Impression/Enable/Thank you Impression',
        'Subscription Management',
        'tracking',
        dayLeft.toString(),
      )
      trackRosettaGA4Event({
        category: 'subs',
        subcategory: 'result',
        action: 'imp',
        customized_parameters: {
          call_to_action: 'enable auto-renew',
          result: 'success',
          result_source: 'rosetta',
          event_name: 'subs_result_imp',
        },
      })
    }
    await turnOnAutoRenew(subscriptionId, callback)
  }

  useEffect(() => {
    triggerGtmEvent(
      'Subscription Management - Account App/Expiry Reminders/Impression',
      'Subscription Management',
      'tracking',
      dayLeft.toString(),
    )
    trackRosettaGA4Event({
      category: 'myaccount',
      subcategory: 'subscription',
      action: 'imp',
      customized_parameters: {
        trigger_point: 'expiry reminder',
        call_to_action: 'enable auto-renew',
        event_name: 'myaccount_subscription_imp',
      },
    })
  }, [])

  const autoRenewElement = (
    <AutoRenewWrapper>
      <ContentInner>
        <AlertIcon />
        <div>
          {dayLeft === 0
            ? <>Your subscription <ExpiresIn>expires today.</ExpiresIn></>
            : dayLeft > 0 && dayLeft <= 30 ? <>Your subscription <ExpiresIn>expires in {dayLeft} {dayLeft > 1 ? 'days' : 'day'}.</ExpiresIn></> : null
          }
          <strong>Enable auto-renew</strong> to avoid losing access.
        </div>
      </ContentInner>
      <EnableButton onClick={() => tryEnableAndShowDialog()}>Enable</EnableButton>
      {isShowModal && <AutoRenewConfirmationPopup />}
    </AutoRenewWrapper>
  )

  return showAutoRenewWidget ? autoRenewElement : null
}

const ExpiresIn = styled.strong`
  color: #EB4254;

  margin-right: 2px;
`

const AutoRenewWrapper = styled.div`
  color: #001246;
  font-family: Roboto;
  font-size: 14px;
  line-height: 140%;
  background: rgba(235, 66, 84, 0.05);
  border: 1px solid rgba(235, 66, 84, 0.5);
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;

  ${media.tabletUp`
    margin-bottom: 0;
  `}
`

const EnableButton = styled.button`
  font-family: Roboto;
  padding: 9px 16px;
  color: white;
  font-size: 14px;
  line-height: 20px;
  margin-left: 16px;
  background: #4585ff;
  border-radius: 3px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
`

const ContentInner = styled.div`
  line-height: 20px;
  display: flex;
  align-items: flex-start;
`

const AlertIcon = styled(AlertCircle)`
  width: 24px;
  height: 24px;
  vertical-align: top;
  margin-right: 10px;
  flex-shrink: 0;
`

export default AutoRenewWidget
