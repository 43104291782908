import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import agreementOnIcon from 'assets/svg/icon-agreement-on.svg'
import agreementOffIcon from 'assets/svg/icon-agreement-off.svg'
import FacebookLogo from 'assets/svg/icon_fb.svg'
import GoogleLogo from 'assets/svg/icon_google.svg'
import EmailLogo from 'assets/svg/icon-email.svg'
import { FlexContainer } from 'components/flex'
import { ButtonWithLogo } from 'components/buttonWithLogo'

import { REGISTRATION_STEP } from 'states/constants/registration'
import * as registrationActions from 'states/actions/registration'
import { AppState } from 'states/reducers'

import { AnimatedFlexContainer } from 'components/animation'
import { DataLayerEventCategory, DataLayerEventAction, DataLayerGtmCustomEventName } from 'config/constants'
import { triggerGA4GtmEvent, triggerGtmCustomEvent } from 'utils/tracking'

type Props = {
  className?: any,
}
const CreateAnAccountComponent: React.FunctionComponent<Props> = ({ ...Props }) => {
  const dispatch = useDispatch()
  const { optIn } = useSelector((state: AppState) => state.registration)
  const callbackUrl = useSelector((state: AppState) => state.signin.callbackUrl)
  const destination = useSelector((state: AppState) => state.application.redirectDestinationUrl)

  const facebookRegHandler = (): void => {
    try {
      const path = `/login/facebook/?callback=${callbackUrl}${destination ? '&destination=' + destination : ''}${optIn ? '&optIn=' + optIn : ''}`
      triggerGtmCustomEvent(DataLayerEventAction.FACEBOOK_REGISTER, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN, path)
      triggerGA4GtmEvent({
        category: 'login',
        subcategory: 'result',
        action: 'sys',
        customized_parameters: {
          method: 'facebook',
          result: 'register_success',
        },
      })
      window.location.href = path
    } catch (e) {
      console.log('An error has been caught while login in with facebook ', e)
    }
  }

  const googleRegHandler = (): void => {
    try {
      const path = `/login/google/?callback=${callbackUrl}${destination ? '&destination=' + destination : ''}${optIn ? '&optIn=' + optIn : ''}`
      triggerGtmCustomEvent(DataLayerEventAction.GOOGLE_REGISTER, DataLayerEventCategory.LOGIN, DataLayerGtmCustomEventName.LOGIN, path)
      triggerGA4GtmEvent({
        category: 'login',
        subcategory: 'result',
        action: 'sys',
        customized_parameters: {
          method: 'google',
          result: 'register_success',
        },
      })
      window.location.href = path
    } catch (e) {
      console.log('An error has been caught while login in with google ', e)
    }
  }

  const emailRegHandler = (): void => {
    dispatch(registrationActions.updateRegistrationStep(REGISTRATION_STEP.CREATE_ACCOUNT_WITH_EMAIL))
  }

  const updateAgreementHandler = (): void => {
    dispatch(registrationActions.updateRegistrationOptInAgreement(!optIn))
  }

  return (
    <ComponentContainer id='create-an-account' animationType='fadeIn' flexDirection='column' alignItem='center' justifyContent='space-between' className={Props.className}>
      <RegisterMethodContainer flexDirection='column' justifyContent='space-between'>
        <Title>Create an account</Title>
        <RegisterButton onClick={facebookRegHandler} image={FacebookLogo} nonBoldtext='WITH' boldText='FACEBOOK' backgroundColor='#1977F2'/>
        <RegisterButton onClick={googleRegHandler} image={GoogleLogo} nonBoldtext='WITH' boldText='GOOGLE' backgroundColor='#4285F4'/>
        <RegisterButton onClick={emailRegHandler} image={EmailLogo} nonBoldtext='WITH' boldText='EMAIL' backgroundColor='#FFCA05' textColor='#000000'/>
      </RegisterMethodContainer>
      <AgreementContainer>
        <Agreement
          activeImage={agreementOnIcon}
          disabledImage={agreementOffIcon}
          state={optIn}
          onClick={updateAgreementHandler}
        />
        <AgreementText onClick={updateAgreementHandler}>I agree to use my email address for the direct marketing of publications from South China Morning Post Publishers Limited.</AgreementText>
      </AgreementContainer>

      <HelperText>
        Already have an account? <HelperUrl to="/login">Sign In</HelperUrl>
      </HelperText>
    </ComponentContainer>
  )
}

const ComponentContainer = styled(AnimatedFlexContainer)`
  min-width: 375px;
  flex: 1;
`

const Title = styled.span`
  color: #001246;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 24px;
`

const RegisterMethodContainer = styled(FlexContainer)`
  min-width: 270px;
`

const RegisterButton = styled(ButtonWithLogo)`
  margin-bottom: 16px;
`

const AgreementContainer = styled(FlexContainer)`
  flex: 1;
`

const AgreementText = styled.span`
  height: 42px;
  width: 244px;
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;
  margin-bottom: 15px;
  cursor: pointer;
`

interface AgreementProps {
  state?: boolean
  activeImage?: string
  disabledImage?: string
}
const Agreement = styled.div<AgreementProps>`
  background: url(${({ state, activeImage, disabledImage }) => state ? activeImage : disabledImage}) center center no-repeat;
  height: 18px;
  width: 18px;
  cursor: pointer;
`

const HelperText = styled.span`
  height: 19px;
  width: 236px;
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
`

const HelperUrl = styled(Link)`
  text-decoration: none;
  color: #EB4254;
  font-weight: bold;
  font-family: Roboto;
`

export default CreateAnAccountComponent
