import get from 'lodash/get'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import PhotoPlaceholder from 'assets/svg/photo-placeholder.svg'
import { ReactComponent as SmallIconArticleSvg } from 'assets/svg/smallicon_article.svg'
import { ReactComponent as CornerLineSvg } from 'assets/svg/corner_line-corner.svg'

import { FlexContainer } from 'components/flex'

import { AppState } from 'states/reducers'

import { triggerGA4GtmEvent } from 'utils/tracking'

type Props = {
  comments: any[]
  hasMoreConversations: boolean
  loading: boolean
  loadMoreHandler: any
}

interface CommentGroupsInterface {
  [date: string]: {
    title: string
    items: any[]
  }
}

const CommentList: React.FunctionComponent<Props> = ({ comments, hasMoreConversations, loading, loadMoreHandler }) => {
  const { userPic } = useSelector((state: AppState) => state.userInfo)
  const [commentGroups, updateCommentGroups] = useState<any>([])
  useEffect(() => {
    const commentGroups: CommentGroupsInterface = {}

    comments.map((comment: any) => {
      const commentDate = moment(Date.parse(comment.createdAt)).format('DD MMM, YYYY')

      if (!commentGroups[commentDate]) {
        commentGroups[commentDate] = { title: commentDate, items: [] }
      }
      commentGroups[commentDate].items.push(comment)

      return commentGroups
    })
    updateCommentGroups(commentGroups)
  }, [comments])

  const getConversationUrl = (articleUrl: string, commentId: string, d: string): string => {
    return `${articleUrl}?commentId=${commentId}#comments`
  }

  const isReply = (item: any) => {
    return get(item, 'parent.id', null)
  }

  const getViewConversationLinkClickHandler = (articleId: string) => () => {
    triggerGA4GtmEvent({
      category: 'comment',
      subcategory: 'popup',
      action: 'imp',
      customized_parameters: {
        article_id: articleId,
        trigger_point: 'my_account',
      },
    })
  }

  return (
    <GroupList>
      {Object.keys(commentGroups).map((date: string, group: any) => (
        <CommentGroup key={date}>
          <GroupTitle>{ date }</GroupTitle>
          <GroupContainer>
            {commentGroups[date].items.map((item: any) => {
              return (
                <CommentItem key={item.id} wrap='nowrap'>
                  <AuthorContainer flexDirection='column' alignItem='flex-start'>
                    <UserIcon src={userPic || PhotoPlaceholder}></UserIcon>
                  </AuthorContainer>
                  <CommentContainer alignItem='flex-start'>
                    <CommentTextContainer isResponseTo={isReply(item)} flexDirection='column'>
                      {
                        isReply(item) && <ResponseTo>Replied</ResponseTo>
                      }
                      <CommentText dangerouslySetInnerHTML={{ __html: get(item, 'body', '') }} isResponseTo={isReply(item)} />
                    </CommentTextContainer>
                    <ArticleTitleContainer wrap='nowrap'>
                      <CornerLine />
                      <ArticleLink target='_blank' href={ item.story.url }>
                        <ArticleTitleWrapper alignItem='center' wrap='nowrap'>
                          <SmallIconArticle width='12px' height='13px' />
                          <ArticleTitle>{ item.story.title }</ArticleTitle>
                        </ArticleTitleWrapper>
                      </ArticleLink>
                    </ArticleTitleContainer>
                    { false && <UnderModeration alignItem='stretch'>Under Moderation</UnderModeration> }
                    <CommentLink target='_blank' href={`${getConversationUrl(item.story.url, item.id, item.id)}`} onClick={getViewConversationLinkClickHandler(item.story.id)}>View conversations</CommentLink>
                  </CommentContainer>
                </CommentItem>
              )
            })}
          </GroupContainer>
        </CommentGroup>
      ))}
      <MoreContainer justifyContent='center' alignItem='center' onClick={loadMoreHandler} hasMoreConversations={hasMoreConversations}>
        { !loading && hasMoreConversations && <MoreButton alignItem='center' justifyContent='center'>MORE CONVERSATIONS</MoreButton> }
      </MoreContainer>
    </GroupList>
  )
}

interface MoreContainerProps {
  hasMoreConversations: boolean
}

interface CommentTextContainerProps {
  isResponseTo: boolean
}

interface CommentTextProps {
  isResponseTo: boolean
}

const GroupList = styled.div`
  background: #F2F2F2;
  padding: 8px 8px 0;
`

const GroupContainer = styled.div``

const CommentGroup = styled.div`
  background: #FFFFFF;
  border-radius: 5px;
  padding: 12px 12px 20px;
  margin-top: 8px;
  &:first-child {
    margin-top: 0;
  }
`

const GroupTitle = styled.div`
  color: #001246;
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
`

const CommentItem = styled(FlexContainer)`
  margin-top: 20px;
`

const AuthorContainer = styled(FlexContainer)`
  margin-right: 8px;
`

const UserIcon = styled.img`
  align-self: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
`

const ArticleTitleContainer = styled(FlexContainer)`
  margin-top: 8px;
`

const ArticleLink = styled.a``

const ArticleTitleWrapper = styled(FlexContainer)`
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #F2F2F2;
  padding: 8.5px;
  max-width: 302px;
`

const CornerLine = styled(CornerLineSvg)`
  margin-right: 4px;
  min-width: 12px;
`

const ArticleTitle = styled.div`
  color: #000000;
  font-family: Merriweather;
  font-size: 12px;
  font-style: italic;
  letter-spacing: 0;
  line-height: 15px;
`

const CommentContainer = styled(FlexContainer)`
  max-width: 607px;
  flex-direction: column;
`

const CommentTextContainer = styled(FlexContainer)<CommentTextContainerProps>`
  padding-left: ${({ isResponseTo }) => isResponseTo ? '14px' : '0'};
  border-left: ${({ isResponseTo }) => isResponseTo ? '2px solid #D7D7D7' : '0'};
`

const CommentText = styled.div<CommentTextProps>`
  color: #000000;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: ${({ isResponseTo }) => isResponseTo ? '4px' : '0'};
`

const ResponseTo = styled.div`
  color: #666666;
  font-family: "Roboto Condensed";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
`

const UnderModeration = styled(FlexContainer)`
  border-radius: 2px;
  background-color: #666666;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding: 2px 4px;
  text-transform: uppercase;
`

const CommentLink = styled.a`
  margin-top: 8px;
  display: inline-block;
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
  cursor: pointer;
`

const MoreContainer = styled(FlexContainer)<MoreContainerProps>`
  background-color: #F2F2F2;
  height: ${({ hasMoreConversations }) => hasMoreConversations ? '76px' : '8px'};
`

const MoreButton = styled(FlexContainer)`
  box-sizing: border-box;
  border: 1px solid #4585FF;
  border-radius: 2px;
  text-transform: uppercase;
  color: #4585FF;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  height: 44px;
  width: 250px;
  cursor: pointer;
`

const SmallIconArticle = styled(SmallIconArticleSvg)`
  min-width: 20px;
  margin-right: 8px;
  g {
    fill: #22252b;
  }
`

export default CommentList
