import { Route, Redirect } from 'react-router-dom'

import CommonPage from '../../containers/CommonPageContainer'
import _isEmpty from 'lodash/isEmpty'
import * as appActions from 'states/actions/application'
import { useDispatch } from 'react-redux'
import _includes from 'lodash/includes'

const getOriginalUrl = (isSignedIn: boolean) => {
  const urlParams = new URLSearchParams(window.location.search)
  const _destination: string = urlParams.get('destination') || ''

  let originalUrl = ''

  if (!isSignedIn && _isEmpty(_destination)) {
    if (window.location.pathname !== '/login') {
      originalUrl = window.location.href
    }
  }

  return originalUrl
}

const checkIsAuthorized = (
  authed: boolean,
  isRequireCorpSub: boolean,
  isCorpSubscriber: boolean,
  isStaff: boolean,
  requireCorpSubTier: string[] | undefined,
  corpSubscriberTier: string,
) => {
  let isAuthorized: boolean = authed

  if (isRequireCorpSub) {
    if (isStaff) {
      isAuthorized = true
    } else {
      if (!isCorpSubscriber) {
        isAuthorized = false
      }

      // handle page doesn't allow to view
      if (requireCorpSubTier && corpSubscriberTier && !_includes(requireCorpSubTier, corpSubscriberTier)) {
        isAuthorized = false
      }
    }
  }

  return isAuthorized
}

export const AuthOnlyRoute = ({
  component: Component,
  authed,
  withContainer,
  isRequireCorpSub,
  isCorpSubscriber,
  requireCorpSubTier,
  corpSubscriberTier,
  userLevel,
  ...rest
}) => {
  const dispatch = useDispatch()
  const updateDestination = (destination: string) => {
    dispatch(appActions.updateDestinationUrl(destination))
  }

  const originalUrl = getOriginalUrl(authed)
  const isAuthorized = checkIsAuthorized(authed, isRequireCorpSub, isCorpSubscriber, userLevel.isStaff)

  // TODO:
  // 1. account.scmp.com/bookmark => /login?destination=/bookmark
  // 2. account.scmp.com/bookmark?destination=www.scmp.com => /login?destination=www.scmp.com
  if (originalUrl) {
    updateDestination(originalUrl)
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          isAuthorized
            ? (
                withContainer
                  ? <CommonPage id={ '' } component={ Component } props={ props }/>
                  : <Component props={ props }/>
              )
            : <Redirect exact to={{
              pathname: '/login',
              search: originalUrl ? `?destination=${originalUrl}` : '',
            }} />
        )
      }}
    />
  )
}

export const UnauthOnlyRoute = ({ component: Component, authed, isIpAccess, isRequireCorpSub, noRedirect, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          authed === false || noRedirect
            ? <Component isIpAccess={isIpAccess} { ...props } />
            : <Redirect to={{ pathname: '/settings' }} />
        )
      }
      }
    />
  )
}
