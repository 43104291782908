
const hasValue = (value: string): boolean => {
  return !!value.trim().length
}

const hasNumber = (value: string) => {
  return /[0-9]/.test(value)
}

const hasMixed = (value: string) => {
  return /[a-z]/.test(value) && /[A-Z]/.test(value)
}

const hasSpecial = (value: string) => {
  return /[!#@$%^&*)(+=._-]/.test(value)
}

const isValidEmail = (value: string) => {
  return /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/.test(value)
}

const isEmailWithPlusSign = (value: string) => {
  return /(\+d{0,10})@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})/.test(value)
}

const isValidMobile = (value: string) => {
  return /^[0-9]*$/.test(value)
}

export {
  hasValue,
  hasNumber,
  hasMixed,
  hasSpecial,
  isValidEmail,
  isEmailWithPlusSign,
  isValidMobile,
}
