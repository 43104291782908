import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FlexContainer } from 'components/flex'
import { media } from 'utils/style'
import EmailAlerts from './emailAlerts'
import FollowingList from './followingList'
import { useDispatch } from 'react-redux'
import _get from 'lodash/get'
import _forEach from 'lodash/forEach'
import _isEmpty from 'lodash/isEmpty'
import _reverse from 'lodash/reverse'
import * as appActions from 'states/actions/application'
import GET_FOLLOW_ITEMS from 'universal/apollo/d8/GET_FOLLOW_ITEMS.gql'
import { useQuery } from '@apollo/react-hooks'

interface UserFollowList {
  authorList: any[]
  topicList: any[]
  knowledgeList: any[]
  sectionList: any[]
}

const FollowingContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  useQuery(GET_FOLLOW_ITEMS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!_isEmpty(data)) {
        const rawFollowItems = [..._get(data, 'currentUserContext.preferencesProfiles.entity.fieldSectionsTopicsAuthors', [])]
        const processedData = _reverse(rawFollowItems)
        const userFollowList: UserFollowList = {
          authorList: [],
          topicList: [],
          knowledgeList: [],
          sectionList: [],
        }
        _forEach(processedData, (data: any) => {
          if (_get(data, 'entity.type', '') === 'TaxonomyTermTopics') {
            userFollowList.topicList.push({
              entityId: _get(data, 'entity.entityId', ''),
              name: _get(data, 'entity.entityLabel', ''),
              urlAlias: _get(data, 'entity.entityUrl.path', ''),
            })
          }
          if (_get(data, 'entity.type', '') === 'TaxonomyTermAuthor') {
            userFollowList.authorList.push({
              entityId: _get(data, 'entity.entityId', ''),
              name: _get(data, 'entity.entityLabel', ''),
              urlAlias: _get(data, 'entity.entityUrl.path', ''),
            })
          }
          if (_get(data, 'entity.type', '') === 'TaxonomyTermSection') {
            if (_get(data, 'entity.taxonomyTermParents[0].entityLabel', '') === 'Knowledge') {
              userFollowList.knowledgeList.push({
                entityId: _get(data, 'entity.entityId', ''),
                name: _get(data, 'entity.entityLabel', ''),
                urlAlias: _get(data, 'entity.entityUrl.path', ''),
              })
            } else {
              userFollowList.sectionList.push({
                entityId: _get(data, 'entity.entityId', ''),
                name: _get(data, 'entity.entityLabel', ''),
                urlAlias: _get(data, 'entity.entityUrl.path', ''),
              })
            }
          }
        })
        dispatch(appActions.updateFollowList(userFollowList))
      }
    },
  })

  useEffect(() => {
    dispatch(appActions.updateZendeskVisibility(false))
    return () => {
      dispatch(appActions.updateZendeskVisibility(true))
    }
  }, [])

  return (
    <PageContainer id='following-container' flexDirection='column' alignItem='stretch' justifyContent='flex-start' wrap='no-wrap'>
      <EmailAlerts />
      <FollowingList />
    </PageContainer>
  )
}

const PageContainer = styled(FlexContainer)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  padding-bottom: 20px;
  max-width: 572px;
  ${media.mobileUp`
    margin: 0;
    padding: 0;
  `}
  ${media.smallDesktopUp`
    max-width: 680px;
  `}
`

export default FollowingContainer
