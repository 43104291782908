import React from 'react'
import styled from 'styled-components'
import ReportItemContainer from './reportItemContainer'
import { Report } from './reportItem'
import { media } from 'utils/style'

import NoResultPage from 'components/CorporateSubscription/NoResultPage'

type Props = {
  reports: Report[]
}

const ReportsContainer: React.FunctionComponent<Props> = ({ reports }) => {
  return (
    <PageContainer id='corporate-subscription-reports-container'>
      <Title>Policy Briefing and Industry Reports</Title>
      <Description>SCMP Research Briefing Reports produces key industry and policy reports, as well as the yearly China Internet Report, to provide your company with access to actionable insights and proprietary data that digests China’s latest policy and economic development, and examines the implications critical to your industries priorities and operations.</Description>
      {reports.length > 0
        ? <ReportWrapper><ReportItemContainer reports={reports}/></ReportWrapper>
        : <NoResultPage />
      }
    </PageContainer>
  )
}

const ReportWrapper = styled.div`
  margin-top: 24px;

  ${media.tabletUp`
    margin-top: 32px;
  `}
`

const PageContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const Title = styled.div`
  margin-top: 24px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  ${media.tabletUp`
    margin-top: 0px;
    margin-bottom: 0px;
  `}
`

const Description = styled.div`
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;

  margin-top: 12px;

  ${media.tabletUp`
    margin-top: 16px;
  `}
`

export default ReportsContainer
