import country from './_options/country.json'
import qualification from './_options/qualification.json'
import position from './_options/position.json'
import jobFunction from './_options/job_function.json'
import industry from './_options/industry.json'
import annualIncome from './_options/annual_income.json'

import {
  UPDATE_MODAL_MESSAGE,
  UPDATE_UNFOLLOW_MODAL_MESSAGE,
  UPDATE_REDIRECT_DESTINATION_URL,
  UPDATE_REDIRECT_SOURCE,
  UPDATE_SOICAL_MEDIA_CONNECTION_ERROR,
  UPDATE_REDIRECT_BEHAVIOUR,
  UPDATE_FLOW_TYPE,
  UPDATE_PAGE_URL,
  UPDATE_PIANO_STATUS,
  UPDATE_ZENDESK_STATUS,
  UPDATE_ZENDESK_VISIBILITY,
  UPDATE_PIANO_IS_SUPPORTED,
  UPDATE_PIANO_CONVERSIONS,
  UPDATE_PIANO_IS_CORP_SUBSCRIBER,
  UPDATE_PIANO_CORP_SUBSCRIBER_TIER,
  UPDATE_PIANO_IS_YP_SUBSCRIBER,
  UPDATE_FOLLOW_LIST,
  UPDATE_IS_GTM_READY,
} from '../constants/action-types'

/**
 * TODO: No API from drupal to get the availale options, temp hard code options in JSON
 */
const initialState = {
  selectOption: {
    country,
    qualification,
    position,
    jobFunction,
    industry,
    annualIncome,
  },
  modalMessage: '',
  unFollowModalMessage: '',
  uuid: '',
  actionType: '',
  id: '',
  modalDestinationUrl: '',
  redirectDestinationUrl: '',
  redirectSource: '',
  socialMediaError: { media: '', code: '' },
  redirectBehaviour: { from: '', destination: '' },
  flowType: '',
  isPianoReady: false,
  isPianoSupported: null,
  ifFreeSubscriber: null,
  isCorpSubscriber: null,
  isYPSubscriber: null,
  corpSubscriberTier: null,
  isZendeskReady: false,
  isZendeskVisible: true,
  isGtmReady: false,
  userFollowList: {
    authorList: [],
    topicList: [],
    knowledgeList: [],
  },
  pageUrl: '',
}

export default function application (state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_MODAL_MESSAGE:
      return {
        ...state,
        modalMessage: action.message,
        modalDestinationUrl: action.destination,
      }
    case UPDATE_UNFOLLOW_MODAL_MESSAGE:
      return {
        ...state,
        uuid: action.uuid,
        unFollowModalMessage: action.unFollowModalMessage,
        actionType: action.actionType,
        id: action.id,
      }
    case UPDATE_REDIRECT_DESTINATION_URL:
      return {
        ...state,
        redirectDestinationUrl: action.destination,
      }
    case UPDATE_FLOW_TYPE:
      return {
        ...state,
        flowType: action.flowType,
      }
    case UPDATE_REDIRECT_SOURCE:
      return {
        ...state,
        redirectRedirectSource: action.redirectSource,
      }
    case UPDATE_SOICAL_MEDIA_CONNECTION_ERROR:
      return action.media && action.code
        ? {
            ...state,
            socialMediaError: {
              media: action.media,
              code: action.code,
            },
          }
        : {
            ...state,
            socialMediaError: { media: '', code: '' },
          }
    case UPDATE_REDIRECT_BEHAVIOUR:
      return action.from
        ? {
            ...state,
            redirectBehaviour: {
              from: action.from,
              destination: action.destination,
            },
          }
        : {
            ...state,
            redirectBehaviour: { from: '', destination: '' },
          }
    case UPDATE_PIANO_STATUS:
      return {
        ...state,
        isPianoReady: action.isPianoReady,
      }
    case UPDATE_PIANO_IS_SUPPORTED:
      return {
        ...state,
        isPianoSupported: action.isPianoSupported,
      }
    case UPDATE_PIANO_CONVERSIONS:
      return {
        ...state,
        pianoConversions: action.pianoConversions,
      }
    case UPDATE_PIANO_IS_CORP_SUBSCRIBER:
      return {
        ...state,
        isCorpSubscriber: action.isCorpSubscriber,
      }
    case UPDATE_PIANO_CORP_SUBSCRIBER_TIER:
      return {
        ...state,
        corpSubscriberTier: action.corpSubscriberTier,
      }
    case UPDATE_PIANO_IS_YP_SUBSCRIBER:
      return {
        ...state,
        isYPSubscriber: action.isYPSubscriber,
      }
    case UPDATE_IS_GTM_READY:
      return {
        ...state,
        isGtmReady: action.isGtmReady,
      }
    case UPDATE_ZENDESK_STATUS:
      return {
        ...state,
        isZendeskReady: action.isZendeskReady,
      }
    case UPDATE_ZENDESK_VISIBILITY:
      return {
        ...state,
        isZendeskVisible: action.isZendeskVisible,
      }
    case UPDATE_FOLLOW_LIST:
      return {
        ...state,
        userFollowList: action.userFollowList,
      }
    case UPDATE_PAGE_URL:
      return {
        ...state,
        pageUrl: action.pageUrl,
      }
    default:
      return state
  }
}
