import React from 'react'
import styled from 'styled-components'

import List from 'components/CorporateSubscription/Events/list'
import NoResultPage from 'components/CorporateSubscription/NoResultPage'

import { media } from 'utils/style'

import { GetCorpSubscriberEventsQuery } from 'typings/contentService/graphql'

type Props = {
  events: GetCorpSubscriberEventsQuery['events'] | GetCorpSubscriberEventsQuery['roundTables'],
  title?: string,
}
const EventsContainer: React.FunctionComponent<Props> = ({ events, title = 'Exclusive Events / Webinars' }) => {
  return (
    <PageContainer id='corporate-subscription-events-container'>
      <HeaderBar>
        <Title>{title}</Title>
      </HeaderBar>
      { events && events.length > 0
        ? <List events={events}/>
        : <NoResultPage />
      }
    </PageContainer>
  )
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 288px;
  ${media.mobileUp`
    max-width: 100%;
    min-width: 320px;
  `}
  ${media.tabletUp`
    width: 100%;
  `}
`

const HeaderBar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${media.tabletUp`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  `}
`

const Title = styled.div`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  align-self: auto;
  ${media.tabletUp`
    align-self: flex-end;
    margin-bottom: 0px;
  `}
`

export default EventsContainer
