import React, { useEffect } from 'react'
import { useQuery } from '@apollo/react-hooks'
import _get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import { useDispatch } from 'react-redux'

import { NewsletterList } from 'components/manage/newsletter'
import GET_SUBSCRIBED_NEWSLETTER from 'universal/apollo/d8/GET_SUBSCRIBED_NEWSLETTER.gql'

import * as appActions from 'states/actions/application'
import * as manageAction from 'states/actions/manage'
import * as userInfoActions from 'states/actions/user-info'

const NewsletterContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const { data } = useQuery(GET_SUBSCRIBED_NEWSLETTER)

  useEffect(() => {
    if (!isEmpty(data)) {
      const newsletterIds: string[] = []
      const subscriptionList: any[] = _get(data, 'user.newsletters.entities[0].subscriptions', [])
      _map(subscriptionList, (value) => {
        if (value.subscription && value.subscription.entityId && value.status) {
          newsletterIds.push(value.subscription.entityId)
        }
      })
      dispatch(manageAction.updateManageNewsletterIds(newsletterIds))
      const userOptIn = _get(data, 'user.optIn.entities[0]', {})
      const userOptIn3rd = _get(data, 'user.optIn3rd.entities[0]', {})
      dispatch(userInfoActions.updateUserOptIn(userOptIn.fieldOptInScmp))
      dispatch(userInfoActions.updateUser3rdOptIn(userOptIn3rd.fieldOptIn3rd))
      dispatch(userInfoActions.updateUserOptInId(userOptIn.entityId))
    }
  }, [data])

  useEffect(() => {
    dispatch(appActions.updateZendeskVisibility(false))
    return () => {
      dispatch(appActions.updateZendeskVisibility(true))
    }
  }, [])

  return (
    <NewsletterList/>
  )
}

export default NewsletterContainer
