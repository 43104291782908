import React from 'react'
import styled from 'styled-components'
import { FlexContainer } from 'components/flex'

type Props = {
  className?: string
  buttonTitle: string
  active?: boolean
  onClick?: () => Promise<void>
}

const MyProfileButton: React.FunctionComponent<Props> = ({ ...Props }) => {
  return (
    <ButtonContainer className={Props.className} onClick={Props.onClick}>
      <ButtonTitle>{Props.buttonTitle}</ButtonTitle>
    </ButtonContainer>
  )
}

export default MyProfileButton

const ButtonContainer = styled(FlexContainer)`
`
const ButtonTitle = styled.div`
  color: #222222;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
`
