import * as types from '../../constants/action-types'

export const updateUserUid = (uid: string) => ({ type: types.UPDATE_USER_UID, uid })
export const updateUserUuid = (uuid: string) => ({ type: types.UPDATE_USER_UUID, uuid })
export const updateUserUsername = (username: string) => ({ type: types.UPDATE_USER_USERNAME, username })
export const updateUserEmail = (email: string) => ({ type: types.UPDATE_USER_EMAIL, email })
export const updateUserFirstname = (firstname: string) => ({ type: types.UPDATE_USER_FRSTNAME, firstname })
export const updateUserLastname = (lastname: string) => ({ type: types.UPDATE_USER_LASTNAME, lastname })
export const updateUserSigninStatus = (isSignedIn: boolean) => ({ type: types.UPDATE_USER_SIGNIN_STATUS, isSignedIn })
export const updateUserUserPic = (userPic: string) => ({ type: types.UPDATE_USER_USERPIC, userPic })
export const updateUserSubscriptionData = (packageName: string, renewDate: string) => ({ type: types.UPDATE_USER_SUBSCRIPTION_DATA, packageName, renewDate })
export const updateIsChurnSubscriber = () => ({ type: types.UPDATE_USER_IS_CHURN_SUBSCRIBER })
export const updateIsPianoSubscriberDataReady = () => ({ type: types.UPDATE_ISPIANOSUBSCRIBER_DATA_READY })
export const updateUserOptIn = (optIn: boolean) => ({ type: types.UPDATE_USER_OPTIN, optIn })
export const updateUser3rdOptIn = (optIn3rd: boolean) => ({ type: types.UPDATE_USER_3RD_OPTIN, optIn3rd })
export const updateUserOptInId = (optInId: string) => ({ type: types.UPDATE_USER_OPTINID, optInId })
export const updateUserUserLvl = (userLvl: string[]) => ({ type: types.UPDATE_USER_USERLVL, userLvl })
export const updateUserUserScs = (userScs: string[]) => ({ type: types.UPDATE_USER_USERSCS, userScs })
export const updateUserUserCreated = (userCreated: string[]) => ({ type: types.UPDATE_USER_USERCREATED, userCreated })
export const updateUserUserSource = (userSource: string) => ({ type: types.UPDATE_USER_USERSOURCE, userSource })
export const updateUserIsScmpUser = (isScmpUser: boolean) => ({ type: types.UPDATE_USER_ISSCMPUSER, isScmpUser })
export const updateUserKeywordAlerts = (keywordAlerts: boolean) => ({ type: types.UPDATE_USER_KEYWORD_ALERTS, keywordAlerts })
export const updateUserIsShowRosetta = (isShowRosetta: boolean) => ({ type: types.UPDATE_USER_ISSHOWROSETTA, isShowRosetta })
