import copy from 'copy-to-clipboard'
import moment from 'moment'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import { ReactComponent as IconBookmark } from 'assets/svg/icon_bookmark.svg'
import { ReactComponent as DefaultAuthor } from 'assets/svg/icon-defaultAuthor.svg'
import { ReactComponent as EmailLogo } from 'assets/svg/icon-email.svg'
import { ReactComponent as FacebookLogo } from 'assets/svg/icon_fb_share.svg'
import { ReactComponent as CopyLinkLogo } from 'assets/svg/icon-share-copylink.svg'
import { ReactComponent as TwitterLogo } from 'assets/svg/icon-share-twitter.svg'

import { FlexContainer } from 'components/flex'

import { SCMP_COM_HOST, SCMP_COM_PROTOCOL, TWITTER_NAME } from 'config/app.config'

import { OnClickRemoveButton } from 'containers/Bookmark'

import { media } from 'utils/style'
import { ShareWidgetMethod, triggerGA4GtmEvent } from '../../utils/tracking'

type Props = {
  className?: string,
  article: any,
  index: number,
  modalIndex?: number,
  updateModelIndex: (number:number) => void,
  onClickRemoveButton: OnClickRemoveButton,
}
const BookmarkItemComponent: React.FunctionComponent<Props> = ({ ...Props }) => {
  const { article, updateModelIndex, index, modalIndex, onClickRemoveButton } = Props
  const [showModal, isShowModal] = useState<boolean>(false)
  const [showCopied, updateShowCopied] = useState<boolean>(false)
  const urlAlias = _get(article, 'urlAlias', '')
  const entityId = _get(article, 'entityId', '')
  const socialHeadline = _get(article, 'socialHeadline', '')
  const headline = _get(article, 'headline', '')
  const authors = _get(article, 'authors', []).slice(0, 1)

  useEffect(() => {
    if (modalIndex !== index && showModal) {
      isShowModal(!showModal)
    }
  }, [modalIndex])
  const toggleModal = () => {
    isShowModal(!showModal)
    updateModelIndex(index)
  }

  const redirectToAuthor = (urlAlias: string) => {
    const path = `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}${urlAlias}`
    window.open(path, '_blank')
  }

  const getAuthorsImage = () => {
    const authorsList = _map(authors, (author: any, i: number) => {
      const image = _get(author, 'entity.image.size_118x118.url', '')
      const key = _get(author, 'entity.entityId', '')
      const urlAlias = _get(author, 'entity.urlAlias.path', '')
      if (image !== '') {
        return <AuthorImageContainer key={`author_${key}_${i}`} onClick={() => redirectToAuthor(urlAlias)}><StyledAuthorImage src={image} /></AuthorImageContainer>
      } else {
        return <AuthorImageContainer key={`author_${key}_${i}`} onClick={() => redirectToAuthor(urlAlias)}><IconDefaultAuthor/></AuthorImageContainer>
      }
    })

    return authorsList
  }

  const redirectToArticle = () => {
    triggerGA4GtmEvent({
      category: 'bookmark',
      subcategory: '',
      action: 'click',
      customized_parameters: {
        action_type: 'read',
        article_id: entityId,
        trigger_point: 'my_account',
      },
    })

    const urlAlias = _get(article, 'urlAlias', '')
    const path = `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}${urlAlias}`
    window.open(path, '_blank')
  }

  const getAuthorsName = () => {
    const authorsListName = _map(authors, (author: any, i: number) => {
      const name = _get(author, 'entity.name', '')
      const key = _get(author, 'entity.entityId', '')
      const urlAlias = _get(author, 'entity.urlAlias.path', '')
      return <AuthorName key={`author${key}_${i}`} onClick={() => redirectToAuthor(urlAlias)}>{name}</AuthorName>
    })

    return authorsListName
  }

  const getArticlePublishDate = () => {
    const publishedDate = _get(article, 'publishedDate', '')
    if (!_isEmpty(publishedDate)) {
      return moment(publishedDate, 'x').format('D MMM YYYY - hh:mmA')
    }
    return ''
  }

  const getArticleImage = () => {
    const image = _get(article, 'images[0].size_118x118.url', '')
    return <ArticleImage src={image}></ArticleImage>
  }

  const getURLWithUTMSearchParameters = ({
    path,
    utmCampaign,
    utmSource,
  }: {
    path: string
    utmCampaign: string,
    utmSource: string,
  }) => {
    const updateURLObject = new URL(path)
    const searchParameters = updateURLObject.searchParams
    searchParameters.set('utm_source', utmSource)
    searchParameters.set('utm_medium', 'share_widget')
    searchParameters.set('utm_campaign', utmCampaign)
    return updateURLObject.toString()
  }

  const onClickShareButton = (type: string = '') => {
    const path = `${SCMP_COM_PROTOCOL}://${SCMP_COM_HOST}${urlAlias}`
    const data = {
      shareUrl: getURLWithUTMSearchParameters({ path, utmCampaign: entityId, utmSource: type }),
      shareTitle: socialHeadline,
      title: socialHeadline,
      subheading: socialHeadline,
    }

    let href
    let options = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,'
    let nameWindow = '_self'

    const trackingShareMethodMap: Record<string, ShareWidgetMethod> = {
      Facebook: 'facebook',
      Twitter: 'twitter',
      link: 'copy_link',
      email: 'email',
    }

    triggerGA4GtmEvent({
      category: 'share',
      subcategory: '',
      action: 'click',
      customized_parameters: {
        method: trackingShareMethodMap[type],
        position: 'bookmark',
        share_url: path,
      },
    })

    switch (type) {
      case 'Facebook':
        href = 'https://www.facebook.com/sharer/sharer.php?u=' + data.shareUrl + '&src=sdkpreparse'
        options += 'height=300,width=600'
        nameWindow = '_blank'
        window.open(href, nameWindow, options)
        break
      case 'Twitter':
        href = `https://twitter.com/intent/tweet?text=${data.shareTitle}&url=${encodeURIComponent(data.shareUrl)}&related=&via=${TWITTER_NAME}`
        options += 'height=254,width=600'
        nameWindow = '_blank'
        window.open(href, nameWindow, options)
        break
      case 'link':
        copy(getURLWithUTMSearchParameters({ path, utmCampaign: entityId, utmSource: 'copy_link' }))
        updateShowCopied(true)
        setTimeout(() => {
          updateShowCopied(false)
        }, 1000)
        break
      case 'email':
        href = `mailto:?subject=Shared from South China Morning Post&body=${data.title}%0D%0A${data.shareUrl}`
        nameWindow = '_blank'
        options += 'height=300,width=600'
        window.open(href, nameWindow, options)
        break
      default:
        break
    }
  }

  return (
    <>
    { !_isEmpty(article.entityId)
      ? (<BookmarkItem flexDirection='row' className={Props.className}>
        <Details flexDirection='row' justifyContent="space-between">
          <LeftContainer flexDirection='column'>
            <AuthorContainer alignItem="center">
              <AuthorImage grid={authors.length}>{getAuthorsImage()}</AuthorImage>
              <AuthorNameContainer>
                {getAuthorsName()}
              </AuthorNameContainer>
            </AuthorContainer>
            <ArticleHeadline onClick={redirectToArticle}>{ headline }</ArticleHeadline>
            <ArticlePublishDate>{getArticlePublishDate()}</ArticlePublishDate>
          </LeftContainer>
          <RightContainer flexDirection='column' alignItem="flex-end">
            <MoreActionContainer>
              <DotContainer onClick={toggleModal} alignItem="center">
                <Dot active={modalIndex === index && showModal} />
                <Dot active={modalIndex === index && showModal} />
                <Dot active={modalIndex === index && showModal} />
              </DotContainer>
              { showModal &&
                <ActionModal wrap="nowrap">
                  <ActionButton>
                    <RemoveBookmarkContainer wrap="nowrap" onClick={() => onClickRemoveButton({ entityId, urlAlias })}>
                      <IconBookmarkContainer/>Remove
                    </RemoveBookmarkContainer>
                  </ActionButton>
                  <ActionHorizontalRuler/>
                  <ActionButton wrap="nowrap">
                    Share
                    <ShareFbButton onClick={() => onClickShareButton('Facebook')}/>
                    <ShareTwitterButton onClick={() => onClickShareButton('Twitter')}/>
                    <ShareLinkContainer>
                      <ShareLinkButton onClick={() => onClickShareButton('link')} />
                      { showCopied && <ShareLinkSuccessMessage>Link copied</ShareLinkSuccessMessage> }
                    </ShareLinkContainer>
                    <ShareEmailButton onClick={() => onClickShareButton('email')}/>
                  </ActionButton>
                </ActionModal>
              }
            </MoreActionContainer>

            <ArticleImageContainer onClick={redirectToArticle}>
              {getArticleImage()}
            </ArticleImageContainer>
          </RightContainer>
        </Details>
      </BookmarkItem>)
      : null }
    </>
  )
}

const BookmarkItem = styled(FlexContainer)`
  padding: 16px;
  margin: 4px 8px;
  background-color: #FFFFFF;
  width: calc(100% - 48px);
  border-radius: 4px;
`
const Details = styled(FlexContainer)`
  flex: 1 1 0;
  cursor: pointer;
`

const LeftContainer = styled(FlexContainer)`
  max-width: calc(100% - 82px);
  width: auto;
  cursor: pointer;
  ${media.tabletUp`
    max-width: 484px;
  `}
`
const RightContainer = styled(FlexContainer)`
  max-width: 60px;
  width: 100%;
  ${media.tabletUp`
    max-width: 83px;
    justify-content: flex-start;
  `}
`

const AuthorContainer = styled(FlexContainer)`
  cursor: pointer;
`

const AuthorNameContainer = styled(FlexContainer)`
`

const MoreActionContainer = styled(FlexContainer)`
  position: relative;
`

const DotContainer = styled(FlexContainer)`
  height: 20px;
  cursor: pointer;
`
const ArticleImageContainer = styled.div`
  width: 60px;
  height: 60px;
  margin-top: 12px;
  cursor: pointer;
`

const ActionModal = styled(FlexContainer)`
  position: absolute;
  padding: 20px 24px;
  right: -4px;
  top: 20px;
  background: #fff;
  border-radius: 6px;
  width: 335px;
  box-shadow: 0 0 2px 0 rgba(0,0,0,0.14), 0 2px 2px 0 rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2);
  box-sizing: border-box;
`

const ActionButton = styled(FlexContainer)`
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  font-family: Roboto;
`

interface DotStyle {
  active?: boolean
}

const Dot = styled.div<DotStyle>`
  border-radius: 50%;
  display: inline-block;
  background: ${props => (props.active ? '#000' : '#999999')};
  width: 4px;
  height: 4px;
  margin-left: 2px;
`

const ArticleImage = styled.img`
  width: 100%;
  height: 100%;
`

interface AuthorImageStyle {
  grid?: string
}

const AuthorImage = styled.div<AuthorImageStyle>`
  margin-right: 8px;
  display: grid;
  grid-template-columns: ${props => (props.grid ? `repeat(${props.grid}, 20px)` : '')};
`
const AuthorImageContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-right:8px;
`
const AuthorName = styled.div`
  font-weight: 900;
  color: #001246;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
`

const ArticlePublishDate = styled.div`
  font-weight: 400;
  color: #999999;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 8px;
  cursor: auto;
`

const StyledAuthorImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  box-sizing: border-box;
`

const ArticleHeadline = styled.div`
  margin-top: 12px;
  color: #000000;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 21px;
  font-weight: 900;
  cursor: pointer;
`

const IconBookmarkContainer = styled(IconBookmark)`
  width:18px;
  height:21px;
  margin: 0 10px 0 0;
  g {
    fill: #001246;
  }
`
const IconDefaultAuthor = styled(DefaultAuthor)`
  width:100%;
  height:100%;
  border-radius: 50%;
  box-sizing: border-box;
`

const RemoveBookmarkContainer = styled(FlexContainer)`
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  cursor: pointer;
  font-family: Roboto;
`

const ActionHorizontalRuler = styled.div`
  border-left: 1px solid #BBBBBB;
  margin: 0 14px;
`

const ShareFbButton = styled(FacebookLogo)`
  width: 10px;
  height: 18px;
  margin-right: 18px;
  margin-left: 16px;
  cursor: pointer;
  fill:#001246;
`

const ShareTwitterButton = styled(TwitterLogo)`
  width: 17px;
  height: 18px;
  margin-right: 18px;
  cursor: pointer;
  fill:#001246;
`

const ShareEmailButton = styled(EmailLogo)`
  width: 16px;
  cursor: pointer;
  height: 18px;
  g {
    fill: #001246;
  }
`

const ShareLinkButton = styled(CopyLinkLogo)`
  width: 16px;
  height: 18px;
  cursor: pointer;
  margin-right: 18px;
  g {
    fill: #001246;
  }
`

const ShareLinkContainer = styled.div`
  position: relative;
`
const ShareLinkSuccessMessage = styled.div`
  position: absolute;
  font-size: 13px;
  color: white;
  line-height: 16px;
  padding: 7px 10px;
  width: 92px;
  height:30px;
  border-radius: 15px;
  transform:  translate(-43%,-100%);
  left: 0;
  top: -8px;
  background: rgba(0,0,0,0.75);
  text-align: center;
  box-sizing: border-box;
  font-family: Roboto;
`

export default BookmarkItemComponent
