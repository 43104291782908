import styled from 'styled-components'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'states/reducers'
import { triggerGtmEvent } from 'utils/tracking'
import * as subscriptionStatusActions from 'states/actions/subscription-status'
import RosettaSDK from '@product/rosetta-sdk'
import {
  DataLayerEventAction,
  DataLayerEventCategory,
  DataLayerGtmCustomEventName,
} from 'config/constants'
const AutoRenewConfirmationPopup: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const {
    firstname,
    username,
  } = useSelector((state: AppState) => state.userInfo)

  const engine = RosettaSDK.rulesEngine
  const showAutoRenewWidget: boolean = engine.getOutcome('ui.account.showAutoRenewWidget')
  const dayLeft: number = engine.getOutcome('user.overallSubscriptionDaysLeft')

  useEffect(() => {
    triggerGtmEvent(
      DataLayerEventAction.SUBSCRIPTION_EXPIRY_REMINDERS_IMPRESSION,
      DataLayerEventCategory.SUBSCRIPTION_MANAGEMENT,
      DataLayerGtmCustomEventName.TRACKING,
      dayLeft.toString(),
    )
  }, [])

  const autoRenewElement = (
    <Overlay>
      <OverlayInner>
        <OverlayTitle>
          {firstname ? `${firstname}`.split('@')[0] : username.split('@')[0]}, thank you for choosing auto-renew as your subscription preference.
        </OverlayTitle>
        <OverlayContent>
          We hope you continue to enjoy our award-winning journalism and the SCMP’s exclusive subscriber benefits.
        </OverlayContent>
        <CloseButton onClick={() => dispatch(subscriptionStatusActions.updateAutoRenew(true))}>Close</CloseButton>
      </OverlayInner>
    </Overlay>
  )

  return showAutoRenewWidget ? autoRenewElement : null
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9999;
  display: block;
`

const OverlayInner = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 24px 32px;
  order: 1px solid #BBBBBB;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 271px;
  min-width: 294px;
  text-align: center;
`

const OverlayTitle = styled.div`
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  color: #001246;
  font-weight: bold;
  margin-bottom: 16px;
`

const OverlayContent = styled.div`
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #666666;
  margin-bottom: 16px;
`

const CloseButton = styled.button`
  font-family: Roboto;
  border: none;
  margin: 0 auto;
  background: #4585FF;
  border-radius: 2px;
  padding: 10px 24px;
  text-transform: uppercase;
  color: white;
  width: 148px;
  text-align: center;
  cursor: pointer;
`

export default AutoRenewConfirmationPopup
