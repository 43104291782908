import React from 'react'
import styled from 'styled-components'

type Props = {
  className?: any,
  top?: string | null
  toggleHandler: (eventName: string) => void,
}
interface PasswordTextStyle {
  top?: string
}
const ShowPasswordButton: React.FunctionComponent<Props> = ({ ...Props }) => {
  return (
    <ShowPasswordText
      top={(Props.top ? Props.top : '12px')}
      onMouseOut={() => Props.toggleHandler('onMouseOut')}
      onMouseDown={() => Props.toggleHandler('onMouseDown')}
      onMouseUp={() => Props.toggleHandler('onMouseUp')}
      onTouchStart={() => Props.toggleHandler('onTouchStart')}
      onTouchEnd={() => Props.toggleHandler('onTouchEnd')}>show</ShowPasswordText>
  )
}

const ShowPasswordText = styled.p<PasswordTextStyle>`
  position: absolute;
  right: 10px;
  top: ${props => props.top};
  color: #4585FF;
  font-family: Roboto;
  font-size: 12px;
  line-height: 14px;
  padding: 0;
  margin: 0;
  cursor: pointer;
`

export default ShowPasswordButton
