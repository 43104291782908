import axios, { AxiosError } from 'axios'
import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import _includes from 'lodash/includes'
import _forEach from 'lodash/forEach'
import 'navigator.locks'

import {
  GRAPHQL_CONTENTSERVICE_APIKEY,
  CORP_SUB_TIER_API_URI,
} from 'config/app.config'

import { SCMP_USER_LEVEL_ID } from 'config/constants'

export function isScmpUser (email: string) {
  const scmpEmailRegex = /^[A-Za-z0-9._%+-]+@scmp.com$/
  return scmpEmailRegex.test(email)
}

const userLevelMapping = (userLevels: any[] = []) => {
  const output: any[] = []
  _forEach(userLevels, (userLevel: any) => {
    const id = _get(userLevel, 'id')
    switch (id) {
      case SCMP_USER_LEVEL_ID.SCMP_EDITORIAL: {
        output.push('editorial')
        break
      }
      case SCMP_USER_LEVEL_ID.SCMP_EXPERT: {
        output.push('expert')
        break
      }
      case SCMP_USER_LEVEL_ID.SCMP_STAFF: {
        output.push('staff')
        break
      }
    }
  })
  return output
}

const userLevelMappingByName = (userLevels = []) => {
  // "SCMP Editorial", "SCMP Expert", "SCMP Staff"
  const output: any[] = []
  _forEach(userLevels, (userLevel: any) => {
    switch (userLevel) {
      case 'SCMP Editorial': {
        output.push('editorial')
        break
      }
      case 'SCMP Expert': {
        output.push('expert')
        break
      }
      case 'SCMP Staff': {
        output.push('staff')
        break
      }
      case 'SCMP+Editorial': {
        output.push('editorial')
        break
      }
      case 'SCMP+Expert': {
        output.push('expert')
        break
      }
      case 'SCMP+Staff': {
        output.push('staff')
        break
      }
      case 'YP': {
        output.push('yp')
        break
      }
      case 'Posties': {
        output.push('posties')
        break
      }
    }
  })
  return output
}

export function hasUserRole (userLevels: string[], role: string) {
  return _includes(userLevels, role)
}

export function getUserLevel (userInfo: any) {
  let userLevels = []
  if (!_isEmpty(_get(userInfo, 'lvl'))) {
    userLevels = userLevelMappingByName(_get(userInfo, 'lvl'))
  } else if (!_isEmpty(_get(userInfo, 'userLevel'))) {
    userLevels = userLevelMapping(_get(userInfo, 'userLevel'))
  }
  const isStaff = hasUserRole(userLevels, 'staff')
  const isEditorial = hasUserRole(userLevels, 'editorial')
  const isExpert = hasUserRole(userLevels, 'expert')
  const isYPUser = hasUserRole(userLevels, 'yp')
  const isPostiesUser = hasUserRole(userLevels, 'posties')
  // const isSubscriber = includes(userRoles, 'subscriber') || get(data, 'isSubscriber', false)
  const isMember = isStaff || isEditorial || isExpert

  return { isStaff, isEditorial, isExpert, isMember, isYPUser, isPostiesUser }
}

export async function getCorporateSubscriberTier (email: string) {
  try {
    const data = {
      email,
    }
    const corpSubscriberTier: any = await axios.post(`${CORP_SUB_TIER_API_URI}/tier`,
      data,
      {
        headers: {
          apikey: GRAPHQL_CONTENTSERVICE_APIKEY,
          'Content-Type': 'application/json',
        },
      })
    return _get(corpSubscriberTier, 'data.Tier', 'T0')
  } catch (error) {
    console.log('getCorporateSubscriberTier error', error)
  }
}

export async function refreshJWT () {
  const postPromise = async () => {
    try {
      // call refresh token
      return await axios.post('/refreshToken', {}, { headers: { 'Content-Type': 'application/json' }, withCredentials: true })
    } catch (error) {
      const { response } = error as Required<AxiosError<{ code: number; message: string }>>
      if (response && response.data.message === 'missing scmp_at or scmp_rt') {
        // Check if the refresh token is already changed by other request, if yes not need to be logout
        window.location.href = '/logout'
      }
    }
  }
  return navigator.locks.request('refreshToken', async () => await postPromise())
}
