import React from 'react'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

import FieldTitle from './FieldTitle'

import InfoIcon from 'assets/svg/icon_info.svg'

type Props = {
  id?: string,
  title: string,
  mandatory?: boolean,
  value?: string,
  error?: string,
  disabled: boolean,
  onChange?: React.ChangeEventHandler<HTMLInputElement>,
}

const InputField: React.FunctionComponent<Props> = ({ id, title, mandatory, value, error, disabled, onChange }) => {
  return (
    <InputContainer>
      <FieldTitle mandatory={mandatory}>{ title }</FieldTitle>
      { disabled && InfoIcon && title === 'First Name' &&
        <Tooltip>
          <span data-tip="React-tooltip" data-for={id ? id + '-tooltip' : 'input-field-tooltip'}>
            <SvgImg src={InfoIcon}/>
          </span>
          <CustomReactTooltip
            id={id ? id + '-tooltip' : 'input-field-tooltip'}
            place="bottom"
            type="light"
            effect="solid"
            backgroundColor={'#f6f6f6'}
            className='custom-color'
            border={true}
            borderColor={'#ccc'}
            textColor={'#222'}
            clickable={true}
            offset={{ left: -69 }}
            data-html={true}>
            Your <b>first name</b> and <b>last name</b> has been locked as part of our account verification process. To change this, first disconnect your linked Facebook or Google account. Then, edit and save your SCMP account profile.
          </CustomReactTooltip>
        </Tooltip>
      }
      {error
        ? (
          <ErrorMsg>{error}</ErrorMsg>
          )
        : null}
      <Input className={error ? 'error' : ''} value={value} onChange={onChange} disabled={disabled} />
    </InputContainer>
  )
}

const InputContainer = styled.div`
  text-align: left;
`

const ErrorMsg = styled.div`
  margin: 1px 0 7px 0;
  color: #EB4254;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.14px;
  line-height: 14px;
`

const Tooltip = styled.div`
  display: inline-block;
`

const SvgImg = styled.img`
  display: inline-block;
  margin-left: 8px;
  vertical-align: bottom;
  width: 16px;
`

// plugin override
const CustomReactTooltip = styled(ReactTooltip)`
  width: 257px;
  border: 1px solid #ccc;
  font-size: 14px !important;
  line-height: 18px;
  padding: 20.5px !important;
  font-family: Roboto;
  opacity: 1 !important;
  ::before {
    left: 28% !important;
  }
  ::after {
    left: 28% !important;
  }
`

const Input = styled.input`
  box-sizing: border-box;
  padding: 0 13px;
  width: 100%;
  height: 37px;
  border: 1px solid #D7D7D7;
  border-radius: 2px;
  outline: none;
  color: #666666;
  font-family: Roboto;
  font-size: 16px;
  letter-spacing: 0.19px;
  line-height: 19px;

  &:disabled {
    background: #F2F2F2;
  }

  &.error {
    border: 1px solid #EB4254;
    color: #EB4254;
  }
`

export default InputField
