import { useQuery } from '@apollo/react-hooks'
import axios from 'axios'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import fromUnixTime from 'date-fns/fromUnixTime'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { ReactComponent as IconDesktop } from 'assets/svg/icon_desktop.svg'
import { ReactComponent as IconPhone } from 'assets/svg/icon_phone.svg'
import { ReactComponent as IconTablet } from 'assets/svg/icon_tablet.svg'

import { FlexContainer } from 'components/flex'
import { Loading } from 'components/Loading'

import { DataLayerEventCategory, DataLayerEventAction, DataLayerGtmCustomEventName } from 'config/constants'

import GET_DEVICE_LIST from 'universal/apollo/d8/GET_DEVICE_LIST.gql'

import { media } from 'utils/style'
import { triggerGA4GtmEvent, triggerGtmEvent } from 'utils/tracking'

const DeviceListContainer: React.FunctionComponent = () => {
  const [deviceList, setDeviceList] = useState<any>([])
  const { loading, data, refetch } = useQuery(GET_DEVICE_LIST, { notifyOnNetworkStatusChange: true })

  const listPreprocess = (data: any) => {
    const originalDeviceList = JSON.parse(_get(data, 'currentUserContext.deviceList', '{}'))
    if (originalDeviceList) {
      const processedDeviceList = originalDeviceList.reduce((acc: any, curr: any) => {
        if (curr.current === true) {
          return [curr, ...acc]
        } else {
          return [...acc, curr]
        }
      }, [])
      setDeviceList(processedDeviceList)
    }
  }

  useEffect(() => {
    if (data) {
      listPreprocess(data)
    }
  }, [data])

  const deviceCategory = (brand: string, deviceType: string, osName: string) => {
    if (!_isEmpty(brand)) {
      switch (brand) {
        case 'Apple':
          switch (deviceType) {
            case 'desktop':
              return 'Mac'
            case 'smartphone':
              return 'iPhone'
            case 'tablet':
              return 'iPad'
          }
          break
        default:
          return `${brand} ${deviceType}`
      }
    } else if (_isEmpty(brand) && !_isEmpty(osName)) {
      return `${osName} ${deviceType}`
    } else if (_isEmpty(brand) && _isEmpty(osName)) {
      return 'Unknown Device'
    }
  }

  const deviceImage = (deviceType: string) => {
    switch (deviceType) {
      case 'desktop':
        return <IconDesktop />
      case 'tablet':
        return <IconTablet />
      case 'smartphone':
        return <IconPhone />
      default:
        return <IconDesktop />
    }
  }

  const removeDevice = ({ deviceName, hmac, id }: { deviceName: string | undefined, hmac: string, id: string }) => {
    triggerGA4GtmEvent({
      category: 'myaccount',
      subcategory: 'manage_devices_remove',
      action: 'click',
      customized_parameters: {
        device: deviceName ?? '',
      },
    })

    axios.post('/token/revoke', {
      items: [
        {
          id,
          hmac,
        },
      ],
    })
      .then(function (response) {
        const data = _get(response, 'data[0]')
        if (data.success) {
          // refetch the list again
          triggerGtmEvent(
            DataLayerEventAction.REMOVE_DEVICE,
            DataLayerEventCategory.MY_ACCOUNT,
            DataLayerGtmCustomEventName.TRACKING,
            '',
          )
          refetch()
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const dateFunction = (browser: string, date: number) => {
    if (browser) {
      return `${browser}, ${formatDistanceToNowStrict(fromUnixTime(date), { addSuffix: true })}`
    }
    return `${formatDistanceToNowStrict(fromUnixTime(date), { addSuffix: true })}`
  }

  const deviceListComp =
  deviceList.map((device: any) => {
    const deviceName = deviceCategory(_get(device, 'device_info.brand'), _get(device, 'device_info.device'), _get(device, 'device_info.os.name'))
    return (
      <DeviceItemContainer key={device.id}>
        <DeviceWrapper>
          <DeviceIcon>
            { _get(device, 'device_info.device')
              ? <> {deviceImage(_get(device, 'device_info.device'))} </>
              : <IconDesktop />
            }
          </DeviceIcon>
          <DeviceInfoDetails flexDirection="column">
            <DeviceNameContainer>
              <DeviceName> {deviceName} </DeviceName>
            </DeviceNameContainer>
            <DeviceLastSeen>
              { _get(device, 'current')
                ? <> <CurrentDot /> Current Device </>
                : <> {dateFunction(_get(device, 'device_info.client.name'), _get(device, 'created'))} </>
              }
            </DeviceLastSeen>
          </DeviceInfoDetails>
        </DeviceWrapper>
        { _get(device, 'current')
          ? null
          : <RemoveDevice onClick={() => removeDevice({ deviceName, hmac: _get(device, 'hmac', ''), id: _get(device, 'id', '') })}> REMOVE </RemoveDevice>
        }
      </DeviceItemContainer>
    )
  })

  return (
    <DeviceListBlock>
      { loading
        ? <LoadingWrapper><LoadingContainer></LoadingContainer></LoadingWrapper>
        : <> { deviceListComp }</>
      }
    </DeviceListBlock>
  )
}

const DeviceListBlock = styled(FlexContainer)`
  max-width: 100%;
  width: 100%;
  margin: 32px 0 0 0;
  ${media.tabletUp`
    margin: 30px 0 0 0;
  `}
`

const DeviceItemContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;
  margin-top: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #D2D2D2;
`

const DeviceWrapper = styled(FlexContainer)`
`

const DeviceIcon = styled.div`
  width: 14px;
  height: 22px;
  background: green;
  align-self: center;
`

const DeviceInfoDetails = styled(FlexContainer)`
  margin-left: 19px;
`

const DeviceNameContainer = styled(FlexContainer)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`

const DeviceName = styled.div`
`
const DeviceLastSeen = styled(FlexContainer)`
  margin-top: 2px;
  font-family: Roboto;
  font-size: 12px;
  line-height: 16.8px;
  color: #666666;
  align-items: center;
`

const CurrentDot = styled.div`
  background: #34B879;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 6px;
`

const RemoveDevice = styled.div`
  align-self: center;
  border: 1px solid #4585FF;
  border-radius: 2px;
  padding: 4px;
  color: #4585FF;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
`

const LoadingWrapper = styled.div`
  width: 100%;
  text-align: center;
`

const LoadingContainer = styled(Loading)`
  margin-top: 20px;
`

export default DeviceListContainer
