import styled from 'styled-components'
import FlexCustomizedProps from 'typings/flex'

const FlexContainer = styled.div<FlexCustomizedProps>`
  display: flex;
  flex-wrap: ${({ wrap }) => wrap || 'wrap'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItem }) => alignItem || 'stretch'};
`

export default FlexContainer
